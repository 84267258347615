<div *ngIf="data && data.dataMap" class="wrapper">
  <div class="webkit">

    <app-page1 [data]="data"></app-page1>
    

    <app-page2 [data]="data"></app-page2>

    <app-page3 [data]="data"></app-page3>

    <app-page4 [data]="data"></app-page4>
    
    <app-page5 [data]="data"></app-page5>
   
   
    
      <app-page6></app-page6>
      <app-footer [footerData]="data.dataMap"></app-footer>
      <div class="text-center pg-num">
        6
      </div>

      <app-page7 [data]="data"></app-page7>
      <app-footer [footerData]="data.dataMap"></app-footer>
      <div class="text-center pg-num">
        7
      </div>

      <app-page8></app-page8>
      <app-footer [footerData]="data.dataMap"></app-footer>
      <div class="text-center pg-num">
        8
      </div>

      <app-page9></app-page9>
      <app-footer [footerData]="data.dataMap"></app-footer>
      <div class="text-center pg-num">
        9
      </div>

      <app-page10 [data]="data"></app-page10> 
      <app-footer [footerData]="data.dataMap"></app-footer>
      <div class="text-center pg-num">
        10
      </div>
    
      <app-page11 [data]="data"></app-page11>
      <app-footer [footerData]="data.dataMap"></app-footer>
      <div class="text-center pg-num">
        11
      </div>

      <app-page12 [data]="data"></app-page12>
      <app-footer [footerData]="data.dataMap"></app-footer>
      <div class="text-center pg-num">
        12
      </div>
      <app-page13 [data]="data"></app-page13>
      <app-footer [footerData]="data.dataMap"></app-footer>
      <div class="text-center pg-num">
        13
      </div>
      <app-page14 [data]="data"></app-page14>
      <app-footer [footerData]="data.dataMap"></app-footer>
      <div class="text-center pg-num">
        14
      </div>
      <app-page15></app-page15>
      <app-footer [footerData]="data.dataMap"></app-footer>
      <div class="text-center pg-num">
        15
      </div>
      <app-page16 [data]="data"></app-page16>
      <app-footer [footerData]="data.dataMap"></app-footer>
      <div class="text-center pg-num">
        16
      </div>
      <app-page17 [data]="data"></app-page17>
      <app-footer [footerData]="data.dataMap"></app-footer>
      <div class="text-center pg-num">
        17
      </div>
      <app-page18 [data]="data"></app-page18>
      <app-footer [footerData]="data.dataMap"></app-footer>
      <div class="text-center pg-num">
        18
      </div>
     <app-page19 [data]="data"></app-page19>
     <app-footer [footerData]="data.dataMap"></app-footer>
     <div class="text-center pg-num">
       19
     </div>
     <app-page20 [data]="data"></app-page20>
     <app-footer [footerData]="data.dataMap"></app-footer>
     <div class="text-center pg-num">
       20
     </div>
     <app-page21 [data]="data"></app-page21>
     <app-footer [footerData]="data.dataMap"></app-footer>
     <div class="text-center pg-num">
       21
     </div>

     <app-page22 [data]="data"></app-page22>
     <app-footer [footerData]="data.dataMap"></app-footer>
     <div class="text-center pg-num">
       22
     </div>

     <app-page23 [data]="data"></app-page23>
     <app-footer [footerData]="data.dataMap"></app-footer>
     <div class="text-center pg-num">
       23
      </div>
      <app-page24></app-page24>
      <app-footer [footerData]="data.dataMap"></app-footer>
      <div class="text-center pg-num">
        24
       </div>

      <app-page25 [data]="data"></app-page25>      

      <app-page26 [data]="data"></app-page26>
      <app-footer [footerData]="data.dataMap"></app-footer>
      <div class="text-center pg-num">
        26
       </div>

      <app-page27 [data]="data"></app-page27>
      <app-footer [footerData]="data.dataMap"></app-footer>
      <div class="text-center pg-num">
        27
      </div>

       <app-page28 [data]="data"></app-page28>
       <app-footer [footerData]="data.dataMap"></app-footer>
       <div class="text-center pg-num">
        28
       </div>

      <app-page29 [data]="data"></app-page29>
      <app-footer [footerData]="data.dataMap"></app-footer>
      <div class="text-center pg-num">
        29
      </div>

       <app-page30 [data]="data"></app-page30>
      <app-footer [footerData]="data.dataMap"></app-footer>
      <div class="text-center pg-num">
        30
      </div>
      <app-page31 [data]="data"></app-page31>
      <app-footer [footerData]="data.dataMap"></app-footer>
      <div class="text-center pg-num">
        31
      </div>

      <app-page32 [data]="data"></app-page32>
      <app-footer [footerData]="data.dataMap"></app-footer>
      <div class="text-center pg-num">
        32
      </div>

      <app-page33></app-page33>
      <app-footer [footerData]="data.dataMap"></app-footer>
      <div class="text-center pg-num">
        33
      </div>
      <app-page34></app-page34>
      <app-footer [footerData]="data.dataMap"></app-footer>
      <div class="text-center pg-num">
        34
      </div>
      <app-page35></app-page35>
      <app-footer [footerData]="data.dataMap"></app-footer>
      <div class="text-center pg-num">
        35
      </div>
      <app-page36></app-page36>
      <app-footer [footerData]="data.dataMap"></app-footer>
      <div class="text-center pg-num">
        36
      </div>

      <app-page37></app-page37>
      <app-footer [footerData]="data.dataMap"></app-footer>
      <div class="text-center pg-num">
        37
      </div>

      <app-page38></app-page38>
      <app-footer [footerData]="data.dataMap"></app-footer>
      <div class="text-center pg-num">
        38
      </div>

      <app-page39></app-page39>
      <app-footer [footerData]="data.dataMap"></app-footer>
      <div class="text-center pg-num">
        39
      </div>
      <app-page40></app-page40>
      <app-footer [footerData]="data.dataMap"></app-footer>
      <div class="text-center pg-num">
        40
      </div>

      <app-page41></app-page41>
      <app-footer [footerData]="data.dataMap"></app-footer>
      <div class="text-center pg-num">
        41
      </div>

      <app-page42></app-page42>
      <app-footer [footerData]="data.dataMap"></app-footer>
      <div class="text-center pg-num">
        42
      </div>
    
      <app-page43></app-page43>
      <app-footer [footerData]="data.dataMap"></app-footer>
      <div class="text-center pg-num">
        43
      </div>

      <app-page44></app-page44>
      <app-footer [footerData]="data.dataMap"></app-footer>
      <div class="text-center pg-num">
        44
      </div>

      <app-page45 [data]="data"></app-page45>
      <app-footer [footerData]="data.dataMap"></app-footer>
      <div class="text-center pg-num">
        45
      </div>

     

      <app-page47 [data]="data"></app-page47>
      <app-footer [footerData]="data.dataMap"></app-footer>
      <div class="text-center pg-num">
        46
      </div>

      <!-- <app-page46></app-page46>
      <app-footer [footerData]="data.dataMap"></app-footer>
      <div class="text-center pg-num">
        47
      </div> -->

      <app-page48></app-page48>
      <app-footer [footerData]="data.dataMap"></app-footer>
      <div class="text-center pg-num">
        47
      </div>

      <app-page49 [data]="data"></app-page49>
      <app-footer [footerData]="data.dataMap"></app-footer>
      <div class="text-center pg-num">
        48
      </div>
   
    
    
   
    
  </div>
</div>

<!-- {{ data | json }} -->