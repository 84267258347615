<div class="forty-four-page mb-20 mt-40 text-right">
    <div class="text-right">
        <img class="logo-img" src="assets/images/Header.png" />
    </div>

    <div class="mt-20 blue-header">
        THREE MAIN FINGERPRINT TYPES & THEIR BASIC VARIANTS 
    </div>

    <div class="mt-20 blue-header-caption">
        WHORLS
    </div>

    <div class="mt-20 flex-row">
        <div class="flex-1">
            <img class="finger-img" src="assets/images/Page 44/page44-whorls.PNG" />
        </div>
        <div class="flex-5 font-size-11">
            Whorls account for about 30 — 35 percent of total occurring patterns in the whole world's population. Whorl pattern indicates the person to be goal-oriented, strong-willed, independent, persistent & highly competitive. It signifies the intensity of a character and intelligence. A whorl pattern with higher ridge counts denotes higher levels of understanding of corresponding cognitive abilities and human behavior. Such kind of person loves tasks that involve dynamism, research, creation, and not just some mundane administrative job. They are easy on taking up new challenges every day which involves, execution of ideas, non-conventional methods of prosecution of methodology, etc. They actively seek out solitude and need their space for self-reflection. If this person has other types of patterns, preferably loops on the ring and middle fingers, a taste in artistic trends has been shown by them. Having a practical approach towards life, they do not tend to take life too easily. They remain productive in careers they aim for. The speech of people with a higher number of whorls is most of the time, politically correct. On the downside, they are often perceived to be stubborn, individualist & blunt. Hence, tender care needs to be taken when dealing with individuals having such patterns. 
        </div>
    </div>

    <div class="mt-20 center-heading">
        DIFFERENT VARIANTS OF WHORLS 
    </div>

    <div class="mt-20 flex-row">
        <div class="flex-1 ">
            <div class="bg-gray">WT</div>
            <div class="mt-2 bg-gray">WDO</div>
        </div>

        <div class="flex-1">
            <div class="bg-gray">WTO</div>
            <div class="mt-2 bg-gray">WDR</div>
            
        </div>

        <div class="flex-1">
            <div class="bg-gray">WTR</div>
            <div class="mt-2 bg-gray">WC</div>
        </div>

        <div class="flex-1">
            <div class="bg-gray">WS</div>
            <div class="mt-2 bg-gray">WCO</div>
        </div>

        <div class="flex-1">
            <div class="bg-gray">WSO</div>
            <div class="mt-2 bg-gray">WCR</div>
        </div>

        <div class="flex-1">
            
            <div class="bg-gray">WSR</div>
            <div class="mt-2 bg-gray">WI</div>
        </div>

        <div class="flex-1">
            
            <div class="bg-gray">WE</div>
            <div class="mt-2 bg-gray">WIO</div>
        </div>

        <div class="flex-1">
            
            <div class="bg-gray">WEO</div>
            <div class="mt-2 bg-gray">WIR</div>
        </div>

        <div class="flex-1">
            
            <div class="bg-gray">WER</div>
            <div class="mt-2 bg-gray">WPE</div>
        </div>

        <div class="flex-1">
            
            <div class="bg-gray">WES</div>
            <div class="mt-2 bg-gray">WPR</div>
        </div>

        <div class="flex-1">
            <div class="bg-gray">WESO</div>
            <div class="mt-2 bg-gray">WL</div>
        </div>

        <div class="flex-1">
            
            <div class="bg-gray">WESR</div>
            <div class="mt-2 bg-gray">WA</div>
        </div>

        <div class="flex-1">
            
            <div class="bg-gray">WD</div>
            <div class="mt-2 bg-gray">&nbsp;</div>
        </div>

    </div>

    <div class="mt-40 blue-header-caption">
        LOOPS
    </div>

    <div class="mt-20 flex-row">
        <div class="flex-1">
            <img class="finger-img" src="assets/images/Page 44/page44-loops.PNG" />
        </div>
        <div class="flex-5 font-size-11">
            Loops are the most commonly occurring features on an individual's fingerprints as well as in an arbitrary sample space of several fingerprints. They account for about 60 — 65 percent of total occurring patterns in the whole world's population. Primarily of easy-going nature, individuals with loops have easiness of expressing themselves and are better at communication skills. They are sociable, emotionally responsive & adjustable. They are effective learners with strong imitative abilities. They can fare well in almost every aspect. They are happy to go with the flow, can maintain a balance between serious as well as humorous sides of matters. They fare better at jobs that require a change of place and environment because of their adaptable nature but resist unpredictable circumstances. If an individual has loops on all his fingers, that person can survive most of the unfavorable conditions. Although, such people are much of humanitarian natured, but can revolt back if not given prior importance. If there are six or more loops in a subject's hands, this signifies easy going affectionate nature, who can easily understand and respond to others' mood changes. They are adaptable and show emotional plasticity or relaxation. On the downside, a loop-oriented person is a bit risk-avoiding, and lack assertiveness. 
        </div>
    </div>

    <div class="mt-20 center-heading">
        DIFFERENT VARIANTS OF LOOPS 
    </div>

    <div class="mt-20 flex-row">
        <div class="flex-1 ">
            <div class="bg-gray">UL</div>
        </div>

        <div class="flex-1">
            <div class="bg-gray">ULH</div>
        </div>

        <div class="flex-1">
            <div class="bg-gray">RL</div>
        </div>

        <div class="flex-1">
            <div class="bg-gray">RLH</div>
        </div>

        <div class="flex-1">
            <div class="bg-gray">FL</div>
        </div>

        <div class="flex-1">
            <div class="bg-gray">RFL</div>
        </div>

        <div class="flex-1">
            <div class="bg-gray">PEL</div>
        </div>

        <div class="flex-1">
            <div class="bg-gray">RPEL</div>
        </div>

        <div class="flex-1">
            <div class="bg-gray">AL</div>
        </div>

    </div>


    <div class="mt-40 blue-header-caption">
        ARCHES
    </div>

    <div class="mt-20 flex-row">
        <div class="flex-1">
            <img class="finger-img" src="assets/images/Page 44/page44-arches.PNG" />
        </div>
        <div class="flex-5 font-size-11">
            Arches account for only 10 — 12 percent of the total fingerprints that are found in any random sample space. The arch pattern indicates the person to be highly hardworking and pragmatic. They are reflective learners, who tend to absorb their surroundings like a sponge and imitate the same through their thoughts & behavior. They prove skillful in doing anything that they are trained in and possess infinite elasticity for learning. They have the capability to improvise efficiently, using anything in the surrounding. They impute high energy in expressions & feelings but may be reserved in an unfamiliar environment. On the downside, Arch people tend to be impatient and emotionally erratic. Their thought patterns are Bi-polar by nature, often swinging on two extremes. They are inherently suspicious by nature and extremely judgmental about people & situations. Being defensive and conservative of their ideas they look to object to ambiguity & dishonesty in others. Since they are very cautious of safety & security, they tend to be doubtful by nature. 
        </div>
    </div>

    <div class="mt-20 center-heading">
        DIFFERENT VARIANTS OF ARCHES 
    </div>

    <div class="mt-20 flex-row">
        <div class="flex-1 ">
            <div class="bg-gray">AS</div>
        </div>

        <div class="flex-1">
            <div class="bg-gray">ASF</div>
        </div>

        <div class="flex-1">
            <div class="bg-gray">ASR</div>
        </div>

        <div class="flex-1">
            <div class="bg-gray">AT</div>
        </div>

        <div class="flex-1">
            <div class="bg-gray">ATF</div>
        </div>

        <div class="flex-1">
            <div class="bg-gray">ATR</div>
        </div>

        <div class="flex-1">
            <div class="bg-gray">AU</div>
        </div>

        <div class="flex-1">
            <div class="bg-gray">AR</div>
        </div>

        <div class="flex-1">
            <div class="bg-gray">AFL</div>
        </div>

        <div class="flex-1">
            <div class="bg-gray">AFLR</div>
        </div>

        <div class="flex-1">
            <div class="bg-gray">APE</div>
        </div>

        <div class="flex-1">
            <div class="bg-gray">APER</div>
        </div>

    </div>



</div>