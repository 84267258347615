<div class="fifthteenth-page mt-40">
    
        <div class="text-right">
            <img class="logo-img" src="assets/images/Header.png" />
        </div>
        
        <div class="sixteenth-page-hd1">
            <div class="sixteenth-page-headingsec">
                <div>CAREER PROSPECTS FOR THE STREAM OF MATHEMATICAL SCIENCE (PCM)</div>
            </div>
        </div>
        
        <div class="mt-5 sixteenth-page-fsize" style="display: flex; flex-wrap: wrap; justify-content: space-between; align-items: center; font-size: 15px;">
            <div class="sixteenth-page-careeroption">
                CAREER OPTIONS
            </div>
            <div class="sixteenth-page-careeroption1">
                %
            </div>
            <div class="sixteenth-page-careeroption">
                CAREER OPTIONS
            </div>
            <div class="sixteenth-page-careeroption1">
                %
            </div>
            <div class="sixteenth-page-careeroption">
                CAREER OPTIONS
            </div>
            <div class="sixteenth-page-careeroption1" style="border-right: none;">
                %
            </div>
            <div class="clear-both"></div>
        </div>

        <div class="mt-5">
            <div class="sectioncareer" style="display: flex; flex-wrap: wrap; justify-content: space-between;">
                <div class="seventeen-page-careerwidth32">
                    <div class="flex-row">
                        <div class="seventeen-page-width79" style="border-right: none;">
                            <div class="sixteenth-page-careerhuman">
                                <div>CHEMICAL ENGINEERING</div>
                            </div>
                        </div>
                        <!-- <div class="sixteenth-page-careerhumanperc1">
                            <div>10%</div>
                        </div> -->
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div>BIOMOLECULAR ENGG
                                    <span style="font-size: 8px;">Genetic, DNA and RNA
                                        (Genetic) Immunology - biomolecular or biochemical
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ch_1', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('ch_1', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('ch_1', data?.dataMap?.page17?.second_runnerup)  }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.chemical_engineering.ch_1}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div>MATERIALS ENGINEERING
                                    <span style="font-size: 8px;">Metallurgical, Ceramic, Polymer, 
                                        Crystal - Biomaterials
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ch_2', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('ch_2', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('ch_2', data?.dataMap?.page17?.second_runnerup)  }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.chemical_engineering.ch_2}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>

                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div>MOLECULAR ENGINEERING
                                    <span style="font-size: 8px;">Study of Molecular Properties
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ch_3', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('ch_3', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('ch_3', data?.dataMap?.page17?.second_runnerup)  }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.chemical_engineering.ch_3}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>

                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div>PROCESS ENGINEERING
                                    <span style="font-size: 8px;">Petroleum refinery, Plastics,
                                         Paper, Textile
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ch_4', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('ch_4', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('ch_4', data?.dataMap?.page17?.second_runnerup) }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.chemical_engineering.ch_4}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div>CORROSION ENGINEERING
                                    <span style="font-size: 8px;">Design & Implementation of Materials,
                                        Structures, Devices, Systems & Procedures to manage Corrosion_
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ch_5', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('ch_5', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('ch_5', data?.dataMap?.page17?.second_runnerup)  }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.chemical_engineering.ch_5}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79" style="border-right: none;">
                            <div class="sixteenth-page-careerbio1" style="padding: 10px 5px;">
                                <div>CIVIL ENGINEERING
                                </div>
                            </div>
                        </div>
                        <!-- <div class="sixteenth-page-careerhumanperc1">
                            <div>10%</div>
                        </div> -->
                         <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div>ENVIRONMENTAL ENGG
                                    <span style="font-size: 8px;">Ecological, Fire protection, Sanitary,
                                        Wastewater, Municipal or urban
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('cv_1', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('cv_1', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('cv_1', data?.dataMap?.page17?.second_runnerup)  }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.civil_engineering.cv_1}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div>GEOTECHNICAL ENGG
                                    <span style="font-size: 8px;">Mining, Foundation, Rock Support System
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('cv_2', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('cv_2', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('cv_2', data?.dataMap?.page17?.second_runnerup)  }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.civil_engineering.cv_2}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div>STRUCTURAL ENGINEERING
                                    <span style="font-size: 8px;">Earthquake, Wind, Architectural, Ocean
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('cv_3', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('cv_3', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('cv_3', data?.dataMap?.page17?.second_runnerup)  }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.civil_engineering.cv_3}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div class="line-height-15">MINING ENGINEERING
                                    <span style="font-size: 8px;">Extraction of Minerals & Materials
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('cv_4', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('cv_4', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('cv_4', data?.dataMap?.page17?.second_runnerup)  }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.civil_engineering.cv_4}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div class="line-height-15">TRANSPORT ENGINEERING
                                    <span style="font-size: 8px;">Traffic, Highway, Railway systems
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('cv_5', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('cv_5', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('cv_5', data?.dataMap?.page17?.second_runnerup)  }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.civil_engineering.cv_5}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div class="line-height-15">UTILITY ENGINEERING
                                    <span style="font-size: 8px;">Subsurface Utility (SUE)
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('cv_6', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('cv_6', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('cv_6', data?.dataMap?.page17?.second_runnerup)  }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.civil_engineering.cv_6}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div class="line-height-15">WATER RESOURCES ENGINEERING
                                    <span style="font-size: 8px;">Hydraulic, River, Coastal, Groundwater
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('cv_7', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('cv_7', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('cv_7', data?.dataMap?.page17?.second_runnerup)  }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.civil_engineering.cv_7}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79" style="border-right: none;">
                            <div class="sixteenth-page-careerbio1" style="padding: 10px 5px;">
                                <div>MECHANICAL ENGINEERING

                                </div>
                            </div>
                        </div>
                        <!-- <div class="sixteenth-page-careerhumanperc1">
                            <div>10%</div>
                        </div> -->
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div class="line-height-15">ACOUSTICAL ENGINEERING
                                    <span style="font-size: 8px;">
                                        Sound & Vibration
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('me_1', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('me_1', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('me_1', data?.dataMap?.page17?.second_runnerup) }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.mechanical_engineering.me_1}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div class="line-height-15">ENERGY ENGINEERING
                                    <span style="font-size: 8px;">Energy Efficiency & Services
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{'highlight': isWinner('me_2', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('me_2', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('me_2', data?.dataMap?.page17?.second_runnerup)  }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.mechanical_engineering.me_2}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div class="line-height-15">MANUFACTURING ENGINEERING
                                    <span style="font-size: 8px;">Machine, Tools, Material- science,
                                        Tribology & Quality Control
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('me_3', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('me_3', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('me_3', data?.dataMap?.page17?.second_runnerup)  }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.mechanical_engineering.me_3}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div class="line-height-15">THERMAL ENGINEERING
                                    <span style="font-size: 8px;">Air conditioning, Refrigeration, Heating,
                                        Ventilating
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('me_4', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('me_4', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('me_4', data?.dataMap?.page17?.second_runnerup) }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.mechanical_engineering.me_4}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div class="line-height-15">VEHICLE ENGINEERING
                                    <span style="font-size: 8px;">Automotive, Naval architecture,
                                        Aerospace, Marine/Oceanographic
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('me_5', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('me_5', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('me_5', data?.dataMap?.page17?.second_runnerup)  }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.mechanical_engineering.me_5}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio" style="padding-bottom: 0;">
                                <div class="line-height-15">POWER PLANT ENGINEERING 
                                    <span style="font-size: 8px;">Geothermal, Coal-fired,
                                        Hydroelectric, Diesel engine (ICE), Tidal power,
                                        Wind turbine power, Solar power
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('me_6', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('me_6', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('me_6', data?.dataMap?.page17?.second_runnerup)  }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.mechanical_engineering.me_6}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                </div>

                <div class="seventeen-page-careerwidth32">
                    <div class="flex-row">
                        <div class="seventeen-page-width79" style="border-right: none;">
                            <div class="sixteenth-page-careerbio1" >
                                <div>ELECTRICAL & ELECTRONIC ENGINEERING</div>
                            </div>
                        </div>
                        <!-- <div class="sixteenth-page-careerhumanperc1">
                            <div>10%</div>
                        </div> -->
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div class="line-height-15">ELECTRONIC & COMMUNICATION ENGG
                                    <span style="font-size: 8px;">Control, Telecommunications, Digital Electronic
                                        Systems, Electronic Devices & Software applications</span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ee_1', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('ee_1', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('ee_1', data?.dataMap?.page17?.second_runnerup) }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.electrical_electronics_engineering.ee_1}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div class="line-height-15">COMPUTER ENGINEERING
                                    <span style="font-size: 8px;">Hardware, Network, Software</span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ee_2', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('ee_2', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('ee_2', data?.dataMap?.page17?.second_runnerup)  }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.electrical_electronics_engineering.ee_2}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div class="line-height-15">POWER ENGINEERING
                                    <span style="font-size: 8px;">Power System Planning, Power System Design, 
                                        Power System Operations & Control, Protection & Control, High Voltage</span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ee_3', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('ee_3', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('ee_3', data?.dataMap?.page17?.second_runnerup)  }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.electrical_electronics_engineering.ee_3}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div class="line-height-15">OPTICAL ENGINEERING
                                    <span style="font-size: 8px;">Lenses, microscope, stathisscope,etc</span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ee_4', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('ee_4', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('ee_4', data?.dataMap?.page17?.second_runnerup)  }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.electrical_electronics_engineering.ee_4}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79" style="border-right: none;">
                            <div class="sixteenth-page-careerbio1" style="padding: 10px 5px;">
                                <div>INTERDISCIPLINARY
                                </div>
                            </div>
                        </div>
                        <!-- <div class="sixteenth-page-careerhumanperc1">
                            <div>10%</div>
                        </div> -->
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div class="line-height-15">AEROSPACE ENGINEERING
                                    <span style="font-size: 8px;">Aeronautics, Astronautics</span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('id_1', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('id_1', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('id_1', data?.dataMap?.page17?.second_runnerup)  }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.interdisciplinary.id_1}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div class="line-height-15">AGRICULTURAL ENGG
                                    <span style="font-size: 8px;">Aquaculture, Biomechanical, Bioprocess Biotechnical, 
                                        Ecological, Food, Forest, Health & safety, Natural
                                        resources, Machinery systems,
                                        Information & electrical systems
                                        </span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('id_2', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('id_2', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('id_2', data?.dataMap?.page17?.second_runnerup) }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.interdisciplinary.id_2}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div class="line-height-15">APPLIED ENGINEERING
                                    <span style="font-size: 8px;">Automation/control systems/ mechatronics/robotics, 
                                        CADD, Construction, Electronics, General, Graphics, Nanotechnology</span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{'highlight': isWinner('id_3', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('id_3', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('id_3', data?.dataMap?.page17?.second_runnerup) }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.interdisciplinary.id_3}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio" style="padding-bottom: 15px;">
                                <div class="line-height-15">BIOMEDICAL ENGINEERING
                                    <span style="font-size: 8px;">Bioinstrumentation, Bioinformatics, Biomechanics, 
                                        Biomaterial, Biomedical optics, Biosignal processing, Tissue, Biotechnology, 
                                        Clinical, Medical- imaging Neural, Pharmaceutical, Rehabilitation</span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('id_4', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('id_4', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('id_4', data?.dataMap?.page17?.second_runnerup)  }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.interdisciplinary.id_4}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio" style="padding-bottom: 15px;">
                                <div class="line-height-15">BIOLOGICAL ENGINEERING
                                    <span style="font-size: 8px;">Bioacoustics, Biochemical, Biosystems, Biotechnical,
                                         Biomolecular, Bioresource, Bioprocess, Cellular, Genetic, 
                                         Food & Biological- process, Health and safety, Microbiological, Molecular, Protein, 
                                         Systems biology, Synthetic biology</span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{'highlight': isWinner('id_5', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('id_5', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('id_5', data?.dataMap?.page17?.second_runnerup)  }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.interdisciplinary.id_5}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div class="line-height-15">NUCLEAR ENGINEERING
                                    <span style="font-size: 8px;">Medical physics, Nuclear fuel, Radiation protection</span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('id_6', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('id_6', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('id_6', data?.dataMap?.page17?.second_runnerup)  }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.interdisciplinary.id_6}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div class="line-height-15">PROJECT ENGINEERING
                                    <span style="font-size: 8px;">Mechanical, Process, Instrumentation & control, Civil, Structural,
                                        Environmental, Electrical
                                        </span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('id_7', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('id_7', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('id_7', data?.dataMap?.page17?.second_runnerup)  }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.interdisciplinary.id_7}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio" style="padding-bottom: 6px;">
                                <div class="line-height-15">INFORMATION ENGINEERING
                                    
                                    <span style="font-size: 8px;">Machine learning, Data science, 
                                        Artificial intelligence, Control theory, Signal processing, Telecommunications, 
                                        Image processing, Information theory, Computer vision, Natural language processing, 
                                        Bioinformatics, Medical image computing, Autonomous robotics, Mobile robotics
                                        </span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{'highlight': isWinner('id_8', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('id_8', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('id_8', data?.dataMap?.page17?.second_runnerup)  }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.interdisciplinary.id_8}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                </div>

                <div class="seventeen-page-careerwidth32" style="margin-right: 0;">
                    <div class="flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div>INDUSTRIAL ENGINEERING
                                    <span style="font-size: 8px;">Manufacturing, Component, Systems,
                                        Construction, Safety, Reliability</span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('id_9', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('id_9', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('id_9', data?.dataMap?.page17?.second_runnerup)  }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.interdisciplinary.id_9}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div>MECHATRONICS ENGG
                                    <span style="font-size: 8px;">Robotics, Instrumentation, Avionics,
                                        Opt-mechatronics, Bio-mechatronics</span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('id_10', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('id_10', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('id_10', data?.dataMap?.page17?.second_runnerup)  }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.interdisciplinary.id_10}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div>NANO ENGINEERING
                                    <span style="font-size: 8px;">Materials, Biomedical,
                                        Instrumentation, Electronic</span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('id_11', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('id_11', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('id_11', data?.dataMap?.page17?.second_runnerup)  }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.interdisciplinary.id_11}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div>MILITARY ENGINEERING
                                    <span style="font-size: 8px;">Combat, Logistics, Design 
                                        & Communication.</span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('id_12', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('id_12', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('id_12', data?.dataMap?.page17?.second_runnerup)  }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.interdisciplinary.id_12}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div>PETROLEUM ENGINEERING
                                    <span style="font-size: 8px;">Reservoir, Drilling, Production</span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('id_13', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('id_13', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('id_13', data?.dataMap?.page17?.second_runnerup)  }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.interdisciplinary.id_13}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div>RAILWAY ENGINEERING
                                    <span style="font-size: 8px;">Design, Construction & Operation.</span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('id_14', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('id_14', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('id_14', data?.dataMap?.page17?.second_runnerup) }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.interdisciplinary.id_14}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div>SOFTWARE ENGINEERING
                                    <span style="font-size: 8px;">Information technology (IT), 
                                        Cryptographic, Tele-traffic, Web</span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{'highlight': isWinner('id_15', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('id_15', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('id_15', data?.dataMap?.page17?.second_runnerup) }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.interdisciplinary.id_15}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>

                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div>TEXTILE ENGINEERING
                                    
                                    <span style="font-size: 8px;">Manufacturing of fiber, yarn & fabric</span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('id_16', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('id_16', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('id_16', data?.dataMap?.page17?.second_runnerup)  }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.interdisciplinary.id_16}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>

                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79" style="border-right: none;">
                            <div class="sixteenth-page-careerbio1" style="padding: 10px 5px;">
                                <div>BUILDING SERVICES ENGINEERING
                                    
                                </div>
                            </div>
                        </div>
                        <!-- <div class="sixteenth-page-careerhumanperc1">
                            <div>10%</div>
                        </div> -->
                        <div class="clear-both"></div>
                    </div>
                    
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div>PUBLIC HEALTH <span style="font-size: 8px; display: inline;">- Water services,</span>
                                    <span style="font-size: 8px; display: inline;"> Drainage 
                                        & Plumbing, Building Facade</span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('bs_1', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('bs_1', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('bs_1', data?.dataMap?.page17?.second_runnerup)  }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.building_services_engineering.bs_1}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div>ELECTRICAL <span style="font-size: 8px; display: inline;">- Lighting</span>
                                    <span style="font-size: 8px; display: inline;">
                                        (Artificial & emergency lighting, low voltage systems, containment, 
                                        distribution, distribution boards & switchgear)
                                        Lightning protection, Security, video and alarm systems, Escalators & lifts
                                        </span>
                                </div>
                            </div>
                        </div>
                        
                        <div [ngClass]="{ 'highlight': isWinner('bs_2', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('bs_2', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('bs_2', data?.dataMap?.page17?.second_runnerup) }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.building_services_engineering.bs_2}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="clear-both"></div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div>FIRE ENGINEERING <span style="font-size: 8px; display: inline;">- Fire </span>
                                    <span style="font-size: 8px; display: inline;">detection and fire protection</span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{'highlight': isWinner('bs_3', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('bs_3', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('bs_3', data?.dataMap?.page17?.second_runnerup)  }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.building_services_engineering.bs_3}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div>ENERGY SUPPLY <span style="font-size: 8px; display: inline;">- Gas,</span>
                                    <span style="font-size: 8px; display: inline;"> electricity & renewable sources</span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('bs_4', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('bs_4', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('bs_4', data?.dataMap?.page17?.second_runnerup) }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.building_services_engineering.bs_4}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79" style="border-right: none;">
                            <div class="sixteenth-page-careerbio1" style="padding: 10px 5px;">
                                <div>NON-ENGINEERING
                                    
                                </div>
                            </div>
                        </div>
                        <!-- <div class="sixteenth-page-careerhumanperc1">
                            <div>10%</div>
                        </div> -->
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div class="line-height-15">ARCHITECTURE
                                    <span style="font-size: 8px;">Design Buildings & Physical Structures</span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ne_1', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('ne_1', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('ne_1', data?.dataMap?.page17?.second_runnerup) }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.non_engineering.ne_1}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div>ANIMATION & MULTIMEDIA
                                    <span style="font-size: 8px;">Graphics & Sound in Advertisement, Movies etc_</span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ne_2', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('ne_2', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('ne_2', data?.dataMap?.page17?.second_runnerup) }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.non_engineering.ne_2}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div>ETHICAL HACKING
                                    <span style="font-size: 8px;">Authorised Identifiers of Data, Breaches 
                                        & Threats</span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ne_3', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('ne_3', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('ne_3', data?.dataMap?.page17?.second_runnerup) }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.non_engineering.ne_3}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div>PHYSICS
                                    <span style="font-size: 8px;">Scientist- Specialized Physicist</span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ne_4', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('ne_4', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('ne_4', data?.dataMap?.page17?.second_runnerup)  }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.non_engineering.ne_4}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div>CHEMISTRY
                                    <span style="font-size: 8px;">Scientist-Specialized in particular subject</span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ne_5', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('ne_5', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('ne_5', data?.dataMap?.page17?.second_runnerup) }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.non_engineering.ne_5}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div class="line-height-15">MATHEMATICS
                                    <span style="font-size: 8px;">Specialized Mathematician.</span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ne_6', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('ne_6', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('ne_6', data?.dataMap?.page17?.second_runnerup)}" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.non_engineering.ne_6}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div class="line-height-15">FORENSIC SCIENCE
                                    <span style="font-size: 8px;">Collect, Analyse & Record crime 
                                        evidences</span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ne_7', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('ne_7', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('ne_7', data?.dataMap?.page17?.second_runnerup) }" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.non_engineering.ne_7}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div class="line-height-15">PHARMACY
                                    <span style="font-size: 8px;">Chemist/ Druggist- Usage & 
                                        Effect of drugs</span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ne_8', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('ne_8', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('ne_8', data?.dataMap?.page17?.second_runnerup)}" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.non_engineering.ne_8}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="seventeen-page-width79">
                            <div class="sixteenth-page-careerbio">
                                <div class="line-height-15">DEFENCE
                                    <span style="font-size: 8px;">Army, Navy or Airforce</span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ne_9', data?.dataMap?.page17?.winner),
                        'first_runnerup': isWinner('ne_9', data?.dataMap?.page17?.first_runnerup),
                        'second_runnerup': isWinner('ne_9', data?.dataMap?.page17?.second_runnerup)}" class="sixteenth-page-careerhumanperc">
                            <div>{{data.dataMap.page17.non_engineering.ne_9}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                </div>

                <div class="clear-both"></div>
            </div>

            <div class="clear-both"></div>
        </div>




    <div class="clear-both"></div>
</div>