<div class="forty-two-page mt-40">
    <div class="text-right">
        <img class="logo-img" src="assets/images/Header.png" />
    </div>

    <div style="background-color: #1b3081;">
        <div class="thirty-three-page-blue-header">
            2D-VISUAL (Picture Smart) 
        </div>
        <div class="mt-10">
            <div class="mt-5">
                <div class="thirty-three-page-cont1">
                    <div>
                        <div class="thirty-three-page-fs23">
                            2D-Visual Intelligence is the ability to Perceive, Acquire and Retain (in Memory) anything and everything all that, what Eyes can see. People with high 2D-Visuals have excellent Photographic Memory (seeing the seen) helpful in recalling places & faces. They are good at differentiating Objects of different Shapes, Sizes, and Colors. They are naturally inclined towards Aesthetic pleasures derived from Art and Beauty. 
                        </div>
                        <div class="thirty-three-page-ipr">
                            2D-VISUALS IS PHOTOGRAPHIC MEMORY OF THE BRAIN 
                        </div>
                        <div class="clear-both"></div>
                        <div class="thirty-three-page-line-height">
                            <ul>
                                <li>Picture memory</li>
                                <li>Objects, Colors, Shapes, Sizes </li>
                                <li>Diagrams, Animation, Sketches </li>
                                <li>Neatness & Cleanliness</li>
                                <li>Identifying places & Faces</li>
                                <li>Mirror imaging & Aesthetic Sense</li>
                                <li>Photography, Art & Designing</li>
                                <li>Geography & Partial History </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="thirty-three-page-first-box" style="text-align: center;">
                    <img src="assets/images/Page 42/page42-poster.PNG" class="width-100">

                </div>
                <div class="clear-both"></div>
            </div>
        </div>
    </div>

    <div class="mt-20">
        <div class="purple-header">
            CHARACTERSTICS OF PERSON WITH HIGH 2D-VISUAL INTELLIGENCE 
        </div>
        <div class="thirty-three-page-row-wrapper">
            <div class="tbl-bx">
                <div class="thirty-three-page-section-heading">
                    PROS
                </div>
                <div class="clear-both"></div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Meticulous attention to Colours and Shades. <br/> Eye of a born Artist or a Designer. 
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Can easily recall Faces, Objects, Maps & Places.<br/> Excellent Photographic Memory. 
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Matured Aesthetic Sense, Luxuriant brand<br/> awareness and Profuse Art Appreciation. 
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-one" style="border-bottom: none;">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Like to be Immaculate for Self and Surroundings.<br/> Cleanliness is Godliness. 
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="clear-both"></div>
            </div>
            <div class="tbl-bx" style="border-left: none;">
                <div class="thirty-three-page-section-heading">
                    CONS
                </div>
                <div class="clear-both"></div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Obsessions for Neatness and Cleanliness to a <br/>Fault. Prone to Obsessive Compulsive Disorder.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Compulsion for Dress to Occasion. Also misjudges <br/>people by their Rich Looks and Appearances. 
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>More Memory based & less Fact based.<br/> Sometimes people misjudge as a logical person. 
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-one" style="border-bottom: none;">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Highly inclined towards current Brands. Feels <br/>uneasy wearing or possessing anything unbranded. 
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="clear-both"></div>
            </div>
        </div>
    </div>

    <div class="mt-20">
        <div class="purple-header">
            REMEDIAL MEASURES IF YOUR 2D-VISUAL INTELLIGENCE IS LOW 
        </div>
        <div class="IIL-bg">
            <div>
                <div class="IIL-sec">Take measure to improve the following </div>
                <div class="IIL-sec1">1) JOIN DRAWING/ SKETCHING/ PAINTING ACADEMY. SET A GOAL TO GIFT YOUR MASTERPIECE TO YOUR LOVED ONES.</div>
                <div class="clear-both"></div>
            </div>

            <div>
                <div class="IIL-sec1">2) PLAY PICTURE PUZZLE GAMES SUCH AS RUBIK CUBE, JIGSAW ETC.</div>
                
                <div class="clear-both"></div>
            </div>
            <div>
                <div class="IIL-sec1">3) DEVELOP A HOBBY SUCH AS SCULPTING, CALLIGRAPHY, GRAPHOLOGY, PHOTOGRAPHY, GRAPHIC DESIGNING ETC.</div>
                
                <div class="clear-both"></div>
            </div>
            <div>
                <div class="IIL-sec1">4) TRY TO TRANSFORM ALL YOUR ROUTINE PLANNING INTO FLOWCHARTS AND PPTS. USE HIGHLIGHTERS AND POINTERS ETC.</div>
               
                <div class="clear-both"></div>
            </div>

            <div>
                <div class="IIL-sec1">5) READ AND FOLLOW MAGAZINES ON FASHION, INTERIOR DESIGNING AND ARTS ETC. KEEP YOURSELF UPDATED WITH LATEST TRENDS.</div>
            </div>

            <div>
                <div class="IIL-sec1">6) MAINTAIN HYGIENE. STAY CLEAN AND TIDY. MAKE FREQUENT USE OF SANITIZERS AND CLEANERS.</div>
            </div>

            <div>
                <div class="IIL-sec1">7) TAKE EXTRA COURSES ON GEOGRAPHY AND HUMAN HISTORY. </div>
            </div>
           
        </div>
    </div>

</div>