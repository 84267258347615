<div class="seventh-page mt-20">
  <div class="">
    <div class="text-right">
      <img class="logo-img" src="assets/images/Header.png" />
    </div>
    <div class="text-center">
      <img style="width: 35%;" src="assets/images/Page 7/Seventh-Ages 4-5.png">
    </div>

    <div class="">
      <div class="seventh-page-gray-box" style="">
        <div class="seventh-page-fs20" style="width: 1%;"><strong>Q</strong></div>
        <div class="seventh-page-fs21" style="width: 93%;"><strong>Which is My Preferred school?</strong> </div>

        <div class="" style="position: relative; padding-bottom: 5px;">
          <div class="seventh-page-fs22" style="width: 1%;"><strong>A</strong></div>
          <div class="seventh-page-fs23" style="width: 90%;">
            Your input zone is the key to your preferred learning method. Different school boards across the globe 
            prefer a variety of teaching methods, i.e. Text, Pictures, Audio, Practical, Demonstrations, Visuals, 
            Experiments, Activities, etc. Select the school board that emphasizes your preferred learning method.
          </div>

        </div>
      </div>

      <div class="seventh-page-first-box" style=" ">
        <img class="width-100" src="assets/images/Page 7/Seventh-pageIMG1.png">
      </div>
      <div class="clear-both"></div>
    </div>

    <div class="blue-header mt-7">
      YOUR PREFERRED LEARNING METHOD
  </div>
    <div class="mt-7">
      <div class="seventh-page-firstbx25" style=" background-color: #e6e7e8; width: 50%; float: left;">
        <div class="" style="padding: 0px 5px; color: #0d0d0d;">
          <span style="color: #00a6bf; font-weight: bold;font-size: 20px; ">TEXT- PICTURE</span>
          <div>Moderately loaded curriculum stressing traditional text and picture-based teaching. Recommended for students with stronger visual input zones. Making notes, flowcharts, and re-reading to be carried out for comprehensive understanding and remembering the subject matter.                                                                                                                    
          </div>
        </div>
      </div>
      <div class="seventh-page-first-bx45" style="width: 45%;">
        <div class="seventh-page-fs1" style="width: 85%; float: left; margin-left: 1px;" >
          <div class="ssc-bar" [ngStyle]="{'width': data.dataMap.page7.ssc_perc+'%' }"></div>
          <div class="seventh-page-fs1" [ngStyle]="{'margin-left': minusTen(data.dataMap.page7.ssc_perc)+'%' }" 
            style="float: left; text-align: center; margin-top: 7px; font-weight: bold; margin-left: 5px; position: absolute;">
            {{data.dataMap.page7.ssc_perc}}% 
            <div>{{data.dataMap.page7.ssc_arch}}</div>
         </div>

        </div>
       
        <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>

    <div class="mt-7">
      <div class="seventh-page-firstbx25" style="background-color: #e6e7e8; width: 50%; float: left;">
        <div class="" style="padding: 0px 5px; color: #0d0d0d;">
          <span style="color: #00a6bf; font-weight: bold;font-size: 20px; "> AUDIO-VISUAL-AUTONOMOUS 
          </span>
          <div>Integrated auditory and visual styles of coaching along with traditional text-based teaching methodology. 
            Helpful in pursuing competitive exams for careers in engineering, medical fields, and application-based subjects. 
            A conventional and universal study pattern that can be well-suitable for migration from one school to another if required.</div>
          
        </div>       
      </div>

      <div class="seventh-page-first-bx45" style="width: 45%; ">
        <div class="seventh-page-fs1" style="width: 85%; float: left; margin-left: 1px;" >
          <div class="cbse-bar" [ngStyle]="{'width': data.dataMap.page7.cbse_perc+'%' }"></div>
          <div class="seventh-page-fs1" [ngStyle]="{'margin-left': minusTen(data.dataMap.page7.cbse_perc)+'%' }" style="float: left; text-align: center; margin-top: 7px; font-weight: bold; margin-left: 5px; position: absolute;">
            {{data.dataMap.page7.cbse_perc}}% 
            <div>{{data.dataMap.page7.cbse_arch}}</div>
         </div>
        </div>

        
       <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>

    <div class="mt-7">
      <div class="seventh-page-firstbx25" style="background-color: #e6e7e8; width: 50%; float: left;">
        <div class="" style="padding: 0px 5px; color: #0d0d0d;">
          <span style="color: #00a6bf; font-weight: bold;font-size: 20px; "> AUDIO-VISUAL-INTERACTIVE         </span>
          <div>Audio-visual-based teachings with a comprehensive and diverse curriculum that includes elaborated discussions, and demonstrations. An education system that emphasizes group work, project-based learning, and case studies. A broad range of curriculum that focuses on a holistic approach to developing the communication and analytical skills.</div>
        </div>       
      </div>

      <div class="seventh-page-first-bx45" style="width: 45%; ">
        <div class="seventh-page-fs1" style="width: 85%; float: left; margin-left: 1px;" >
          <div class="icse-bar" [ngStyle]="{'width': data.dataMap.page7.icse_perc+'%' }"></div>
          <div class="seventh-page-fs1" [ngStyle]="{'margin-left': minusTen(data.dataMap.page7.icse_perc)+'%' }" style="float: left; text-align: center; margin-top: 7px; font-weight: bold; margin-left: 5px; position: absolute;">
            {{data.dataMap.page7.icse_perc}}% 
            <div>{{data.dataMap.page7.icse_arch}}</div>
         </div>
         <div class="clear-both"></div>
        </div>

        
      
      </div>
      <div class="clear-both"></div>
    </div>
    
    <div class="mt-7">
      <div class="seventh-page-firstbx25" style="background-color: #e6e7e8; width: 50%; float: left;">
        <div class="" style="padding: 0px 5px; color: #0d0d0d;">
          <span style="color: #00a6bf; font-weight: bold;font-size: 20px; "> PRACTICAL- EXPERIMENTAL</span>
          <div>Curriculum based on experimentation and  application, suitable for pro-active and practical learners. Recognized by most of the universities of the world, where acquiring knowledge is accomplished through dynamic cycles of inquiry, action, and reflection. Dealing with a practical approach towards learning in collaboration.</div>
        </div>       
      </div>

      <div class="seventh-page-first-bx45" style="width: 45%; ">
        <div class="seventh-page-fs1" style="width: 85%; float: left; margin-left: 1px;" >
          <div class="igcse-bar" [ngStyle]="{'width': data.dataMap.page7.ib_perc+'%' }"></div>
          <div class="seventh-page-fs1" [ngStyle]="{'margin-left': minusTen(data.dataMap.page7.ib_perc)+'%' }" style="float: left; text-align: center; margin-top: 7px; font-weight: bold; margin-left: 5px; position: absolute;">
            {{data.dataMap.page7.ib_perc}}% 
            <div>{{data.dataMap.page7.ib_arch}}</div>
         </div>
        </div>

        
       <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>

    <div class="mt-7" style="background-color: #e6e7e8!important;">
      <div class="fifth-page-row">
        <div class="fifth-page-first-box" style="width: 2%;"><strong>Q</strong></div>
        <div class="fifth-page-first-box" style="width: 90%;">
          <strong>We have already selected a school which is less preferred, what should we do?</strong> </div>
        <div class="clear-both"></div>
      </div>
      <div class="fifth-page-row">
        <div class="fifth-page-first-box" style="width: 2%;"><strong>A</strong></div>
        <div class="fifth-page-first-box" style="width: 90%; text-align: justify; ">
          We do not recommend to change the school. The above statistics are just an indication of the most preferred learning methods of this brain. 
          Utilizing the favored method gives better retention in the memory. Follow your acquiring style (further explained) to get the best results.
        </div>
        <div class="clear-both"></div>
      </div>
    </div>
    
    
  
  </div>
</div>