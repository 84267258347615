<div class="third-page mt-40 ml-20">
    <div class="ml-20">
      <table class="page3-outer" align="center">

        <tr>
          <td class="page3-one-column">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <div class="section3">
                <tr>
                  <td>
                    <table width="100%" border="0" cellspacing="0" cellpadding="0">

                      <tr>
                        <td align="right" class="full-width-image">
                          <img class="logo-img" src="assets/images/Header.png" />
                        </td>
                      </tr>

                      <tr>
                        <td class="paddtop23">
                          <table cellspacing="0" cellpadding="0" width="100%" border="0">
                            <tr>

                              <td width="100%" colspan="2" align="left" valign="top" style="line-height:0px; ">
                                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                  <tr>
                                    <td width="100%" align="left" valign="top" bgcolor="#00aac1"
                                      class="page3-main-head">YOUR PERSONAL DETAILS</td>

                                  </tr>
                                  <tr>

                                    <td width="100%" colspan="2" align="left" valign="top"
                                      style="line-height:0px; ">
                                      <table width="100%" border="1" cellspacing="0" cellpadding="0"  class="page3-tbl-1">
                                        <tbody>
                                          <tr>
                                            <td class="page3-sec1">YOUR NAME</td>
                                            <td class="page3-sec2">{{ data.dataMap.page3.personal.name }}</td>
                                            <td bgcolor="#ebebeb" class="page3-sec3" width="27%" rowspan="5"
                                              align="center" valign="middle">
                                              <div *ngIf="data.dataMap.page3.personal.photo == ''; else other_content">ADD <br>PHOTO</div>
                                              
                                              <ng-template #other_content>
                                                <img class="width-100" src="assets/images/Header.png" />
                                              </ng-template>
                                              
                                            </td>
                                          </tr>


                                          <tr>
                                            <td class="page3-sec1">PARENT/GUARDIAN</td>
                                            <td class="page3-sec2">{{ data.dataMap.page3.personal.guardian }}</td>
                                          </tr>

                                          <tr>
                                            <td class="page3-sec1">GENDER </td>
                                            <td class="page3-sec2">
                                              <input type="checkbox" class="va-middle" value="0" [attr.checked]="data.dataMap.page3.personal.gender === '0' ? true : null " /> MALE
                                              <input type="checkbox" class="va-middle" value="1" [attr.checked]="data.dataMap.page3.personal.gender === '1' ? true : null" /> FEMALE
                                              <input type="checkbox" class="va-middle" value="2" [attr.checked]="data.dataMap.page3.personal.gender === '2' ? true : null" /> OTHER
                                            </td>
                                          </tr>
                                          <tr>
                                            <td class="page3-sec1">DATE OF BIRTH</td>
                                            <td class="page3-sec2">{{ data.dataMap.page3.personal.dob }}</td>
                                          </tr>
                                          <tr>
                                            <td class="page3-sec1">CONTACT NUMBER</td>
                                            <td class="page3-sec2">{{ data.dataMap.page3.personal.contact }}</td>
                                          </tr>
                                          <tr>
                                            <td class="page3-sec1">EMAIL</td>
                                            <td class="page3-sec-email-det" colspan="2">{{ data.dataMap.page3.personal.email }}</td>

                                          </tr>

                                          <tr height="80px">
                                            <td class="page3-sec-add">ADDRESS</td>
                                            <td height="15" class="page3-sec-details" colspan="2">{{ data.dataMap.page3.personal.address }}</td>

                                          </tr>
                                          <!-- <tr>
                                            <td height="15" class="page3-sec-details" colspan="2"></td>

                                          </tr>
                                          <tr>
                                            <td height="15" class="page3-sec-details" colspan="2"></td>

                                          </tr> -->

                                          <tr>
                                            <td class="page3-sec-evaluation">EVALUATION NO.</td>
                                            <td class="page3-sec-evl-details" colspan="2">{{ data.dataMap.page3.personal.eval }}</td>

                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>

                      <tr>
                        <td class="">
                          <table class="mt-10" cellspacing="0" cellpadding="0" width="100%" border="0">
                            <tr>

                              <td width="100%" colspan="2" align="left" valign="top" style="line-height:0px; ">
                                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                  <tr>
                                    <td width="100%" align="left" valign="top" bgcolor="#00aac1"
                                      class="page3-main-head">COMPANY CONSULTANT DETAILS</td>

                                  </tr>
                                  <tr>

                                    <td width="100%" colspan="2" align="left" valign="top"
                                      style="line-height:0px; ">
                                      <table width="100%" border="1" cellspacing="0" cellpadding="0" style="border-collapse: collapse; border-color: black;">
                                        <tbody>


                                          <tr>
                                            <td class="page3-sec4">NAME</td>
                                            <td class="page3-sec-details">{{ data.dataMap.page3.company.name }}</td>
                                          </tr>

                                          <tr>
                                            <td class="page3-sec4">CONTACT NUMBER</td>
                                            <td class="page3-sec-details">{{ data.dataMap.page3.company.contact }}</td>
                                          </tr>
                                          <tr>
                                            <td class="page3-sec4">EMAIL</td>
                                            <td class="page3-sec-details" colspan="2">{{ data.dataMap.page3.company.email }}</td>

                                          </tr>

                                          <tr height="70px">
                                            <td class="page3-sec-add">ADDRESS</td>
                                            <td height="15" class="page3-sec-details" colspan="2">
                                              {{ data.dataMap.page3.company.address }}
                                            </td>

                                          </tr>
                                          <!-- <tr>
                                            <td height="15" class="page3-sec-details" colspan="2"></td>

                                          </tr>
                                          <tr>
                                            <td height="15" class="page3-sec-details" colspan="2"></td>

                                          </tr> -->
                                          <tr>
                                            <td class="page3-sec-evaluation">DEALER CODE</td>
                                            <td class="page3-sec-evl-details" colspan="2">{{ data.dataMap.page3.company.dealerCode }}</td>

                                          </tr>

                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <table class="mt-20" width="100%" border="0" cellspacing="0" cellpadding="0">
                            <tr>
                              <td class="third-page-dsclmr">
                                <h3 class="third-page-disclaimer"> DISCLAIMER</h3>
                                </td>
                            </tr>

                            <tr>
                              <td style="background-color: #ebebeb;">
                                <p
                                  style="font-family: Arial; font-size: 12px; color: #000000; font-weight: normal; margin: 0;
                                  padding-left: 15px;padding-right: 15px;line-height: 18px; text-align: justify; padding-bottom: 20px;">
                                  The contents of this BRAINOGRAPHY report are assessment based on Dermatoglyphics. 
                                  By providing your fingerprints or biometrics yourself or 
                                  under the supervision and permission 
                                  of your legal Parent or Guardian (if you are a minor), 
                                  you are voluntarily agreeing to the method and process of our 
                                  evaluation parameters. 
                                  This report should be considered for information purposes only and the 
                                  values of the assessments should not be interpreted as medical advice. 
                                  The decision to follow any instruction, advice, suggestion, 
                                  or recommendation either printed in this report or verbally communicated by our consultant or 
                                  uploaded on our website, completely depends upon you and your legal 
                                  Parents or Guardians, and you or your legal Parents 
                                  or Guardians will be solely responsible for the consequences of the same. 
                                  Before taking any crucial decision based on this report please 
                                  refer a qualified Doctor. Our organization accepts no liability for 
                                  the consequences under any circumstances for the action taken based on statistics provided, 
                                  or your failure in any particular course of studies or activities by following 
                                  the recommendation in the report. The assessment of this report is not based on any 
                                  factor other than the way of Dermatoglyphics. 10 LOBES BRAINOGRAPHY and 
                                  this assessment report is our copyrighted intellectual property. 
                                  Using our name or any of our content of 
                                  this report and our website, directly or indirectly or by reverse engineering, 
                                  without our consent is illegal and can attract legal action. 
                                  Disputes subject to Palghar jurisdiction.</p>


                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td height="1" bgcolor="#ffffff" style="line-height: 0px;">&nbsp;</td>
                      </tr>

                      <tr>
                        <td class="Page3box" bgcolor="#ffffff">
                          <table width="100%" border="0" cellspacing="0" cellpadding="0" style="min-width: 100%;">
                            <tr>
                              <td bgcolor="#ffffff">

                                <table border="0" cellpadding="0" cellspacing="0" width="100%"
                                  style="width:100%; min-width:100% !important;">
                                  <tr>
                                    <td class="page3-sec-footer-img">
                                      <img src="assets\images\Page 3\logo.png" class="width-100" alt="" />
                                    </td>
                                    <td class="page3-btm-sec">
                                      <table border="0" cellpadding="0" cellspacing="0" width="60%"
                                        style="width:100%; min-width:100% !important;">
                                        <tr>
                                          <td>
                                            <div class="page3-width-30" >
                                              <img class="width-100" src="assets/images/AOB Logo_New.png" />
                                            </div>
                                            <div class="page-btm-12">
                                              <strong> ACADEMY OF BRAINOGRAPHY</strong>
                                              <div >
                                                Baban Smruti, Next to Magnus TVS,
                                                Opp. Big Bazar, Virar&nbsp;West - 401303
                                              </div>

                                              <div>
                                                <img src="assets/images/Page 3/phone  icon.png" class="page3-cnt-detl"> 8888677788

                                                <img src="assets/images/Page 3/Email Icon.png" class="page3-cnt-detl"> info@brainography.com
                                              </div>
                                            </div>
                                            
                                              <!-- 
                                             
                                              </p> -->
                                          </td>

                                        </tr>

                                      </table>



                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td height="1" bgcolor="#ffffff" style="line-height: 0px;">&nbsp;</td>
                            </tr>

                            <tr>
                              <td></td>
                            </tr>



                          </table>
                        </td>
                      </tr>

                      <div class="mt-2">
                        <div class="footer-elem">
                          Evaluation# {{ data.dataMap.footer.evalNo }}
                        </div>
                        <div class="footer-elem2 text-left">
                          Date {{ data.dataMap.footer.date }}
                        </div>
                        <div class="footer-elem1">
                          @Copyright - Siyasaar Enterprise
                        </div>
                        <div class="footer-elem">
                          www.brainography.com
                        </div>
                        <div class="footer-elem">
                          Design By Freepik
                        </div>

                        <div class="clear-both"></div>
                      </div>

                      <div class="text-center">
                        3
                      </div>
                    </table>
                  </td>
                </tr>
              </div>

            </table>
          </td>
        </tr>
      </table>
    </div>

  </div>