<div class="twentyth-page mt-20">
    <div class="">
      <div class="text-right">
        <img class="logo-img" src="assets/images/Header.png" />
      </div>
      <div class="text-center">
        <img style="width: 60%;" src="assets/images/Page 20/Age-22profession-1.jpg">
      </div>
     <div class="mt-5">
        <div class="seventh-page-gray-box" >
          <div class="seventh-page-fs20" style="width: 1%;"><strong>Q</strong></div>
          <div class="seventh-page-fs21" style="width: 93%;"><strong>How can I advance in my profession?</strong> </div>
  
          <div class="" style="position: relative; padding-bottom: 5px;">
            <div class="seventh-page-fs22" style="width: 1%;"><strong>A</strong></div>
            <div class="seventh-page-fs23" style="width: 90%;">
                If you keep these 3 parameters in mind you are bound to progress.<br>
                1)	Type of your Profession- YOUR PROFESSIONAL QUADRANT<br>
                2)	Type of Job role in that Profession- YOUR FORTE<br>
                3)	The degree of your Specified Quality- YOUR QUOTIENT
            </div>
          </div>
        </div>
      <div class="seventh-page-first-box">
          <img src="assets/images/Page 20/Age-22icon.jpg">
        </div>
        <div class="clear-both"></div>
      </div>
      <div class="mt-5">
       <div class="pad5"><span class="twentyth-page-PQ">1) YOUR PROFESSIONAL QUADRANTS</span>
        <div class="pad5 pad-top">Let's first understand the Professional Quadrants & how do you rate in each section. 
        The ultimate reason for being into a profession is to earn your livelihood. 
        Professions are governed by either a <span style="text-decoration: underline;">'Set of Rules' or a 'Process' or a 'System',</span> 
        where some people like to invent them and others like to either follow them or use them 
        to their advantage. All the roles are interdependent on each other. Let's identify what 
        suits you better.</div>
        </div>
       <div class="clear-both"></div>
      </div>
      <div class="mt-5">
          <div class="bx-row">
              <div class="twenty-page-sec">
                  <div class="employee-sec-width25">
                      <div class="employee-num" [ngStyle]="{'bottom': getBottom(data.dataMap.page20.emp)+'px' }">{{data.dataMap.page20.emp}}%</div>
                      <span class="emply-bar" [ngStyle]="{'height': getHeight(data.dataMap.page20.emp)+'px' }"></span>
                      <div class="emply-border"></div>
                      <div class="employee-title">Employee</div>
                      <div class="clear-both"></div>
                  </div>
                  <div class="employee-sec-width25">
                    <div class="employee-num" [ngStyle]="{'bottom': getBottom(data.dataMap.page20.self_emp)+'px' }">{{data.dataMap.page20.self_emp}}%</div>
                    <span class="emply-bar" [ngStyle]="{'height': getHeight(data.dataMap.page20.self_emp)+'px' }" style="background-color: rgb(149, 207, 176);"></span>
                    <div class="emply-border"></div>
                    <div class="employee-title" style="left: 30%;">Self Employed</div>
                    <div class="clear-both"></div>
                </div>
                <div class="employee-sec-width25">
                    <div class="employee-num" [ngStyle]="{'bottom': getBottom(data.dataMap.page20.inv)+'px' }">{{data.dataMap.page20.inv}}%</div>
                    <span class="emply-bar" [ngStyle]="{'height': getHeight(data.dataMap.page20.inv)+'px' }" style="background-color: rgb(64, 182, 237);"></span>
                    <div class="emply-border"></div>
                    <div class="employee-title">Investor</div>
                    <div class="clear-both"></div>
                </div>
                <div class="employee-sec-width25">
                    <div class="employee-num" [ngStyle]="{'bottom': getBottom(data.dataMap.page20.bo)+'px' }">{{data.dataMap.page20.bo}}%</div>
                    <span class="emply-bar" [ngStyle]="{'height': getHeight(data.dataMap.page20.bo)+'px' }" style="background-color: rgb(160, 151, 197);"></span>
                    <div class="emply-border"></div>
                    <div class="employee-title" style="left: 31%;">Business Owner</div>
                    <div class="clear-both"></div>
                </div>
              </div>
          </div>
      </div>
      <div class="mt-10">
        <div class="seventh-page-firstbx25 width-100" >
            <div class="twentyh-page-empl">
              <div class="seventh-page-igcse-wrapper">
                <div class="seventh-page-igcse">
                  <span style=" font-weight: bold;font-size: 20px;  text-align: center;"> EMPLOYEE</span>
                  <div class="txt-lft"> 
                    <ul class="mr-5 pl-20">
                      <li class="padding5">An Employee prefers to accomplish a task or manage a 
                       program which is pre-decided by <span style="text-decoration: underline;">Set of rules, 
                        process or a system </span>designed by their Employers.
                      </li>
                      <li class="padding5">Dynamic performer once the guidelines & targets are understood.
                       </li>
                       <li class="padding5">Earning are mostly by remunerations, wages, 
                         compensations, incentives or sometimes on fixed contracts.
                       </li>
                    </ul>
                  </div>
                  <div class="clear-both"></div>
                </div>
              </div>
              
              <div class="seventh-page-ib-wrapper">
                <div class="seventh-page-ib">
                  <span style="font-weight: bold;font-size: 20px;  text-align: center;"> SELF EMPLOYED</span>
                  <div class="txt-lft"> 
                    <ul class="mr-5 pl-20">
                      <li class="padding5">
                        A Self Employed prefer to work individually for self either by working him/herself
                         or by hiring few employees where the <span style="text-decoration: underline;">set of rules,  process or a system</span> is design by him /herself.
                      </li>
                      <li class="padding5">
                        Classified as Professionals, Entrepreneurs, Freelancers or Private contractors.
                       </li>
                       <li class="padding5">
                        Earns from professional fees, net profit or from deals in case of freelancing or private contracts. 
                       </li>
                    </ul>
                  </div>
                </div>
              </div>
              
            </div>

            <div class="twentyh-page-empl ">
              <div class="seventh-page-third-wrapper">
                <div class="seventh-page-third">
                  <span style=" font-weight: bold;font-size: 20px;  text-align: center;"> INVESTOR</span>
                  <div class="txt-lft"> 
                    <ul class="mr-5 pl-20">
                      <li class="padding5">
                        An Investors prefers to follow his/her analysis & guts to allocate his/her capital trusting a 
                        <span style="text-decoration: underline;">set of rule, process or a system</span>  designed by someone else predicting financial returns. 
                      </li>
                      <li class="padding5">
                        The other 3 quadrants can also be investors along with their current jobs & businesses
                       </li>
                       <li class="padding5">
                        Earnings are in form of financial returns from investing on stocks, commodities, real estate or various business models. 
                       </li>
                    </ul>
                  </div>
                 
                </div>
              </div>
              
              <div class="seventh-page-forth-wrapper">
                <div class="seventh-page-forth">
                  <span style="font-weight: bold;font-size: 20px;  text-align: center;"> BUSINESS OWNER</span>
                  <div class="txt-lft"> 
                    <ul class="mr-5 pl-20">
                      <li class="padding5">
                        A Business Owner prefers to design a 
                        <span style="text-decoration: underline;">set of rules, process or a system</span> to be followed by others rather
                         than by him/herself delegating entire work load to hierarchy of staff and management.
                      </li>
                      <li class="padding5">
                        Can work individually or in a team where core decisions are taken by him/herself.
                       </li>
                       <li class="padding5">
                        Earns from the profit or by selling off the entire model to someone. 
                       </li>
                    </ul>
                  </div>
                </div>
              </div>
             
              
            </div>
            
            <div class="middle-icon-wrapper">
              <div class="img-wrapper-flex" style="border-bottom: 2px solid white;">
                <img style="width: 50px; border-right: 2px solid white;" src="assets/images/Page 20/E-img.png" />
                <img style="width: 50px;" src="assets/images/Page 20/S-img.png" />
              </div>

              <div class="img-wrapper-flex">
                <img style="width: 50px; border-right: 2px solid white;" src="assets/images/Page 20/I-img.png" />
                <img style="width: 49px;" src="assets/images/Page 20/B-img.png" />
              </div>
            </div>
        </div>
        <div class="clear-both"></div>
      </div>
  
      
    </div>
  </div>