<div class="fifthteenth-page mt-40">
    <div class="">
        <div class="text-right">
            <img class="logo-img" src="assets/images/Header.png" />
        </div>
        <div class="">
            <div class="eighteen-page-hd1" style="margin-right: 4px;">
                <div class="eighteen-page-headingsec">
                    <div>CAREER PROSPECTS FOR THE STREAM OF ARTS</div>
                </div>
                <div class="clear-both"></div>
            </div>
        </div>

        <div>
            <div class="mt-5">
                <div class="eighteen-page-careerwidth32">
                    <div style="font-size: 16px;">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careerhuman ">
                                <div>CAREER OPTIONS</div>
                            </div>
                        </div>
                        <div class="eighteen-page-careerhumanperc">
                            <div>%</div>
                        </div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="width-100">
                            <div class="eighteen-page-careerbio">
                                <div>EDUCATION
                             </div>
                            </div>
                        </div>
                       
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm ">
                                <div>SCHOOL TEACHER/ LECTURER/ PROFESSOR
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ed_1', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('ed_1', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('ed_1', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.education.ed_1}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm ">
                                <div>EDUCATIONAL OFFICER
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ed_2', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('ed_2', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('ed_2', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.education.ed_2}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>EDUCATIONAL RESEARCHER
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ed_3', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('ed_3', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('ed_3', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.education.ed_3}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>EDUCATIONAL TRAINER

                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ed_4', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('ed_4', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('ed_4', data?.dataMap?.page19?.second_runnerup)}" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.education.ed_4}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm ">
                                <div>EDUCATIONAL COUNSELLOR
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ed_5', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('ed_5', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('ed_5', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.education.ed_5}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>VOCATIONAL COUNSELLOR
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ed_6', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('ed_6', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('ed_6', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.education.ed_6}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="width-100">
                            <div class="eighteen-page-careerbio">
                                <div>MUSIC
                                </div>
                            </div>
                        </div>
                      
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>PLAYER/ CONDUCTOR
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('mu_1', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('mu_1', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('mu_1', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.music.mu_1}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm ">
                                <div>MUSIC TEACHER
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('mu_2', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('mu_2', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('mu_2', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.music.mu_2}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>MUSIC DIRECTOR
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('mu_3', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('mu_3', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('mu_3', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.music.mu_3}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm ">
                                <div>EDITOR
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('mu_4', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('mu_4', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('mu_4', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.music.mu_4}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>DJ (DISC JOCKEY)
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('mu_5', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('mu_5', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('mu_5', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.music.mu_5}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>PUBLISHER/ PROMOTER
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('mu_6', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('mu_6', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('mu_6', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.music.mu_6}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>RETAILER
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('mu_7', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('mu_7', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('mu_7', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.music.mu_7}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>CRITIC
                              </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('mu_8', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('mu_8', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('mu_8', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.music.mu_8}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>SINGER/ VOICE OVER
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('mu_9', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('mu_9', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('mu_9', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.music.mu_9}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>COMPOSER/ MUSIC WRITER
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('mu_10', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('mu_10', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('mu_10', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.music.mu_10}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm10">
                                <div>THERAPIST/ AUDIOLOGIST/ <br>SPEECH PATHOLOGIST
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('mu_11', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('mu_11', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('mu_11', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc10">
                            <div>{{data.dataMap.page19.music.mu_11}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>LYRICIST
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('mu_12', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('mu_12', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('mu_12', data?.dataMap?.page19?.second_runnerup)}" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.music.mu_12}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="width-100">
                            <div class="eighteen-page-careerbio">
                                <div>SPORTS & ADVENTURE
                                </div>
                            </div>
                        </div>
                       
                        <div class="clear-both"></div>
                    </div>

                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm " style="text-transform: none;">
                                <div>ATHLETE/ MARTIAL ARTS/ BOXER, etc.
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('sp_1', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('sp_1', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('sp_1', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.sports_adventure.sp_1}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm ">
                                <div>SPORTSPERSON (LOGICAL GAME)
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('sp_2', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('sp_2', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('sp_2', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.sports_adventure.sp_2}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm ">
                                <div>SPORTSPERSON (TEAM GAME) 
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('sp_3', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('sp_3', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('sp_3', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.sports_adventure.sp_3}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm ">
                                <div>SPORTSMAN (TARGETED GAME)
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('sp_4', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('sp_4', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('sp_4', data?.dataMap?.page19?.second_runnerup)}" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.sports_adventure.sp_4}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                   
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>GYMNAST/ GYM/ YOGA/ AEROBICS
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('sp_5', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('sp_5', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('sp_5', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.sports_adventure.sp_5}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>DIVER- SEA & SKY/ SWIMMER</div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('sp_6', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('sp_6', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('sp_6', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.sports_adventure.sp_6}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm ">
                                <div>CONSULTANT/ COACH/ TRAINER/ PT TEACHER
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{'highlight': isWinner('sp_7', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('sp_7', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('sp_7', data?.dataMap?.page19?.second_runnerup)}" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.sports_adventure.sp_7}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm ">
                                <div>SPORTS GOODS RETAILER/ MANUFACTURER
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('sp_8', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('sp_8', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('sp_8', data?.dataMap?.page19?.second_runnerup)}" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.sports_adventure.sp_8}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="width-100">
                            <div class="eighteen-page-careerbio">
                                <div>PERFORMING ARTS 
                                </div>
                            </div>
                        </div>
                       
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>PERFORMER/ TEACHER
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('pa_1', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('pa_1', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('pa_1', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.performing_arts.pa_1}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm ">
                                <div>DANCER/ CHOREOGRAPHER
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('pa_2', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('pa_2', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('pa_2', data?.dataMap?.page19?.second_runnerup)}" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.performing_arts.pa_2}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>ACTOR/ THEATRE ARTIST/ FILM ARTIST
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('pa_3', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('pa_3', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('pa_3', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.performing_arts.pa_3}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm ">
                                <div>STAND-UP COMEDIAN/ MIMICRY
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('pa_4', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('pa_4', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('pa_4', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.performing_arts.pa_4}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm" style="padding-bottom: 10px;">
                                <div>MAGICIAN/ SPECIAL TALENT ACT/ JUGGLER
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{'highlight': isWinner('pa_5', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('pa_5', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('pa_5', data?.dataMap?.page19?.second_runnerup)}" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.performing_arts.pa_5}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                </div>
                <div class="eighteen-page-careerwidth32">
                    <div style="font-size: 16px;">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careerhuman " >
                                <div>CAREER OPTIONS</div>
                            </div>
                        </div>
                        <div class="eighteen-page-careerhumanperc">
                            <div>%</div>
                        </div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="width-100">
                            <div class="eighteen-page-careerbio">
                                <div>ARTIST
                             </div>
                            </div>
                        </div>
                       
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm ">
                                <div>PAINTER/ CRAFTS/ SCULPTOR- EARTH & METAL
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('at_1', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('at_1', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('at_1', data?.dataMap?.page19?.second_runnerup)}" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.artist.at_1}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm ">
                                <div>DESIGNER- INTERIOR/ FASHION/ JEWELLERY
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('at_2', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('at_2', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('at_2', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.artist.at_2}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>GRAPHIC DESIGNER/ LOGO DESIGNER
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('at_3', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('at_3', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('at_3', data?.dataMap?.page19?.second_runnerup)}" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.artist.at_3}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>BRAND DESIGNER
                               </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('at_4', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('at_4', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('at_4', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.artist.at_4}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="width-100">
                            <div class="eighteen-page-careerbio">
                                <div>LANGUAGE-LITERATURE
                                </div>
                            </div>
                        </div>
                       
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm ">
                                <div>LANGUAGE TEACHER
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ll_1', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('ll_1', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('ll_1', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.language_literature.ll_1}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>INTERPRETER/ TRANSLATOR/ PR 
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ll_2', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('ll_2', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('ll_2', data?.dataMap?.page19?.second_runnerup)}" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.language_literature.ll_2}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>TOURS & TRAVEL GUIDE/ OPERATOR
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ll_3', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('ll_3', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('ll_3', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.language_literature.ll_3}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm ">
                                <div>NEWS READER
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ll_4', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('ll_4', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('ll_4', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.language_literature.ll_4}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>HOST
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{'highlight': isWinner('ll_5', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('ll_5', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('ll_5', data?.dataMap?.page19?.second_runnerup)}" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.language_literature.ll_5}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>HISTORIAN/ LIBRARIAN/ MUSEUM OFFICER
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ll_6', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('ll_6', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('ll_6', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.language_literature.ll_6}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    
                    <div class="mt-5 flex-row">
                        <div class="width-100">
                            <div class="eighteen-page-careerbio">
                                <div>JOURNALISM & <br>MASS COMMUNICATION 
                                </div>
                            </div>
                        </div>
                       
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm " style="padding: 10px;">
                                <div>JOURNALIST/ MEDIA REPORTER/ RESEARCHER
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('jm_1', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('jm_1', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('jm_1', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.journalism_mass_communication.jm_1}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm ">
                                <div>EDITOR
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{'highlight': isWinner('jm_2', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('jm_2', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('jm_2', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.journalism_mass_communication.jm_2}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>WRITER - CONTENT/ BLOG/ SCRIPT
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('jm_3', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('jm_3', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('jm_3', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.journalism_mass_communication.jm_3}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm ">
                                <div>ADVERTISING MANAGER/ BRAND MANAGER
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{'highlight': isWinner('jm_4', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('jm_4', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('jm_4', data?.dataMap?.page19?.second_runnerup)}" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.journalism_mass_communication.jm_4}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>RADIO/ VIDEO JOCKEY
                              </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('jm_5', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('jm_5', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('jm_5', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.journalism_mass_communication.jm_5}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>EVENT MANAGER 
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{'highlight': isWinner('jm_6', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('jm_6', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('jm_6', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.journalism_mass_communication.jm_6}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="width-100">
                            <div class="eighteen-page-careerbio" style="padding: 10px;">
                                <div>LAW / POLITICAL / <br>PUBLIC AFFAIRS
                                </div>
                            </div>
                        </div>
                       
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>LAWYER/ JUDGE 
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('lp_1', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('lp_1', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('lp_1', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.law_affairs.lp_1}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm ">
                                <div>LEGAL ADVISOR/ PUBLIC PROSECUTOR 
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('lp_2', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('lp_2', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('lp_2', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.law_affairs.lp_2}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm10" style="padding: 10px;">
                                <div>GOVERNMENT OFFICER- IPS/ IAS/ <br>SECRETARY/ FOREIGN SERVICES 
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('lp_3', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('lp_3', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('lp_3', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc10">
                            <div>{{data.dataMap.page19.law_affairs.lp_3}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                     <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm " style="text-transform: none;">
                                <div>ADMINISTRATION OFFICER/ STAFF
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('lp_4', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('lp_4', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('lp_4', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.law_affairs.lp_4}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm ">
                                <div>POLITICIAN/ BUREAUCRATS
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('lp_5', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('lp_5', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('lp_5', data?.dataMap?.page19?.second_runnerup)}" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.law_affairs.lp_5}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="width-100">
                            <div class="eighteen-page-careerbio">
                                <div>LANGUAGE-LITERATURE
                                </div>
                            </div>
                        </div>
                       
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm ">
                                <div>PSYCHOLOGIST/ COUNSELLOR 
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ph_1', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('ph_1', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('ph_1', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.language_literatures.ph_1}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>PSYCHIATRIST
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ph_2', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('ph_2', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('ph_2', data?.dataMap?.page19?.second_runnerup)}" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.language_literatures.ph_2}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                   
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>PHILOSOPHER
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ph_3', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('ph_3', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('ph_3', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.language_literatures.ph_3}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm ">
                                <div>SPIRITUAL LEADER/ GURU/ HEALER</div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ph_4', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('ph_4', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('ph_4', data?.dataMap?.page19?.second_runnerup)}" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.language_literatures.ph_4}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="width-100">
                            <div class="eighteen-page-careerbio">
                                <div>SERVICE INDUSTRY
                                </div>
                            </div>
                        </div>
                       
                        <div class="clear-both"></div>
                    </div>

                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm ">
                                <div>HOSPITALITY/ HOTEL INDUSTRY 
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('si_1', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('si_1', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('si_1', data?.dataMap?.page19?.second_runnerup)}" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.service_industry.si_1}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm ">
                                <div>SOCIAL WORK/ NGO / TRUST RELATED 
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('si_2', data?.dataMap?.page19?.winner),
                        'first_runnerup': isWinner('si_2', data?.dataMap?.page19?.first_runnerup),
                        'second_runnerup': isWinner('si_2', data?.dataMap?.page19?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page19.service_industry.si_2}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    
                </div>
            </div>
            <div class="clear-both"></div>
        </div>
    </div>
</div>