<div class="thirty-six-page mt-40">
    <div class="text-right">
        <img class="logo-img" src="assets/images/Header.png" />
    </div>
    <div style="background-color: #205e4c;">
        <div class="thirty-five-page-blue-header">
            3D IMAGINATION - (spatial smart)
        </div>
        <div>
            <div class="mt-5">
                <div class="thirty-five-page-cont1">
                    <div>
                        <div class="thirty-five-page-fs23">
                            3D Imagination (Spatial Intelligence) is application of mental skill to imagine and
                            picturize objects, faces or scenes in a non-visual way (like a video). In other words, the
                            ability to visualize with the mind's eye. Persons with high Spatial Intelligence can Plan,
                            Project, Conceive, Rotate & even manipulate almost anything in their Imagination. They can
                            navigate with the sense of Speed, Velocity, Force and direction from multiple angles in
                            their mind. They also have a huge memory to store Astronomical amount of Data for
                            interminable period of time.
                        </div>
                        <div class="thirty-five-page-ipr">
                            Implementation of concepts comes <br>from 3D Imagination
                        </div>
                        <div class="clear-both"></div>
                        <div class="thirty-five-page-line-height">
                            <ul class="mt-5">
                                <li>What is next- Present to Future.</li>
                                <li>Idea formation</li>
                                <li>Creativity & Color combination</li>
                                <li>Living in fantasy — Day dreaming,</li>
                                <li>Long term memory recall</li>
                                <li>Innovation & Future orientation</li>
                                <li>Implementation of concept</li>
                                <li>Algebra, Chemistry, Trigonometry & Calculus</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="thirty-five-page-first-box image-sec">
                    <img src="assets/images/Page 36/PAGE-36-IMAGINATION.jpg" class="width-100">

                </div>
                <div class="clear-both"></div>
            </div>
        </div>
    </div>
    <div class="mt-20">
        <div class="purple-header">
            CHARACTERITICS OF A PERSON HAVING HIGH 3D-SPATIAL INTELLIGENCE
        </div>
        <div class="thirty-five-page-row-wrapper">
            <div class="tbl-bx">
                <div class="thirty-five-page-section-heading">
                    PROS
                </div>
                <div class="clear-both"></div>
                <div class="thirty-five-page-column-one">
                    <div class="thirty-five-page-section-subheading">
                        <ul class="thirty-five-page-list">
                            <li>Creative in all sense-- Material, Verbal, Literature,
                                <br>Colors, Aesthetics and Innovations.

                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-five-page-column-two">
                    <div class="thirty-five-page-section-subheading">
                        <ul class="thirty-five-page-list">
                            <li>Competent in Designing such as Object, Structures,
                                <br>Machinery, Softwares, Graphics, etc.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-five-page-column-two">
                    <div class="thirty-five-page-section-subheading">
                        <ul class="thirty-five-page-list">
                            <li>Future Term Planners by default. Can think more
                                <br>than 4 to 5 years in Advance.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-five-page-column-two" style="border-bottom: none;">
                    <div class="thirty-five-page-section-subheading">
                        <ul class="thirty-five-page-list">
                            <li>Long term memory recall of moving visuals. Can
                                <br>easily recollect Words and Vocabulary instantly.
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="clear-both"></div>
            </div>
            <div class="tbl-bx" style="border-left: none;">
                <div class="thirty-five-page-section-heading">
                    CONS
                </div>
                <div class="clear-both"></div>
                <div class="thirty-five-page-column-one">
                    <div class="thirty-five-page-section-subheading">
                        <ul class="thirty-five-page-list">
                            <li>Does excessive mental travelling, Difficulty
                                <br>to be in the present.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-five-page-column-two">
                    <div class="thirty-five-page-section-subheading">
                        <ul class="thirty-five-page-list">
                            <li>Gets bored very easily. Have hard time <br>sitting idle.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-five-page-column-two">
                    <div class="thirty-five-page-section-subheading">
                        <ul class="thirty-five-page-list">
                            <li>May develop Fears, Anxieties or Phobias <br>especially in childhood.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-five-page-column-two" style="border-bottom: none;">
                    <div class="thirty-five-page-section-subheading">
                        <ul class="thirty-five-page-list">
                            <li>Dependent more on Perceiving and Intuition
                                <br>rather than Fact and Reality.
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="clear-both"></div>
            </div>
        </div>
    </div>

    <div class="mt-20">
        <div class="purple-header">
            REMEDIAL MEASURES IF YOUR 3D- SPATIAL INTELLIGENCE IS LOW
        </div>
        <div class="IIL-bg">
            <div>
                <div class="IIL-sec">Take measures to improve the following</div>
                <div class="IIL-sec1">1) PERSISTENCE AND PERSEVERANCE-</div>
                <div>
                    <ul class="thirty-five-page-list-IIL">
                        <li>First step towards improving your 3D-Spatial is to gather will-power and strong
                            determination because you will be required to <br>train your guns on connecting the threads
                            in
                            your mind, which requires serious concentration and causes mental exhaustion.
                        </li>
                    </ul>
                </div>
                <div class="clear-both"></div>
            </div>
            <div>
                <div class="IIL-sec1">2) JOIN BRAIN GYM</div>
                <div>
                    <ul class="thirty-five-page-list-IIL">
                        <li>Explore Spatial Puzzles and Games, Learn Rubik cubes, try drawing and Painting, Play Action/
                            Thriller Video Games.
                        </li>
                        <li>Take extra courses in Chemistry, Trigonometry, Calculus and Comprehension.
                        </li>
                        <li>Develop hobbies like Videography, Flying Drones, Bartending, Astrology,
                            etc. Also learn planning in context to future.
                        </li>
                        <li>Explore YouTube channels on Cosmology, Architecture, Life planning etc.
                        </li>
                    </ul>
                </div>
                <div class="clear-both"></div>
            </div>
            <div>
                <div class="IIL-sec1">3) EXPLORE PHYSICAL WORLD.</div>
                <div>
                    <ul class="thirty-five-page-list-IIL">
                        <li>Explore real world and train yourself to visualize Space, Distance and Direction. Observe
                            closely-Roads, Bridges, Cities, <br>Mountains, Oceans, etc. Develop sense of distance without
                            having to measure it. Also by observing Orientation of things <br>practically improve on your
                            Memory by Reference.
                        </li>
                    </ul>
                </div>
                <div class="clear-both"></div>
            </div>
            <div style="padding-bottom: 15px;">
                <div class="IIL-sec1">4) IMPROVE MEMORY RECALL</div>
                <div>
                    <ul class="thirty-five-page-list-IIL">
                        <li> Do Meditation on regular basis and improve on your concentration.
                        </li>
                        <li>Include Memory Enhancing Diet and Supplements. Check with Nutritionist & find what is
                            suitable for you
                        </li>
                        <li>Do regular Physical Exercise and Sleep well to enhance Memory Consolidation Process.
                        </li>
                    </ul>
                </div>
                <div class="clear-both"></div>
            </div>
        </div>
    </div>



</div>