<div class="second-page mt-40">
    <div class="ml-20">
      <div class="text-right">
        <img class="logo-img" src="assets/images/Header.png" />
      </div>

      <div class="mx-17 mt-16">
        <div class="">
          Dear {{ data.dataMap.page2.name }},
        </div>

        <div class="mt-20">
          Hearty Congratulations..!!! <br />
          For Taking Most important step of your life - To Know Yourself Better
        </div>

        <div class="mt-20">
          <strong>We consider ourselves fortunate to come across this wonderful science and further by sharing the
            knowledge
            with you.
          </strong>
        </div>

        <div class="mt-20">
          This vital information about yourself derived from your fingerprints is not a pseudoscience, or Astrology or
          a Palmistry in our consideration. BRAINOGRAPHY is based on Dermatoglyphics which is scientific study of
          fingerprints. It is the result of tremendous research done by thousands of researchers and enthusiasts for
          more than last 350 years. Dermatoglyphics is now a professional industry which studies on behavior patterns
          and health prognosis relating to Neurobiology, Genetics, Brain study, Embryology etc.
        </div>

        <div class="mt-40 bg-gray text-center py-20">
          <strong>BRAINOGRAPHY REPORT WILL HELP YOU DISCOVER</strong>
          <div class="ml-60">
            <div class="heading-blue">
              YOUR PASSION AND PREFERENCES....................... Have a Vision in Your life!
            </div>
            <div class="heading-blue">
              YOUR INBORN INTELLIGENCE AND ABILITIES........ Believe in Your Potential!
            </div>
            <div class="heading-blue">
              YOUR TALENTS AND CAPABILITIES............................... Achieve Your Dreams!
            </div>
            <div class="heading-blue">
              YOUR TRUE NATURE AND PERSONALITY.................. Garner Love & Respect!
            </div>
            <div class="heading-blue">
              YOUR SHORTCOMINGS AND LIMITATIONS............. Be Patient and Persistent!
            </div>

          </div>
        </div>
      </div>

      <div class="mt-40 mx-17">
        <div class="text-center">
          <strong>BRAINOGRAPHY - YOUR BRAIN BIBLE</strong>
        </div>

        <div class="mt-20">
          BRAINOGRAPHY is 'once in a lifetime' report which is significant at any stage of your life. We recommend you
          to read this report on regular intervals for the rest of your life. You will be surprised how the
          information of your capabilities and limitations given in this report will make relevance from different
          angles and dimensions every time you go through it.

        </div>

        <div class="mt-20">
          One last thing before we explore-This Science is still evolving. There is someone working on this Science
          right now as you read this. New findings are discovered every other day in different parts of the world. So
          updation or correction from the last information is ongoing and inevitable process. For latest updation, if
          any, keep visiting our website - www.brainography.com
        </div>

        <div class="mt-30">
          <div class="text-center">
            <strong style="font-weight: 900;" class="heading-blue">ALL THE VERY BEST & HAVE A WONDERFUL LIFE AHEAD</strong>
          </div>
        </div>

        <div class="round-corner-box mt-20">
          <div class="text-center">
            <div class="heading-center-align">BRAINOGRAPHY GUARANTEE</div>
          </div>

          <div>
            <ul class="list-color-disc">
              <li>
                <span class="disc-color"></span>
                Your Biometrics got encrypted the moment they were saved. They are irretrievable.</li>
              <li>
                <span class="disc-color"></span>
                Biometrics get deleted from the system automatically after the generation of the report.</li>
              <li>
                <span class="disc-color"></span>
                We save your identity only if you volunteer to provide your fingerprints for further research.</li>
              <li><span class="disc-color"></span>
                Your Personal information & your Brainography report is safe and shall remain confidential.</li>
              <li>
                <span class="disc-color"></span>
                Your feedbacks and testimonials will not be published without your prior consent.</li>
            </ul>

          </div>
        </div>

        <div>
          <div class="footer-elem">
            Evaluation# {{ data.dataMap.footer.evalNo }}
          </div>
          <div class="footer-elem2 text-left">
            Date {{ data.dataMap.footer.date }}
          </div>
          <div class="footer-elem1">
            @Copyright - Siyasaar Enterprise
          </div>
          <div class="footer-elem">
            www.brainography.com
          </div>
          <div class="footer-elem">
            Design By Freepik
          </div>

          <div class="clear-both"></div>
        </div>

        <div class="text-center">
          2
        </div>
      </div>

    </div>
  </div>