<div class="fifthteenth-page mt-40">
    <div class="">
        <div class="text-right">
            <img class="logo-img" src="assets/images/Header.png" />
        </div>
        <div class="">
            <div class="eighteen-page-hd1" style="margin-right: 4px;">
                <div class="eighteen-page-headingsec">
                    <div>CAREER PROSPECTS FOR THE STREAM OF COMMERCE</div>
                </div>
                <div class="clear-both"></div>
            </div>
        </div>

        <div>
            <div class="mt-5">
                <div class="eighteen-page-careerwidth32">
                    <div style="font-size: 16px;">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careerhuman ">
                                <div>CAREER OPTIONS</div>
                            </div>
                        </div>
                        <div class="eighteen-page-careerhumanperc">
                            <div>%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="width-100">
                            <div class="eighteen-page-careerbio">
                                <div>GENERAL MANAGEMENT</div>
                            </div>
                        </div>
                        <!-- <div class="eighteen-page-careerbio1">
                            <div>99%</div>
                        </div> -->
                        <div class="clear-both"></div>
                    </div>

                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm ">
                                <div>OPERATIONS - WAREHOUSE/ FLOOR/ LOBBY/ OUTLET
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('gm_1', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('gm_1', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('gm_1', data?.dataMap?.page18?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.general_management.gm_1}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>OPERATIONS - DEPARTMENT/ MATERIAL
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{'highlight': isWinner('gm_2', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('gm_2', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('gm_2', data?.dataMap?.page18?.second_runnerup)}" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.general_management.gm_2}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>INDUSTRIAL PRODUCTION/ MANUFACTURING

                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('gm_3', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('gm_3', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('gm_3', data?.dataMap?.page18?.second_runnerup)}" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.general_management.gm_3}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm ">
                                <div>PURCHASING/ FACILITIES CO-ORDINATOR
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('gm_4', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('gm_4', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('gm_4', data?.dataMap?.page18?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.general_management.gm_4}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm10">
                                <div>QUALITY ASSURANCE/ INSPECTION
                                    <br>INVENTORY CONTROLLER
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('gm_5', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('gm_5', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('gm_5', data?.dataMap?.page18?.second_runnerup) }" class="eighteen-page-careercommperc10">
                            <div>{{data.dataMap.page18.general_management.gm_5}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>LOGISTICS/ SHIPPING/ SUPPLY CHAIN
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('gm_6', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('gm_6', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('gm_6', data?.dataMap?.page18?.second_runnerup)}" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.general_management.gm_6}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm ">
                                <div>IT/ CHANNEL/ GSM
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('gm_7', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('gm_7', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('gm_7', data?.dataMap?.page18?.second_runnerup)}" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.general_management.gm_7}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="width-100">
                            <div class="eighteen-page-careerbio">
                                <div>PRODUCT & MARKETING
                                </div>
                            </div>
                        </div>
                       
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm ">
                                <div>PRODUCT
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('pm_1', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('pm_1', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('pm_1', data?.dataMap?.page18?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.product_marketing.pm_1}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm10 ">
                                <div>BRAND/ ADVERTISING/ DIGITAL/ MEDIA/ <br>PROMOTION
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('pm_2', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('pm_2', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('pm_2', data?.dataMap?.page18?.second_runnerup) }" class="eighteen-page-careercommperc10">
                            <div>{{data.dataMap.page18.product_marketing.pm_2}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm10">
                                <div>CORPORATE SALES/ E-COMMERCE/ <br>BIDDING-TENDERS
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('pm_3', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('pm_3', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('pm_3', data?.dataMap?.page18?.second_runnerup) }" class="eighteen-page-careercommperc10">
                            <div>{{data.dataMap.page18.product_marketing.pm_3}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="width-100">
                            <div class="eighteen-page-careerbio">
                                <div>SALES & SERVICE
                                </div>
                            </div>
                        </div>
                       
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm10 ">
                                <div>SERVICE/ RELATIONSHIP/ CALL CENTRE/ <br>SERVICE CENTRE

                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ss_1', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('ss_1', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('ss_1', data?.dataMap?.page18?.second_runnerup) }" class="eighteen-page-careercommperc10">
                            <div>{{data.dataMap.page18.sales_services.ss_1}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm10">
                                <div>SALES/ RETAIL/ WHOLE SALE/ DEALER-<br>DISTRIBUTOR NETWORKING
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ss_2', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('ss_2', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('ss_2', data?.dataMap?.page18?.second_runnerup) }" class="eighteen-page-careercommperc10">
                            <div>{{data.dataMap.page18.sales_services.ss_2}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="width-100">
                            <div class="eighteen-page-careerbio">
                                <div>H.R. (Human Resources)
                                </div>
                            </div>
                        </div>
                        
                        <div class="clear-both"></div>
                    </div>

                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm ">
                                <div>EMPLOYEE STAFFING/ RECRUITING/RELATIONS
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('hr_1', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('hr_1', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('hr_1', data?.dataMap?.page18?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.hr.hr_1}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm ">
                                <div>PLANNING/ TRAINING & DEVELOPMENT
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('hr_2', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('hr_2', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('hr_2', data?.dataMap?.page18?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.hr.hr_2}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm ">
                                <div>LABOUR LEGISLATION/ COMPENSATION WELFARE & SOCIAL SECURITY
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('hr_3', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('hr_3', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('hr_3', data?.dataMap?.page18?.second_runnerup)}" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.hr.hr_3}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="width-100">
                            <div class="eighteen-page-careerbio">
                                <div>EVENT MANAGEMENT
                                </div>
                            </div>
                        </div>
                       
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm ">
                                <div>PRIVATE FUNCTIONS
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('em_1', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('em_1', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('em_1', data?.dataMap?.page18?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.event_management.em_1}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm ">
                                <div>TALENT HUNTS
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('em_2', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('em_2', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('em_2', data?.dataMap?.page18?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.event_management.em_2}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm ">
                                <div>FASHION/ CULTURAL
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('em_3', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('em_3', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('em_3', data?.dataMap?.page18?.second_runnerup)}" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.event_management.em_3}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>REALITY SHOWS
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('em_4', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('em_4', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('em_4', data?.dataMap?.page18?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.event_management.em_4}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm10">
                                <div>RELIGIOUS FUNCTIONS/ CHARITY/ SENIOR<br> SENIOR CITIZEN/ WEDDINGS/ BIRTHDAYS
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('em_5', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('em_5', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('em_5', data?.dataMap?.page18?.second_runnerup) }" class="eighteen-page-careercommperc10">
                            <div>{{data.dataMap.page18.event_management.em_5}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm ">
                                <div>CORPORATE/ CONFERENCES/ EXHIBITIONS
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('em_6', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('em_6', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('em_6', data?.dataMap?.page18?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.event_management.em_6}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm ">
                                <div>ACADEMIC/ SPORTS
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('em_7', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('em_7', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('em_7', data?.dataMap?.page18?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.event_management.em_7}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm" style="padding-bottom: 10px;">
                                <div>SEMINARS/ MOTIVATIONAL SPEECH
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('em_8', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('em_8', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('em_8', data?.dataMap?.page18?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.event_management.em_8}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="width-100">
                            <div class="eighteen-page-careerbio">
                                <div>ECONOMICS
                                </div>
                            </div>
                        </div>
                       
                        <div class="clear-both"></div>
                    </div>

                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm " style="padding-bottom: 10px;">
                                <div>ECONOMIST/ STRATEGIST
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ec_1', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('ec_1', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('ec_1', data?.dataMap?.page18?.second_runnerup)}" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.economics.ec_1}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm" style="padding-bottom: 10px;">
                                <div>BUDGET ANALYST
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ec_2', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('ec_2', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('ec_2', data?.dataMap?.page18?.second_runnerup)}" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.economics.ec_2}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm " style="padding-bottom: 10px;">
                                <div>OPERATION RESEARCH ANALYST
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ec_3', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('ec_3', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('ec_3', data?.dataMap?.page18?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.economics.ec_3}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm" style="padding-bottom: 15px;">
                                <div>RISK MANAGEMENT ANALYST
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ec_4', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('ec_4', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('ec_4', data?.dataMap?.page18?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.economics.ec_4}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                </div>

                <div class="eighteen-page-careerwidth32">
                    <div style="font-size: 16px;">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careerhuman">
                                <div>CAREER OPTIONS
                                </div>
                            </div>
                        </div>
                        <div class="eighteen-page-careerhumanperc">
                            <div>%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="width-100">
                            <div class="eighteen-page-careerbio">
                                <div>ACTUARIAL SCIENCE

                                </div>
                            </div>
                        </div>
                      
                        <div class="clear-both"></div>
                    </div>

                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm10">
                                <div>INSURANCE/ GENERAL/ LIFE/ HEALTH/ <br>PENSION & RETIREMENT
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('as_1', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('as_1', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('as_1', data?.dataMap?.page18?.second_runnerup) }" class="eighteen-page-careercommperc10">
                            <div>{{data.dataMap.page18.actual_science.as_1}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <!-- <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm10">
                                <div>WAREHOUSE/ FLOOR/ LOBBY/ OUTLET/
                                    <br> DEPARTMENT/ MATERIAL
                                </div>
                            </div>
                        </div>
                        <div class="eighteen-page-careercommperc10">
                            <div>99%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div> -->
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm10">
                                <div>ACTUARY CONSULTING/ INVESTMENT/ <br>RISK MANAGEMENT/ SURVEYOR
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('as_2', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('as_2', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('as_2', data?.dataMap?.page18?.second_runnerup) }" class="eighteen-page-careercommperc10">
                            <div>{{data.dataMap.page18.actual_science.as_2}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="width-100">
                            <div class="eighteen-page-careerbio">
                                <div>C.A. (Chartered Accountant)
                                </div>
                            </div>
                        </div>
                        
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm10">
                                <div>ACCOUNTING/ AUDITING/ TAX CONSULTANT/<br> PROJECT REPORT
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ca_1', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('ca_1', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('ca_1', data?.dataMap?.page18?.second_runnerup)}" class="eighteen-page-careercommperc10">
                            <div>{{data.dataMap.page18.ca.ca_1}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm10">
                                <div>STOCK BROKING/ PORTFOLIO MANAGEMENT/<br> MUTUAL FUND
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('ca_2', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('ca_2', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('ca_2', data?.dataMap?.page18?.second_runnerup)}" class="eighteen-page-careercommperc10">
                            <div>{{data.dataMap.page18.ca.ca_2}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="width-100">
                            <div class="eighteen-page-careerbio">
                                <div>C.S. (Company Secretary)
                                </div>
                            </div>
                        </div>
                       
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>LEGAL/ SECRETARIAL & CORPORATE
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('cs_1', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('cs_1', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('cs_1', data?.dataMap?.page18?.second_runnerup)}" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.cs.cs_1}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>GOVERNANCE RESTRUCTURING/ ADVISORY
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('cs_2', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('cs_2', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('cs_2', data?.dataMap?.page18?.second_runnerup)}" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.cs.cs_2}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>FOREIGN COLLABORATION/ JOINT VENTURES
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('cs_3', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('cs_3', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('cs_3', data?.dataMap?.page18?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.cs.cs_3}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>ARBITRATION & CONCILIATION
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('cs_4', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('cs_4', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('cs_4', data?.dataMap?.page18?.second_runnerup)}" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.cs.cs_4}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>PROJECT PLANNING
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('cs_5', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('cs_5', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('cs_5', data?.dataMap?.page18?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.cs.cs_5}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="width-100">
                            <div class="eighteen-page-careerbio">
                                <div>FINANCE

                                </div>
                            </div>
                        </div>
                       
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm10">
                                <div>CFP/ CFA/ ASSET MANAGEMENT/ FINANCIAL <br>PLANNING/ CONSULTING
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('fi_1', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('fi_1', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('fi_1', data?.dataMap?.page18?.second_runnerup) }" class="eighteen-page-careercommperc10">
                            <div>{{data.dataMap.page18.finance.fi_1}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>FUNDAMENTAL ANALYST/ TREASURER
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('fi_2', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('fi_2', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('fi_2', data?.dataMap?.page18?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.finance.fi_2}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>CORPORATE FINANCE & BANKING
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('fi_3', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('fi_3', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('fi_3', data?.dataMap?.page18?.second_runnerup)}" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.finance.fi_3}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>INVESTMENT BANKING/ PRIVATE EQUITY & VENTURE CAPITAL
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('fi_4', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('fi_4', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('fi_4', data?.dataMap?.page18?.second_runnerup)}" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.finance.fi_4}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm10">
                                <div>FRM/ CREDIT & RISK MANAGEMENT/ MANAGE
                                    <br>HEDGE-FUND / DERIVATIVES STRUCTURING
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('fi_5', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('fi_5', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('fi_5', data?.dataMap?.page18?.second_runnerup)}" class="eighteen-page-careercommperc10">
                            <div>{{data.dataMap.page18.finance.fi_5}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>CMT/ TECHNICAL STOCK RESEARCH ANALYST
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('fi_6', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('fi_6', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('fi_6', data?.dataMap?.page18?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.finance.fi_6}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="width-100">
                            <div class="eighteen-page-careerbio">
                                <div>BANK P.O. (Probationary Officer)
                                </div>
                            </div>
                        </div>
                        
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>ACCOUNTS CLERK
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('bo_1', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('bo_1', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('bo_1', data?.dataMap?.page18?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.bank.bo_1}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>ADMINISTRATIVE ASSISTANT
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{'highlight': isWinner('bo_2', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('bo_2', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('bo_2', data?.dataMap?.page18?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.bank.bo_2}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>CASHIER
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('bo_3', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('bo_3', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('bo_3', data?.dataMap?.page18?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.bank.bo_3}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>CREDIT OFFICER
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('bo_4', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('bo_4', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('bo_4', data?.dataMap?.page18?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.bank.bo_4}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>INSURANCE OFFICER
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('bo_5', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('bo_5', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('bo_5', data?.dataMap?.page18?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.bank.bo_5}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>POSTAL EMPLOYEE
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('bo_6', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('bo_6', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('bo_6', data?.dataMap?.page18?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.bank.bo_6}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="width-100">
                            <div class="eighteen-page-careerbio">
                                <div>CMA (Cost & Management Accounting)
                                </div>
                            </div>
                        </div>
                       
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm10">
                                <div>FINANCE DIRECTOR/ FINANCIAL CONSULTANT <br>& AUDITING
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{'highlight': isWinner('cma_1', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('cma_1', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('cma_1', data?.dataMap?.page18?.second_runnerup) }" class="eighteen-page-careercommperc10">
                            <div>{{data.dataMap.page18.cma.cma_1}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>FINANCIAL CONTROLLER/ COST CONTROLLEF
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('cma_2', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('cma_2', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('cma_2', data?.dataMap?.page18?.second_runnerup)}" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.cma.cma_2}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>ASSOCIATE PROFESSOR
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('cma_3', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('cma_3', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('cma_3', data?.dataMap?.page18?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.cma.cma_3}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="width-100">
                            <div class="eighteen-page-careerbio">
                                <div>STATISTICIAN
                                </div>
                            </div>
                        </div>
                        
                        <div class="clear-both"></div>
                    </div>

                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm10">
                                <div>ANALYSIS-RISK/ CONTENT/ INVESTMENT/ <br>RESEARCH
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('st_1', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('st_1', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('st_1', data?.dataMap?.page18?.second_runnerup) }" class="eighteen-page-careercommperc10">
                            <div>{{data.dataMap.page18.statistician.st_1}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>ELECTION POLLS /ONLINE SERVICE
                                    
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('st_2', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('st_2', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('st_2', data?.dataMap?.page18?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.statistician.st_2}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="mt-5 flex-row">
                        <div class="eighteen-page-width79">
                            <div class="eighteen-page-careercomm">
                                <div>BIOSTATISTICS
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ 'highlight': isWinner('st_3', data?.dataMap?.page18?.winner),
                        'first_runnerup': isWinner('st_3', data?.dataMap?.page18?.first_runnerup),
                        'second_runnerup': isWinner('st_3', data?.dataMap?.page18?.second_runnerup) }" class="eighteen-page-careercommperc">
                            <div>{{data.dataMap.page18.statistician.st_3}}%</div>
                        </div>
                        <div class="clear-both"></div>
                    </div>

                </div>
            </div>
            <div class="clear-both"></div>
        </div>
    </div>
</div>