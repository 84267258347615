<div class="sixth-page mt-40">
    <div>
      <div class="text-right">
        <img class="logo-img" src="assets/images/Header.png" />
      </div>
      <div class="mt-10" style="background-color: #e6e7e8!important;">
        <div class="">
          <div class="sixth-page-fs1" style="width: 1%; "><strong>Q</strong></div>
          <div class="sixth-page-fs1" style="width: 93%; ;"><strong>How is it possible to analyze precisely about the functionalities of brain from fingerprints?</strong> </div>
          <div class="clear-both"></div>
        </div>
        <div class="" style="position: relative;">
          <div class="sixth-page-fs2" style="width: 1%; "><strong>A</strong></div>
          <div class="sixth-page-fs3" style="width: 77%; ; text-align: justify; padding-right: 10px;">
            The strong correlation between brain and fingerprints was first noticed accidently by American 
            doctors who discovered babies born with no brain (or defected brain) had irregular fingerprints. 
            The neural tube defect, also famously known as <span style="text-decoration: underline;">Anencephaly</span> is the absence of the prime brain portions, 
            skull as well as scalp. It occurs due to failure in complete and proper development of cephalic end of neural tube. 
            A fetus born with such deformity usually has no forebrain. 
            It has been observed that such babies are devoid of any fingerprint ridges too.
          </div>
          <div class="sixth-page-first-box" style="width: 13%; padding-top: 0px; position: relative;">
            <img src="assets/images/Page 6/page6-img1.png"  style="width: 100%; position: absolute; right: 0;top: 2px;">
          </div>
          <div class="clear-both"></div>
        </div>
      </div>
       <div class="text-center mt-20">
        <img style="width: 80%;" src="assets/images/Page 6/page6-img2.png" >   
       </div>
       <div class="mt-10" style="text-align: justify; padding-right: 11px; font-size: 15px;">
        Medical science has confirmed that during the growth of the fetus within the mother's womb from the 13th — 21st week, fingerprints are formed simultaneously along with the development of the neocortex and are closely related to the 
        infant's brain development. The distribution of prints is considered to represent the proportion and 
        distribution of brain cells in each of the brain lobes. Through research, geneticists have proven that 
        dermatoglyph formations are controlled by chromosomes, and the patterns are influenced by polygenetic 
        inheritance. As the science progressed, more patterns and sub-patterns of fingerprints were discovered
         and their precise relations with human behavior were established. To view some of the research papers
        on the subject visit our website www.brainography.com
       </div>
       <div class="mt-10" style="text-align: justify; padding-right: 11px; font-size: 15px;">
        The development of all the 6 Input Zones is a gradual process right from Mother's womb
        up to 8 years of Age. Fine motors develop first followed by Gross motors (in Mother's womb before birth). 
        Rest all the Zones start developing one after another as the child grows.
       </div>
       <div class="mt-20" style="background-color: #e6e7e8!important;">
        <div class="">
          <div class="sixth-page-fs1" style="width: 1%; ; padding-top: 8px;"><strong>Q</strong></div>
          <div class="sixth-page-fs1" style="width: 93%; ; padding-top: 8px;"><strong>What should I do with this information? Can I improve on my Limitations?</strong> </div>
          <div class="clear-both"></div>
        </div>
        <div class="" style="position: relative;">
          <div class="sixth-page-fs2" style="width: 1%; ;"><strong>A</strong></div>
          <div class="sixth-page-fs3" style="width: 77%; ; padding-bottom: 18px; text-align: justify; padding-right: 10px;">
            Explore your strengths and work hard on your limitations. Study indicates that by applying strong 
            willpower and Repetitive learning the capabilities can be enhanced further 8 %, however the 
            preferred Input method of the brain 
            will always be biased towards the Strong Zones.
          </div>
          <div class="sixth-page-first-box" style="width: 13%; padding-top: 0px; position: relative;">
            <img src="assets/images/Page 6/page6-img3.png"  style="width: 100%; position: absolute; right: 0;top: -24px;">
          </div>
          <div class="clear-both"></div>
        </div>
      </div>
      <div class="mt-20" style="background-color: #e6e7e8!important;">
        <div class="">
          <div class="sixth-page-fs1" style="width: 1%; "><strong>Q</strong></div>
          <div class="sixth-page-fs1" style="width: 93%; ;"><strong>Will I face difficulties due to the weaker zones?</strong> </div>
          <div class="clear-both"></div>
        </div>
        <div class="" style="position: relative;">
          <div class="sixth-page-fs2" style="width: 1%; "><strong>A</strong></div>
          <div class="sixth-page-fs3" style="width: 77%; ; text-align: justify; padding-bottom: 10px; padding-right: 10px;">
            Not if you are not competing with someone who is stronger than you in that particular zone. 
            Everyone have their own Strengths and Limitations. Within your 6 input zones, the Stronger zones 
            compensate for your weaker Zones. Brain does not function Zone wise. It works in Synergy where all
             the 10 Lobes contribute in their individual capacities. So yes, the Decisions and Actions of
              an individual are always influenced by the Stronger Zones. Also nurturing of all the zones is equally important. Without proper nurturing even 
            the strong zones may fail to deliver efficiently.
          </div>
          <div class="sixth-page-first-box" style="width: 13%; padding-top: 0px; position: relative;">
            <img src="assets/images/Page 6/page6-img4.png"  style="width: 100%; position: absolute; right: 0;top: -12px;">
          </div>
          <div class="clear-both"></div>
        </div>
      </div>
      
      
      <!-- <div style="margin-top: 5px;">
        <div class="footer-elem">
          Evaluation# {{ data.footer.evalNo }}
        </div>
        <div class="footer-elem2 text-left">
          Date
        </div>
        <div class="footer-elem1">
          @Copyright - Siyasaar Enterprise
        </div>
        <div class="footer-elem">
          www.brainography.com
        </div>
        <div class="footer-elem">
          Design By Freepik
        </div>

        <div class="clear-both"></div>
      </div> -->
    </div>
  </div>
