<div class="thirteen-page mt-40">
    <div class="text-right">
        <img class="logo-img" src="assets/images/Header.png" />
    </div>
    <div class="mt-20" style="background-color: #e6e7e8;">
        <div class="tenth-page-row">
          <div class="fifth-page-first-box" style="width: 2%; font-size: 14px;"><strong>Q</strong></div>
          <div class="fifth-page-first-box" style="width: 94%; font-size: 14px;"><strong>So as a stress buster what should
             I prefer ?</strong> </div>
          <div class="clear-both"></div>
        </div>
        <div class="tenth-page-row">
          <div class="fifth-page-first-box" style="width: 2%; font-size: 14px;"><strong>A</strong></div>
          <div class="fifth-page-first-box" style="width: 94%; text-align: justify; font-size: 14px; ">
            Select anything from a list of your Passion from page no 10 & 11. 
            Also check this list of some&nbsp;unique&nbsp;hobbies
          </div>
          <div class="clear-both"></div>
        </div>
    </div>
    <div class="graph">
    <div class="mt-20">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Story, Poetry, Lyrics etc. Writing  </div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth81" [ngStyle]="{'width': data.dataMap.page13.hobby_1+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_1 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_1 > 40 && data.dataMap.page13.hobby_1 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_1 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_1+'%' }">{{data.dataMap.page13.hobby_1}}%</span> 
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
      
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Baking, Noodling </div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth60" [ngStyle]="{'width': data.dataMap.page13.hobby_2+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_2 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_2 > 40 && data.dataMap.page13.hobby_2 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_2 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_2+'%' }">{{data.dataMap.page13.hobby_2}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Learning how to DIY </div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth60" [ngStyle]="{'width': data.dataMap.page13.hobby_3+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_3 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_3 > 40 && data.dataMap.page13.hobby_3 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_3 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_3+'%' }">{{data.dataMap.page13.hobby_3}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Thrifting & Selling back online  </div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth81" [ngStyle]="{'width': data.dataMap.page13.hobby_4+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_4 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_4 > 40 && data.dataMap.page13.hobby_4 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_4 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_4+'%' }">{{data.dataMap.page13.hobby_4}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Making Perfumes, Soaps, Jams etc. </div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth68" [ngStyle]="{'width': data.dataMap.page13.hobby_5+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_5 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_5 > 40 && data.dataMap.page13.hobby_5 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_5 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_5+'%' }">{{data.dataMap.page13.hobby_5}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Astrology  </div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth50" [ngStyle]="{'width': data.dataMap.page13.hobby_6+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_6 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_6 > 40 && data.dataMap.page13.hobby_6 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_6 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_6+'%' }">{{data.dataMap.page13.hobby_6}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Sewing, Embroidery, Crocheting, Tapestry, Quilting etc. </div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth81" [ngStyle]="{'width': data.dataMap.page13.hobby_7+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_7 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_7 > 40 && data.dataMap.page13.hobby_7 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_7 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_7+'%' }">{{data.dataMap.page13.hobby_7}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Optical Illusion Games   </div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth56" [ngStyle]="{'width': data.dataMap.page13.hobby_8+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_8 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_8 > 40 && data.dataMap.page13.hobby_8 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_8 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_8+'%' }">{{data.dataMap.page13.hobby_8}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Mountaineering, Rock climbing, Bouldering  </div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth77" [ngStyle]="{'width': data.dataMap.page13.hobby_9+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_9 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_9 > 40 && data.dataMap.page13.hobby_9 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_9 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_9+'%' }">{{data.dataMap.page13.hobby_9}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Robot Making   </div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth50" [ngStyle]="{'width': data.dataMap.page13.hobby_10+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_10 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_10 > 40 && data.dataMap.page13.hobby_10 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_10 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_10+'%' }">{{data.dataMap.page13.hobby_10}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Stock Market Games   </div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth56" [ngStyle]="{'width': data.dataMap.page13.hobby_11+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_11 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_11 > 40 && data.dataMap.page13.hobby_11 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_11 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_11+'%' }">{{data.dataMap.page13.hobby_11}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Unique Crafts- Model building, Pottery, Macrame, Lapidary etc.  </div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth77" [ngStyle]="{'width': data.dataMap.page13.hobby_12+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_12 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_12 > 40 && data.dataMap.page13.hobby_12 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_12 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_12+'%' }">{{data.dataMap.page13.hobby_12}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Star Gazing </div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth81" [ngStyle]="{'width': data.dataMap.page13.hobby_13+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_13 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_13 > 40 && data.dataMap.page13.hobby_13 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_13 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_13+'%' }">{{data.dataMap.page13.hobby_13}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Juggling, Ventriloquizing, Magic Tricks </div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth56" [ngStyle]="{'width': data.dataMap.page13.hobby_14+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_14 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_14 > 40 && data.dataMap.page13.hobby_14 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_14 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_14+'%' }">{{data.dataMap.page13.hobby_14}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Designing Anagrams </div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth56" [ngStyle]="{'width': data.dataMap.page13.hobby_15+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_15 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_15 > 40 && data.dataMap.page13.hobby_15 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_15 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_15+'%' }">{{data.dataMap.page13.hobby_15}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Marathon Running  </div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth45" [ngStyle]="{'width': data.dataMap.page13.hobby_16+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_16 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_16 > 40 && data.dataMap.page13.hobby_16 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_16 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_16+'%' }">{{data.dataMap.page13.hobby_16}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Play Sudoku, Board Games, Solve Mazes  </div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth81" [ngStyle]="{'width': data.dataMap.page13.hobby_17+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_17 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_17 > 40 && data.dataMap.page13.hobby_17 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_17 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_17+'%' }">{{data.dataMap.page13.hobby_17}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Practicing & Teaching Survival & self-defence Skills   </div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth45" [ngStyle]="{'width': data.dataMap.page13.hobby_18+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_18 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_18 > 40 && data.dataMap.page13.hobby_18 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_18 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_18+'%' }">{{data.dataMap.page13.hobby_18}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Spirituality  </div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth56" [ngStyle]="{'width': data.dataMap.page13.hobby_19+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_19 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_19 > 40 && data.dataMap.page13.hobby_19 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_19 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_19+'%' }">{{data.dataMap.page13.hobby_19}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Weekend Adventuring, Trivia Contests  </div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth45" [ngStyle]="{'width': data.dataMap.page13.hobby_20+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_20 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_20 > 40 && data.dataMap.page13.hobby_20 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_20 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_20+'%' }">{{data.dataMap.page13.hobby_20}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Blogging, Podcasting  </div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth56" [ngStyle]="{'width': data.dataMap.page13.hobby_21+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_21 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_21 > 40 && data.dataMap.page13.hobby_21 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_21 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_21+'%' }">{{data.dataMap.page13.hobby_21}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Pet Sitting, Fish Keeping, Reef Aquariums   </div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth68" [ngStyle]="{'width': data.dataMap.page13.hobby_22+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_22 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_22 > 40 && data.dataMap.page13.hobby_22 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_22 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_22+'%' }">{{data.dataMap.page13.hobby_22}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Lock picking ( Unlocking Doors, Cars, Safes etc. without keys)  </div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth81" [ngStyle]="{'width': data.dataMap.page13.hobby_23+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_23 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_23 > 40 && data.dataMap.page13.hobby_23 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_23 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_23+'%' }">{{data.dataMap.page13.hobby_23}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Tattooing  </div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth45" [ngStyle]="{'width': data.dataMap.page13.hobby_24+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_24 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_24 > 40 && data.dataMap.page13.hobby_24 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_24 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_24+'%' }">{{data.dataMap.page13.hobby_24}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Singing, Rapping etc.  </div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth81" [ngStyle]="{'width': data.dataMap.page13.hobby_25+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_25 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_25 > 40 && data.dataMap.page13.hobby_25 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_25 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_25+'%' }">{{data.dataMap.page13.hobby_25}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Organizing Clean Up Drives ( Neighbourhood, Beaches etc.) </div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth56" [ngStyle]="{'width': data.dataMap.page13.hobby_26+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_26 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_26 > 40 && data.dataMap.page13.hobby_26 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_26 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_26+'%' }">{{data.dataMap.page13.hobby_26}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Social Service, Volunteering </div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth56" [ngStyle]="{'width': data.dataMap.page13.hobby_27+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_27 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_27 > 40 && data.dataMap.page13.hobby_27 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_27 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_27+'%' }">{{data.dataMap.page13.hobby_27}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Cooking, Barbequing, Recipe Creation</div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth70" [ngStyle]="{'width': data.dataMap.page13.hobby_28+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_28 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_28 > 40 && data.dataMap.page13.hobby_28 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_28 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_28+'%' }">{{data.dataMap.page13.hobby_28}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Playing Fine Musical Instruments</div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth50" [ngStyle]="{'width': data.dataMap.page13.hobby_29+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_29 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_29 > 40 && data.dataMap.page13.hobby_29 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_29 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_29+'%' }">{{data.dataMap.page13.hobby_29}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Playing Gross Musical Instruments </div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth70" [ngStyle]="{'width': data.dataMap.page13.hobby_30+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_30 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_30 > 40 && data.dataMap.page13.hobby_30 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_30 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_30+'%' }">{{data.dataMap.page13.hobby_30}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Reading </div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth70" [ngStyle]="{'width': data.dataMap.page13.hobby_31+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_31 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_31 > 40 && data.dataMap.page13.hobby_31 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_31 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_31+'%' }">{{data.dataMap.page13.hobby_31}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Learning Foreign Language </div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth56" [ngStyle]="{'width': data.dataMap.page13.hobby_32+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_32 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_32 > 40 && data.dataMap.page13.hobby_32 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_32 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_32+'%' }">{{data.dataMap.page13.hobby_32}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Making Music, EDM, Recording, Remixing Song/Fusion  </div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth70" [ngStyle]="{'width': data.dataMap.page13.hobby_33+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_33 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_33 > 40 && data.dataMap.page13.hobby_33 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_33 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_33+'%' }">{{data.dataMap.page13.hobby_33}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Palmistry, Tarot Reading  </div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth56" [ngStyle]="{'width': data.dataMap.page13.hobby_34+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_34 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_34 > 40 && data.dataMap.page13.hobby_34 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_34 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_34+'%' }">{{data.dataMap.page13.hobby_34}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Debating  </div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth56" [ngStyle]="{'width': data.dataMap.page13.hobby_35+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_35 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_35 > 40 && data.dataMap.page13.hobby_35 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_35 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_35+'%' }">{{data.dataMap.page13.hobby_35}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Collecting Stamps, Coins, Sea- Shells, Rocks etc.   </div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth70" [ngStyle]="{'width': data.dataMap.page13.hobby_36+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_36 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_36 > 40 && data.dataMap.page13.hobby_36 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_36 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_36+'%' }">{{data.dataMap.page13.hobby_36}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Finger Painting, Scrapbooking, Taxidermy, Wall Art etc.   </div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth56" [ngStyle]="{'width': data.dataMap.page13.hobby_37+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_37 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_37 > 40 && data.dataMap.page13.hobby_37 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_37 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_37+'%' }">{{data.dataMap.page13.hobby_37}}%</span>
        </div>
        

         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Photography, Lomography  </div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth70" [ngStyle]="{'width': data.dataMap.page13.hobby_38+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_38 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_38 > 40 && data.dataMap.page13.hobby_38 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_38 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_38+'%' }">{{data.dataMap.page13.hobby_38}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Gardening of Exotic plants, Bonsais, Terrarium Making etc.   </div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth56" [ngStyle]="{'width': data.dataMap.page13.hobby_39+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_39 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_39 > 40 && data.dataMap.page13.hobby_39 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_39 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_39+'%' }">{{data.dataMap.page13.hobby_39}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Feng Shui Decorating  </div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth40" [ngStyle]="{'width': data.dataMap.page13.hobby_40+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_40 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_40 > 40 && data.dataMap.page13.hobby_40 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_40 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_40+'%' }">{{data.dataMap.page13.hobby_40}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Repairing Gadgets</div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth70" [ngStyle]="{'width': data.dataMap.page13.hobby_41+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_41 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_41 > 40 && data.dataMap.page13.hobby_41 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_41 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_41+'%' }">{{data.dataMap.page13.hobby_41}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="">
      <div class="thirteen-page-stressbustrsec">
        <div class="" style="float: right;">Origami, Tangram, Matching Games etc </div>
       </div>
       <div class="thirteen-page-stressbustrsec1" >
        <div class="thirteen-page-stressbustrsecwidth99" [ngStyle]="{'width': data.dataMap.page13.hobby_42+'%' }">
          <div [ngClass]="{'thirteen-page-stressbustrsecred': data.dataMap.page13.hobby_42 < 41 ,
                           'thirteen-page-stressbustrsecblue':  data.dataMap.page13.hobby_42 > 40 && data.dataMap.page13.hobby_42 < 66,
                           'thirteen-page-stressbustrsecgreen' : data.dataMap.page13.hobby_42 > 65}">

          </div>
          <span class="perc-label" [ngStyle]="{'left': data.dataMap.page13.hobby_42+'%' }">{{data.dataMap.page13.hobby_42}}%</span>
        </div>
        
         <div class="clear-both"></div>
      </div>
      <div class="clear-both"></div>
    </div>
  </div>
  <div class="clear-both"></div>
  <div style="margin-top: 5px; margin-bottom: 10px;">
    <div class="clear-both"></div>
    <div>
  <div class="" style="text-align: center; font-weight: bold; font-size: 14px;">
    <span style=" margin-right: 4px; margin-left: 40px;">Just Fair : </span><span style="background-color: red; padding: 2px 5px; color: #ffffff;">RED</span>
   <span style="margin-left: 23px; margin-right: 4px;"> Good :</span> <span style="background-color: blue; padding: 2px 5px; color: #ffffff;">BLUE</span> 
   <span style="margin-left: 23px; margin-right: 4px;">Excellent :</span> <span style="background-color: green; padding: 2px 5px; color: #ffffff;">GREEN</span>
  </div>
</div>
  </div>
</div>