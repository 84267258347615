import { Component, OnInit } from '@angular/core';
import { SidenavService } from '../services/sidenav.service';
import { ReportService } from '../services/report.service';
import { ActivatedRoute } from '@angular/router';
import { CryptoService } from '../crypto.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
  
  data: any;

  constructor(private sideNavService: SidenavService, private reportService: ReportService, private route: ActivatedRoute, private cryptoService: CryptoService) { }

  ngOnInit(): void {
    var dealerClientId = this.route.snapshot.paramMap.get('dealerClientId');
    var clientId = this.route.snapshot.paramMap.get('clientId');
    var sequenceClientId = this.route.snapshot.paramMap.get('sequenceClientId');

    // var lactose = this.route.snapshot.paramMap.get('lactoseId');
    // var gluten = this.route.snapshot.paramMap.get('glutenId');

    this.loadReportData(dealerClientId, clientId, sequenceClientId);
  }

  loadReportData(dealerClientId, clientId, sequenceClientId) {
    var dataModel = { "dealer_client_id":dealerClientId, "clientId": clientId, "sequence_client_id": parseInt(sequenceClientId)
                      // "lactose": parseInt(lactose), "gluten": parseInt(gluten)
                    };

    var payload1 = JSON.stringify(dataModel).toString();
    var loginPayload = this.cryptoService.getEncryptedBody(localStorage.getItem("guid"), payload1);
    //console.log("encrypted model: ", loginPayload.toString());

    var model = {
      //"headers": { "x-shyld-app-id": congnitoIdEnc },
      "data": loginPayload.toString()
    }
    
    this.reportService.loadReportData(model).subscribe((result: any) => {
      var res = JSON.parse(this.cryptoService.getDecryptedBody(localStorage.getItem('guid'), result.data));
      console.log("report data : ", res);
      // var result = res.dataMap;
      this.data = res;
    });
  }

  // ngAfterViewInit() {
  //   setTimeout(() => this.sideNavService.close(), 100);
  // }
}
