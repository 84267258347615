<div class="twentyfirst-page mt-20">
    <div class="text-right">
        <img class="logo-img" src="assets/images/Header.png" />
    </div>

    <div class="mt-5">
        <span class="twentyth-page-PQ">2) YOUR FORTE</span>

        <div class="pad5 pad-top">
            Now let's explore your FORTE. Irrespective to your preferred Professional Quadrants,
         you will be required to identify your inborn potential. Your profession will require you to perform these basic
          5 common universal tasks. So, discover your comfort zone and figure out which task suits you better. 
        </div>

    </div>

    <div class="mt-5">
        <div class="row-wrapper">
            <div class="column-one">
                <span class="twentyth-page-section-heading">
                    ADMINISTRATION:
                </span>
                <span class="text-bold" style="text-decoration: underline;">The Ability to Manage</span>

                <div>
                    Competence of putting things into perspective and getting the job done by reviewing efforts and contributions of self and others. 
                </div>
            </div>
            <div class="column-two">
                <div class="perc-col">
                    {{data.dataMap.page21.admin}}%<br />
                    {{data.dataMap.page21.admin_2}}
                </div>
            </div>
        </div>

        <div class="row-wrapper">
            <div class="column-one">
                <span class="twentyth-page-section-heading">
                    PLANNING:
                </span>
                <span class="text-bold" style="text-decoration: underline;">The Ability to take Right Decision</span>

                <div>
                    Expertise in analyzing all significant aspects and sequencing them in right order to determine the next course of action. 
                </div>
            </div>
            <div class="column-two">
                <div class="perc-col">
                    {{data.dataMap.page21.planning}}%<br />
                    {{data.dataMap.page21.planning_2}}
                </div>
            </div>
        </div>

        <div class="row-wrapper">
            <div class="column-one">
                <span class="twentyth-page-section-heading">
                    EXECUTION:
                </span>
                <span class="text-bold" style="text-decoration: underline;">The Ability to Accomplish</span>

                <div>
                    Capacity and skills to indulge physically, to perform a task persistently, utilizing, Dexterity, Stamina and Endurance. 
                </div>
            </div>
            <div class="column-two">
                <div class="perc-col">
                    {{data.dataMap.page21.execution}}%<br />
                    {{data.dataMap.page21.execution_2}}
                </div>
            </div>
        </div>

        <div class="row-wrapper">
            <div class="column-one">
                <span class="twentyth-page-section-heading">
                    CORRESPONDENCE:
                </span>
                <span class="text-bold" style="text-decoration: underline;">The Ability to Communicate</span>

                <div>
                    Natural propensity to read between the lines, understanding verbal & non-verbal communication & responding appropriately to generate desired results. 
                </div>
            </div>
            <div class="column-two">
                <div class="perc-col">
                    {{data.dataMap.page21.correspondence}}%<br />
                    {{data.dataMap.page21.correspondence_2}}
                </div>
            </div>
        </div>

        <div class="row-wrapper">
            <div class="column-one">
                <span class="twentyth-page-section-heading">
                    EXPLORATION:
                </span>
                <span class="text-bold" style="text-decoration: underline;">The Ability to Probe and Update</span>

                <div>
                    Efficiency to observe how the world is progressing and moving forward, getting into details and applying foresight to upgrade to next generation 
                </div>
            </div>
            <div class="column-two">
                <div class="perc-col">
                    {{data.dataMap.page21.exploration}}%<br />
                    {{data.dataMap.page21.exploration_2}}
                </div>
            </div>
        </div>
    </div>

    <div class="mt-10">
        <span class="twentyth-page-PQ">3) YOUR PERSONAL QUOTIENTS</span>

        <div class="mt-5 row-wrapper">
            <div class="content-section">
                Your personal Quotient is a degree and a measure of a specified quality in yourself, which is inborn and constant. 
                Although there can be endless sets of specified qualities, but these 4 most relevant ones can specify your strength and 
                limitations in the field of your profession. Namely, IQ, EQ, AQ, and CQ. Let's explore one at a time. 
            </div>
            <div class="graph-section">
                <div class="bar-col">
                    <span class="text-bold" [ngStyle]="{'bottom': addTwelve(data.dataMap.page21.iq)+'px' }"> {{data.dataMap.page21.iq}}%
                        <br /> {{data.dataMap.page21.iq_2}}
                    </span>
                    <div></div>
                    <div class="bar" [ngStyle]="{'height': addTwelve(data.dataMap.page21.iq)+'px' }" style="background-color: rgb(245, 185, 190);">
                        <div class="bar-index">IQ</div>
                    </div>
                </div>
                <div class="bar-col">
                    <span class="text-bold" [ngStyle]="{'bottom': addTwelve(data.dataMap.page21.eq)+'px' }">{{data.dataMap.page21.eq}}%
                        <br />{{data.dataMap.page21.eq_2}}
                    </span>
                    <div></div>
                    <div class="bar" [ngStyle]="{'height': addTwelve(data.dataMap.page21.eq)+'px' }" style="background-color: rgb(149, 207, 176);">
                        <div class="bar-index">EQ</div>
                    </div>
                </div>
                <div class="bar-col">
                    <span class="text-bold" [ngStyle]="{'bottom': addTwelve(data.dataMap.page21.aq)+'px' }">{{data.dataMap.page21.aq}}%
                        <br />{{data.dataMap.page21.aq_2}}
                    </span>
                    <div></div>
                    <div class="bar" [ngStyle]="{'height': addTwelve(data.dataMap.page21.aq)+'px' }" style="background-color: rgb(64, 182, 237);">
                        <div class="bar-index">AQ</div>
                    </div>
                </div>
                <div class="bar-col">
                    <span class="text-bold" [ngStyle]="{'bottom': addTwelve(data.dataMap.page21.cq)+'px' }">{{data.dataMap.page21.cq}}%
                        <br />{{data.dataMap.page21.cq_2}}
                    </span>
                    <div></div>
                    <div class="bar" [ngStyle]="{'height': addTwelve(data.dataMap.page21.cq)+'px' }" style="background-color: rgb(160, 151, 197);">
                        <div class="bar-index">CQ</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="color-boxes" style="background-color: #F8D1C9;">
        <div class="text-bold">IQ - INTELLIGENCE QUOTIENT</div>
        <div>
            It is the measure of your ability to analyze cost-benefit effect logically and communicate it in an effective language to achieve the desired results. It is your level of cognitive skills in Thinking, Perception, Analysis, Synthesis and Reasoning. 
        </div>
    </div>

    <div class="color-boxes" style="background-color:#d7ebdf;">
        <div class="text-bold">EQ - EMOTIONAL QUOTIENT</div>
        <div>
            It is the measure of your ability to manage self- emotions smartly when dealing with others. It is your potential to display self- control with confidence and skills to respond to the feelings of others in a matured way. 
        </div>
    </div>

    <div class="color-boxes" style="background-color:#b6e0f8;">
        <div class="text-bold">AQ - ADVERSITY QUOTIENT</div>
        <div>
            It is the measure of your ability to respond to surprise or unpredicted events or crisis. It is the quantification of your resilience and capacity to live, work and deal with unavoidable & undesirable circumstances such as Flight-or-Fight response, Panic situations, Accidents etc. 
        </div>
    </div>

    <div class="color-boxes" style="background-color: #d8d3e9;">
        <div class="text-bold">CQ - CREATIVITY QUOTIENT</div>
        <div>
            Is the measure of your ability to combine and re-arrange ideas, things, techniques and approaches in a new way to accomplish a task. It is the depth of your creative intelligence with expressiveness and applied innovation. (Right from Intuition to Improvisation). 
        </div>
    </div>
</div>