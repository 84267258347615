import { Injectable } from '@angular/core';
import { Common } from '../model/common.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  common: Common;

  constructor(private http: HttpClient) {
    this.common = new Common();
  }

  loadReportData(model) {
    var reqHeader = new HttpHeaders({ 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Methods': 'POST', 'Origin':'*', 'guid':btoa(localStorage.getItem('guid')) });
    return this.http.post(this.common.APIUrl + '/generatereport', model, { headers: reqHeader });
  }
}
