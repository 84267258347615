<div *ngIf="data" class="fifth-page mt-40">
    <div class="ml-40">
      <div class="text-right">
        <img class="logo-img" src="assets/images/Header.png" />
      </div>

      <div class="text-center mt-10">
        <img style="width: 40%;" src="assets/images/Page 5/Ages 12 &3 Img.png" >
      </div>

      <div class="mt-20" style="background-color: #e6e7e8!important;">
        <div class="fifth-page-row">
          <div class="fifth-page-first-box" style="width: 2%;"><strong>Q</strong></div>
          <div class="fifth-page-first-box" style="width: 90%;"><strong>What Is The First Thing I Should Know?</strong> </div>
          <div class="clear-both"></div>
        </div>
        <div class="fifth-page-row">
          <div class="fifth-page-first-box" style="width: 2%;"><strong>A</strong></div>
          <div class="fifth-page-first-box" style="width: 90%; text-align: justify; ">
            Just like a CPU (Central Processing Unit) of a Computer which receives information from keyboard & mouse, 
            our central nervous system (CNS) receives information from our 5 senses. Our CNS is made up of nerve cells 
            which are called Neurons. They are highly specialized cells responsible for communicating information in both 
            chemical and electrical forms. Our brain collects data with the help of Neurons via 6 input zones. The higher 
            the Neural density stronger the zone. So naturally, stronger input zones will become the most preferred input
             method of the brain by default.
          </div>
          <div class="clear-both"></div>
        </div>
      </div>

      <div class="mt-20" style="background-color: #e6e7e8!important;">
        <div class="fifth-page-row">
          <div class="fifth-page-first-box" style="width: 2%;"><strong>Q</strong></div>
          <div class="fifth-page-first-box" style="width: 90%;"><strong>So what is the most preferred input method of this Brain?</strong> </div>
          <div class="clear-both"></div>
        </div>
      </div>

      <div class="fifth-page-main mt-10">
        <div class="fifth-page-first-main">
          <div style="position: absolute; left: 21px;">A. </div>   LEFT BRAIN
        </div>
        <div class="fifth-page-second-main">
          LEARNING <br/> STYLE
        </div>
        <div class="fifth-page-third-main">
          RIGHT BRAIN
        </div>
        <div class="clear-both"></div>
      </div>

      <div class="fifth-page-main mt-10">
        <div class="fifth-page-first-main-content">
          <div>
            <div class="rank">{{ data.dataMap.page5.fms_rank }}</div>
            <div class="fifth-section-name">FINE MUSCLES</div>
            <div class="perc">{{ data.dataMap.page5.fms_perc }}%</div>
            <div class="clear-both"></div>
          </div>
          <div>
             <div class="fifth-page-left-image">
              <img src="assets/images/Page 5/Fine Muscles.png" />
             </div>
             <div class="fifth-page-left-content Fifthpage20">
             
                <ul>
                  <li>Writing, </li>
                  <li>Gripping, </li>
                  <li>Drawing, </li>
                  <li>Cutting, </li>
                  <li>Decorating, </li>
                  <li>Presentation, </li>
                  <li>Finesse,etc </li>
                  <li style="list-style-type: none;">&nbsp; </li>
                </ul>
              
             </div>
          </div>
        </div>
        <div class="fifth-page-second-main-content">
          <div class="position-relative">
            <img src="assets/images/Page 5/Kinesthetic.png" style="width: 80%;" />
            <div class="Fifth-page-second-content-absolute-num">{{ data.dataMap.page5.kinesthetic_rank }}</div>
          </div>
          
          <div class="clear-both"></div>
          <div class="mt-20 text-center">
            {{ data.dataMap.page5.kinesthetic_perc }}% <br>
            <strong>KINESTHETIC</strong>
          </div>
        </div>

        <div class="fifth-page-third-main-content">
          <div>
            <div class="rank">{{ data.dataMap.page5.gms_rank }}</div>
            <div class="fifth-section-name">GROSS MUSCLES</div>
            <div class="perc">{{ data.dataMap.page5.gms_perc }}%</div>
            <div class="clear-both"></div>
          </div>
          
          <div>
            
            <div class="fifth-page-left-content Fifthpage20">
            
               <ul>
                <li>Walking,</li>
                <li>Running,</li>
                <li>Jumping,</li>
                <li>Hopping</li>
                <li>Dancing,</li>
                <li>Stamina,</li>
                <li>Physical <br/>activities, etc</li>
               </ul>
             
            </div>

            <div class="fifth-page-left-image">
              <img src="assets/images/Page 5/Gross muscles.png" />
             </div>
         </div>

        </div>
        <div class="clear-both"></div>
      </div>

      <div class="fifth-page-main mt-10">
        <div class="fifth-page-first-main-content">
          <div>
            <div class="rank">{{ data.dataMap.page5.linguistic_rank }}</div>
            <div class="fifth-section-name">LINGUISTICS</div>
            <div class="perc">{{ data.dataMap.page5.linguistic_perc }}%</div>
            <div class="clear-both"></div>
          </div>
          <div>
             <div class="fifth-page-left-image">
              <img src="assets/images/Page 5/Linguistics.png" />
             </div>
             <div class="fifth-page-left-content Fifthpage20">
                <ul>
                  <li>Hearing-identification, </li>
                  <li>Word, </li>
                  <li>Vocabulary,</li>
                  <li>Speech </li>
                  <li>Accent</li>
                  <li>Short term-memory etc.</li>
                </ul>
              
             </div>
          </div>
        </div>
        <div class="fifth-page-second-main-content">
          <div class="position-relative">
            <img src="assets/images/Page 5/Auditory.png" style="width: 80%;" />
            <div class="Fifth-page-second-content-absolute-num">{{ data.dataMap.page5.auditory_rank }}</div>
          </div>
          
          <div class="clear-both"></div>
          <div class="mt-20 text-center">
            {{ data.dataMap.page5.auditory_perc }}% <br>
            <strong>AUDITORY</strong>
          </div>
        </div>

        <div class="fifth-page-third-main-content">
          <div>
            <div class="rank">{{ data.dataMap.page5.music_rank }}</div>
            <div class="fifth-section-name">SOUND</div>
            <div class="perc">{{ data.dataMap.page5.music_perc }}%</div>
            <div class="clear-both"></div>
          </div>
          
          <div>
            
            <div class="fifth-page-left-content Fifthpage20">
            
               <ul>
                <li>Rhythm</li>
                <li>Tunes</li>
                <li>Pitch</li>
                <li>Timbre</li>
                <li>Feelings </li>
                <li>Emotions,</li>
                <li>Sound-Patterns etc.</li>
               </ul>
             
            </div>

            <div class="fifth-page-left-image">
              <img src="assets/images/Page 5/Sound.png" />
             </div>
         </div>

        </div>

        <div class="clear-both"></div>
      </div>

      <div class="fifth-page-main mt-10">
        <div class="fifth-page-first-main-content">
          <div>
            <div class="rank">{{ data.dataMap.page5.minute_rank }}</div>
            <div class="fifth-section-name">TEXT</div>
            <div class="perc">{{ data.dataMap.page5.minute_perc }}%</div>
            <div class="clear-both"></div>
          </div>
          <div>
             <div class="fifth-page-left-image">
              <img src="assets/images/Page 5/Text.png" />
             </div>
             <div class="fifth-page-left-content Fifthpage20">
                <ul>
                  <li>Written documents</li>
                  <li> Font size</li>
                  <li>Arithmetic (numbers)</li>
                  <li> Trajectory </li>
                  <li>Minute - Observation etc. </li>
                </ul>
              
             </div>
          </div>
        </div>
        <div class="fifth-page-second-main-content">
          <div class="position-relative">
            <img src="assets/images/Page 5/visual.png" style="width: 80%;" />
            <div class="Fifth-page-second-content-absolute-num">{{ data.dataMap.page5.visual_rank }}</div>
          </div>
          
          <div class="clear-both"></div>
          <div class="mt-20 text-center">
            {{ data.dataMap.page5.visual_perc }}% <br>
            <strong>VISUAL</strong>
          </div>
        </div>

        <div class="fifth-page-third-main-content">
          <div>
            <div class="rank">{{ data.dataMap.page5.twod_rank }}</div>
            <div class="fifth-section-name">PICTURE</div>
            <div class="perc">{{ data.dataMap.page5.twod_perc }}%</div>
            <div class="clear-both"></div>
          </div>
          
          <div>
            
            <div class="fifth-page-left-content Fifthpage20">
            
               <ul>
                <li>Shapes</li>
                <li>Sizes</li>
                <li>2D objects</li>
                <li>Maps,</li>
                <li>Diagram</li>
                <li>Sketches</li>
                <li>Layouts</li>
                <li>Art etc.</li>
               </ul>
             
            </div>

            <div class="fifth-page-left-image">
              <img src="assets/images/Page 5/Picture.png" />
             </div>
         </div>

        </div>

        <div class="clear-both"></div>
      </div>
      
      <div style="margin-top: 5px;">
        <div class="footer-elem">
          Evaluation# {{ data.dataMap.footer.evalNo }}
        </div>
        <div class="footer-elem2 text-left">
          Date {{ data.dataMap.footer.date }}
        </div>
        <div class="footer-elem1">
          @Copyright - Siyasaar Enterprise
        </div>
        <div class="footer-elem">
          www.brainography.com
        </div>
        <div class="footer-elem">
          Design By Freepik
        </div>

        <div class="clear-both"></div>
      </div>

      <div class="text-center">
        5
      </div>
    </div>
  </div>