<div class="fifthteenth-page mt-40">
    <div class="">
      <div class="text-right">
        <img class="logo-img" src="assets/images/Header.png" />
      </div>
      <div class="" >
        <div class="sixteenth-page-hd1">
           <div class="sixteenth-page-headingsec">
             <div>CAREER PROSPECTS FOR THE STREAM OF BIOLOGICAL SCIENCE (PCB)</div> </div>
          <div class="clear-both"></div>
        </div>
      </div>

       <div class="mt-5 sixteenth-page-fsize" >
        <div class="sixteenth-page-careeroption" >
            CAREER OPTIONS
        </div>
        <div class="sixteenth-page-careeroption1">
            %
        </div>
        <div class="sixteenth-page-careeroption">
            CAREER OPTIONS
        </div>
        <div class="sixteenth-page-careeroption1">
            %
        </div>
        <div class="sixteenth-page-careeroption">
            CAREER OPTIONS
          </div>
          <div class="sixteenth-page-careeroption1" style="border-right: none;">
            %
          </div>
        <div class="clear-both"></div>
      </div>

      <div class="mt-5 flex-row">
        <div class="sixteenth-page-careerhuman" >
          <div>HUMAN BIOLOGY</div>
        </div>
        <!-- <div class="sixteenth-page-careerhumanperc"  >
            <div>10%</div>
        </div> -->
        <div class="sixteenth-page-careergastro">
          <div class="sixteenth-page-pdlft5">GASTROENTEROLOGIST
             <br><span style="font-size: 8px;">Disorders of Stomach & intestine
          </span>
        </div>
        </div>
        <div [ngClass]="{ 
          'highlight': isWinner('sp_1', data?.dataMap?.page16?.winner),
          'first_runnerup': isWinner('sp_1', data?.dataMap?.page16?.first_runnerup),
          'second_runnerup': isWinner('sp_1', data?.dataMap?.page16?.second_runnerup)
      }" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.specialisation.sp_1}}%</div>
        </div>
        <div class="sixteenth-page-careergastro">
          <div class="sixteenth-page-pdlft5"> PULMONOLOGIST<br>
            <span style="font-size: 8px;">Lungs, Respiratory System etc.</span>
          </div>
          </div>
          <div [ngClass]="{
             'highlight': isWinner('sp_2', data?.dataMap?.page16?.winner),
             'first_runnerup': isWinner('sp_2', data?.dataMap?.page16?.first_runnerup),
             'second_runnerup': isWinner('sp_2', data?.dataMap?.page16?.second_runnerup)
            }" class="sixteenth-page-careergastroperc" style="border-right: none;">
            <div>{{data.dataMap.page16.specialisation.sp_2}}%</div>
          </div>
        <div class="clear-both"></div>
      </div>

      <div class="mt-5 flex-row">
        <div class="sixteenth-page-careergastro">
          <div class="sixteenth-page-pdlft5">M.B.B.S.
             <br><span style="font-size: 8px;">Bachelor of Medicine & Surgery
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('hb_1', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('hb_1', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('hb_1', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.human_biology.hb_1}}%</div>
        </div>
        <div class="sixteenth-page-careergastro">
          <div class="sixteenth-page-pdlft5">HEMATOLOGIST
             <br><span style="font-size: 8px;">Diseases related to Blood
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('sp_3', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('sp_3', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('sp_3', data?.dataMap?.page16?.second_runnerup)  }" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.specialisation.sp_3}}%</div>
        </div>
        <div class="sixteenth-page-careergastro">
          <div class="sixteenth-page-pdlft5"> PODIATRIST / CHIROPODIST<br>
            <span style="font-size: 8px;">Feet & lower Limbs</span>
          </div>
          </div>
          <div [ngClass]="{ 'highlight': isWinner('sp_4', data?.dataMap?.page16?.winner),
          'first_runnerup': isWinner('sp_4', data?.dataMap?.page16?.first_runnerup),
          'second_runnerup': isWinner('sp_4', data?.dataMap?.page16?.second_runnerup)  }" class="sixteenth-page-careergastroperc" style="border-right: none;">
            <div>{{data.dataMap.page16.specialisation.sp_4}}%</div>
          </div>
        <div class="clear-both"></div>
      </div>

      <div class="mt-5 flex-row">
        <div class="sixteenth-page-careergastro">
          <div class="sixteenth-page-pdlft5">B.D.S.
             <br><span style="font-size: 8px;">Bachelor of Dental Surgery
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('hb_2', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('hb_2', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('hb_2', data?.dataMap?.page16?.second_runnerup)  }" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.human_biology.hb_2}}%</div>
        </div>
        <div class="sixteenth-page-careergastro">
          <div class="sixteenth-page-pdlft5">HEPATOLOGIST
             <br><span style="font-size: 8px;">Liver, Gallbladder, Pancreas etc.
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('sp_5', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('sp_5', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('sp_5', data?.dataMap?.page16?.second_runnerup)  }" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.specialisation.sp_5}}%</div>
        </div>
        <div class="sixteenth-page-careergastro">
          <div class="sixteenth-page-pdlft5"> RADIOLOGIST<br>
            <span style="font-size: 8px;">X-rays, CT Scan, MRI, PET, Ultra sound</span>
          </div>
          </div>
          <div [ngClass]="{ 'highlight': isWinner('sp_6', data?.dataMap?.page16?.winner),
          'first_runnerup': isWinner('sp_6', data?.dataMap?.page16?.first_runnerup),
          'second_runnerup': isWinner('sp_6', data?.dataMap?.page16?.second_runnerup)  }" class="sixteenth-page-careergastroperc" style="border-right: none;">
            <div>{{data.dataMap.page16.specialisation.sp_6}}%</div>
          </div>
        <div class="clear-both"></div>
      </div>
      
      <div class="mt-5 flex-row">
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">B.H.M.S.
             <br><span style="font-size: 7px;">Bachelor Homeopathic Med & Surgery
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('hb_3', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('hb_3', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('hb_3', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.human_biology.hb_3}}%</div>
        </div>
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">IMMUNOLOGIST
             <br><span style="font-size: 7px;">Immune System- Antigens - Antibodies
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('sp_7', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('sp_7', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('sp_7', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.specialisation.sp_7}}%</div>
        </div>
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5"> RHEUMATOLOGIST<br>
            <span style="font-size: 7px;">Rheumatic illness- Arthritis, body-pain</span>
          </div>
          </div>
          <div [ngClass]="{ 'highlight': isWinner('sp_8', data?.dataMap?.page16?.winner),
          'first_runnerup': isWinner('sp_8', data?.dataMap?.page16?.first_runnerup),
          'second_runnerup': isWinner('sp_8', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc" style="border-right: none;">
            <div>{{data.dataMap.page16.specialisation.sp_8}}%</div>
          </div>
        <div class="clear-both"></div>
      </div>
      
      <div class="mt-5 flex-row">
        <div class="sixteenth-page-careerbams">
          <div class="sixteenth-page-pdlft5">B.A.M.S.
             <br><span style="font-size: 7px;">Bachelor of Ayurvedic Med & Surgery
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('hb_4', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('hb_4', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('hb_4', data?.dataMap?.page16?.second_runnerup)}" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.human_biology.hb_4}}%</div>
        </div>
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">MAXILLOFACIAL & ORAL SURGEON
             <br><span style="font-size: 7px;">Surgeon Head Neck Jaw face etc.
          </span>
        </div>
        </div>
        <div [ngClass]="{'highlight': isWinner('sp_9', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('sp_9', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('sp_9', data?.dataMap?.page16?.second_runnerup)}" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.specialisation.sp_9}}%</div>
        </div>
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5"> SPINAL CORD INJURY<br>
            <span style="font-size: 7px;">Specialized physiatrist</span>
          </div>
          </div>
          <div [ngClass]="{ 'highlight': isWinner('sp_10', data?.dataMap?.page16?.winner),
          'first_runnerup': isWinner('sp_10', data?.dataMap?.page16?.first_runnerup),
          'second_runnerup': isWinner('sp_10', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc" style="border-right: none;">
            <div>{{data.dataMap.page16.specialisation.sp_10}}%</div>
          </div>
        <div class="clear-both"></div>
      </div>

      <div class="mt-5 flex-row">
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">M.D.
             <br><span style="font-size: 7px;">Doctor of Medicine
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('hb_5', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('hb_5', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('hb_5', data?.dataMap?.page16?.second_runnerup)}" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.human_biology.hb_5}}%</div>
        </div>
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">MEDICAL GENETICIST
             <br><span style="font-size: 7px;">Hereditary disorders- Genes etc.
          </span>
        </div>
        </div>
        <div [ngClass]="{'highlight': isWinner('sp_11', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('sp_11', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('sp_11', data?.dataMap?.page16?.second_runnerup)}" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.specialisation.sp_11}}%</div>
        </div>
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">SPORTS MEDICINE SPECIALIST<br>
            <span style="font-size: 7px;">Doctor for Athletes</span>
          </div>
          </div>
          <div [ngClass]="{ 'highlight': isWinner('sp_12', data?.dataMap?.page16?.winner),
          'first_runnerup': isWinner('sp_12', data?.dataMap?.page16?.first_runnerup),
          'second_runnerup': isWinner('sp_12', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc" style="border-right: none;">
            <div>{{data.dataMap.page16.specialisation.sp_12}}%</div>
          </div>
        <div class="clear-both"></div>
      </div>

      <div class="mt-5 flex-row">
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">M.S.
             <br><span style="font-size: 7px;">Master of Surgery
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('hb_6', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('hb_6', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('hb_6', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.human_biology.hb_6}}%</div>
        </div>
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">NEONATOLOGIST
             <br><span style="font-size: 7px;">New-born Birth Disorders & Defects
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('sp_13', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('sp_13', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('sp_13', data?.dataMap?.page16?.second_runnerup)}" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.specialisation.sp_13}}%</div>
        </div>
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5"> THORACIC SURGEON<br>
            <span style="font-size: 7px;">Chest, Heart, Lungs, Esophagus etc.</span>
          </div>
          </div>
          <div [ngClass]="{ 'highlight': isWinner('sp_14', data?.dataMap?.page16?.winner),
          'first_runnerup': isWinner('sp_14', data?.dataMap?.page16?.first_runnerup),
          'second_runnerup': isWinner('sp_14', data?.dataMap?.page16?.second_runnerup)}" class="sixteenth-page-careergastroperc" style="border-right: none;">
            <div>{{data.dataMap.page16.specialisation.sp_14}}%</div>
          </div>
        <div class="clear-both"></div>
      </div>
      <div class="mt-5 flex-row">
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">D.M.
             <br><span style="font-size: 7px;">Doctorate of Medicine
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('hb_7', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('hb_7', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('hb_7', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.human_biology.hb_7}}%</div>
        </div>
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">NEPHROLOGIST
             <br><span style="font-size: 7px;">Kidney disorders - etc.
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('sp_15', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('sp_15', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('sp_15', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.specialisation.sp_15}}%</div>
        </div>
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">UROLOGIST<br>
            <span style="font-size: 7px;">Urinary track, Adrenal glands etc.</span>
          </div>
          </div>
          <div [ngClass]="{ 'highlight': isWinner('sp_16', data?.dataMap?.page16?.winner),
          'first_runnerup': isWinner('sp_16', data?.dataMap?.page16?.first_runnerup),
          'second_runnerup': isWinner('sp_16', data?.dataMap?.page16?.second_runnerup)}" class="sixteenth-page-careergastroperc" style="border-right: none;">
            <div>{{data.dataMap.page16.specialisation.sp_16}}%</div>
          </div>
        <div class="clear-both"></div>
      </div>
      <div class="mt-5 flex-row">
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">B Pharm.
             <br><span style="font-size: 7px;">Bachelor of Pharmacy
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('hb_8', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('hb_8', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('hb_8', data?.dataMap?.page16?.second_runnerup)}" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.human_biology.hb_8}}%</div>
        </div>
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">NEUROLOGIST
             <br><span style="font-size: 7px;">Nerves & Nervous Systems
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('sp_17', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('sp_17', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('sp_17', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.specialisation.sp_17}}%</div>
        </div>
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">VASCULAR SURGEON<br>
            <span style="font-size: 7px;">Blood Vessels, Arteries, Veins</span>
          </div>
          </div>
          <div [ngClass]="{ 'highlight': isWinner('sp_18', data?.dataMap?.page16?.winner),
          'first_runnerup': isWinner('sp_18', data?.dataMap?.page16?.first_runnerup),
          'second_runnerup': isWinner('sp_18', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc" style="border-right: none;">
            <div>{{data.dataMap.page16.specialisation.sp_18}}%</div>
          </div>
        <div class="clear-both"></div>
      </div>
      <div class="mt-5 flex-row">
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">B.Sc. Nursing.
             <br><span style="font-size: 7px;">Bachelor of Science - Nursing
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('hb_9', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('hb_9', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('hb_9', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.human_biology.hb_9}}%</div>
        </div>
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">NEUROSURGEON
             <br><span style="font-size: 7px;">Nervous System-Brain & Spinal cord
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('sp_19', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('sp_19', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('sp_19', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.specialisation.sp_19}}%</div>
        </div>
        <div class="sixteenth-page-careerplant">
          <div class="sixteenth-page-pdlft5">PLANT BIOLOGY<br>
            
        </div>
        </div>
        <!-- <div class="sixteenth-page-careerplant1">
          <div>10%</div>
        </div> -->
        <div class="clear-both"></div>
      </div>
      <div class="mt-5 flex-row">
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">B.P.T.
             <br><span style="font-size: 7px;">Bachelor of Physiotherapy
          </span>
        </div>
        </div>
        <div [ngClass]="{ 
          'highlight': isWinner('hb_10', data?.dataMap?.page16?.winner),
          'first_runnerup': isWinner('hb_10', data?.dataMap?.page16?.first_runnerup),
          'second_runnerup': isWinner('hb_10', data?.dataMap?.page16?.second_runnerup)
        }" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.human_biology.hb_10}}%</div>
        </div>
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">NATUROPATHIC DOCTOR
             <br><span style="font-size: 7px;">ND/ NMD. .Alternate Medicine
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('sp_20', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('sp_20', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('sp_20', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.specialisation.sp_20}}%</div>
        </div>
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">BOTANIST- PHYTOLOGIST<br>
            <span style="font-size: 7px;">Plant Scientist</span>
          </div>
          </div>
          <div [ngClass]="{ 'highlight': isWinner('pb_1', data?.dataMap?.page16?.winner),
          'first_runnerup': isWinner('pb_1', data?.dataMap?.page16?.first_runnerup),
          'second_runnerup': isWinner('pb_1', data?.dataMap?.page16?.second_runnerup)}" class="sixteenth-page-careergastroperc" style="border-right: none;">
            <div>{{data.dataMap.page16.plant_biology.pb_1}}%</div>
          </div>
        <div class="clear-both"></div>
      </div>
      <div class="mt-5 flex-row">
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">B.U.M.S.
             <br><span style="font-size: 7px;">Bachelor of Unani Medicine & Surgery
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('hb_11', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('hb_11', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('hb_11', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.human_biology.hb_11}}%</div>
        </div>
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">NUCLEAR MEDICINE
             <br><span style="font-size: 7px;">Specialist- Radiopharmaceuticals
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('sp_21', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('sp_21', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('sp_21', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.specialisation.sp_21}}%</div>
        </div>
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">PLANT BIOLOGISTS<br>
            <span style="font-size: 7px;">Plant breeding & Genetics etc.</span>
          </div>
          </div>
          <div [ngClass]="{ 'highlight': isWinner('pb_2', data?.dataMap?.page16?.winner),
          'first_runnerup': isWinner('pb_2', data?.dataMap?.page16?.first_runnerup),
          'second_runnerup': isWinner('pb_2', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc" style="border-right: none;">
            <div>{{data.dataMap.page16.plant_biology.pb_2}}%</div>
          </div>
        <div class="clear-both"></div>
      </div>
      
      <div class="mt-5 flex-row">
        <div class="sixteenth-page-careerhuman" >
          <div>SPECIALISATION</div>
          </div>
        <!-- <div class="sixteenth-page-careerhumanperc"  >
            <div>10%</div>
        </div> -->
        <div class="sixteenth-page-careergastro">
          <div class="sixteenth-page-pdlft5">OBSTETRICIAN-GYNEC
             <br><span style="font-size: 8px;">Female Specialist
          </span>
        </div>
          </div>
        <div [ngClass]="{ 'highlight': isWinner('sp_22', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('sp_22', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('sp_22', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.specialisation.sp_22}}%</div>
        </div>
        <div class="sixteenth-page-careergastro">
          <div class="sixteenth-page-pdlft5"> PLANT PHYSIOLOGIST<br>
            <span style="font-size: 8px;">Behavior, Development & Growth</span>
          </div>
          </div>
          <div [ngClass]="{ 'highlight': isWinner('pb_3', data?.dataMap?.page16?.winner),
          'first_runnerup': isWinner('pb_3', data?.dataMap?.page16?.first_runnerup),
          'second_runnerup': isWinner('pb_3', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc" style="border-right: none;">
            <div>{{data.dataMap.page16.plant_biology.pb_3}}%</div>
          </div>
        <div class="clear-both"></div>
      </div>
      <div class="mt-5 flex-row">
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">ALLERGIST
             <br><span style="font-size: 7px;">Treatment of Allergies
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('sp_23', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('sp_23', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('sp_23', data?.dataMap?.page16?.second_runnerup)}" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.specialisation.sp_23}}%</div>
        </div>
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">OCCUPATIONAL MEDICINE
             <br><span style="font-size: 7px;">Specialist Workplace related
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('sp_24', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('sp_24', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('sp_24', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.specialisation.sp_24}}%</div>
        </div>
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">PHOTO-BIOLOGIST<br>
            <span style="font-size: 7px;">Effect of light on living organisms</span>
          </div>
          </div>
          <div [ngClass]="{ 'highlight': isWinner('pb_4', data?.dataMap?.page16?.winner),
          'first_runnerup': isWinner('pb_4', data?.dataMap?.page16?.first_runnerup),
          'second_runnerup': isWinner('pb_4', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc" style="border-right: none;">
            <div>{{data.dataMap.page16.plant_biology.pb_4}}%</div>
          </div>
        <div class="clear-both"></div>
      </div>
      <div class="mt-5 flex-row">
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">ANAESTHESIOLOGIST
             <br><span style="font-size: 7px;">Administering Anesthetics
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('sp_25', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('sp_25', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('sp_25', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.specialisation.sp_25}}%</div>
        </div>
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">ONCOLOGIST
             <br><span style="font-size: 7px;">Cancers, Tumors etc.
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('sp_26', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('sp_26', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('sp_26', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.specialisation.sp_26}}%</div>
        </div>
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">AGRICULTURE & HORTICULTURE<br>
            <span style="font-size: 7px;">Professionals of the field</span>
          </div>
          </div>
          <div [ngClass]="{ 'highlight': isWinner('pb_5', data?.dataMap?.page16?.winner),
          'first_runnerup': isWinner('pb_5', data?.dataMap?.page16?.first_runnerup),
          'second_runnerup': isWinner('pb_5', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc" style="border-right: none;">
            <div>{{data.dataMap.page16.plant_biology.pb_5}}%</div>
          </div>
        <div class="clear-both"></div>
      </div>
      <div class="mt-5 flex-row">
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">ANDROLOGIST
             <br><span style="font-size: 7px;">Male fertility & Sexuality
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('sp_27', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('sp_27', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('sp_27', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.specialisation.sp_27}}%</div>
        </div>
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">OPHTHALMOLOGIST
             <br><span style="font-size: 7px;">Disease & Disorders of Eyes
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('sp_28', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('sp_28', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('sp_28', data?.dataMap?.page16?.second_runnerup)}" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.specialisation.sp_28}}%</div>
        </div>
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">CONSERVATIONISTS<br>
            <span style="font-size: 7px;">Managing parks, forests & wild areas</span>
          </div>
          </div>
          <div [ngClass]="{ 'highlight': isWinner('pb_6', data?.dataMap?.page16?.winner),
          'first_runnerup': isWinner('pb_6', data?.dataMap?.page16?.first_runnerup),
          'second_runnerup': isWinner('pb_6', data?.dataMap?.page16?.second_runnerup)}" class="sixteenth-page-careergastroperc" style="border-right: none;">
            <div>{{data.dataMap.page16.plant_biology.pb_6}}%</div>
          </div>
        <div class="clear-both"></div>
      </div>
      <div class="mt-5 flex-row">
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">ACUPUNCTURIST
             <br><span style="font-size: 7px;">Treatment with Acupuncture needles
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('sp_29', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('sp_29', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('sp_29', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.specialisation.sp_29}}%</div>
        </div>
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">ORTHOPEDIST
             <br><span style="font-size: 7px;">Skeleton, Bones, Joints, Muscles, etc.
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('sp_30', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('sp_30', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('sp_30', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.specialisation.sp_30}}%</div>
        </div>
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">MICROBIOLOGIST<br>
            <span style="font-size: 7px;">Bacteria, Virus, Fungi, Algae etc</span>
          </div>
          </div>
          <div [ngClass]="{ 'highlight': isWinner('pb_7', data?.dataMap?.page16?.winner),
          'first_runnerup': isWinner('pb_7', data?.dataMap?.page16?.first_runnerup),
          'second_runnerup': isWinner('pb_7', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc" style="border-right: none;">
            <div>{{data.dataMap.page16.plant_biology.pb_7}}%</div>
          </div>
        <div class="clear-both"></div>
      </div>
      <div class="mt-5 flex-row">
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">AUDIOLOGIST
             <br><span style="font-size: 7px;">Auditory & Vestibular- Hearing loss etc
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('sp_31', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('sp_31', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('sp_31', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.specialisation.sp_31}}%</div>
        </div>
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">OTOLARYNGOLOGIST
             <br><span style="font-size: 7px;">Ear, Nose, Throat Specialist
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('sp_32', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('sp_32', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('sp_32', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.specialisation.sp_32}}%</div>
        </div>
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">GEOLOGIST<br>
            <span style="font-size: 7px;">Earth Process, Earth Scientist</span>
          </div>
          </div>
          <div [ngClass]="{ 'highlight': isWinner('pb_8', data?.dataMap?.page16?.winner),
          'first_runnerup': isWinner('pb_8', data?.dataMap?.page16?.first_runnerup),
          'second_runnerup': isWinner('pb_8', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc" style="border-right: none;">
            <div>{{data.dataMap.page16.plant_biology.pb_8}}%</div>
          </div>
        <div class="clear-both"></div>
      </div>
      <div class="mt-5 flex-row">
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">CARDIOLOGIST
             <br><span style="font-size: 7px;">Heart diseases & Abnormalities
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('sp_33', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('sp_33', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('sp_33', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.specialisation.sp_33}}%</div>
        </div>
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">PATHOLOGIST
             <br><span style="font-size: 7px;">Various Cause & Effects of illness
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('sp_34', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('sp_34', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('sp_34', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.specialisation.sp_34}}%</div>
        </div>
        <div class="sixteenth-page-careerplant">
          <div class="sixteenth-page-pdlft5">ANIMAL BIOLOGY<br>
            
        </div>
        </div>
        <!-- <div class="sixteenth-page-careerplant1">
          <div>10%</div>
        </div> -->
        <div class="clear-both"></div>
      </div>
      <div class="mt-5 flex-row">
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">CARDIAC ELECTROPHYSIO-LOGIST
             <br><span style="font-size: 7px;">Heart Electrical activities
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('sp_35', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('sp_35', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('sp_35', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.specialisation.sp_35}}%</div>
        </div>
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">PERINATOLOGIST
             <br><span style="font-size: 7px;">OB/GYN for High-risk Pregnancy
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('sp_36', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('sp_36', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('sp_36', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.specialisation.sp_36}}%</div>
        </div>
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">ANIMAL SCIENCE<br>
            <span style="font-size: 7px;">Breeding & Care of Domestic/Livestock</span>
          </div>
          </div>
          <div [ngClass]="{ 'highlight': isWinner('ab_1', data?.dataMap?.page16?.winner),
          'first_runnerup': isWinner('ab_1', data?.dataMap?.page16?.first_runnerup),
          'second_runnerup': isWinner('ab_1', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc" style="border-right: none;">
            <div>{{data.dataMap.page16.animal_biology.ab_1}}%</div>
          </div>
        <div class="clear-both"></div>
      </div>
      <div class="mt-5 flex-row">
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">DERMATOLOGIST
             <br><span style="font-size: 7px;">Skin Disorders
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('sp_37', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('sp_37', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('sp_37', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.specialisation.sp_37}}%</div>
        </div>
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">PEDIATRICIAN
             <br><span style="font-size: 7px;">Child Specialist
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('sp_38', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('sp_38', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('sp_38', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.specialisation.sp_38}}%</div>
        </div>
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">ZOOLOGY<br>
            <span style="font-size: 7px;">Study or Biology of Animal Kingdom</span>
          </div>
          </div>
          <div [ngClass]="{ 'highlight': isWinner('ab_2', data?.dataMap?.page16?.winner),
          'first_runnerup': isWinner('ab_2', data?.dataMap?.page16?.first_runnerup),
          'second_runnerup': isWinner('ab_2', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc" style="border-right: none;">
            <div>{{data.dataMap.page16.animal_biology.ab_2}}%</div>
          </div>
        <div class="clear-both"></div>
      </div>
      <div class="mt-5 flex-row">
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">ENDOCRINOLOGIST
             <br><span style="font-size: 7px;">Diabetes, Thyroid, hormones etc.
          </span>
        </div>
        </div>
        <div [ngClass]="{'highlight': isWinner('sp_39', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('sp_39', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('sp_39', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.specialisation.sp_39}}%</div>
        </div>
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">PHYSIATRIST- PM&R
             <br><span style="font-size: 7px;">Physical Medicine & Rehabilitation
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('sp_40', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('sp_40', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('sp_40', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.specialisation.sp_40}}%</div>
        </div>
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">MARINE BIOLOGY<br>
            <span style="font-size: 7px;">Ocean Organisms & Water Bodies</span>
          </div>
          </div>
          <div [ngClass]="{ 'highlight': isWinner('ab_3', data?.dataMap?.page16?.winner),
          'first_runnerup': isWinner('ab_3', data?.dataMap?.page16?.first_runnerup),
          'second_runnerup': isWinner('ab_3', data?.dataMap?.page16?.second_runnerup)}" class="sixteenth-page-careergastroperc" style="border-right: none;">
            <div>{{data.dataMap.page16.animal_biology.ab_3}}%</div>
          </div>
        <div class="clear-both"></div>
      </div>
      <div class="mt-5 flex-row">
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">EPIDEMIOLOGIST
             <br><span style="font-size: 7px;">Public health- Risk & Pattern
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('sp_41', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('sp_41', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('sp_41', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.specialisation.sp_41}}%</div>
        </div>
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">PLASTIC SURGEON
             <br><span style="font-size: 7px;">Cosmetic & Reconstructive Surgery
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('sp_42', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('sp_42', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('sp_42', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.specialisation.sp_42}}%</div>
        </div>
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">PARASITOLOGY<br>
            <span style="font-size: 7px;">Study of Parasitic Organisms</span>
          </div>
          </div>
          <div [ngClass]="{ 'highlight': isWinner('ab_4', data?.dataMap?.page16?.winner),
          'first_runnerup': isWinner('ab_4', data?.dataMap?.page16?.first_runnerup),
          'second_runnerup': isWinner('ab_4', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc" style="border-right: none;">
            <div>{{data.dataMap.page16.animal_biology.ab_4}}%</div>
          </div>
        <div class="clear-both"></div>
      </div>
      <div class="mt-5 flex-row">
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">FORENSIC SCIENCE
             <br><span style="font-size: 7px;">Criminal investigation & analysis
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('sp_43', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('sp_43', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('sp_43', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.specialisation.sp_43}}%</div>
        </div>
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">PSYCHIATRIST
             <br><span style="font-size: 7px;">Treatment of Mental Illness
          </span>
        </div>
        </div>
        <div [ngClass]="{ 'highlight': isWinner('sp_44', data?.dataMap?.page16?.winner),
        'first_runnerup': isWinner('sp_44', data?.dataMap?.page16?.first_runnerup),
        'second_runnerup': isWinner('sp_44', data?.dataMap?.page16?.second_runnerup) }" class="sixteenth-page-careergastroperc">
          <div>{{data.dataMap.page16.specialisation.sp_44}}%</div>
        </div>
        <div class="sixteenth-page-careerbhms">
          <div class="sixteenth-page-pdlft5">VETERINARY SCIENCES<br>
            <span style="font-size: 7px;">Animal Treatment, Husbandry etc.</span>
          </div>
          </div>
          <div [ngClass]="{ 'highlight': isWinner('ab_5', data?.dataMap?.page16?.winner),
          'first_runnerup': isWinner('ab_5', data?.dataMap?.page16?.first_runnerup),
          'second_runnerup': isWinner('ab_5', data?.dataMap?.page16?.second_runnerup)}" class="sixteenth-page-careergastroperc" style="border-right: none;">
            <div>{{data.dataMap.page16.animal_biology.ab_5}}%</div>
          </div>
        <div class="clear-both"></div>
      </div>
      </div>
  </div>