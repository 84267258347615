<div class="twenty-nine-page mt-20">
    <div class="">
        <div class="text-right">
            <img class="logo-img" src="assets/images/Header.png" />
        </div>
        <div class="mt-10">
            <div>
                <div class="text-center twenty-nine-page-Research">{{ data.dataMap.page26_29.employee_title }}</div>
                <div class="twenty-nine-page-sec">
                    <div class="twenty-nine-page-Rsa-1">
                        {{ data.dataMap.page26_29.employee_para }}
                        </div>
                </div>
            </div>
            <div class="clear-both"></div>
        </div>
        <div class="text-center mt-20">
            <img [attr.src]="data.dataMap.page26_29.image2" class="width-100">
        </div>
        <div class="mt-15">
            <div>
                <div class="text-center twenty-nine-page-Research">{{ data.dataMap.page26_29.boss_title }}</div>
                <div class="twenty-nine-page-sec">
                    <div class="twenty-nine-page-Rsa-1">
                        {{ data.dataMap.page26_29.boss_para }}
                        </div>
                </div>
            </div>
            <div class="clear-both"></div>
        </div>
    </div>
</div>