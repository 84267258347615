import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatDialog, MatTableDataSource, MatSort } from '@angular/material';
import { ClientService } from '../services/client.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HostListener } from '@angular/core';
import { CryptoService } from '../crypto.service';

export interface UserData {
  dealer_client_id: string;
  clientId: string;
  clientname: string;
  email: string;
  contact: string;
  action: string;
}

@Component({
  selector: 'app-pending-report',
  templateUrl: './pending-report.component.html',
  styleUrls: ['./pending-report.component.css']
})
export class PendingReportComponent implements OnInit {

  pageNo: number = 1;
  pageSize: number = 50;
  totalRecord: number = 0;

  clientModel: any = {}
  displayedColumns: string[] = ['dealer_client_id', 'clientId', 'clientname', 'email', 'contact', 'action'];
  dataSource: MatTableDataSource<UserData>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  @HostListener('window:popstate', ['$event'])

  onPopState(event) {
    console.log("back press event pressed!!!");
    history.forward();
    history.go(1);
  }

  constructor(private clientService: ClientService, private route: Router, private dialog: MatDialog,
     private SpinnerService: NgxSpinnerService, private cryptoService: CryptoService) { 

  }

  ngOnInit(): void {
    this.getClientList();
    
  }

  // ngAfterViewInit() {
  //   this.dataSource.sort = this.sort;
  // }

  getClientList() {
    this.SpinnerService.show();
    this.clientService.getPendingReport(this.pageNo, this.pageSize).subscribe((result: any) => {
      var res = JSON.parse(this.cryptoService.getDecryptedBody(localStorage.getItem('guid'), result.data));
      this.dataSource = new MatTableDataSource(res.dataMap.clients);
      this.dataSource.sort = this.sort;
      this.totalRecord = res.dataMap.totalRecords;
      this.SpinnerService.hide();
    });
  }

  pageChanged(event) {
    this.pageNo = event.pageIndex + 1;
    this.pageSize = event.pageSize;

    this.getClientList();
  }

  viewWorkpad(delaerClientId, clientId, sequenceClientId) {
    this.route.navigate(['/workpad/load-workpad', delaerClientId, clientId, sequenceClientId]);
  }

  viewDetail(clientId, delaerClientId) {
    this.route.navigate(['/client/client-detail', clientId, delaerClientId, 'pending']);
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
}
