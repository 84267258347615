<div class="thirty-two-page mt-40">
    <div class="page-heading-img text-center">
       <img class="width-100" src="assets/images/Page 32/heading.jpg" />
    </div>

    <div class="flex-row mt-10">
        <div class="flex-col mr-10">
            <div class="flex-head">RIGHT HAND - LEFT BRAIN</div>
            <div class="flex-body">
                <ul class="ul-list">
                    <li>FINGER NO.</li>
                    <li>RIDGE COUNT %</li>
                    <li>FINGER PRINT TYPE </li>
                </ul>
            </div>
        </div>
        <div class="img-col text-center mr-10">
            <img style="height: 56px; margin-top: 1px;" src="assets/images/Header.png" />
        </div>
        <div class="flex-col">
            <div class="flex-head">LEFT HAND - RIGHT BRAIN</div>
            <div class="flex-body">
                <ul class="ul-list">
                    <li>FINGER NO.</li>
                    <li>RIDGE COUNT %</li>
                    <li>FINGER PRINT TYPE </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="flex-row mt-10">
        <div class="flex-col mr-10">
            <div class="flex-body">
                <div class="float-rank-left">
                    R1
                </div>
                <div class="text-center">
                    <div class="round-rank" [style.backgroundColor]="data.dataMap.page32.reportDtl[0].colorCode">
                        {{ data.dataMap.page32.reportDtl[0].legends }}</div>
                    <div class="mt-5" style="font-size: 14px;">
                        <span *ngIf="data.dataMap.page32.reportDtl[0].isArc == 'NO';else other_content">
                            {{ data.dataMap.page32.reportDtl[0].percentage }}%
                        </span>
                        <ng-template #other_content>X%</ng-template>
                        <br/> 
                        <span *ngIf="data.dataMap.page32.reportDtl[0].isReverse != 'NO'">+ R </span>
                        <span *ngIf="data.dataMap.page32.reportDtl[0].isMemoryBackUp != 'NO'">+ B </span>
                    </div>
                    <div [ngClass]="(data.dataMap.page32.reportDtl[0].isReverse != 'NO' && data.dataMap.page32.reportDtl[0].isMemoryBackUp != 'NO') ? 'mt-10' : 'mt-20'" class="pink-box">
                        {{ data.dataMap.page32.reportDtl[0].fpp }}
                    </div>
                </div>
            </div>
        </div>
        <div class="middle-col mr-10">
            <div class="maroon-heading">PRE-FRONTAL LOBES (ACTION ZONE)</div>
            <div class="flex-row mt-5">
                <div class="gray-heading" style="margin-right: 5px;">INTRAPERSONAL</div>
                <div class="gray-heading">INTERPERSONAL</div>
            </div>
            <div class="flex-row mt-5">
                <div class="gray-box" style="margin-right: 5px;">
                    <ul>
                        <li>All about Me, My & I, ( Self-connect)</li>
                        <li>Self-respect & Self-Confidence</li>
                        <li>Self-motivation & Self-introspection</li>
                        <li>Self-Assessment & Self-Belief</li>
                        <li>Honesty & Integrity</li>
                        <li>Rational thinking</li>
                        <li>Administration- by controlling others</li>
                        <li>Economics, Psychology & Civics</li>
                    </ul>
                </div>
                <div class="gray-box">
                    <ul>
                        <li>All about We, Us & Ours (People Connect)</li>
                        <li>Leadership & Relationship management</li>
                        <li>Interpersonal skills & Social interaction</li>
                        <li>Sensitivity & Response</li>
                        <li>Intuitive Powers & Goal Visualization</li>
                        <li>Empathy & Compassion</li>
                        <li>Administration by Motivating others</li>
                        <li>Social service, History & Politics </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="flex-col">
            <div class="flex-body">
                <div class="float-rank-right">
                    L1
                </div>

                <div class="text-center">
                    <div class="round-rank" [style.backgroundColor]="data.dataMap.page32.reportDtl[5].colorCode">
                        {{ data.dataMap.page32.reportDtl[5].legends }}</div>
                    <div class="mt-5" style="font-size: 14px;">
                        <span *ngIf="data.dataMap.page32.reportDtl[5].isArc == 'NO';else other_content">
                            {{ data.dataMap.page32.reportDtl[5].percentage }}%
                        </span>
                        <ng-template #other_content>X%</ng-template>
                        <br/> 
                        <span *ngIf="data.dataMap.page32.reportDtl[5].isReverse != 'NO'">+ R </span>
                        <span *ngIf="data.dataMap.page32.reportDtl[5].isMemoryBackUp != 'NO'">+ B </span>
                    </div>
                    <div [ngClass]="(data.dataMap.page32.reportDtl[5].isReverse != 'NO' && data.dataMap.page32.reportDtl[5].isMemoryBackUp != 'NO') ? 'mt-10' : 'mt-20'" class="pink-box">
                        {{ data.dataMap.page32.reportDtl[5].fpp }}
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="flex-row mt-10">
        <div class="flex-col mr-10">
            <div class="flex-body">
                <div class="float-rank-left">
                    R2
                </div>
                <div class="text-center">
                    <div class="round-rank" [style.backgroundColor]="data.dataMap.page32.reportDtl[1].colorCode">
                        {{ data.dataMap.page32.reportDtl[1].legends }}</div>
                    <div class="mt-5" style="font-size: 14px;">
                        <span *ngIf="data.dataMap.page32.reportDtl[1].isArc == 'NO';else other_content">
                            {{ data.dataMap.page32.reportDtl[1].percentage }}%
                        </span>
                        <ng-template #other_content>X%</ng-template>
                        <br/> 
                        <span *ngIf="data.dataMap.page32.reportDtl[1].isReverse != 'NO'">+ R </span>
                        <span *ngIf="data.dataMap.page32.reportDtl[1].isMemoryBackUp != 'NO'">+ B </span>
                    </div>
                    <div [ngClass]="(data.dataMap.page32.reportDtl[1].isReverse != 'NO' && data.dataMap.page32.reportDtl[1].isMemoryBackUp != 'NO') ? 'mt-10' : 'mt-20'" class="pink-box">
                        {{ data.dataMap.page32.reportDtl[1].fpp }}
                    </div>
                </div>
            </div>
        </div>
        <div class="middle-col mr-10">
            <div class="maroon-heading">FRONTAL LOBES (THOUGHT ZONE)</div>
            <div class="flex-row mt-5">
                <div class="gray-heading" style="margin-right: 5px;">LOGIC / MATHEMATICS </div>
                <div class="gray-heading">3D / SPATIAL </div>
            </div>
            <div class="flex-row mt-5">
                <div class="gray-box" style="margin-right: 5px;">
                    <ul>
                        <li>What is now- Past to Present</li>
                        <li>Understanding of numbers</li>
                        <li>People & Situational analysis</li>
                        <li>Conceptual & Financial analysis</li>
                        <li>Planning, Reasoning & Strategizing</li>
                        <li>Classification of Categories</li>
                        <li>Numerical analysis & its Differentiation</li>
                        <li>Physics, Geometry, Statistics & Grammar</li>
                    </ul>
                </div>
                <div class="gray-box">
                    <ul>
                        <li>What is next- Present to Future.</li>
                        <li>Idea formation</li>
                        <li>Creativity & Color combination</li>
                        <li>Living in fantasy — Day dreaming,</li>
                        <li>Long term memory recall</li>
                        <li>Innovation & Future orientation</li>
                        <li>Implementation of concept</li>
                        <li>Algebra, Chemistry, Trigo' & Calculus </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="flex-col">
            <div class="flex-body">
                <div class="float-rank-right">
                    L2
                </div>

                <div class="text-center">
                    <div class="round-rank" [style.backgroundColor]="data.dataMap.page32.reportDtl[6].colorCode">
                        {{ data.dataMap.page32.reportDtl[6].legends }}</div>
                    <div class="mt-5" style="font-size: 14px;">
                        <span *ngIf="data.dataMap.page32.reportDtl[6].isArc == 'NO';else other_content">
                            {{ data.dataMap.page32.reportDtl[6].percentage }}%
                        </span>
                        <ng-template #other_content>X%</ng-template>
                        <br/> 
                        <span *ngIf="data.dataMap.page32.reportDtl[6].isReverse != 'NO'">+ R </span>
                        <span *ngIf="data.dataMap.page32.reportDtl[6].isMemoryBackUp != 'NO'">+ B </span>
                    </div>
                    <div [ngClass]="(data.dataMap.page32.reportDtl[6].isReverse != 'NO' && data.dataMap.page32.reportDtl[6].isMemoryBackUp != 'NO') ? 'mt-10' : 'mt-20'" class="pink-box">
                        {{ data.dataMap.page32.reportDtl[6].fpp }}
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="flex-row mt-10">
        <div class="flex-col mr-10">
            <div class="flex-body">
                <div class="float-rank-left">
                    R3
                </div>
                <div class="text-center">
                    <div class="round-rank" [style.backgroundColor]="data.dataMap.page32.reportDtl[2].colorCode">
                        {{ data.dataMap.page32.reportDtl[2].legends }}</div>
                    <div class="mt-5" style="font-size: 14px;">
                        <span *ngIf="data.dataMap.page32.reportDtl[2].isArc == 'NO';else other_content">
                            {{ data.dataMap.page32.reportDtl[2].percentage }}%
                        </span>
                        <ng-template #other_content>X%</ng-template>
                        <br/> 
                        <span *ngIf="data.dataMap.page32.reportDtl[2].isReverse != 'NO'">+ R </span>
                        <span *ngIf="data.dataMap.page32.reportDtl[2].isMemoryBackUp != 'NO'">+ B </span>
                    </div>
                    <div [ngClass]="(data.dataMap.page32.reportDtl[2].isReverse != 'NO' && data.dataMap.page32.reportDtl[2].isMemoryBackUp != 'NO') ? 'mt-10' : 'mt-20'" class="pink-box">
                        {{ data.dataMap.page32.reportDtl[2].fpp }}
                    </div>
                </div>
            </div>
        </div>
        <div class="middle-col mr-10">
            <div class="maroon-heading">PARIETAL LOBES (KINESTHETIC ZONE)</div>
            <div class="flex-row mt-5">
                <div class="gray-heading" style="margin-right: 5px;">FINE MOTOR</div>
                <div class="gray-heading">GROSS MOTOR</div>
            </div>
            <div class="flex-row mt-5">
                <div class="gray-box" style="margin-right: 5px;">
                    <ul>
                        <li>Small & Micro muscular movements</li>
                        <li>Writing, Drawing, Coloring, Crafting etc </li>
                        <li>Finishing touches (Finesse), Presentation</li>
                        <li>Chewing, Facial expression — suppression</li>
                        <li>Precision based tasks</li>
                        <li>Cutting, Chopping, Stitching etc </li>
                        <li>All Indoor games- carom, dart, pool etc</li>
                        <li>Racket based sports</li>
                    </ul>
                </div>
                <div class="gray-box">
                    <ul>
                        <li>Big muscle movements</li>
                        <li>Running, Jumping, Walking etc</li>
                        <li>All Physical activities</li>
                        <li>Strength & Stamina</li>
                        <li>Pro-activeness & Endurance</li>
                        <li>Grabbing Opportunities</li>
                        <li>Rhythmic movements & Co-ordination</li>
                        <li>Outdoor & All field & track games</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="flex-col">
            <div class="flex-body">
                <div class="float-rank-right">
                    L3
                </div>

                <div class="text-center">
                    <div class="round-rank" [style.backgroundColor]="data.dataMap.page32.reportDtl[7].colorCode">
                        {{ data.dataMap.page32.reportDtl[7].legends }}</div>
                    <div class="mt-5" style="font-size: 14px;">
                        <span *ngIf="data.dataMap.page32.reportDtl[7].isArc == 'NO';else other_content">
                            {{ data.dataMap.page32.reportDtl[7].percentage }}%
                        </span>
                        <ng-template #other_content>X%</ng-template>
                        <br/> 
                        <span *ngIf="data.dataMap.page32.reportDtl[7].isReverse != 'NO'">+ R </span>
                        <span *ngIf="data.dataMap.page32.reportDtl[7].isMemoryBackUp != 'NO'">+ B </span>
                    </div>
                    <div [ngClass]="(data.dataMap.page32.reportDtl[7].isReverse != 'NO' && data.dataMap.page32.reportDtl[7].isMemoryBackUp != 'NO') ? 'mt-10' : 'mt-20'" class="pink-box">
                        {{ data.dataMap.page32.reportDtl[7].fpp }}
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="flex-row mt-10">
        <div class="flex-col mr-10">
            <div class="flex-body">
                <div class="float-rank-left">
                    R4
                </div>
                <div class="text-center">
                    <div class="round-rank" [style.backgroundColor]="data.dataMap.page32.reportDtl[3].colorCode">
                        {{ data.dataMap.page32.reportDtl[3].legends }}</div>
                    <div class="mt-5" style="font-size: 14px;">
                        <span *ngIf="data.dataMap.page32.reportDtl[3].isArc == 'NO';else other_content">
                            {{ data.dataMap.page32.reportDtl[3].percentage }}%
                        </span>
                        <ng-template #other_content>X%</ng-template>
                        <br/> 
                        <span *ngIf="data.dataMap.page32.reportDtl[3].isReverse != 'NO'">+ R </span>
                        <span *ngIf="data.dataMap.page32.reportDtl[3].isMemoryBackUp != 'NO'">+ B </span>
                    </div>
                    <div [ngClass]="(data.dataMap.page32.reportDtl[3].isReverse != 'NO' && data.dataMap.page32.reportDtl[3].isMemoryBackUp != 'NO') ? 'mt-10' : 'mt-20'" class="pink-box">
                        {{ data.dataMap.page32.reportDtl[3].fpp }}
                    </div>
                </div>
            </div>
        </div>
        <div class="middle-col mr-10">
            <div class="maroon-heading">TEMPORAL LOBES (AUDITORY ZONE) </div>
            <div class="flex-row mt-5">
                <div class="gray-heading" style="margin-right: 5px;">LINGUISTICS</div>
                <div class="gray-heading">MUSIC/EMOTIONS</div>
            </div>
            <div class="flex-row mt-5">
                <div class="gray-box" style="margin-right: 5px;">
                    <ul>
                        <li>Language learning ability</li>
                        <li>Hearing identification</li>
                        <li>Attention to Word & Vocabulary</li>
                        <li>Attention to meaning & Understanding</li>
                        <li>Verbal communication</li>
                        <li>Speech, pronunciation & Accent</li>
                        <li>Paraphrasing, Comprehension, Spellings</li>
                        <li>Short term memory</li>
                    </ul>
                </div>
                <div class="gray-box">
                    <ul>
                        <li>Sound Sensitivity</li>
                        <li>Sound pattern & Volume</li>
                        <li>Love & Urge for Music</li>
                        <li>Melodies, Tune, Rhythm & Tempo</li>
                        <li>Frequency, Pitch, Tone & Timbre</li>
                        <li>Feelings & Emotions</li>
                        <li>Nonverbal communication</li>
                        <li>Sympathy & Compassion</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="flex-col">
            <div class="flex-body">
                <div class="float-rank-right">
                    L4
                </div>

                <div class="text-center">
                    <div class="round-rank" [style.backgroundColor]="data.dataMap.page32.reportDtl[8].colorCode">
                        {{ data.dataMap.page32.reportDtl[8].legends }}</div>
                    <div class="mt-5" style="font-size: 14px;">
                        <span *ngIf="data.dataMap.page32.reportDtl[8].isArc == 'NO';else other_content">
                            {{ data.dataMap.page32.reportDtl[8].percentage }}%
                        </span>
                        <ng-template #other_content>X%</ng-template>
                        <br/> 
                        <span *ngIf="data.dataMap.page32.reportDtl[8].isReverse != 'NO'">+ R </span>
                        <span *ngIf="data.dataMap.page32.reportDtl[8].isMemoryBackUp != 'NO'">+ B </span>
                    </div>
                    <div [ngClass]="(data.dataMap.page32.reportDtl[8].isReverse != 'NO' && data.dataMap.page32.reportDtl[8].isMemoryBackUp != 'NO') ? 'mt-10' : 'mt-20'" class="pink-box">
                        {{ data.dataMap.page32.reportDtl[8].fpp }}
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="flex-row mt-10">
        <div class="flex-col mr-10">
            <div class="flex-body">
                <div class="float-rank-left">
                    R5
                </div>
                <div class="text-center">
                    <div class="round-rank" [style.backgroundColor]="data.dataMap.page32.reportDtl[4].colorCode">
                        {{ data.dataMap.page32.reportDtl[4].legends }}</div>
                    <div class="mt-5" style="font-size: 14px;">
                        <span *ngIf="data.dataMap.page32.reportDtl[4].isArc == 'NO';else other_content">
                            {{ data.dataMap.page32.reportDtl[4].percentage }}%
                        </span>
                        <ng-template #other_content>X%</ng-template>
                        <br/> 
                        <span *ngIf="data.dataMap.page32.reportDtl[4].isReverse != 'NO'">+ R </span>
                        <span *ngIf="data.dataMap.page32.reportDtl[4].isMemoryBackUp != 'NO'">+ B </span>
                    </div>
                    <div [ngClass]="(data.dataMap.page32.reportDtl[4].isReverse != 'NO' && data.dataMap.page32.reportDtl[4].isMemoryBackUp != 'NO') ? 'mt-10' : 'mt-20'" class="pink-box">
                        {{ data.dataMap.page32.reportDtl[4].fpp }}
                    </div>
                </div>
            </div>
        </div>
        <div class="middle-col mr-10">
            <div class="maroon-heading">OCCIPITAL LOBES (VISUAL ZONE) </div>
            <div class="flex-row mt-5">
                <div class="gray-heading" style="margin-right: 5px;">NATURALIST / OBSERVATION</div>
                <div class="gray-heading">2D/OBJECTS </div>
            </div>
            <div class="flex-row mt-5">
                <div class="gray-box" style="margin-right: 5px;">
                    <ul>
                        <li>Love for Animals, Plants & Nature</li>
                        <li>Minute Observation & Detailing</li>
                        <li>Visual Identification & Sensitivity</li>
                        <li>Reading, Editing, Auditing, Document</li>
                        <li>Identifying errors, Trouble shooting</li>
                        <li>Speed, Judgement & Trajectory</li>
                        <li>Intensity of all 5 Senses</li>
                        <li>Biology & Culinary skills</li>
                    </ul>
                </div>
                <div class="gray-box">
                    <ul>
                        <li>Picture memory</li>
                        <li>Objects, Colors, Shapes, Sizes</li>
                        <li>Diagrams, Animation, Sketches</li>
                        <li>Neatness & Cleanliness</li>
                        <li>Identifying places & Faces</li>
                        <li>Mirror imaging &Aesthetic Sense</li>
                        <li>Photography comes from here</li>
                        <li>Geography & Partial History </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="flex-col">
            <div class="flex-body">
                <div class="float-rank-right">
                    L5
                </div>

                <div class="text-center">
                    <div class="round-rank" [style.backgroundColor]="data.dataMap.page32.reportDtl[9].colorCode">
                        {{ data.dataMap.page32.reportDtl[9].legends }}</div>
                    <div class="mt-5" style="font-size: 14px;">
                        <span *ngIf="data.dataMap.page32.reportDtl[9].isArc == 'NO';else other_content">
                            {{ data.dataMap.page32.reportDtl[9].percentage }}%
                        </span>
                        <ng-template #other_content>X%</ng-template>
                        <br/> 
                        <span *ngIf="data.dataMap.page32.reportDtl[9].isReverse != 'NO'">+ R </span>
                        <span *ngIf="data.dataMap.page32.reportDtl[9].isMemoryBackUp != 'NO'">+ B </span>
                    </div>
                    <div [ngClass]="(data.dataMap.page32.reportDtl[9].isReverse != 'NO' && data.dataMap.page32.reportDtl[9].isMemoryBackUp != 'NO') ? 'mt-10' : 'mt-20'" class="pink-box">
                        {{ data.dataMap.page32.reportDtl[9].fpp }}
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="flex-row mt-10 gray-bg-shadow">
        <div class="flex-1 text-center">
            <div class="text-bold mt-30">
                LTRC
            </div>
            <div class="text-bold mt-10">
                {{ data.dataMap.page32.reportDetails.leftRidgeCount }}
            </div>

        </div>
        <div class="flex-3">
            <div class="flex-row mt-10">
                <div class="flex-1 text-center">
                    <div class="circle-1"></div>
                    <div class="caption">PRESUMED STRENGTH</div>
                </div>
                <div class="flex-1 text-center">
                    <div class="circle-1" style="background-color: #00923d;"></div>
                    <div class="caption">STRENGTH</div>
                </div>
                <div class="flex-1 text-center">
                    <div class="circle-1" style="background-color: #08aac1;"></div>
                    <div class="caption">INTERMEDIATE</div>
                </div>
                <div class="flex-1 text-center">
                    <div class="circle-1" style="background-color: #173887;"></div>
                    <div class="caption">AVERAGE</div>
                </div>
                <div class="flex-1 text-center">
                    <div class="circle-1" style="background-color: #da2419;"></div>
                    <div class="caption">ERRATIC LIMITATION</div>
                </div>
            </div>
        </div>

        <div class="flex-1 text-center">
            <div class="text-bold mt-30">
                RTRC
            </div>
            <div class="text-bold mt-10">
                {{ data.dataMap.page32.reportDetails.rightRidgeCount }}
            </div>
        </div>
    </div>

    <div class="mt-10 text-center gray-bg-shadow text-bold">
        TOTAL RIDGE COUNT OF ALL THE 10 LOBES (TRC) <div class="zero-div"> {{ data.dataMap.page32.reportDetails.totalRidgeCount }}</div>
    </div>
</div>
