<div>
    <input class="form-control" (keyup)="applyFilter($event.target.value)" placeholder="Search..." />
</div>

<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 user-table" style="width: 100%; margin-top: 10px;">
    <ng-container matColumnDef="dealer_client_id">
        <th mat-header-cell *matHeaderCellDef> Dealer Client Id </th>
        <td mat-cell *matCellDef="let element"> {{element.dealer_client_id}} </td>
    </ng-container>
    <ng-container matColumnDef="clientId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Client Id </th>
        <td mat-cell *matCellDef="let element"> {{element.clientId}} </td>
    </ng-container>
    <ng-container matColumnDef="clientname">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.clientname}} </td>
    </ng-container>
    <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> Email </th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>
    <ng-container matColumnDef="contact">
        <th mat-header-cell *matHeaderCellDef> Phone </th>
        <td mat-cell *matCellDef="let element"> {{element.contact}} </td>
    </ng-container>
    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element">
            <i class="material-icons" (click)="viewWorkpad(element.dealer_client_id, element.clientId, element.sequenceClientId)" style="cursor: pointer;"
                title="Workpad">
                desktop_windows
            </i>
            <i class="material-icons" (click)="viewDetail(element.clientId, element.dealer_client_id)" style="cursor: pointer; margin-left: 8px;" title="View Detail">
                view_list
            </i>
            <i class="material-icons" (click)="download(element)" style="cursor: pointer; margin-left: 8px;" title="Download">
                cloud_download
            </i>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 50, 100]" (page)="pageChanged($event)"
    [length]="totalRecord">
</mat-paginator>