<div class="forty-three-page text-right mt-40">
    <div class="text-right">
        <img class="logo-img" src="assets/images/Header.png" />
    </div>

    <div class="blue-header">
        THE ORIGIN & EXPOSITION OF THE SCIENCE OF FINGERPRINTS 
    </div>

    <div class="mt-5">
        Fingerprints are the most infallible means of identification of a person and Dermatoglyphics have now proven that the grooves on the fingertips is the mirror of the innate talents that accompany a person right from before they are even born. This science of identifying true nature of a person on the basis of their fingerprint patterns originated in India. Today, fingerprint individuality is accepted and taken for granted all over the world, but the Indians knew about the relevance and significance of fingerprinting before any other civilization. Since the dawn of recorded history, till the present times, Indians have consistently studied and researched this branch of knowledge, traversing from initial speculation, through interpretation, to its development into the most perfect system of character identification. Fingerprinting has remained intertwined with Indian culture since the ancient times. An Indian scripture, Samudrika Shastra, compiled by a sage named Samudra Rishi in 3102 BC, tells us a great deal about fingerprinting. 
    </div>

    <div class="mt-10 flex-row">
        <div class="flex-1 text-center">
            <div class="inline-block-class">
                <img style="height: 60px; float: left;" src="assets/images/Page 43/page43-arches.PNG" />
                <div class="thumbnail-txt">A) Arches <br> (Seep)</div>
            </div>
        </div>
        <div class="flex-1 text-center">
            <div class="inline-block-class">
                <img style="height: 60px; float: left;" src="assets/images/Page 43/page43-loops.PNG" />
                <div class="thumbnail-txt">B) Loops <br> (Sankha)</div>
            </div>
        </div>
        <div class="flex-1 text-center">
            <div class="inline-block-class">
                <img style="height: 60px; float: left;" src="assets/images/Page 43/page43-whorls.PNG" />
                <div class="thumbnail-txt">C) Whorls <br> (Chakra)</div>
            </div>
        </div>
    </div>

    <div class="mt-10 disc-text">
        Modern fingerprint examiners broadly classify fingerprint patterns in 
        <strong>Three Basic patterns of fingerprints. (A) Arches (B) Loops & (C) Whorls. </strong>(See Fig. 1). 
        Further they have their multiple variants (see next page) <br />
        Considering fingers of the entire world population it has been noted that statistically, 5 to 10 % of fingerprints
         have Arch pattern, 60 to 65 % are Loops and around 30 to 35 % have whorl patterns. <strong>Samudrika Shastra</strong>
          too identifies three types of fingerprints. 
          It describes that there are two common types i.e. <strong>Sankha</strong> (corresponding to Loops), and <strong>Chakra</strong> 
          (corresponding to Whorls), while the third type, <strong>Seep</strong> (corresponding to Arches) are rare. 
          It is not surprising that the fingerprint examiners of modern era and <strong>Samudra Rishi</strong> of ancient era reached the 
          same conclusion. The astonishing fact is that what the experts inferred merely one hundred years ago, <strong>Samudra Rishi</strong>
           could ratiocinate more than 5000 years ago! There is no written record on description of fingerprints prior to <strong>Samudrika Shastra.</strong>
    </div>

    <div class="mt-10 blue-header">
        SOME MORE INTERESTING FACTS 
    </div>

    <div class="mt-5">
        The modern science of fingerprinting asserts that parallel ridges on fingertips are interrupted by seven common types of irregularities, known as fingerprint characters or 'minutiae' (Fig. 2). 
    </div>

    <div class="mt-10 flex-row">
        <div class="flex-1 text-right">
            <img style="height: 180px; margin-right: 20px;" src="assets/images/Page 43/page43-table.PNG" />
        </div>
        <div class="flex-2 fingerprint-caption">
            The fingerprint characters <br />
            (a) Ridge termination — when the ridge flow abruptly ends.<br />
            (b) Bifurcation — when the ridge splits into two, making a 'Y'.<br />
            (c) Lake — when there is an enclosed area in between the ridge.<br />
            (d) Independent ridge — when a relatively small ridge within the overall pattern.<br />
            (e) Island — when there is a small dot or circle within the overall pattern.<br />
            (f) Hook or spur — when a small curved ridge is attached to a regular ridge.<br />
            (g) Crossover — when two parallel ridges are joined by a small ridge.<br />
            There are many sub- characters apart from these basic types. <strong>Samudrika Shastra</strong> <br />
            too identifies many of these characters along with their relevance. 
        </div>
    </div>

    <div class="mt-10 blue-header-caption">
        Fingerprint characters as depicted in Samudrika Shastra 
    </div>

    <div class="mt-20 flex-row">
        <div class="flex-2 margin-right-25">
            <div class="font-size-10" style="line-height: 15px;">
                As shown in Fig. 3 APURAN JAVA, PURAN JAVA, PADAM & AAX are concurrent to Bifurcation, Lake, Hook & Island respectively, of modern fingerprint discipline. NIRPAAX & KUNDLA are close to Island, while KAMAL is close to lake. TORAN appears similar to crossover. 
            </div>

            <div class="mt-10">
                The modern science of fingerprinting asserts that parallel ridges on fingertips are interrupted by seven common types of irregularities, known as fingerprint characters or 'minutiae' (Fig. It is relatively easy to discern the outer pattern of a fingerprint with naked eye, but for locating the 'minutiae', one has to use a magnifying glass. Samudra Rishi and his disciples were able to decipher the ridge characters, some with perfection and others with blemish, without the aid of a magnifier. It is obvious that they must have developed a method to record the finger impressions on a suitable surface. This also means that they would have designed a version of modern day stamp pad. 
            </div>
        </div>
        <div class="flex-15 mx-10">
            <img class="width-100" src="assets/images/Page 43/page43-lastbox.PNG" />
        </div>
    </div>
</div>
