<div class="fourteenth-page mt-20">
    <div class="">
      <div class="text-right">
        <img class="logo-img" src="assets/images/Header.png" />
      </div>
      <div class="text-center">
        <img style="width: 40%;" src="assets/images/Page 14/PAGE-14Age13to15.jpg">
      </div>
      
       <div class="" style="background-color: #e6e7e8!important;">
          <div class="fourteenth-page-row">
            <div class="fourteenth-page-first-box" style="width: 2%;"><strong>Q</strong></div>
            <div class="fourteenth-page-first-box" style="width: 90%;"><strong>Which is My Most Preferred Stream after Grade 10th ?</strong> </div>
            <div class="clear-both"></div>
          </div>
          <div class="clear-both"></div>
        </div>
  
      <div class="mt-20">
        <div class="fourteenth-page-firstbx25" >
          <div class="" style="padding: 0px 5px;">
            <span style="color: #00a6bf; font-weight: 900;font-size: 20px; "> ARTS</span>
            <div style="color: #0d0d0d;"> Covers wide range of studies such as — Visual arts, Performing art,
             Literary arts, Humanities, History, Psychology, Sociology, Economics, Law, etc. 
             There are lot of other subjects available to study, so the career you choose is based 
             on the choice of subjects. Arts Subjects encourage self-expression and creativity that builds sense of
              individual identity.
            </div>
          </div>
        </div>
  
        <div class="fourteenth-page-first-bx45" >
          <div class="fourteenth-page-fs1" >
            <div style="height:45px; width: 100%; background-color: #f5b9be; float: left;" 
              [ngStyle]="{'width': addTen(data.dataMap.page14.arts)+'%' }"></div>
            <div class="fourteenth-page-perc" [ngStyle]="{'left': addTen(data.dataMap.page14.arts)+'%' }">
              {{data.dataMap.page14.arts}}%
            </div>
            <div class="clear-both"></div>
          </div>
          
          <div class="clear-both"></div>
        </div>
        <div class="clear-both"></div>
      </div>
  
      <div class="mt-20">
        <div class="fourteenth-page-firstbx25" >
          <div class="" style="padding: 0px 5px;">
            <span style="color: #00a6bf; font-weight: 900;font-size: 20px; "> COMMERCE</span>
            <div style="color: #0d0d0d;">Study in exchange of goods and services including its Legal, Economical, Political, 
            Social, Cultural and Technological Systems. Good for students aspiring for Accountancy, 
            Marketing, Finance, Business Management, Planning etc. Commerce is essentially learning of the various activities that transpire in the diverse world of business.</div>
          </div>       
        </div>
  
        <div class="fourteenth-page-first-bx45" >
          <div class="fourteenth-page-fs1" >
            <div style="height:45px; width: 100%; float: left; background-color: #95cfb0;" 
            [ngStyle]="{'width': addTen(data.dataMap.page14.com)+'%' }"></div>
            <div class="fourteenth-page-perc" [ngStyle]="{'left': addTen(data.dataMap.page14.com)+'%' }">
              {{data.dataMap.page14.com}}%
            </div>
            <div class="clear-both"></div>
          </div>
          
         <div class="clear-both"></div>
        </div>
        <div class="clear-both"></div>
      </div>

      <div class="mt-20">
        <div class="fourteenth-page-firstbx25" >
          <div class="" style="padding: 0px 5px;">
            <span style="color: #00a6bf; font-weight: 900;font-size: 19px; "> MATHEMATICAL SCIENCE (PCM)</span>
            <div style="color: #0d0d0d;">Most versatile stream for students who opt from Various types of Quantitative 
            Sciences, Different branches in field of Engineering, Designing, Architecture, 
            Bachelorette in Physics, Chemistry, Mathematics etc. (PCM) is for students who want to build career in 'Technical Sector'.</div>
          </div>       
        </div>
  
        <div class="fourteenth-page-first-bx45" >
          <div class="fourteenth-page-fs1" >
            <div style="height:45px; float: left; width: 100%; background-color: #40b6ed;" 
            [ngStyle]="{'width': addTen(data.dataMap.page14.math_sci)+'%' }"></div>
            <div class="fourteenth-page-perc" [ngStyle]="{'left': addTen(data.dataMap.page14.math_sci)+'%' }">
              {{data.dataMap.page14.math_sci}}%
            </div>
            <div class="clear-both"></div>
          </div>

         <div class="clear-both"></div>
        </div>
        <div class="clear-both"></div>
      </div>
      
     
      <div class="mt-20">
          <div class="fourteenth-page-firstbx25" >
            <div class="" style="padding: 0px 5px;">
              <span style="color: #00a6bf; font-weight: 900;font-size: 20px; "> BIOLOGICAL SCIENCE (PCB)</span>
              <div style="color: #0d0d0d;">Natural science concerned with the study of Living organisms including their structure, 
              Function, Growth, Origin, Evolution, Taxonomy, etc. (PCB) is for students who like to pursue 
              studies in the field of Medicine, Genetic Engineering, Physiotherapy, Bio technology, Zoology, Botany etc.
              </div>
            </div>    
            <div class="clear-both"></div>
          </div>
    
          <div class="fourteenth-page-first-bx45" >
            <div class="fourteenth-page-fs1" >
              <div style="height:45px; float: left; width: 100%; background-color: #a097c5;" 
              [ngStyle]="{'width': addTen(data.dataMap.page14.bio_sci)+'%' }"></div>
              <div class="fourteenth-page-perc" [ngStyle]="{'left': addTen(data.dataMap.page14.bio_sci)+'%' }">
                {{data.dataMap.page14.bio_sci}}%
             </div>
             <div class="clear-both"></div>
            </div>
    
           <div class="clear-both"></div>
          </div>
          <div class="clear-both"></div>
      </div>

       <div class="mt-20" style="background-color: #e6e7e8!important;">
        <div class="fourteenth-page-row" style="padding-bottom: 0;">
          <div class="fourteenth-page-first-box" style="width: 2%;"><strong>Q</strong></div>
          <div class="fourteenth-page-first-box" style="width: 90%; "><strong>What if I decide to select a stream which is less preferred as per this report?</strong> </div>
          <div class="clear-both"></div>
        </div>
        <div class="fourteenth-page-row" style="padding-bottom: 20px;">
          <div class="fourteenth-page-first-box" style="width: 2%;"><strong>A</strong></div>
          <div class="fourteenth-page-first-box" style="width: 90%; text-align: justify; ">
              As the saying goes "Nothing is Impossible", the less preferred stream does not indicate that 
              you won't be able to peruse the studies in that particular stream. The less preferred stream will 
              demand a little bit of extra effort on certain subjects. We suggest you to apply your thoughts, 
              take guidance from experts and do brief research on all the subjects of that particular stream 
              before you take you final call. You still have couple of more years to ponder upon before taking 
              the&nbsp;final&nbsp;plunge. 
          </div>
          <div class="clear-both"></div>
        </div>
        <div class="clear-both"></div>
      </div>
      
      
    
    </div>
  </div>