import { Component, OnInit } from '@angular/core';
import { ClientService } from '../services/client.service';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CryptoService } from '../crypto.service';

@Component({
  selector: 'app-client-detail',
  templateUrl: './client-detail.component.html',
  styleUrls: ['./client-detail.component.css']
})
export class ClientDetailComponent implements OnInit {

  clientModel: any = {};
  fileToUpload: File = null;
  isCompleted: boolean = false;
  isPending: boolean = false;
  base64: string;
  imageUrl: string;
  imageUploadURL: any;
  formData: FormData;

  constructor(private clientService: ClientService, private route: ActivatedRoute, private SpinnerService: NgxSpinnerService, private cryptoService: CryptoService) { }

  ngOnInit(): void {
    var type = this.route.snapshot.paramMap.get('type');
    if (type == "pending")
      this.isPending = true;
    else
      this.isCompleted = true;
    this.loadClientDetail();
  }

  loadClientDetail() {
    this.SpinnerService.show();
    var dataModel = {
      "clientId": this.route.snapshot.paramMap.get('clientId'),
      "dealer_client_id": this.route.snapshot.paramMap.get('dealerClientId')
    }

    var payload1 = JSON.stringify(dataModel).toString();
    var loginPayload = this.cryptoService.getEncryptedBody(localStorage.getItem("guid"), payload1);
    //console.log("encrypted model: ", loginPayload.toString());

    var model = {
      //"headers": { "x-shyld-app-id": congnitoIdEnc },
      "data": loginPayload.toString()
    }

    this.clientService.getClientDetail(model).subscribe((result: any) => {
      var res = JSON.parse(this.cryptoService.getDecryptedBody(localStorage.getItem('guid'), result.data));
      this.clientModel = res.dataMap.client;
      // this.imageUrl = "data:image/png;base64," + this.clientModel.photo;
      this.imageUrl = this.clientModel.photo;
      console.log(this.imageUrl);
      this.imageUploadURL = this.clientModel.reportUploadUrl;
      console.log(this.imageUploadURL);
      this.SpinnerService.hide();
    });
  }

  download(dealer_client_id) {
    this.SpinnerService.show();
    var dataModel = {
      "dealer_client_id": dealer_client_id
    }

    var payload1 = JSON.stringify(dataModel).toString();
    var loginPayload = this.cryptoService.getEncryptedBody(localStorage.getItem("guid"), payload1);
    //console.log("encrypted model: ", loginPayload.toString());

    var model = {
      //"headers": { "x-shyld-app-id": congnitoIdEnc },
      "data": loginPayload.toString()
    }

    this.clientService.downloadReport(model).subscribe((result: any) => {
      var res = JSON.parse(this.cryptoService.getDecryptedBody(localStorage.getItem('guid'), result.data));
      if (res.status == "success") {
        const linkSource = res.dataMap.reportFile;
        const downloadLink = document.createElement("a");
        //const fileName = "sample.pdf";
        const fileName = res.dataMap.fileName;
        downloadLink.setAttribute("target", "_blank");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        this.SpinnerService.hide();
      }
      else {
        alert("Report is not available");
        this.SpinnerService.hide();
      }
    });
  }

  handleFileInput(event) {
    const file = event.target.files[0];
    this.formData = new FormData();
    this.formData.append('doc', file);
  }

  uploadFile(clientId, dealerClientId) {
    if(!this.formData) {
      alert('Please choose a report first');
      return;
    }
    this.SpinnerService.show();
   
    this.clientService.uploadReportwithURL(this.imageUploadURL, this.formData).subscribe((result: any) => {
      console.log(result);
      this.sendURLtoBackend(clientId, dealerClientId);
    },
    error => {
      console.log(error);
      alert(error);
      this.SpinnerService.hide();
    });
  }

  sendURLtoBackend(clientId, dealerClientId) {
     var dataModel = {
      "clientId": clientId,
      "dealer_client_id": dealerClientId,
      "report": this.imageUploadURL
    }

    var payload1 = JSON.stringify(dataModel).toString();
    var loginPayload = this.cryptoService.getEncryptedBody(localStorage.getItem("guid"), payload1);
    console.log("encrypted model: ", loginPayload.toString());

    var model = {
      //"headers": { "x-shyld-app-id": congnitoIdEnc },
      "data": loginPayload.toString()
    }

    console.log(model);

    this.clientService.uploadReport(model).subscribe((result: any) => {
      var res = JSON.parse(this.cryptoService.getDecryptedBody(localStorage.getItem('guid'), result.data));
      if (res.status == "success") {
        if (res.dataMap.ReportUploadStatus == "success") {
          alert("Report uploaded successfully.");
        }
        else {
          alert("Error occurred while uploading report. Please try after sometime.");
        }
      }
      else {
        alert("Error occurred while uploading report. Please try after sometime.");
      }
      this.SpinnerService.hide();
    });

  }
}
