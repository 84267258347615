<div class="thirty-five-page mt-40">
    <div class="text-right">
        <img class="logo-img" src="assets/images/Header.png" />
    </div>
    <div style="background-color: #232B2E;">
        <div class="thirty-five-page-blue-header">
            Logical-Mathematical ( Number smart )
        </div>
        <div>
            <div class="mt-5">
                <div class="thirty-five-page-cont1">
                    <div>
                        <div class="thirty-five-page-fs23">
                            Logical-Mathematical Intelligence is processing information through logic & Reasoning
                            to indentify patterns and abstract connection between subject or concepts. People with high
                            Logical-Mathematical
                            intelligence are good at numbers (mathematical calculations) and their Complex mind works
                            like a computer
                            to Measure, Investigate, Compare and Analyze. Thier approach is systematic & scientific.
                            They trust on
                            hard facts & numerical data. They dislike assumptions & require logically satisfying reasons
                            to pursue a task.
                        </div>
                        <div class="thirty-five-page-ipr">
                            Understanding of A Concept Comes from Logic
                        </div>
                        <div class="clear-both"></div>
                        <div class="thirty-five-page-line-height">
                            <ul>
                                <li>What is now- Past to Present</li>
                                <li>Understanding of numbers</li>
                                <li>People and situational analysis</li>
                                <li>Planning, Reasoning & Strategizing</li>
                                <li>Classification of Categories</li>
                                <li>Numerical analysis & its differentiation</li>
                                <li>Physics, Geometry, statistics & Grammar</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="thirty-five-page-first-box image-sec">
                    <img src="assets/images/Page 35/PAGE-35-LOGIC.jpg" class="width-100">

                </div>
                <div class="clear-both"></div>
            </div>
        </div>
    </div>
    <div class="mt-20">
        <div class="purple-header">
            CHARACTERISTICS OF A PERSON HAVING HIGH LOGICAL INTELLIGENCE
        </div>
        <div class="thirty-five-page-row-wrapper">
            <div class="tbl-bx">
                <div class="thirty-five-page-section-heading">
                    PROS
                </div>
                <div class="clear-both"></div>
                <div class="thirty-five-page-column-one">
                    <div class="thirty-five-page-section-subheading">
                        <ul class="thirty-five-page-list">
                            <li>Ability to Classify and Categorize precisely.
                                <br> Good in understanding Fundamentals.

                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-five-page-column-two">
                    <div class="thirty-five-page-section-subheading">
                        <ul class="thirty-five-page-list">
                            <li>Adequate at Reasoning, Bargaining, Analyzing,
                                <br>Strategizing and Recognizing patterns.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-five-page-column-two">
                    <div class="thirty-five-page-section-subheading">
                        <ul class="thirty-five-page-list">
                            <li>Splendid in thinking Conceptually about Numbers <br>and its Relationship.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-five-page-column-two" style="border-bottom: none;">
                    <div class="thirty-five-page-section-subheading">
                        <ul class="thirty-five-page-list">
                            <li>Prolific in solving Complex Computation,
                                <br>Investigations & conducting Scientific experiments.
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="clear-both"></div>
            </div>
            <div class="tbl-bx" style="border-left: none;">
                <div class="thirty-five-page-section-heading">
                    CONS
                </div>
                <div class="clear-both"></div>
                <div class="thirty-five-page-column-one">
                    <div class="thirty-five-page-section-subheading">
                        <ul class="thirty-five-page-list">
                            <li>Over-inquisitive nature. Unable to move forward
                                <br>without proper reasoning.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-five-page-column-two">
                    <div class="thirty-five-page-section-subheading">
                        <ul class="thirty-five-page-list">
                            <li>At times very stubborn and obstinate to
                                <br>explore different options.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-five-page-column-two">
                    <div class="thirty-five-page-section-subheading">
                        <ul class="thirty-five-page-list">
                            <li>Vision is limited to the subject of interest,
                                <br>Inflexible to variations.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-five-page-column-two" style="border-bottom: none;">
                    <div class="thirty-five-page-section-subheading">
                        <ul class="thirty-five-page-list">
                            <li>Often manipulative to win a bargain or debate.
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="clear-both"></div>
            </div>
        </div>
    </div>

    <div class="mt-20">
        <div class="purple-header">
            REMEDIAL MEASURES IF YOUR LOGICAL INTELLIGENCE IS LOW
        </div>
        <div class="IIL-bg">
            <div>
                <div class="IIL-sec">Take measures to improve the following</div>
                <div class="IIL-sec1">1) PERSISTENCE AND PERSEVERANCE</div>
                <div>
                    <ul class="thirty-five-page-list-IIL">
                        <li>The first step towards improving your logic is to cumulate will power and strong
                            determination, because you will have to get used to <br>do mental workouts which might be
                            against your liking or priority. So pull up your socks and get ready.
                        </li>
                    </ul>
                </div>
                <div class="clear-both"></div>
            </div>
            <div style="margin-top:10px;">
                <div class="IIL-sec1">2) JOIN BRAIN GYM</div>
                <div>
                    <ul class="thirty-five-page-list-IIL">
                        <li>Play logical games, solve puzzles/ riddles, Play board games,
                            Try and attempt Brain teasers.
                        </li>
                        <li>Take extra courses on Geometry, Physics, and Grammar. Learn Abacus and Vedic Mathematics.
                        </li>
                        <li>Read Business sections in Newspapers/ Economic Times. Approach an
                            expert who can simplify unfamiliar topics for you.
                        </li>
                        <li>Explore YouTube channels on all Microscopic and Telescopic
                            worlds to improve on Sense of Comparison.
                        </li>
                    </ul>
                </div>
                <div class="clear-both"></div>
            </div>
            <div style="margin-top:10px;">
                <div class="IIL-sec1">3) DEVELOP INQUISITIVENESS</div>
                <div>
                    <ul class="thirty-five-page-list-IIL">
                        <li>Start asking questions and finding answers. Example—What is a Black Hole? How big is
                            Universe? Why is Ocean blue? What is DNA? How does a TV, Fan, Microwave, Piano, Battery,
                            etc. work?
                            
                        </li>
                        <li>
                            Surf informative websites like 'How Stuff Works' etc.
                        </li>
                    </ul>
                </div>
                <div class="clear-both"></div>
            </div>
            <div style="margin-top:10px; padding-bottom: 30px;">
                <div class="IIL-sec1">4) FIND A MENTOR</div>
                <div>
                    <ul class="thirty-five-page-list-IIL">
                        <li> Make a list of Well Wishers to whom you can approach whenever you have any doubt on
                            a particular subject of his/ her expertise.
                        </li>
                        <li>Always take a second opinion for all important decisions in life. Befriend
                            intelligent persons. Avoid company of Fools.
                        </li>
                    </ul>
                </div>
                <div class="clear-both"></div>
            </div>
            
        </div>
    </div>



</div>