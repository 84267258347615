<form *ngIf="!addClientDetails" [formGroup]="clientDetailForm">
<table class="mat-table cdk-table mat-elevation-z8" style="width: 100%;padding: 2%;">
    
        <tr>
            <td class="label-td" align="right">
                <label>Client Name: </label>
            </td>
            <td class="text-td">
                <input type="text" class="input-text" formControlName="clientname" />
                <!-- <label>{{clientModel.clientname}}</label> -->
            </td>
        </tr>
        <tr>
            <td class="label-td" align="right">
                <label>Gender: </label>
            </td>
            <td class="text-td">
                <!-- <input type="text" class="input-text" formControlName="gender" /> -->
                <mat-radio-group formControlName="gender" aria-label="Select an option">
                    <mat-radio-button value="Male">Male</mat-radio-button>
                    <mat-radio-button value="Female">Female</mat-radio-button>
                </mat-radio-group>
                <!-- <label>{{clientModel.gender}}</label> -->
            </td>
        </tr>
        <tr>
            <td class="label-td" align="right">
                <label>DOB: </label>
            </td>
            <td class="text-td">
                <!-- <input type="text" class="input-text" formControlName="dob" /> -->
                <mat-form-field appearance="fill" style="width: 41%;">
                    <input matInput formControlName="dob" [matDatepicker]="picker1">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>

                <!-- <label>{{clientModel.dob}}</label> -->
            </td>
        </tr>
        <tr>
            <td class="label-td" align="right">
                <label>Age: </label>
            </td>
            <td class="text-td">
                <input type="text" class="input-text" formControlName="age" />
                <!-- <label>{{clientModel.age}}</label> -->
            </td>
        </tr>
        <tr>
            <td class="label-td" align="right">
                <label>Handiness: </label>
            </td>
            <td class="text-td">
                <input type="text" class="input-text" formControlName="handiness" />
                <!-- <label>{{clientModel.handiness}}</label> -->
            </td>
        </tr>
        <tr>
            <td class="label-td" align="right">
                <label>Nationality: </label>
            </td>
            <td class="text-td">
                <input type="text" class="input-text" formControlName="nationality" />
                <!-- <label>{{clientModel.nationality}}</label> -->
            </td>
        </tr>
        <tr>
            <td class="label-td" align="right">
                <label>Address: </label>
            </td>
            <td class="text-td">
                <input type="text" class="input-text" formControlName="address" />
                <!-- <label>{{clientModel.address}}</label> -->
            </td>
        </tr>
        <tr>
            <td class="label-td" align="right">
                <label>City: </label>
            </td>
            <td class="text-td">
                <input type="text" class="input-text" formControlName="city" />
                <!-- <label>{{clientModel.city}}</label> -->
            </td>
        </tr>
        <tr>
            <td class="label-td" align="right">
                <label>State: </label>
            </td>
            <td class="text-td">
                <input type="text" class="input-text" formControlName="state" />
                <!-- <label>{{clientModel.state}}</label> -->
            </td>
        </tr>
        <tr>
            <td class="label-td" align="right">
                <label>Country: </label>
            </td>
            <td class="text-td">
                <input type="text" class="input-text" formControlName="country" />
                <!-- <label>{{clientModel.country}}</label> -->
            </td>
        </tr>
        <tr>
            <td class="label-td" align="right">
                <label>Zipcode: </label>
            </td>
            <td class="text-td">
                <input type="text" class="input-text" formControlName="zipcode" />
                <!-- <label>{{clientModel.zipcode}}</label> -->
            </td>
        </tr>
        <tr>
            <td class="label-td" align="right">
                <label>Occupation: </label>
            </td>
            <td class="text-td">
                <input type="text" class="input-text" formControlName="occupation" />
                <!-- <label>{{clientModel.occupation}}</label> -->
            </td>
        </tr>
        <tr>
            <td class="label-td" align="right">
                <label>Contact: </label>
            </td>
            <td class="text-td">
                <input type="text" class="input-text" formControlName="contact" />
                <!-- <label>{{clientModel.contact}}</label> -->
            </td>
        </tr>
        <tr>
            <td class="label-td" align="right">
                <label>Email: </label>
            </td>
            <td class="text-td">
                <input type="text" class="input-text" formControlName="email" />
                <!-- <label>{{clientModel.email}}</label> -->
            </td>
        </tr>
        <tr>
            <td class="label-td" align="right">
                <label>Captured By: </label>
            </td>
            <td class="text-td">
                <input type="text" class="input-text" formControlName="capturedby" />
                <!-- <label>{{clientModel.capturedby}}</label> -->
            </td>
        </tr>
        <tr>
            <td class="label-td" align="right">
                <label>Part In Research: </label>
            </td>
            <td class="text-td">
                <input type="text" class="input-text" formControlName="partinresearch" />
                <!-- <label>{{clientModel.partinresearch}}</label> -->
            </td>
        </tr>
        <tr>
            <td class="label-td" align="right">
                <label>Religion: </label>
            </td>
            <td class="text-td">
                <input type="text" class="input-text" formControlName="religion" />
                <!-- <label>{{clientModel.religion}}</label> -->
            </td>
        </tr>
        <tr>
            <td class="label-td" align="right">
                <label>Cast: </label>
            </td>
            <td class="text-td">
                <input type="text" class="input-text" formControlName="cast" />
                <!-- <label>{{clientModel.cast}}</label> -->
            </td>
        </tr>
        <tr>
            <td class="label-td" align="right">
                <label>Subcast: </label>
            </td>
            <td class="text-td">
                <input type="text" class="input-text" formControlName="subcast" />
                <!-- <label>{{clientModel.subcast}}</label> -->
            </td>
        </tr>
        <tr>
            <td class="label-td" align="right">
                <label>Birthplace: </label>
            </td>
            <td class="text-td">
                <input type="text" class="input-text" formControlName="birthplace" />
                <!-- <label>{{clientModel.birthplace}}</label> -->
            </td>
        </tr>
        <tr>
            <td class="label-td" align="right">
                <label>Parent Name: </label>
            </td>
            <td class="text-td">
                <input type="text" class="input-text" formControlName="parentname" />
                <!-- <label>{{clientModel.parentname}}</label> -->
            </td>
        </tr>
        <tr>
            <td class="label-td" align="right">
                <label>Mother Tongue: </label>
            </td>
            <td class="text-td">
                <input type="text" class="input-text" formControlName="mothertongue" />
                <!-- <label>{{clientModel.isArchive}}</label> -->
            </td>
        </tr>

        <tr>
            <td class="label-td" align="right">
                <label>Photo: </label>
            </td>
            <td class="text-td">
                <input type="file" (change)="handleFileInput($event)" />
                <input type="hidden" name="fileHidden" formControlName="clientphoto"/> 
            </td>
        </tr>

        <tr>
            <td class="label-td" align="right">
               
            </td>
            <td class="text-td" style="padding-top: 10px">
                <button type="submit" (click)="onSubmit()" class="save-btn">Save
                    <i *ngIf="formDataLoading" class="fa fa-spinner fa-spin"></i>
                </button>   
                <!-- <input type="file" formControlName="clientphoto" /> -->
            </td>
        </tr>
        
   
    
</table>
</form>

<div class="white-bg" *ngIf="addClientDetails">
    <div class="flex-row" style="padding-bottom: 10px;">
        <div class="flex-1">
            <label class="label-txt">Fingers</label>
        </div>
        <div class="flex-1">
            <label class="label-txt">Left Image</label>
        </div>
        <div class="flex-1">
            <label class="label-txt">Center Image</label>
        </div>
        <div class="flex-1">
            <label class="label-txt">Right Image</label>
        </div>
    </div>
    <div class="flex-row">
        <div class="flex-1" >
            Left <span style="color: red;">(Thumb)</span> : 
        </div>
        <div class="flex-1">
            <input type="file" (change)="handleFingersFileInput($event, 'leftThumb1')"  />
            <i *ngIf="leftThumb1Check" class="fa fa-check green-tick" aria-hidden="true"></i>
        </div>
        <div class="flex-1">
            <input type="file" (change)="handleFingersFileInput($event, 'leftThumb2')" />
            <i *ngIf="leftThumb2Check" class="fa fa-check green-tick" aria-hidden="true"></i>
        </div>
        <div class="flex-1">
            
            <input type="file" (change)="handleFingersFileInput($event, 'leftThumb3')" />
            <i *ngIf="leftThumb3Check" class="fa fa-check green-tick" aria-hidden="true"></i>
        </div>
        
    </div>

    <div class="flex-row">
        <div class="flex-1" >
            Left <span style="color: #004fff;">(Index)</span> : 
        </div>
        <div class="flex-1">
            
            <input type="file" (change)="handleFingersFileInput($event, 'leftIndex1')" />
            <i *ngIf="leftIndex1Check" class="fa fa-check green-tick" aria-hidden="true"></i>
        </div>
        <div class="flex-1">
            
            <input type="file" (change)="handleFingersFileInput($event, 'leftIndex2')" />
            <i *ngIf="leftIndex2Check" class="fa fa-check green-tick" aria-hidden="true"></i>
        </div>
        <div class="flex-1">
            
            <input type="file" (change)="handleFingersFileInput($event, 'leftIndex3')" />
            <i *ngIf="leftIndex3Check" class="fa fa-check green-tick" aria-hidden="true"></i>
        </div>
        
    </div>

    <div class="flex-row">
        <div class="flex-1" >
            Left <span style="color: #fb00ff;">(Middle)</span> : 
        </div>
        <div class="flex-1">
            
            <input type="file" (change)="handleFingersFileInput($event, 'leftMiddle1')" />
            <i *ngIf="leftMiddle1Check" class="fa fa-check green-tick" aria-hidden="true"></i>
            
        </div>
        <div class="flex-1">
            
            <input type="file" (change)="handleFingersFileInput($event, 'leftMiddle2')" />
            <i *ngIf="leftMiddle2Check" class="fa fa-check green-tick" aria-hidden="true"></i>
        </div>
        <div class="flex-1">
            
            <input type="file" (change)="handleFingersFileInput($event, 'leftMiddle3')" />
            <i *ngIf="leftMiddle3Check" class="fa fa-check green-tick" aria-hidden="true"></i>
        </div>
        
    </div>

    <div class="flex-row">
        <div class="flex-1" >
            Left <span style="color: #01ad01;">(Ring)</span> : 
        </div>
        <div class="flex-1">
            
            <input type="file" (change)="handleFingersFileInput($event, 'leftRing1')" />
            <i *ngIf="leftRing1Check" class="fa fa-check green-tick" aria-hidden="true"></i>
            
        </div>
        <div class="flex-1">
            
            <input type="file" (change)="handleFingersFileInput($event, 'leftRing2')" />
            <i *ngIf="leftRing2Check" class="fa fa-check green-tick" aria-hidden="true"></i>
        </div>
        <div class="flex-1">
            
            <input type="file" (change)="handleFingersFileInput($event, 'leftRing3')" />
            <i *ngIf="leftRing3Check" class="fa fa-check green-tick" aria-hidden="true"></i>
        </div>
        
    </div>

    <div class="flex-row">
        <div class="flex-1" >
            Left <span style="color: #a50000;">(Pinky)</span> : 
        </div>
        <div class="flex-1">
            
            <input type="file" (change)="handleFingersFileInput($event, 'leftPinky1')" />
            <i *ngIf="leftPinky1Check" class="fa fa-check green-tick" aria-hidden="true"></i>
            
        </div>
        <div class="flex-1">
            
            <input type="file" (change)="handleFingersFileInput($event, 'leftPinky2')" />
            <i *ngIf="leftPinky2Check" class="fa fa-check green-tick" aria-hidden="true"></i>
        </div>
        <div class="flex-1">
            
            <input type="file" (change)="handleFingersFileInput($event, 'leftPinky3')" />
            <i *ngIf="leftPinky3Check" class="fa fa-check green-tick" aria-hidden="true"></i>
        </div>
        
    </div>

    <div class="flex-row">
        <div class="flex-1" >
            Right <span style="color: red;">(Thumb)</span> : 
        </div>
        <div class="flex-1">
            
            <input type="file" (change)="handleFingersFileInput($event, 'rightThumb1')" />
            <i *ngIf="rightThumb1Check" class="fa fa-check green-tick" aria-hidden="true"></i>
            
        </div>
        <div class="flex-1">
            
            <input type="file" (change)="handleFingersFileInput($event, 'rightThumb2')" />
            <i *ngIf="rightThumb2Check" class="fa fa-check green-tick" aria-hidden="true"></i>
        </div>
        <div class="flex-1">
            
            <input type="file" (change)="handleFingersFileInput($event, 'rightThumb3')" />
            <i *ngIf="rightThumb3Check" class="fa fa-check green-tick" aria-hidden="true"></i>
        </div>
        
    </div>

    <div class="flex-row">
        <div class="flex-1" >
            Right <span style="color: #004fff;">(Index)</span> : 
        </div>
        <div class="flex-1">
            
            <input type="file" (change)="handleFingersFileInput($event, 'rightIndex1')" />
            <i *ngIf="rightIndex1Check" class="fa fa-check green-tick" aria-hidden="true"></i>
        </div>
        <div class="flex-1">
            
            <input type="file" (change)="handleFingersFileInput($event, 'rightIndex2')" />
            <i *ngIf="rightIndex2Check" class="fa fa-check green-tick" aria-hidden="true"></i>
        </div>
        <div class="flex-1">
            
            <input type="file" (change)="handleFingersFileInput($event, 'rightIndex3')" />
            <i *ngIf="rightIndex3Check" class="fa fa-check green-tick" aria-hidden="true"></i>
        </div>
        
    </div>

    <div class="flex-row">
        <div class="flex-1" >
            Right <span style="color: #fb00ff;">(Middle)</span> : 
        </div>
        <div class="flex-1">
            
            <input type="file" (change)="handleFingersFileInput($event, 'rightMiddle1')" />
            <i *ngIf="rightMiddle1Check" class="fa fa-check green-tick" aria-hidden="true"></i>
            
        </div>
        <div class="flex-1">
            
            <input type="file" (change)="handleFingersFileInput($event, 'rightMiddle2')" />
            <i *ngIf="rightMiddle2Check" class="fa fa-check green-tick" aria-hidden="true"></i>
        </div>
        <div class="flex-1">
            
            <input type="file" (change)="handleFingersFileInput($event, 'rightMiddle3')" />
            <i *ngIf="rightMiddle3Check" class="fa fa-check green-tick" aria-hidden="true"></i>
        </div>
        
    </div>

    <div class="flex-row">
        <div class="flex-1" >
            Right <span style="color: #01ad01;">(Ring)</span> : 
        </div>
        <div class="flex-1">
            
            <input type="file" (change)="handleFingersFileInput($event, 'rightRing1')" />
            <i *ngIf="rightRing1Check" class="fa fa-check green-tick" aria-hidden="true"></i>
            
        </div>
        <div class="flex-1">
            
            <input type="file" (change)="handleFingersFileInput($event, 'rightRing2')" />
            <i *ngIf="rightRing2Check" class="fa fa-check green-tick" aria-hidden="true"></i>
        </div>
        <div class="flex-1">
            
            <input type="file" (change)="handleFingersFileInput($event, 'rightRing3')" />
            <i *ngIf="rightRing3Check" class="fa fa-check green-tick" aria-hidden="true"></i>
        </div>
        
    </div>

    <div class="flex-row">
        <div class="flex-1" >
            Right <span style="color: #a50000;">(Pinky)</span> : 
        </div>
        <div class="flex-1">
            
            <input type="file" (change)="handleFingersFileInput($event, 'rightPinky1')" />
            <i *ngIf="rightPinky1Check" class="fa fa-check green-tick" aria-hidden="true"></i>
            
        </div>
        <div class="flex-1">
            
            <input type="file" (change)="handleFingersFileInput($event, 'rightPinky2')"  />
            <i *ngIf="rightPinky2Check" class="fa fa-check green-tick" aria-hidden="true"></i>
        </div>
        <div class="flex-1">
            
            <input type="file" (change)="handleFingersFileInput($event, 'rightPinky3')"  />
            <i *ngIf="rightPinky3Check" class="fa fa-check green-tick" aria-hidden="true"></i>
        </div>
        
    </div>

    <div style="text-align: center;">
        <button (click)="onFingerSubmit()" class="save-btn">Submit
            <i *ngIf="fingerDataLoading" class="fa fa-spinner fa-spin"></i>
        </button>
    </div>


</div>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">Please wait... </p>
</ngx-spinner>