<h1 mat-dialog-title>Please select appropriate option</h1>
<span style="color: red;">{{msg}}</span>
<div style="margin-top: 20px;">
    <mat-label style="margin-right: 10px;">Lactose: </mat-label>
    <mat-radio-group [(ngModel)]="data.lactose" class="lactose-radio" aria-label="Select an option">
        <mat-radio-button [value]="0">Low</mat-radio-button>
        <mat-radio-button [value]="1">Medium</mat-radio-button>
        <mat-radio-button [value]="2">High</mat-radio-button>
    </mat-radio-group>
</div>

<div style="margin-top: 20px;">
    <mat-label style="margin-right: 20px;">Gluten: </mat-label>
    <mat-radio-group [(ngModel)]="data.gluten" class="lactose-radio" aria-label="Select an option">
        <mat-radio-button [value]="0">Low</mat-radio-button>
        <mat-radio-button [value]="1">Medium</mat-radio-button>
        <mat-radio-button [value]="2">High</mat-radio-button>
    </mat-radio-group>  
</div>


<mat-dialog-actions align="end">
    <button mat-button (click)="onNoClick()">Save</button>

    <button mat-button (click)="onClose()">Close</button>
</mat-dialog-actions>