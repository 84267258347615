import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { CryptoService } from '../crypto.service';
import { ClientService } from '../services/client.service';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.css']
})
export class AddClientComponent implements OnInit {
  addClientDetails: boolean;
  formData: FormData;
  base64textString: string;

  fingerData = {
    "listOfFingers": [
      {
        "userId" :  "BOG0137",
        "dealer_client_id": "AD00001",
        "hand": "LeftHand",
        "fingername": "Thumb",
        "leftImage": "",
        "centerImage": "",
        "rightImage": ""
      },
      {
        "userId" :  "BOG0137",
        "dealer_client_id": "AD00001",
        "hand": "LeftHand",
        "fingername": "Index",
        "leftImage": "",
        "centerImage": "",
        "rightImage": ""
      },
      {
        "userId" :  "BOG0137",
        "dealer_client_id": "AD00001",
        "hand": "LeftHand",
        "fingername": "Middle",
        "leftImage": "",
        "centerImage": "",
        "rightImage": ""
      },
      {
        "userId" :  "BOG0137",
        "dealer_client_id": "AD00001",
        "hand": "LeftHand",
        "fingername": "Ring",
        "leftImage": "",
        "centerImage": "",
        "rightImage": ""
      },
      {
        "userId" : "BOG0137",
        "dealer_client_id": "AD00001",
        "hand": "LeftHand",
        "fingername": "Pinky",
        "leftImage": "",
        "centerImage": "",
        "rightImage": ""
      },
      {
        "userId" :  "BOG0137",
        "dealer_client_id": "AD00001",
        "hand": "RightHand",
        "fingername": "Thumb",
        "leftImage": "",
        "centerImage": "",
        "rightImage": ""
      },
      {
        "userId" :  "BOG0137",
        "dealer_client_id": "AD00001",
        "hand": "RightHand",
        "fingername": "Index",
        "leftImage": "",
        "centerImage": "",
        "rightImage": ""
      },
      {
        "userId" :  "BOG0137",
        "dealer_client_id": "AD00001",
        "hand": "RightHand",
        "fingername": "Middle",
        "leftImage": "",
        "centerImage": "",
        "rightImage": ""
      },
      {
        "userId" :  "BOG0137",
        "dealer_client_id": "AD00001",
        "hand": "RightHand",
        "fingername": "Ring",
        "leftImage": "",
        "centerImage": "",
        "rightImage": ""
      },
      {
        "userId" :  "BOG0137",
        "dealer_client_id": "AD00001",
        "hand": "RightHand",
        "fingername": "Pinky",
        "leftImage": "",
        "centerImage": "",
        "rightImage": ""
      }
    ],
    "dealerId": "admin"
  };
  userId: any;
  dealerClientId: any;
  dealerId: any;
  clientDetailForm: FormGroup;
  formDataLoading: boolean;
  fingerDataLoading: boolean;
  leftThumb1Check: boolean;
  rightPinky3Check: boolean;
  rightPinky2Check: boolean;
  rightPinky1Check: boolean;
  rightRing3Check: boolean;
  rightRing2Check: boolean;
  rightRing1Check: boolean;
  rightMiddle3Check: boolean;
  rightMiddle2Check: boolean;
  rightMiddle1Check: boolean;
  rightIndex3Check: boolean;
  rightIndex2Check: boolean;
  rightIndex1Check: boolean;
  rightThumb3Check: boolean;
  rightThumb2Check: boolean;
  rightThumb1Check: boolean;
  leftPinky3Check: boolean;
  leftPinky2Check: boolean;
  leftPinky1Check: boolean;
  leftRing3Check: boolean;
  leftRing2Check: boolean;
  leftRing1Check: boolean;
  leftMiddle3Check: boolean;
  leftMiddle2Check: boolean;
  leftMiddle1Check: boolean;
  leftIndex3Check: boolean;
  leftIndex2Check: boolean;
  leftIndex1Check: boolean;
  leftThumb3Check: boolean;
  leftThumb2Check: boolean;
  

  constructor(private cryptoService: CryptoService, private clientService: ClientService, 
    private datePipe : DatePipe, private SpinnerService: NgxSpinnerService) {
    
    this.clientDetailForm = new FormGroup({
      // dealerId: new FormControl(),
      // dealer_client_id: new FormControl(),
      clientname: new FormControl(''),
      gender: new FormControl(),
      dob: new FormControl(new Date(2000, 0, 1)),
      age: new FormControl(),
      handiness: new FormControl(),
      nationality: new FormControl(),
      address: new FormControl(),
      city: new FormControl(),
      state: new FormControl(),
      country: new FormControl(),
      zipcode: new FormControl(),
      occupation: new FormControl(),
      contact: new FormControl(),
      email: new FormControl(),
      capturedby: new FormControl(),
      partinresearch: new FormControl(),
      religion: new FormControl(),
      cast: new FormControl(),
      subcast: new FormControl(),
      birthplace: new FormControl(),
      parentname: new FormControl(),
      mothertongue: new FormControl(''),
      clientphoto : new FormControl()
    })
  }
  
  

  ngOnInit(): void {
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textString = 'data:image/png;base64,' + btoa(binaryString);
    this.clientDetailForm.controls['clientphoto'].setValue(this.base64textString);
  }

  handleFileInput(event) {
    const file = event.target.files[0];
    // this.formData = new FormData();
    // this.formData.append('picture', file);
    if (file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    } 
  }

  handleFingersFileInput(event, finger) {
    const file = event.target.files[0];
    const _that = this;
    if (file) {
      var reader = new FileReader();
      reader.onload = (function(file){
        return function(e){
            // console.log(e.target.result);
            var binaryString = e.target.result;
            let str = 'data:image/png;base64,' + btoa(binaryString);

            if(finger == 'leftThumb1') {
              _that.fingerData.listOfFingers[0].leftImage = str;
              _that.leftThumb1Check = true;
            }
            else if(finger == 'leftThumb2') {
              _that.fingerData.listOfFingers[0].centerImage = str;
              _that.leftThumb2Check = true;
            }
            else if(finger == 'leftThumb3') {
              _that.fingerData.listOfFingers[0].rightImage = str;
              _that.leftThumb3Check = true;
            }

            else if(finger == 'leftIndex1') {
              _that.fingerData.listOfFingers[1].leftImage = str;
              _that.leftIndex1Check = true;
            }
            else if(finger == 'leftIndex2') {
              _that.fingerData.listOfFingers[1].centerImage = str;
              _that.leftIndex2Check = true;
            }
            else if(finger == 'leftIndex3') {
              _that.fingerData.listOfFingers[1].rightImage = str;
              _that.leftIndex3Check = true;
            }

            else if(finger == 'leftMiddle1') {
              _that.fingerData.listOfFingers[2].leftImage = str;
              _that.leftMiddle1Check = true;
            }
            else if(finger == 'leftMiddle2') {
              _that.fingerData.listOfFingers[2].centerImage = str;
              _that.leftMiddle2Check = true;
            }
            else if(finger == 'leftMiddle3') {
              _that.fingerData.listOfFingers[2].rightImage = str;
              _that.leftMiddle3Check = true;
            }

            else if(finger == 'leftRing1') {
              _that.fingerData.listOfFingers[3].leftImage = str;
              _that.leftRing1Check = true;
            }
            else if(finger == 'leftRing2') {
              _that.fingerData.listOfFingers[3].centerImage = str;
              _that.leftRing2Check = true;
            }
            else if(finger == 'leftRing3') {
              _that.fingerData.listOfFingers[3].rightImage = str;
              _that.leftRing3Check = true;
            }

            else if(finger == 'leftPinky1') {
              _that.fingerData.listOfFingers[4].leftImage = str;
              _that.leftPinky1Check = true;
            }
            else if(finger == 'leftPinky2') {
              _that.fingerData.listOfFingers[4].centerImage = str;
              _that.leftPinky2Check = true;
            }
            else if(finger == 'leftPinky3') {
              _that.fingerData.listOfFingers[4].rightImage = str;
              _that.leftPinky3Check = true;
            }

            else if(finger == 'rightThumb1') {
              _that.fingerData.listOfFingers[5].leftImage = str;
              _that.rightThumb1Check = true;
            }
            else if(finger == 'rightThumb2') {
              _that.fingerData.listOfFingers[5].centerImage = str;
              _that.rightThumb2Check = true;
            }
            else if(finger == 'rightThumb3') {
              _that.fingerData.listOfFingers[5].rightImage = str;
              _that.rightThumb3Check = true;
            }

            else if(finger == 'rightIndex1') {
              _that.fingerData.listOfFingers[6].leftImage = str;
              _that.rightIndex1Check = true;
            }
            else if(finger == 'rightIndex2') {
              _that.fingerData.listOfFingers[6].centerImage = str;
              _that.rightIndex2Check = true;
            }
            else if(finger == 'rightIndex3') {
              _that.fingerData.listOfFingers[6].rightImage = str;
              _that.rightIndex3Check = true;
            }

            else if(finger == 'rightMiddle1') {
              _that.fingerData.listOfFingers[7].leftImage = str;
              _that.rightMiddle1Check = true;
            }
            else if(finger == 'rightMiddle2') {
              _that.fingerData.listOfFingers[7].centerImage = str;
              _that.rightMiddle2Check = true;
            }
            else if(finger == 'rightMiddle3') {
              _that.fingerData.listOfFingers[7].rightImage = str;
              _that.rightMiddle3Check = true;
            }

            else if(finger == 'rightRing1') {
              _that.fingerData.listOfFingers[8].leftImage = str;
              _that.rightRing1Check = true;
            }
            else if(finger == 'rightRing2') {
              _that.fingerData.listOfFingers[8].centerImage = str;
              _that.rightRing2Check = true;
            }
            else if(finger == 'rightRing3') {
              _that.fingerData.listOfFingers[8].rightImage = str;
              _that.rightRing3Check = true;
            }

            else if(finger == 'rightPinky1') {
              _that.fingerData.listOfFingers[9].leftImage = str;
              _that.rightPinky1Check = true;
            }
            else if(finger == 'rightPinky2') {
              _that.fingerData.listOfFingers[9].centerImage = str;
              _that.rightPinky2Check = true;
            }
            else if(finger == 'rightPinky3') {
              _that.fingerData.listOfFingers[9].rightImage = str;
              _that.rightPinky3Check = true;
            }

        };
      })(file);   

      reader.readAsBinaryString(file);
    } 
  }

  onSubmit() {
    this.formDataLoading = true;
    this.SpinnerService.show();
    const today = new Date();
  
    var finalObj = this.clientDetailForm.value;
    finalObj['saveddate'] = new Date(today.getFullYear(), today.getMonth(), today.getDate(), today.getHours(), today.getMinutes(), today.getSeconds());
    finalObj['thumbnails'] = [
      {
        "dealer_client_id": "",
        "hand": "LeftHand",
        "fingername": "Thumb",
        "leftThumbnail": "data:image/png;base64,TWFuIGlzIGRpc3Rpbmd1aXNoZWQsIG5vdCBvbmx5IGJ5IGhpcyByZWFzb24sIGJ1dCBieSB0aGlzIHNpbmd1bGFyIHBhc3Npb24gZnJvbSBvdGhlciBhbmltYWxzLCB3aGljaCBpcyBhIGx1c3Qgb2YgdGhlIG1pbmQsIHRoYXQgYnkgYSBwZXJzZXZlcmFuY2Ugb2YgZGVsaWdodCBpbiB0aGUgY29udGlu dWVkIGFuZCBpbmRlZmF0aWdhYmxlIGdlbmVyYXRpb24gb2Yga25vd2xlZGdlLCBleGNlZWRzIHRoZSBzaG9ydCB2ZWhlbWVuY2Ugb2YgYW55IGNhcm5hbCBwbGVhc3VyZS4=",
        "centerThumbnail": "data:image/png;base64,TWFuIGlzIGRpc3Rpbmd1aXNoZWQsIG5vdCBvbmx5IGJ5IGhpcyByZWFzb24sIGJ1dCBieSB0aGlzIHNpbmd1bGFyIHBhc3Npb24gZnJvbSBvdGhlciBhbmltYWxzLCB3aGljaCBpcyBhIGx1c3Qgb2YgdGhlIG1pbmQsIHRoYXQgYnkgYSBwZXJzZXZlcmFuY2Ugb2YgZGVsaWdodCBpbiB0aGUgY29udGlu dWVkIGFuZCBpbmRlZmF0aWdhYmxlIGdlbmVyYXRpb24gb2Yga25vd2xlZGdlLCBleGNlZWRzIHRoZSBzaG9ydCB2ZWhlbWVuY2Ugb2YgYW55IGNhcm5hbCBwbGVhc3VyZS4=",
        "rightThumbnail": "data:image/png;base64,TWFuIGlzIGRpc3Rpbmd1aXNoZWQsIG5vdCBvbmx5IGJ5IGhpcyByZWFzb24sIGJ1dCBieSB0aGlzIHNpbmd1bGFyIHBhc3Npb24gZnJvbSBvdGhlciBhbmltYWxzLCB3aGljaCBpcyBhIGx1c3Qgb2YgdGhlIG1pbmQsIHRoYXQgYnkgYSBwZXJzZXZlcmFuY2Ugb2YgZGVsaWdodCBpbiB0aGUgY29udGlu dWVkIGFuZCBpbmRlZmF0aWdhYmxlIGdlbmVyYXRpb24gb2Yga25vd2xlZGdlLCBleGNlZWRzIHRoZSBzaG9ydCB2ZWhlbWVuY2Ugb2YgYW55IGNhcm5hbCBwbGVhc3VyZS4=",
      },
      {
        "dealer_client_id": "",
        "hand": "LeftHand",
        "fingername": "Index",
        "leftThumbnail": "data:image/png;base64,TWFuIGlzIGRpc3Rpbmd1aXNoZWQsIG5vdCBvbmx5IGJ5IGhpcyByZWFzb24sIGJ1dCBieSB0aGlzIHNpbmd1bGFyIHBhc3Npb24gZnJvbSBvdGhlciBhbmltYWxzLCB3aGljaCBpcyBhIGx1c3Qgb2YgdGhlIG1pbmQsIHRoYXQgYnkgYSBwZXJzZXZlcmFuY2Ugb2YgZGVsaWdodCBpbiB0aGUgY29udGlu dWVkIGFuZCBpbmRlZmF0aWdhYmxlIGdlbmVyYXRpb24gb2Yga25vd2xlZGdlLCBleGNlZWRzIHRoZSBzaG9ydCB2ZWhlbWVuY2Ugb2YgYW55IGNhcm5hbCBwbGVhc3VyZS4=",
        "centerThumbnail": "data:image/png;base64,TWFuIGlzIGRpc3Rpbmd1aXNoZWQsIG5vdCBvbmx5IGJ5IGhpcyByZWFzb24sIGJ1dCBieSB0aGlzIHNpbmd1bGFyIHBhc3Npb24gZnJvbSBvdGhlciBhbmltYWxzLCB3aGljaCBpcyBhIGx1c3Qgb2YgdGhlIG1pbmQsIHRoYXQgYnkgYSBwZXJzZXZlcmFuY2Ugb2YgZGVsaWdodCBpbiB0aGUgY29udGlu dWVkIGFuZCBpbmRlZmF0aWdhYmxlIGdlbmVyYXRpb24gb2Yga25vd2xlZGdlLCBleGNlZWRzIHRoZSBzaG9ydCB2ZWhlbWVuY2Ugb2YgYW55IGNhcm5hbCBwbGVhc3VyZS4=",
        "rightThumbnail": "data:image/png;base64,TWFuIGlzIGRpc3Rpbmd1aXNoZWQsIG5vdCBvbmx5IGJ5IGhpcyByZWFzb24sIGJ1dCBieSB0aGlzIHNpbmd1bGFyIHBhc3Npb24gZnJvbSBvdGhlciBhbmltYWxzLCB3aGljaCBpcyBhIGx1c3Qgb2YgdGhlIG1pbmQsIHRoYXQgYnkgYSBwZXJzZXZlcmFuY2Ugb2YgZGVsaWdodCBpbiB0aGUgY29udGlu dWVkIGFuZCBpbmRlZmF0aWdhYmxlIGdlbmVyYXRpb24gb2Yga25vd2xlZGdlLCBleGNlZWRzIHRoZSBzaG9ydCB2ZWhlbWVuY2Ugb2YgYW55IGNhcm5hbCBwbGVhc3VyZS4=",
      },
      {
        "dealer_client_id": "",
        "hand": "LeftHand",
        "fingername": "Middle",
        "leftThumbnail": "data:image/png;base64,TWFuIGlzIGRpc3Rpbmd1aXNoZWQsIG5vdCBvbmx5IGJ5IGhpcyByZWFzb24sIGJ1dCBieSB0aGlzIHNpbmd1bGFyIHBhc3Npb24gZnJvbSBvdGhlciBhbmltYWxzLCB3aGljaCBpcyBhIGx1c3Qgb2YgdGhlIG1pbmQsIHRoYXQgYnkgYSBwZXJzZXZlcmFuY2Ugb2YgZGVsaWdodCBpbiB0aGUgY29udGlu dWVkIGFuZCBpbmRlZmF0aWdhYmxlIGdlbmVyYXRpb24gb2Yga25vd2xlZGdlLCBleGNlZWRzIHRoZSBzaG9ydCB2ZWhlbWVuY2Ugb2YgYW55IGNhcm5hbCBwbGVhc3VyZS4=",
        "centerThumbnail": "data:image/png;base64,TWFuIGlzIGRpc3Rpbmd1aXNoZWQsIG5vdCBvbmx5IGJ5IGhpcyByZWFzb24sIGJ1dCBieSB0aGlzIHNpbmd1bGFyIHBhc3Npb24gZnJvbSBvdGhlciBhbmltYWxzLCB3aGljaCBpcyBhIGx1c3Qgb2YgdGhlIG1pbmQsIHRoYXQgYnkgYSBwZXJzZXZlcmFuY2Ugb2YgZGVsaWdodCBpbiB0aGUgY29udGlu dWVkIGFuZCBpbmRlZmF0aWdhYmxlIGdlbmVyYXRpb24gb2Yga25vd2xlZGdlLCBleGNlZWRzIHRoZSBzaG9ydCB2ZWhlbWVuY2Ugb2YgYW55IGNhcm5hbCBwbGVhc3VyZS4=",
        "rightThumbnail": "data:image/png;base64,TWFuIGlzIGRpc3Rpbmd1aXNoZWQsIG5vdCBvbmx5IGJ5IGhpcyByZWFzb24sIGJ1dCBieSB0aGlzIHNpbmd1bGFyIHBhc3Npb24gZnJvbSBvdGhlciBhbmltYWxzLCB3aGljaCBpcyBhIGx1c3Qgb2YgdGhlIG1pbmQsIHRoYXQgYnkgYSBwZXJzZXZlcmFuY2Ugb2YgZGVsaWdodCBpbiB0aGUgY29udGlu dWVkIGFuZCBpbmRlZmF0aWdhYmxlIGdlbmVyYXRpb24gb2Yga25vd2xlZGdlLCBleGNlZWRzIHRoZSBzaG9ydCB2ZWhlbWVuY2Ugb2YgYW55IGNhcm5hbCBwbGVhc3VyZS4=",
      },
      {
        "dealer_client_id": "",
        "hand": "LeftHand",
        "fingername": "Ring",
        "leftThumbnail": "data:image/png;base64,TWFuIGlzIGRpc3Rpbmd1aXNoZWQsIG5vdCBvbmx5IGJ5IGhpcyByZWFzb24sIGJ1dCBieSB0aGlzIHNpbmd1bGFyIHBhc3Npb24gZnJvbSBvdGhlciBhbmltYWxzLCB3aGljaCBpcyBhIGx1c3Qgb2YgdGhlIG1pbmQsIHRoYXQgYnkgYSBwZXJzZXZlcmFuY2Ugb2YgZGVsaWdodCBpbiB0aGUgY29udGlu dWVkIGFuZCBpbmRlZmF0aWdhYmxlIGdlbmVyYXRpb24gb2Yga25vd2xlZGdlLCBleGNlZWRzIHRoZSBzaG9ydCB2ZWhlbWVuY2Ugb2YgYW55IGNhcm5hbCBwbGVhc3VyZS4=",
        "centerThumbnail": "data:image/png;base64,TWFuIGlzIGRpc3Rpbmd1aXNoZWQsIG5vdCBvbmx5IGJ5IGhpcyByZWFzb24sIGJ1dCBieSB0aGlzIHNpbmd1bGFyIHBhc3Npb24gZnJvbSBvdGhlciBhbmltYWxzLCB3aGljaCBpcyBhIGx1c3Qgb2YgdGhlIG1pbmQsIHRoYXQgYnkgYSBwZXJzZXZlcmFuY2Ugb2YgZGVsaWdodCBpbiB0aGUgY29udGlu dWVkIGFuZCBpbmRlZmF0aWdhYmxlIGdlbmVyYXRpb24gb2Yga25vd2xlZGdlLCBleGNlZWRzIHRoZSBzaG9ydCB2ZWhlbWVuY2Ugb2YgYW55IGNhcm5hbCBwbGVhc3VyZS4=",
        "rightThumbnail": "data:image/png;base64,TWFuIGlzIGRpc3Rpbmd1aXNoZWQsIG5vdCBvbmx5IGJ5IGhpcyByZWFzb24sIGJ1dCBieSB0aGlzIHNpbmd1bGFyIHBhc3Npb24gZnJvbSBvdGhlciBhbmltYWxzLCB3aGljaCBpcyBhIGx1c3Qgb2YgdGhlIG1pbmQsIHRoYXQgYnkgYSBwZXJzZXZlcmFuY2Ugb2YgZGVsaWdodCBpbiB0aGUgY29udGlu dWVkIGFuZCBpbmRlZmF0aWdhYmxlIGdlbmVyYXRpb24gb2Yga25vd2xlZGdlLCBleGNlZWRzIHRoZSBzaG9ydCB2ZWhlbWVuY2Ugb2YgYW55IGNhcm5hbCBwbGVhc3VyZS4=",
      },
      {
        "dealer_client_id": "",
        "hand": "LeftHand",
        "fingername": "Pinky",
        "leftThumbnail": "data:image/png;base64,TWFuIGlzIGRpc3Rpbmd1aXNoZWQsIG5vdCBvbmx5IGJ5IGhpcyByZWFzb24sIGJ1dCBieSB0aGlzIHNpbmd1bGFyIHBhc3Npb24gZnJvbSBvdGhlciBhbmltYWxzLCB3aGljaCBpcyBhIGx1c3Qgb2YgdGhlIG1pbmQsIHRoYXQgYnkgYSBwZXJzZXZlcmFuY2Ugb2YgZGVsaWdodCBpbiB0aGUgY29udGlu dWVkIGFuZCBpbmRlZmF0aWdhYmxlIGdlbmVyYXRpb24gb2Yga25vd2xlZGdlLCBleGNlZWRzIHRoZSBzaG9ydCB2ZWhlbWVuY2Ugb2YgYW55IGNhcm5hbCBwbGVhc3VyZS4=",
        "centerThumbnail": "data:image/png;base64,TWFuIGlzIGRpc3Rpbmd1aXNoZWQsIG5vdCBvbmx5IGJ5IGhpcyByZWFzb24sIGJ1dCBieSB0aGlzIHNpbmd1bGFyIHBhc3Npb24gZnJvbSBvdGhlciBhbmltYWxzLCB3aGljaCBpcyBhIGx1c3Qgb2YgdGhlIG1pbmQsIHRoYXQgYnkgYSBwZXJzZXZlcmFuY2Ugb2YgZGVsaWdodCBpbiB0aGUgY29udGlu dWVkIGFuZCBpbmRlZmF0aWdhYmxlIGdlbmVyYXRpb24gb2Yga25vd2xlZGdlLCBleGNlZWRzIHRoZSBzaG9ydCB2ZWhlbWVuY2Ugb2YgYW55IGNhcm5hbCBwbGVhc3VyZS4=",
        "rightThumbnail": "data:image/png;base64,TWFuIGlzIGRpc3Rpbmd1aXNoZWQsIG5vdCBvbmx5IGJ5IGhpcyByZWFzb24sIGJ1dCBieSB0aGlzIHNpbmd1bGFyIHBhc3Npb24gZnJvbSBvdGhlciBhbmltYWxzLCB3aGljaCBpcyBhIGx1c3Qgb2YgdGhlIG1pbmQsIHRoYXQgYnkgYSBwZXJzZXZlcmFuY2Ugb2YgZGVsaWdodCBpbiB0aGUgY29udGlu dWVkIGFuZCBpbmRlZmF0aWdhYmxlIGdlbmVyYXRpb24gb2Yga25vd2xlZGdlLCBleGNlZWRzIHRoZSBzaG9ydCB2ZWhlbWVuY2Ugb2YgYW55IGNhcm5hbCBwbGVhc3VyZS4=",
      },
      {
        "dealer_client_id": "",
        "hand": "RightHand",
        "fingername": "Thumb",
        "leftThumbnail": "data:image/png;base64,TWFuIGlzIGRpc3Rpbmd1aXNoZWQsIG5vdCBvbmx5IGJ5IGhpcyByZWFzb24sIGJ1dCBieSB0aGlzIHNpbmd1bGFyIHBhc3Npb24gZnJvbSBvdGhlciBhbmltYWxzLCB3aGljaCBpcyBhIGx1c3Qgb2YgdGhlIG1pbmQsIHRoYXQgYnkgYSBwZXJzZXZlcmFuY2Ugb2YgZGVsaWdodCBpbiB0aGUgY29udGlu dWVkIGFuZCBpbmRlZmF0aWdhYmxlIGdlbmVyYXRpb24gb2Yga25vd2xlZGdlLCBleGNlZWRzIHRoZSBzaG9ydCB2ZWhlbWVuY2Ugb2YgYW55IGNhcm5hbCBwbGVhc3VyZS4=",
        "centerThumbnail": "data:image/png;base64,TWFuIGlzIGRpc3Rpbmd1aXNoZWQsIG5vdCBvbmx5IGJ5IGhpcyByZWFzb24sIGJ1dCBieSB0aGlzIHNpbmd1bGFyIHBhc3Npb24gZnJvbSBvdGhlciBhbmltYWxzLCB3aGljaCBpcyBhIGx1c3Qgb2YgdGhlIG1pbmQsIHRoYXQgYnkgYSBwZXJzZXZlcmFuY2Ugb2YgZGVsaWdodCBpbiB0aGUgY29udGlu dWVkIGFuZCBpbmRlZmF0aWdhYmxlIGdlbmVyYXRpb24gb2Yga25vd2xlZGdlLCBleGNlZWRzIHRoZSBzaG9ydCB2ZWhlbWVuY2Ugb2YgYW55IGNhcm5hbCBwbGVhc3VyZS4=",
        "rightThumbnail": "data:image/png;base64,TWFuIGlzIGRpc3Rpbmd1aXNoZWQsIG5vdCBvbmx5IGJ5IGhpcyByZWFzb24sIGJ1dCBieSB0aGlzIHNpbmd1bGFyIHBhc3Npb24gZnJvbSBvdGhlciBhbmltYWxzLCB3aGljaCBpcyBhIGx1c3Qgb2YgdGhlIG1pbmQsIHRoYXQgYnkgYSBwZXJzZXZlcmFuY2Ugb2YgZGVsaWdodCBpbiB0aGUgY29udGlu dWVkIGFuZCBpbmRlZmF0aWdhYmxlIGdlbmVyYXRpb24gb2Yga25vd2xlZGdlLCBleGNlZWRzIHRoZSBzaG9ydCB2ZWhlbWVuY2Ugb2YgYW55IGNhcm5hbCBwbGVhc3VyZS4=",
      },
      {
        "dealer_client_id": "",
        "hand": "RightHand",
        "fingername": "Index",
        "leftThumbnail": "data:image/png;base64,TWFuIGlzIGRpc3Rpbmd1aXNoZWQsIG5vdCBvbmx5IGJ5IGhpcyByZWFzb24sIGJ1dCBieSB0aGlzIHNpbmd1bGFyIHBhc3Npb24gZnJvbSBvdGhlciBhbmltYWxzLCB3aGljaCBpcyBhIGx1c3Qgb2YgdGhlIG1pbmQsIHRoYXQgYnkgYSBwZXJzZXZlcmFuY2Ugb2YgZGVsaWdodCBpbiB0aGUgY29udGlu dWVkIGFuZCBpbmRlZmF0aWdhYmxlIGdlbmVyYXRpb24gb2Yga25vd2xlZGdlLCBleGNlZWRzIHRoZSBzaG9ydCB2ZWhlbWVuY2Ugb2YgYW55IGNhcm5hbCBwbGVhc3VyZS4=",
        "centerThumbnail": "data:image/png;base64,TWFuIGlzIGRpc3Rpbmd1aXNoZWQsIG5vdCBvbmx5IGJ5IGhpcyByZWFzb24sIGJ1dCBieSB0aGlzIHNpbmd1bGFyIHBhc3Npb24gZnJvbSBvdGhlciBhbmltYWxzLCB3aGljaCBpcyBhIGx1c3Qgb2YgdGhlIG1pbmQsIHRoYXQgYnkgYSBwZXJzZXZlcmFuY2Ugb2YgZGVsaWdodCBpbiB0aGUgY29udGlu dWVkIGFuZCBpbmRlZmF0aWdhYmxlIGdlbmVyYXRpb24gb2Yga25vd2xlZGdlLCBleGNlZWRzIHRoZSBzaG9ydCB2ZWhlbWVuY2Ugb2YgYW55IGNhcm5hbCBwbGVhc3VyZS4=",
        "rightThumbnail": "data:image/png;base64,TWFuIGlzIGRpc3Rpbmd1aXNoZWQsIG5vdCBvbmx5IGJ5IGhpcyByZWFzb24sIGJ1dCBieSB0aGlzIHNpbmd1bGFyIHBhc3Npb24gZnJvbSBvdGhlciBhbmltYWxzLCB3aGljaCBpcyBhIGx1c3Qgb2YgdGhlIG1pbmQsIHRoYXQgYnkgYSBwZXJzZXZlcmFuY2Ugb2YgZGVsaWdodCBpbiB0aGUgY29udGlu dWVkIGFuZCBpbmRlZmF0aWdhYmxlIGdlbmVyYXRpb24gb2Yga25vd2xlZGdlLCBleGNlZWRzIHRoZSBzaG9ydCB2ZWhlbWVuY2Ugb2YgYW55IGNhcm5hbCBwbGVhc3VyZS4=",
      },
      {
        "dealer_client_id": "",
        "hand": "RightHand",
        "fingername": "Middle",
        "leftThumbnail": "data:image/png;base64,TWFuIGlzIGRpc3Rpbmd1aXNoZWQsIG5vdCBvbmx5IGJ5IGhpcyByZWFzb24sIGJ1dCBieSB0aGlzIHNpbmd1bGFyIHBhc3Npb24gZnJvbSBvdGhlciBhbmltYWxzLCB3aGljaCBpcyBhIGx1c3Qgb2YgdGhlIG1pbmQsIHRoYXQgYnkgYSBwZXJzZXZlcmFuY2Ugb2YgZGVsaWdodCBpbiB0aGUgY29udGlu dWVkIGFuZCBpbmRlZmF0aWdhYmxlIGdlbmVyYXRpb24gb2Yga25vd2xlZGdlLCBleGNlZWRzIHRoZSBzaG9ydCB2ZWhlbWVuY2Ugb2YgYW55IGNhcm5hbCBwbGVhc3VyZS4=",
        "centerThumbnail": "data:image/png;base64,TWFuIGlzIGRpc3Rpbmd1aXNoZWQsIG5vdCBvbmx5IGJ5IGhpcyByZWFzb24sIGJ1dCBieSB0aGlzIHNpbmd1bGFyIHBhc3Npb24gZnJvbSBvdGhlciBhbmltYWxzLCB3aGljaCBpcyBhIGx1c3Qgb2YgdGhlIG1pbmQsIHRoYXQgYnkgYSBwZXJzZXZlcmFuY2Ugb2YgZGVsaWdodCBpbiB0aGUgY29udGlu dWVkIGFuZCBpbmRlZmF0aWdhYmxlIGdlbmVyYXRpb24gb2Yga25vd2xlZGdlLCBleGNlZWRzIHRoZSBzaG9ydCB2ZWhlbWVuY2Ugb2YgYW55IGNhcm5hbCBwbGVhc3VyZS4=",
        "rightThumbnail": "data:image/png;base64,TWFuIGlzIGRpc3Rpbmd1aXNoZWQsIG5vdCBvbmx5IGJ5IGhpcyByZWFzb24sIGJ1dCBieSB0aGlzIHNpbmd1bGFyIHBhc3Npb24gZnJvbSBvdGhlciBhbmltYWxzLCB3aGljaCBpcyBhIGx1c3Qgb2YgdGhlIG1pbmQsIHRoYXQgYnkgYSBwZXJzZXZlcmFuY2Ugb2YgZGVsaWdodCBpbiB0aGUgY29udGlu dWVkIGFuZCBpbmRlZmF0aWdhYmxlIGdlbmVyYXRpb24gb2Yga25vd2xlZGdlLCBleGNlZWRzIHRoZSBzaG9ydCB2ZWhlbWVuY2Ugb2YgYW55IGNhcm5hbCBwbGVhc3VyZS4=",
      },
      {
        "dealer_client_id": "",
        "hand": "RightHand",
        "fingername": "Ring",
        "leftThumbnail": "data:image/png;base64,TWFuIGlzIGRpc3Rpbmd1aXNoZWQsIG5vdCBvbmx5IGJ5IGhpcyByZWFzb24sIGJ1dCBieSB0aGlzIHNpbmd1bGFyIHBhc3Npb24gZnJvbSBvdGhlciBhbmltYWxzLCB3aGljaCBpcyBhIGx1c3Qgb2YgdGhlIG1pbmQsIHRoYXQgYnkgYSBwZXJzZXZlcmFuY2Ugb2YgZGVsaWdodCBpbiB0aGUgY29udGlu dWVkIGFuZCBpbmRlZmF0aWdhYmxlIGdlbmVyYXRpb24gb2Yga25vd2xlZGdlLCBleGNlZWRzIHRoZSBzaG9ydCB2ZWhlbWVuY2Ugb2YgYW55IGNhcm5hbCBwbGVhc3VyZS4=",
        "centerThumbnail": "data:image/png;base64,TWFuIGlzIGRpc3Rpbmd1aXNoZWQsIG5vdCBvbmx5IGJ5IGhpcyByZWFzb24sIGJ1dCBieSB0aGlzIHNpbmd1bGFyIHBhc3Npb24gZnJvbSBvdGhlciBhbmltYWxzLCB3aGljaCBpcyBhIGx1c3Qgb2YgdGhlIG1pbmQsIHRoYXQgYnkgYSBwZXJzZXZlcmFuY2Ugb2YgZGVsaWdodCBpbiB0aGUgY29udGlu dWVkIGFuZCBpbmRlZmF0aWdhYmxlIGdlbmVyYXRpb24gb2Yga25vd2xlZGdlLCBleGNlZWRzIHRoZSBzaG9ydCB2ZWhlbWVuY2Ugb2YgYW55IGNhcm5hbCBwbGVhc3VyZS4=",
        "rightThumbnail": "data:image/png;base64,TWFuIGlzIGRpc3Rpbmd1aXNoZWQsIG5vdCBvbmx5IGJ5IGhpcyByZWFzb24sIGJ1dCBieSB0aGlzIHNpbmd1bGFyIHBhc3Npb24gZnJvbSBvdGhlciBhbmltYWxzLCB3aGljaCBpcyBhIGx1c3Qgb2YgdGhlIG1pbmQsIHRoYXQgYnkgYSBwZXJzZXZlcmFuY2Ugb2YgZGVsaWdodCBpbiB0aGUgY29udGlu dWVkIGFuZCBpbmRlZmF0aWdhYmxlIGdlbmVyYXRpb24gb2Yga25vd2xlZGdlLCBleGNlZWRzIHRoZSBzaG9ydCB2ZWhlbWVuY2Ugb2YgYW55IGNhcm5hbCBwbGVhc3VyZS4=",
      },
      {
        "dealer_client_id": "",
        "hand": "RightHand",
        "fingername": "Pinky",
        "leftThumbnail": "data:image/png;base64,TWFuIGlzIGRpc3Rpbmd1aXNoZWQsIG5vdCBvbmx5IGJ5IGhpcyByZWFzb24sIGJ1dCBieSB0aGlzIHNpbmd1bGFyIHBhc3Npb24gZnJvbSBvdGhlciBhbmltYWxzLCB3aGljaCBpcyBhIGx1c3Qgb2YgdGhlIG1pbmQsIHRoYXQgYnkgYSBwZXJzZXZlcmFuY2Ugb2YgZGVsaWdodCBpbiB0aGUgY29udGlu dWVkIGFuZCBpbmRlZmF0aWdhYmxlIGdlbmVyYXRpb24gb2Yga25vd2xlZGdlLCBleGNlZWRzIHRoZSBzaG9ydCB2ZWhlbWVuY2Ugb2YgYW55IGNhcm5hbCBwbGVhc3VyZS4=",
        "centerThumbnail": "data:image/png;base64,TWFuIGlzIGRpc3Rpbmd1aXNoZWQsIG5vdCBvbmx5IGJ5IGhpcyByZWFzb24sIGJ1dCBieSB0aGlzIHNpbmd1bGFyIHBhc3Npb24gZnJvbSBvdGhlciBhbmltYWxzLCB3aGljaCBpcyBhIGx1c3Qgb2YgdGhlIG1pbmQsIHRoYXQgYnkgYSBwZXJzZXZlcmFuY2Ugb2YgZGVsaWdodCBpbiB0aGUgY29udGlu dWVkIGFuZCBpbmRlZmF0aWdhYmxlIGdlbmVyYXRpb24gb2Yga25vd2xlZGdlLCBleGNlZWRzIHRoZSBzaG9ydCB2ZWhlbWVuY2Ugb2YgYW55IGNhcm5hbCBwbGVhc3VyZS4=",
        "rightThumbnail": "data:image/png;base64,TWFuIGlzIGRpc3Rpbmd1aXNoZWQsIG5vdCBvbmx5IGJ5IGhpcyByZWFzb24sIGJ1dCBieSB0aGlzIHNpbmd1bGFyIHBhc3Npb24gZnJvbSBvdGhlciBhbmltYWxzLCB3aGljaCBpcyBhIGx1c3Qgb2YgdGhlIG1pbmQsIHRoYXQgYnkgYSBwZXJzZXZlcmFuY2Ugb2YgZGVsaWdodCBpbiB0aGUgY29udGlu dWVkIGFuZCBpbmRlZmF0aWdhYmxlIGdlbmVyYXRpb24gb2Yga25vd2xlZGdlLCBleGNlZWRzIHRoZSBzaG9ydCB2ZWhlbWVuY2Ugb2YgYW55IGNhcm5hbCBwbGVhc3VyZS4=",
      }
    ];
    finalObj['dealerId'] = 'admin';
    finalObj['languagesknown'] = ["english", "french"];
    finalObj['dob'] = this.datePipe.transform(finalObj['dob'], 'dd-MM-yyyy');
    finalObj['saveddate'] = this.datePipe.transform(finalObj['saveddate'], 'dd-MM-yyyy, HH:mm:ss');
    // this.dealerClientId = finalObj['dealer_client_id'];
    
    this.dealerId = finalObj['dealerId'];
    // console.log(finalObj);

    var payload1 = JSON.stringify(finalObj).toString();
    var loginPayload = this.cryptoService.getEncryptedBody(localStorage.getItem("guid"), payload1);

    var model = {
      "data": loginPayload.toString()
    }

    this.clientService.addClientDetails(model).subscribe((result: any) => {
      var res = JSON.parse(this.cryptoService.getDecryptedBody(localStorage.getItem('guid'), result.data));
      // console.log(res);
      if (res.status == "success") {
        this.userId = res.dataMap.userId;
        this.dealerClientId = res.dataMap.dealerClientId;
        this.updateFingerData();
        this.addClientDetails = true;
      }
      this.formDataLoading = false;
      this.SpinnerService.hide();
    });

  }

  updateFingerData() {
    this.fingerData.dealerId = this.dealerId;
    for (const iterator of this.fingerData.listOfFingers) {
      iterator['userId'] = this.userId;
      iterator['dealer_client_id'] = this.dealerClientId;
    }
  }

  onFingerSubmit() {
    this.fingerDataLoading = true;
    this.SpinnerService.show();
    // console.log(this.fingerData);

    var payload1 = JSON.stringify(this.fingerData).toString();
    var loginPayload = this.cryptoService.getEncryptedBody(localStorage.getItem("guid"), payload1);

    var model = {
      "data": loginPayload.toString()
    }

    this.clientService.addClientFingerPrintDetails(model).subscribe((result: any) => {
      var res = JSON.parse(this.cryptoService.getDecryptedBody(localStorage.getItem('guid'), result.data));
      // console.log(res);
      this.fingerDataLoading = false;
      this.SpinnerService.hide();
      if (res.status == "success") {
        alert('Congratulations, Your Details are uploaded..!');
        this.addClientDetails = false;
        this.clientDetailForm.reset();
      }
    });

  }
}
