<div class="forty-five-page mt-40">
    <div class="text-right">
        <img class="logo-img" src="assets/images/Header.png" />
    </div>

    <div class="blue-header">
        YOUR HEALTH SYMPTOMS
    </div>

    <div class="mt-5 flex-row text-center text-bold">
        <div class="flex-05 gray-bg">SR No</div>
        <div class="flex-2 gray-bg">SYMPTOMS</div>
        <div class="flex-1 gray-bg"><div>Marginal</div><div class="font-size-12">Below<br/> 30%</div></div>
        <div class="flex-1 gray-bg"><div>Medial</div> <div class="font-size-12">Between<br/> 30 to 70%</div></div>
        <div class="flex-1 gray-bg"><div>Major</div> <div class="font-size-12">Above<br/> 70%</div></div>
        <div class="flex-5 gray-bg">PRECAUTION</div>
    </div>

    <div *ngIf="data" class="mt-5 flex-row text-bold">
        <div class="flex-05 gray-bg text-center">1.</div>
        <div class="flex-2 gray-bg">INTOLERANCE <br/>TO GLUTEN </div>
        <div class="flex-1 gray-bg">
            <img *ngIf="data.dataMap.page45.gluten == 0; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-1 gray-bg">
            <img *ngIf="data.dataMap.page45.gluten == 1; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-1 gray-bg">
            <img *ngIf="data.dataMap.page45.gluten == 2; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-5 gray-bg desc">
            Eliminate gluten (Wheat/ Maida, Barley & Rye) Include Quinoa, Brown rice, Wild rice, Sorghum, Tapioca, Millet, Amaranth, Oats. Drink plenty of water & chew well. 
        </div>
    </div>

    <div class="mt-5 flex-row text-bold">
        <div class="flex-05 darkgray-bg text-center">2.</div>
        <div class="flex-2 darkgray-bg">INTOLERANCE <br/>TO LACTOSE </div>
        <div class="flex-1 darkgray-bg">
            <img *ngIf="data.dataMap.page45.lactose == 0; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-1 darkgray-bg">
            <img *ngIf="data.dataMap.page45.lactose == 1; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-1 darkgray-bg">
            <img *ngIf="data.dataMap.page45.lactose == 2; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-5 darkgray-bg desc">
            Avoid or limit taking milk & milk products such as: Paneer, Mozzarella, Ricotta, Butter, Yogurt, Ice cream, Frozen yogurt & dairy-based sherbet, buttermilk or Desserts. 
        </div>
    </div>

    <div class="mt-5 flex-row text-bold">
        <div class="flex-05 gray-bg text-center">3.</div>
        <div class="flex-2 gray-bg">PERIODONTITIS</div>
        <div class="flex-1 gray-bg">
            <img *ngIf="data.dataMap.page45.periodontitis == 0; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-1 gray-bg">
            <img *ngIf="data.dataMap.page45.periodontitis == 1; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-1 gray-bg">
            <img *ngIf="data.dataMap.page45.periodontitis == 2; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-5 gray-bg desc">
            Brush your teeth twice a day. Avoid Phytic acid, Sugary foods & drinks. Garlic paste massage once week, chew Guava leaves for better result & root strengthening.
        </div>
    </div>

    <div class="mt-5 flex-row text-bold">
        <div class="flex-05 darkgray-bg text-center">4.</div>
        <div class="flex-2 darkgray-bg">DENTAL ISSUES</div>
        <div class="flex-1 darkgray-bg">
            <img *ngIf="data.dataMap.page45.dental_issues == 0; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-1 darkgray-bg">
            <img *ngIf="data.dataMap.page45.dental_issues == 1; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-1 darkgray-bg">
            <img *ngIf="data.dataMap.page45.dental_issues == 2; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-5 darkgray-bg desc">
            Brush your teeth twice a day & Salt water rinse. Massage the gums with Clove oil. Clean the teeth with thread. Use Hydrogen peroxide rinse to relieve pain & inflammation.
        </div>
    </div>

    <div class="mt-5 flex-row text-bold">
        <div class="flex-05 gray-bg text-center">5.</div>
        <div class="flex-2 gray-bg">INDIGESTION</div>
        <div class="flex-1 gray-bg">
            <img *ngIf="data.dataMap.page45.indigestion == 0; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-1 gray-bg">
            <img *ngIf="data.dataMap.page45.indigestion == 1; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-1 gray-bg">
            <img *ngIf="data.dataMap.page45.indigestion == 2; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-5 gray-bg desc">
            Avoid gulping the food fast, Eat small meals. Avoid acidic food / fruits, Caffeine, Fried / Spicy food. Get Plenty of Fiber & Healthy fats. Stay Hydrated & Manage Your Stress.
        </div>
    </div>

    <div class="mt-5 flex-row text-bold">
        <div class="flex-05 darkgray-bg text-center">6.</div>
        <div class="flex-2 darkgray-bg">ACIDITY</div>
        <div class="flex-1 darkgray-bg">
            <img *ngIf="data.dataMap.page45.acidity == 0; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-1 darkgray-bg">
            <img *ngIf="data.dataMap.page45.acidity == 1; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-1 darkgray-bg">
            <img *ngIf="data.dataMap.page45.acidity == 2; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-5 darkgray-bg desc">
            Avoid over-eating & going to bed with a full stomach. Avoid heartburn triggers. Limit Alcohol Intake, Caffeine, Raw Onion, Carbonated Beverages. Follow a Low-Carb Diet.
        </div>
    </div>

    <div class="mt-5 flex-row text-bold">
        <div class="flex-05 gray-bg text-center">7.</div>
        <div class="flex-2 gray-bg">MIGRAINE <br/>(HEAD ACHE)  </div>
        <div class="flex-1 gray-bg">
            <img *ngIf="data.dataMap.page45.migrain == 0; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-1 gray-bg">
            <img *ngIf="data.dataMap.page45.migrain == 1; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-1 gray-bg">
            <img *ngIf="data.dataMap.page45.migrain == 2; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-5 gray-bg desc">
            Avoid loud noises & bright lights. Pay attention to food choices. Maintain healthy eating habits. Eat & sleep on a regular schedule. Avoid stress & Practice Meditation.
        </div>
    </div>

    <div class="mt-5 flex-row text-bold">
        <div class="flex-05 darkgray-bg text-center">8.</div>
        <div class="flex-2 darkgray-bg">HYPERTENSION/ BLOOD PRESSURE </div>
        <div class="flex-1 darkgray-bg">
            <img *ngIf="data.dataMap.page45.hypertension == 0; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-1 darkgray-bg">
            <img *ngIf="data.dataMap.page45.hypertension == 1; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-1 darkgray-bg">
            <img *ngIf="data.dataMap.page45.hypertension == 2; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-5 darkgray-bg desc">
            Lose weight, Exercise regularly, Eat a healthy diet, Reduce sodium in your diet. Limit Alcohol & Caffeine. Quit smoking. Reduce your stress. Avoid meat, junk food, pickles & cheese. 
        </div>
    </div>

    <div class="mt-5 flex-row text-bold">
        <div class="flex-05 gray-bg text-center">9.</div>
        <div class="flex-2 gray-bg">SUGAR / DIABETES  </div>
        <div class="flex-1 gray-bg">
            <img *ngIf="data.dataMap.page45.sugar == 0; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-1 gray-bg">
            <img *ngIf="data.dataMap.page45.sugar == 1; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-1 gray-bg">
            <img *ngIf="data.dataMap.page45.sugar == 2; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-5 gray-bg desc">
            Cut Sugar & Refined Carbs. Work Out Regularly, Drink plenty of Water, Lose Weight. Quit Smoking. Avoid Red or processed meat. Choose nuts, beans, whole grains etc.
        </div>
    </div>

    <div class="mt-5 flex-row text-bold">
        <div class="flex-05 darkgray-bg text-center">10.</div>
        <div class="flex-2 darkgray-bg">OBESITY / CHOLESTROL </div>
        <div class="flex-1 darkgray-bg">
            <img *ngIf="data.dataMap.page45.obesity == 0; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-1 darkgray-bg">
            <img *ngIf="data.dataMap.page45.obesity == 1; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-1 darkgray-bg">
            <img *ngIf="data.dataMap.page45.obesity == 2; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-5 darkgray-bg desc">
            Follow a healthy-eating plan. Focus on eating low–glycemic index foods. Exercise regularly. Consume less “bad” fat & more “good” fat. Avoid Maida, processed & Sugary foods.
        </div>
    </div>

    <div class="mt-5 flex-row text-bold">
        <div class="flex-05 gray-bg text-center">11.</div>
        <div class="flex-2 gray-bg">THYROID</div>
        <div class="flex-1 gray-bg">
            <img *ngIf="data.dataMap.page45.thyroid == 0; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-1 gray-bg">
            <img *ngIf="data.dataMap.page45.thyroid == 1; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-1 gray-bg">
            <img *ngIf="data.dataMap.page45.thyroid == 2; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-5 gray-bg desc">
            Avoid eating Soy foods. Restrict Certain vegetables: cabbage, broccoli, kale, cauliflower, spinach, etc. Avoid starchy plants, e.g. Sweet potatoes, cassava & Nuts and seeds.
        </div>
    </div>

    <div class="mt-5 flex-row text-bold">
        <div class="flex-05 darkgray-bg text-center">12.</div>
        <div class="flex-2 darkgray-bg">TUMOR / CYST </div>
        <div class="flex-1 darkgray-bg">
            <img *ngIf="data.dataMap.page45.tumor == 0; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-1 darkgray-bg">
            <img *ngIf="data.dataMap.page45.tumor == 1; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-1 darkgray-bg">
            <img *ngIf="data.dataMap.page45.tumor == 2; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-5 darkgray-bg desc">
            Breathe clean air. Eat Organic, avoid foods grown with pesticides or synthetic fertilizers. Avoid Red/Processed meat, Alcohol, Salted & Sugary food, Microwaved/Canned food.
        </div>
    </div>

    <div class="mt-5 flex-row text-bold">
        <div class="flex-05 gray-bg text-center">13.</div>
        <div class="flex-2 gray-bg">ALZHEIMER</div>
        <div class="flex-1 gray-bg">
            <img *ngIf="data.dataMap.page45.alzheimer == 0; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-1 gray-bg">
            <img *ngIf="data.dataMap.page45.alzheimer == 1; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-1 gray-bg">
            <img *ngIf="data.dataMap.page45.alzheimer == 2; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-5 gray-bg desc">
            Quit Alcohol & Smoking. Avoid added Sugar, Cereal, Snack bars, Pre-sweetened yogurts, Canned food, Condiments, like ketchup, BBQ, Salad dressings etc. Avoid taking stress.
        </div>
    </div>

    <div class="mt-5 flex-row text-bold">
        <div class="flex-05 darkgray-bg text-center">14.</div>
        <div class="flex-2 darkgray-bg">CIPO</div>
        <div class="flex-1 darkgray-bg">
            <img *ngIf="data.dataMap.page45.cipo == 0; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-1 darkgray-bg">
            <img *ngIf="data.dataMap.page45.cipo == 1; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-1 darkgray-bg">
            <img *ngIf="data.dataMap.page45.cipo == 2; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-5 darkgray-bg desc">
            Avoid Anxiety & stop over thinking. Avoid acidic food, alcohol, Chocolates, Carbonated drinks, red meat, dried fruits, nuts & seeds, high fiber breads & raw vegetables.
        </div>
    </div>

    <div class="mt-5 flex-row text-bold">
        <div class="flex-05 gray-bg text-center">15.</div>
        <div class="flex-2 gray-bg">BREAST CANCER <br/>(For Female)</div>
        <div class="flex-1 gray-bg">
            <img *ngIf="data.dataMap.page45.breast_cancer == 0; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-1 gray-bg">
            <img *ngIf="data.dataMap.page45.breast_cancer == 1; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-1 gray-bg">
            <img *ngIf="data.dataMap.page45.breast_cancer == 2; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-5 gray-bg desc">
            Breathe clean air. Eat Organic, avoid foods grown with pesticides or synthetic fertilizers. Avoid Red/Processed meat, Alcohol, Salted & Sugary food, Microwaved/Canned food.
        </div>
    </div>

    <div class="mt-5 flex-row text-bold">
        <div class="flex-05 darkgray-bg text-center">16.</div>
        <div class="flex-2 darkgray-bg">PCOD <br/> (For Female)</div>
        <div class="flex-1 darkgray-bg">
            <img *ngIf="data.dataMap.page45.pcod == 0; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-1 darkgray-bg">
            <img *ngIf="data.dataMap.page45.pcod == 1; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-1 darkgray-bg">
            <img *ngIf="data.dataMap.page45.pcod == 2; else other_content" class="tick-width" src="assets/images/Page 22/tick.jpg" />
            <ng-template #other_content>-</ng-template>
        </div>
        <div class="flex-5 darkgray-bg desc">
            Avoid stress, eat less sugar, and lose weight. Eat natural, unprocessed & high-fiber foods. Consume leafy greens, dark red fruits, red grapes, blueberries, blackberries & cherries. 
        </div>
    </div>

    <div class="mt-10 disc-section">
        DISCLAIMER
    </div>

    <div class="mt-5 font-size-12 text-justify" style="line-height: 16px;">
        The health symptoms and remedies provided in the report is derived from research conducted and submissions made by qualified clinical psychologists and doctors. You are advised to consult a qualified person, or person from medical background and get a complete checkup done before you start on any medication based on findings of this report. Any kind of medication or advice should be taken under the supervision and consultation of medical practitioners only. Any kind of reliance placed on the accuracy of the test results is at your own risk. 'Academy of Brainography' uses reasonable endeavors to ensure the accuracy of the information in the findings, however they do not guarantee the accuracy, legality and reliability of the test results. The data has been compiled from variety of sources and the related references that are openly available on the internet. 
    </div>

</div>
