import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page39',
  templateUrl: './page39.component.html',
  styleUrls: ['./page39.component.css']
})
export class Page39Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
