<div class="forty-one-page mt-40">
    <div class="text-right">
        <img class="logo-img" src="assets/images/Header.png" />
    </div>

    <div style="background-color: #149195;">
        <div class="thirty-three-page-blue-header">
            NATURALIST-MINUTE OBSERVATION (Nature Smart) 
        </div>
        <div class="mt-10">
            <div class="mt-5">
                <div class="thirty-three-page-cont1">
                    <div>
                        <div class="thirty-three-page-fs23">
                            Naturalist Intelligence is the ability to observe minutely the gradual changes in Nature. In other words, the ability to utilize the 5 senses efficiently to recognize Flora and Fauna and make other Consequential Distinctions in Natural World. People with high Naturalist Intelligence have sharp Observation and affinity towards Nature (Living and Non-living). They are very practical & good at displaying their talents. 
                        </div>
                        <div class="thirty-three-page-ipr">
                            Naturalist is Observing Minutely & Bonding with Nature 
                        </div>
                        <div class="clear-both"></div>
                        <div class="thirty-three-page-line-height">
                            <ul>
                                <li>Love for Animals, Plants & Nature</li>
                                <li>Minute Observation & Detailing</li>
                                <li>Visual Identification & Sensitivity</li>
                                <li>Reading, Editing, Auditing, Documenting</li>
                                <li>Identifying errors, Trouble shooting</li>
                                <li>Speed, Judgement & Trajectory </li>
                                <li>Intensity of all 5 Senses</li>
                                <li>Biology & Culinary skills </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="thirty-three-page-first-box" style="text-align: center;">
                    <img src="assets/images/Page 41/page41-poster.PNG" class="width-100">

                </div>
                <div class="clear-both"></div>
            </div>
        </div>
    </div>

    <div class="mt-20">
        <div class="purple-header">
            CHARACTERISTICS OF A PERSON HAVING HIGH NATURALIST INTELLIGENCE 
        </div>
        <div class="thirty-three-page-row-wrapper">
            <div class="tbl-bx">
                <div class="thirty-three-page-section-heading">
                    PROS
                </div>
                <div class="clear-both"></div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Ability to classify Living & Non- living entities
                                 <br>Ex. Animals, Plant species, Rocks etc.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Prolific in Reading, Editing, Auditing, Identifying <br/> errors, Speed Judgement & Cooking. 
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Sharp Attention to details & Highly receptive<br/> Senses (Touch, Smell, Taste, Vision & Sound).
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-one" style="border-bottom: none;">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Deeply rooted in Sensitive, Ethical and Holistic <br/>understanding of the world and its complexities. 
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="clear-both"></div>
            </div>
            <div class="tbl-bx" style="border-left: none;">
                <div class="thirty-three-page-section-heading">
                    CONS
                </div>
                <div class="clear-both"></div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Sensitive to changes in Atmosphere or Weather <br />conditions. Body reacts faster to these changes.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Natural inclination towards Conservation and <br />Recycling but sometimes becomes too impractical.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Mood is highly sensitive to the surroundings. <br />Mood changes drastically as per Circumstances.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-one" style="border-bottom: none;">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Good control over Speed and Trajectory but this <br />often leads to Over confidence. 
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="clear-both"></div>
            </div>
        </div>
    </div>

    <div class="mt-20">
        <div class="purple-header">
            REMEDIAL MEASURES IF YOUR NATURALIST INTELLIGENCE IS LOW 
        </div>
        <div class="IIL-bg">
            <div>
                <div class="IIL-sec">Take measures to improve the following </div>
                <div class="IIL-sec1">1) IMPROVE YOUR SENSIBLITY.</div>
                <div>
                    <ul class="thirty-three-page-list-IIL">
                        <li>The most effective way to improve the profoundness of all the 5 Senses is to do Rigorous Meditation.
                             Develop Inquisitiveness <br /> & try to find out 'Why?' & 'How?' of everything that you don't know. 
                        </li>
                    </ul>
                </div>
                <div class="clear-both"></div>
            </div>

            <div>
                <div class="IIL-sec1">2) IMPROVE YOUR MINUTE OBSERVATION.</div>
                <div>
                    <ul class="thirty-three-page-list-IIL">
                        <li> Play Games such as Optical Illusion, Spot the Difference, Jigsaw Puzzles etc.
                        </li>
                        <li>
                            Watch plants grow on daily basis and try to observe the gradual changes happening each day.
                        </li>
                        <li>
                            Assign yourself a challenging Scavenger Hunt activity and photo shoot the subject. Explore the details 
                        </li>
                    </ul>
                </div>
                <div class="clear-both"></div>
            </div>
            <div>
                <div class="IIL-sec1">3) GET CONNECTED TO NATURE.</div>
                <div>
                    <ul class="thirty-three-page-list-IIL">
                        <li>Adopt a Pet Animal and learn to communicate with it.
                        </li>
                        <li>Try Gardening of rare and exotic plants. Grow herbs & spices at home.</li>
                        <li>
                            Make frequent visits to Nature parks, Botanical gardens or zoo.
                        </li>
                        <li>
                            Learn Natural Life Art & Photography. Take part in contests.
                        </li>
                        <li>Use Binoculars, Telescopes, Microscopes & Magnifiers to see things differently.</li>
                        <li>Improve understanding of Nature and Cosmos. Watch Discovery, Animal Planet, National Geographic etc.</li>
                        <li>Indulge in Nature related Sports such as Biking, Trekking, Swimming, Golf etc.</li>
                    </ul>
                </div>
                <div class="clear-both"></div>
            </div>
            <div>
                <div class="IIL-sec1">4) LEARN COOKING.</div>
                <div>
                    <ul class="thirty-three-page-list-IIL">
                        <li> Join cooking classes or learn to cooking online from YouTube. Try dishes from various international cuisines. 
                        </li>
                    </ul>
                </div>
                <div class="clear-both"></div>
            </div>
           
        </div>
    </div>

</div>