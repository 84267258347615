<div class="thirty-nine-page">
    <div class="text-right">
        <img class="logo-img" src="assets/images/Header.png" />
    </div>

    <div style="background-color: #226c94;">
        <div class="thirty-three-page-blue-header">
            LINGUISTIC - VERBAL (Language Smart)
        </div>
        <div class="mt-10">
            <div class="mt-5">
                <div class="thirty-three-page-cont1">
                    <div>
                        <div class="thirty-three-page-fs23">
                            Linguistic - Verbal Intelligence is ability to understand Spoken & Written language and
                            propensity to deliver it efficiently by Speaking or Writing. People with high linguistic
                            intelligence are prolific in verbal as well as written communication. They are naturally
                            good at sensing the Meanings, Synonyms, Antonyms, Tenses, Plurals, Acronyms, Metaphors,
                            Phrases and Jargons of words or sentences.
                        </div>
                        <div class="thirty-three-page-ipr">
                            Linguistics defines Expertise <br>in Correspondence
                        </div>
                        <div class="clear-both"></div>
                        <div class="thirty-three-page-line-height">
                            <ul>
                                <li>Language learning ability
                                </li>
                                <li>Hearing identification</li>
                                <li>Attention to Word & Vocabulary</li>
                                <li>Attention to Meaning & Understanding</li>
                                <li>Verbal Communication</li>
                                <li>Speech, Pronunciation & Accent</li>
                                <li>Paraphrasing, Comprehension, Spellings</li>
                                <li>Short term memory</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="thirty-three-page-first-box" style="text-align: center;">
                    <img src="assets/images/Page 39/PAGE-39-LINGUISTICS.jpg" class="width-100">

                </div>
                <div class="clear-both"></div>
            </div>
        </div>
    </div>

    <div class="mt-20">
        <div class="purple-header">
            CHARACTERISTICS OF A PERSON HAVING HIGH LINGUISTIC-VERBAL INTELLIGENCE
        </div>
        <div class="thirty-three-page-row-wrapper">
            <div class="tbl-bx">
                <div class="thirty-three-page-section-heading">
                    PROS
                </div>
                <div class="clear-both"></div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Enjoy reading & writing, excellent in Word Puzzles, <br>have a knack of Picking up new
                                Languages
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Expressing creativity in language, both verbally or<br> written word. Rich Vocabulary
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Ability to Savor convoluted words, Persuade, <br>Negotiate & Communicate complex
                                concepts
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-one" style="border-bottom: none;">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Excellent Short term memory, Handy for speech, <br>Presentation, Anchoring etc
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="clear-both"></div>
            </div>
            <div class="tbl-bx" style="border-left: none;">
                <div class="thirty-three-page-section-heading">
                    CONS
                </div>
                <div class="clear-both"></div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Becomes habitual in using exclusive and <br>exorbitant words even when not applicable
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Develops unique accent which later becomes <br>the identity of the person
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>At times keeps pointing out mistakes in <br>languages of others
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-one" style="border-bottom: none;">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>May do excessive talking. <br>
                                (if extrovert by nature)

                            </li>
                        </ul>
                    </div>
                </div>

                <div class="clear-both"></div>
            </div>
        </div>
    </div>

    <div class="mt-20">
        <div class="purple-header">
            REMEDIAL MEASURES IF YOUR LINGUISTIC-VERBAL INTELLIGENCE IS LOW
        </div>
        <div class="IIL-bg">
            <div>
                <div class="IIL-sec">Take measures to improve the following </div>
                <div class="IIL-sec1">1) WORD MEMORY IS LIKE A MUSCLE. YOU CAN TRAIN IT TO BE STRONGER.</div>
                <div>
                    <ul class="thirty-three-page-list-IIL">
                        <li>Develop reading & writing habits
                        </li>
                        <li>Read newspapers completely. Mark difficult words and then find their meanings & try to use
                            them.
                        </li>
                        <li>Join a library or buy books (or E-books). Select different subjects each time
                        </li>
                        <li>Write at least 1 page of events of the entire day on daily bases. Maintain a dairy.
                        </li>
                    </ul>
                </div>
                <div class="clear-both"></div>
            </div>

            <div>
                <div class="IIL-sec1">2) LEARN A NEW WORD EVERY DAY.</div>
                <div>
                    <ul class="thirty-three-page-list-IIL">
                        <li>Use Thesaurus on Google for improving vocabulary. Develop a habit of searching Synonyms
                            & Acronyms of words. Practice <br>
                            difficult Pronunciations by repeating it several times & Spellings by writing them several
                            times.
                        </li>

                    </ul>
                </div>
                <div class="clear-both"></div>
            </div>
            <div>
                <div class="IIL-sec1">3) SOLVE CROSSWORDS AND WORD PUZZLES.</div>
                <div>
                    <ul class="thirty-three-page-list-IIL">
                        <li>Play Scrabble & similar word games. Participate in Spelling, elocution,
                            Story-telling, Essay & Speed reading contests.
                        </li>

                    </ul>
                </div>
                <div class="clear-both"></div>
            </div>
            <div>
                <div class="IIL-sec1">4) JOIN CLASSES TO LEARN A FOREIGN LANGUAGE.
                </div>
                <div class="clear-both"></div>
            </div>
            <div>
                <div class="IIL-sec1">5) PRACTICE GIVING A SPEECH, JOIN VOICE OVER CLASSES,
                    PRACTICE TONGUE TWISTERS.</div>
                <div class="clear-both"></div>
            </div>


        </div>
    </div>

</div>