import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { WorkpadService } from '../services/workpad.service';
import { _ } from 'underscore';
import { MatSidenav, MatDialog } from '@angular/material';
import { SidenavService } from '../services/sidenav.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ActivatedRoute, Router } from '@angular/router';
import { last } from 'rxjs/operators';
import { PanZoomConfig } from 'ng2-panzoom';
import { Route } from '@angular/compiler/src/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CryptoService } from '../crypto.service';
import { LactoseGlutenModalComponent } from '../../app/lactose-gluten-modal/lactose-gluten-modal.component';
declare var $: any;

@Component({
  selector: 'app-workpad',
  templateUrl: './workpad.component.html',
  styleUrls: ['./workpad.component.css']
})
export class WorkpadComponent implements OnInit, AfterViewInit {

  @ViewChild("canvas1") canvas1: ElementRef;
  @ViewChild('canvas2') canvas2: ElementRef;

  panZoomConfig: PanZoomConfig = new PanZoomConfig;

  isLeftDot: boolean = false;
  isRightDot: boolean = false;
  disabled: boolean = true;
  bntStyle: string = "";
  index1: number;
  index2: number;
  table1: string = "";
  table2: string = "";
  tableIndex1: number;
  tableIndex2: number;

  workpadData: any = {}
  leftHandData: any = [];
  rightHandData: any = [];
  clientName: string = "";
  clientId: string = "";
  dealerClientId: string = "";
  mainImage1: string = "";
  mainImage2: string = "";

  leftcount: number = 0;
  leftridgeCount: number = 0;
  rightcount: number = 0;
  rightridgeCount: number = 0;

  leftcordJson: any = [];
  rightcordJson: any = [];
  isLeftLineDrawn: boolean = false;
  isRightLineDrawn: boolean = false;
  curIndex: number = 0;

  finalObj: any = {};
  //saveObj: any = {};
  selectedHand: string = "";
  selectedFingure = "";

  autoLRC: number = 0;
  autoRRC: number = 0;
  autoTRC: number = 0;

  allObj: any = [];
  isArcDisabled: boolean = true;

  isLeftHand: boolean = false;
  isRightHand: boolean = false;
  isReverse: boolean = false;
  isBackup: boolean = false;
  leftFingure:string;
  rightFingure:string;
  //isArcDisabled:boolean = true;
  myProperties = {
    "zoomControlPosition":"right-bottom",
    "backgroundColor":"none"
  }
  clientIdURL: string;
  sequenceClientId: string;
  lactose: any = -1;
  gluten: any = -1;
  saveLoading: boolean;
  arcLoading: boolean;
  runDisabled: boolean;
  currentHand: any;

  constructor(private workpadService: WorkpadService, private sideNavService: SidenavService, private route: ActivatedRoute, private dialog: MatDialog, private router: Router, private SpinnerService: NgxSpinnerService, private cryptoService: CryptoService) { }

  ngOnInit(): void {
    this.dealerClientId = this.route.snapshot.paramMap.get("dealerClientId");
    this.clientIdURL = this.route.snapshot.paramMap.get('clientId');
    this.sequenceClientId = this.route.snapshot.paramMap.get('sequenceClientId');


    // this.dealerClientId = "SD00003";
    // var clientId = "BOG0022";

    var model = {
      "dealer_client_id": this.dealerClientId,
      "clientId": this.clientIdURL
    };

    this.loadWorkpadDetail(model);
  }

  loadWorkpadDetail(dataModel) {
    this.SpinnerService.show();

    var payload1 = JSON.stringify(dataModel).toString();
    var loginPayload = this.cryptoService.getEncryptedBody(localStorage.getItem("guid"), payload1);
    //console.log("encrypted model: ", loginPayload.toString());

    var model = {
      //"headers": { "x-shyld-app-id": congnitoIdEnc },
      "data": loginPayload.toString()
    }

    this.workpadService.getWorkpadData(model).subscribe((result: any) => {
      var res = JSON.parse(this.cryptoService.getDecryptedBody(localStorage.getItem('guid'), result.data));
      console.log("data: ", res);
      if (res.status == "success") {
        var response = _.sortBy(res.dataMap.FingerPrintData, function (data) { return data.fingerIndex; });

        for (var i = 0; i < response.length; i++) {
          if (response[i].fpp == "FPP") {
            response[i].fpp = "";
          }
        }

        this.workpadData = res.dataMap;
        this.workpadData.FingerPrintData = response;

        console.log("workpad data : ", this.workpadData);

        this.clientName = this.workpadData.clientData.clientname;
        this.clientId = this.workpadData.clientData.clientId;
        this.leftHandData = _.where(this.workpadData.FingerPrintData, { hand: "LeftHand" });
        this.rightHandData = _.where(this.workpadData.FingerPrintData, { hand: "RightHand" });
        if(this.workpadData.gluten != undefined) {
          this.gluten = this.workpadData.gluten;
        }
        if(this.workpadData.lactose != undefined) {
          this.lactose = this.workpadData.lactose;
        }

        this.addDeselectOption();
      }
      this.SpinnerService.hide();
    });
  }

  addDeselectOption() {
    for (const iterator of this.rightHandData) {
      for (const iterator1 of iterator['fingerPrintList']) {
        iterator1['selected'] = false;
      }
    }

    for (const iterator of this.leftHandData) {
      for (const iterator1 of iterator['fingerPrintList']) {
        iterator1['selected'] = false;
      }
    }
  }

  addImageToContainer(image, index, hand, fingure, imageType, event, imgIndex, currentElem, isBackupData, currentHand) {
    this.currentHand = currentHand;
    console.log(this.currentHand);
    this.disabled = false;
    // if (hand == "LeftHand") {
    //   this.isLeftHand = true;
    // }
    // else if (hand == "RightHand") {
    //   this.isRightHand = true;
    // }

    // var lastChar = imageType.substr(imageType.length - 1);
    // let iType: string = "";

    // let fngr: string = "";

    // var aa: string[] = fingure.split(',');
    // fngr = aa[1];

    // if (lastChar == "A") {
    //   iType = "leftImage";
    // }
    // else if (lastChar == "B") {
    //   iType = "centerImage";
    // }
    // else if (lastChar == "C") {
    //   iType = "rightImage";
    // }

    // var model = {
    //   //"userid": this.userId,
    //   "clientId": this.clientId,
    //   "hand": hand,
    //   "fingername": fngr,
    //   "imageType": iType
    // }

    // this.workpadService.getFingureImage(model).subscribe((res: any) => {

    //image = res.dataMap.fingerPrint;
    if (this.mainImage1 == undefined || this.mainImage1 == "") {
      this.mainImage1 = image;
      this.leftFingure = fingure;

      let ctx: CanvasRenderingContext2D =
        this.canvas1.nativeElement.getContext('2d');

      let imageObj = new Image();
      imageObj.src = "data:image/png;base64," + image;
      setTimeout(() => {
        ctx.drawImage(imageObj, 0, 0, 300, 160);
      }, 10);
      this.curIndex = index;
      this.populateData(hand, fingure);
      // $(event.path[0]).addClass('thumbnail-border');

      this.tableIndex1 = index;
      this.index1 = imgIndex;

      if (hand == "LeftHand") {
        this.table1 = "leftTable";
      }
      else {
        this.table1 = "rightTable";
      }

      currentElem['selected'] = true;
    }
    else if(this.mainImage1 != undefined || this.mainImage1 != "") {
      if(this.leftFingure != fingure) {
        this.mainImage1 = image;
        this.leftFingure = fingure;

        this.removeRightImage();

        let ctx: CanvasRenderingContext2D =
          this.canvas1.nativeElement.getContext('2d');

        let imageObj = new Image();
        imageObj.src = "data:image/png;base64," + image;
        setTimeout(() => {
          ctx.drawImage(imageObj, 0, 0, 300, 160);
        }, 10);
        this.curIndex = index;
        this.populateData(hand, fingure);
        // $(event.path[0]).addClass('thumbnail-border');

        this.tableIndex1 = index;
        this.index1 = imgIndex;

        if (hand == "LeftHand") {
          this.table1 = "leftTable";
        }
        else {
          this.table1 = "rightTable";
        }

        this.addDeselectOption();
        currentElem['selected'] = true;
      }
      else {
        this.mainImage2 = image;
        this.rightFingure = fingure;

        let ctx: CanvasRenderingContext2D =
          this.canvas2.nativeElement.getContext('2d');

        let imageObj = new Image();
        imageObj.src = "data:image/png;base64," + image;
        setTimeout(() => {
          ctx.drawImage(imageObj, 0, 0, 300, 160);
        }, 10);
        this.curIndex = index;
        this.populateData(hand, fingure);
        // $(event.path[0]).addClass('thumbnail-border');

        this.tableIndex2 = index;
        this.index2 = imgIndex;

        if (hand == "LeftHand") {
          this.table2 = "leftTable";
        }
        else {
          this.table2 = "rightTable";
        }

        currentElem['selected'] = true;
      }
    }
    else if (this.mainImage2 == undefined || this.mainImage2 == "") {
      if(this.leftFingure == fingure) {
        this.mainImage2 = image;

        this.rightFingure = fingure;

        let ctx: CanvasRenderingContext2D =
          this.canvas2.nativeElement.getContext('2d');

        let imageObj = new Image();
        imageObj.src = "data:image/png;base64," + image;
        setTimeout(() => {
          ctx.drawImage(imageObj, 0, 0, 300, 160);
        }, 10);
        this.curIndex = index;
        this.populateData(hand, fingure);
        // $(event.path[0]).addClass('thumbnail-border');

        this.tableIndex2 = index;
        this.index2 = imgIndex;

        if (hand == "LeftHand") {
          this.table2 = "leftTable";
        }
        else {
          this.table2 = "rightTable";
        }

        currentElem['selected'] = true;
      }
    }

    this.isBackup = (isBackupData == 'YES') ?  true : false;
    
  }

  populateData(hand, fingure) {
    if (hand == "LeftHand") {
      this.finalObj = this.leftHandData[this.curIndex];
      if (this.finalObj.isReverse.toLowerCase() == "yes")
        this.isReverse = true;

      this.selectedHand = hand;
      var aa: string[] = fingure.split(',');
      this.selectedFingure = aa[1];
    }
    else {
      this.finalObj = this.rightHandData[this.curIndex];
      if (this.finalObj.isReverse.toLowerCase() == "yes")
        this.isReverse = true;

      this.selectedHand = hand;
      var aa: string[] = fingure.split(',');
      this.selectedFingure = aa[1];
    }
  }

  removeLeftImage() {
    this.mainImage1 = "";
    let ctx: CanvasRenderingContext2D =
      this.canvas1.nativeElement.getContext('2d');
    ctx.clearRect(0, 0, 300, 160);
    this.leftcordJson = [];
    this.isLeftLineDrawn = false;
    this.finalObj = {};
    this.leftridgeCount = 0;
    this.autoLRC = 0;
    this.autoTRC = this.autoLRC + this.autoRRC;
    this.leftcount = 0;
    this.isLeftHand = false;
    this.isRightHand = false;
    this.isReverse = false;
    this.isBackup = false;
    this.isLeftDot = false;
    this.leftFingure = "";

    if (this.mainImage1 == "" && this.mainImage2 == "")
      this.disabled = true;

    if (this.table1 == "leftTable") {
      var selectedDiv = $(".leftTable div").eq(this.tableIndex1);
      var selectedTR = $(selectedDiv).children('tr').eq(1);

      var selectedTD = $(selectedTR).children('td').eq(this.index1);
      $(selectedTD).children('img').removeClass('thumbnail-border');
    }
    else if (this.table1 == "rightTable") {
      var selectedDiv = $(".rightTable div").eq(this.tableIndex1);
      var selectedTR = $(selectedDiv).children('tr').eq(1);

      var selectedTD = $(selectedTR).children('td').eq(this.index1);
      $(selectedTD).children('img').removeClass('thumbnail-border');
    }
  }

  removeRightImage() {
    this.mainImage2 = "";
    let ctx: CanvasRenderingContext2D =
      this.canvas2.nativeElement.getContext('2d');
    ctx.clearRect(0, 0, 300, 160);
    this.rightcordJson = [];
    this.isRightLineDrawn = false;
    this.finalObj = {};
    this.rightridgeCount = 0;
    this.autoRRC = 0;
    this.autoTRC = this.autoLRC + this.autoRRC;
    this.rightcount = 0;
    this.isLeftHand = false;
    this.isRightHand = false;
    this.isReverse = false;
    this.isBackup = false;
    this.isRightDot = false;
    this.rightFingure = "";

    if (this.mainImage1 == "" && this.mainImage2 == "")
      this.disabled = true;

    if (this.table2 == "leftTable") {
      var selectedDiv = $(".leftTable div").eq(this.tableIndex2);
      var selectedTR = $(selectedDiv).children('tr').eq(1);

      var selectedTD = $(selectedTR).children('td').eq(this.index2);
      $(selectedTD).children('img').removeClass('thumbnail-border');
    }
    else if (this.table2 == "rightTable") {
      var selectedDiv = $(".rightTable div").eq(this.tableIndex2);
      var selectedTR = $(selectedDiv).children('tr').eq(1);

      var selectedTD = $(selectedTR).children('td').eq(this.index2);
      $(selectedTD).children('img').removeClass('thumbnail-border');
    }
  }

  getCordinatesforpoint(ev, isLeft) {
    var lcount = 0;
    var rcount = 0;
    if (isLeft && this.isLeftDot) {
      if (this.mainImage1 != undefined && this.mainImage1 != "") {
        lcount = ++this.leftcount;
        this.leftridgeCount = lcount - 1;

        let ctx: CanvasRenderingContext2D =
          this.canvas1.nativeElement.getContext('2d');
        var pos = this.getMousePos(ctx.canvas, ev);

        ctx.shadowBlur = 0;
        ctx.fillStyle = "red";
        ctx.imageSmoothingEnabled = true;
        ctx.fillRect(pos.x, pos.y, 3, 2);

        this.leftcordJson.push({ "X": pos.x, "Y": pos.y });
      }
    } else if (!isLeft && this.isRightDot) {
      if (this.mainImage2 != undefined && this.mainImage2 != "") {
        rcount = ++this.rightcount;
        this.rightridgeCount = rcount - 1;

        let ctx: CanvasRenderingContext2D =
          this.canvas2.nativeElement.getContext('2d');
        var pos = this.getMousePos(ctx.canvas, ev);

        ctx.shadowBlur = 0;
        ctx.fillStyle = "red";
        ctx.imageSmoothingEnabled = true;
        ctx.fillRect(pos.x, pos.y, 3, 2);

        this.rightcordJson.push({ "X": pos.x, "Y": pos.y });
      }
    }
  }

  getMousePos(canvas, evt) {
    var rect = canvas.getBoundingClientRect(), // abs. size of element
      scaleX = canvas.width / rect.width,    // relationship bitmap vs. element for X
      scaleY = canvas.height / rect.height;  // relationship bitmap vs. element for Y

    return {
      x: (evt.clientX - rect.left) * scaleX,   // scale mouse coordinates after they have
      y: (evt.clientY - rect.top) * scaleY     // been adjusted to be relative to element
    }
  }

  drawLine(isLeft) {
    if (isLeft && !this.isLeftLineDrawn && this.leftcordJson.length >= 2) {
      let ctx: CanvasRenderingContext2D =
        this.canvas1.nativeElement.getContext('2d');
      ctx.beginPath();
      ctx.moveTo(this.leftcordJson[0].X, this.leftcordJson[0].Y);
      ctx.lineTo(this.leftcordJson[1].X, this.leftcordJson[1].Y);
      ctx.strokeStyle = 'blue';
      ctx.lineWidth = 1;
      ctx.stroke();
      this.isLeftLineDrawn = true;
      this.calculateAutoRC(isLeft);
    }
    else if (!isLeft && !this.isRightLineDrawn && this.rightcordJson.length >= 2) {
      let ctx: CanvasRenderingContext2D =
        this.canvas2.nativeElement.getContext('2d');
      ctx.beginPath();
      ctx.moveTo(this.rightcordJson[0].X, this.rightcordJson[0].Y);
      ctx.lineTo(this.rightcordJson[1].X, this.rightcordJson[1].Y);
      ctx.strokeStyle = 'blue';
      ctx.lineWidth = 0.5;
      ctx.stroke();
      this.isRightLineDrawn = true;
      this.calculateAutoRC(isLeft);
    }
  }

  calculateAutoRC(isLeft) {
    var dataModel;
    if (isLeft) {
      dataModel = {
        "start_point": this.leftcordJson[0].X + "," + this.leftcordJson[0].Y,
        "end_point": this.leftcordJson[1].X + "," + this.leftcordJson[1].Y,
        "base64": this.mainImage1.replace('data:image/jpeg;base64,', '')
      }

    }
    else {
      dataModel = {
        "start_point": this.rightcordJson[0].X + "," + this.rightcordJson[0].Y,
        "end_point": this.rightcordJson[1].X + "," + this.rightcordJson[1].Y,
        "base64": this.mainImage2.replace('data:image/jpeg;base64,', '')
      }
    }

    var payload1 = JSON.stringify(dataModel).toString();
    var loginPayload = this.cryptoService.getEncryptedBody(localStorage.getItem("guid"), payload1);
    //console.log("encrypted model: ", loginPayload.toString());

    var model = {
      //"headers": { "x-shyld-app-id": congnitoIdEnc },
      "data": loginPayload.toString()
    }

    this.workpadService.calculateAutoRC(model).subscribe((result: any) => {
      var res = JSON.parse(this.cryptoService.getDecryptedBody(localStorage.getItem('guid'), result.data));
      if (isLeft) {
        this.autoLRC = res.count;
      }
      else {
        this.autoRRC = res.count;
      }
      this.autoTRC = this.autoLRC + this.autoRRC;
    });
  }

  fppChange() {
    if (this.isLeftHand) {
      this.leftHandData[this.curIndex].fpp = this.finalObj.fpp;
    }
    else if (this.isRightHand) {
      this.rightHandData[this.curIndex].fpp = this.finalObj.fpp;
    }
  }

  saveData() {
    this.saveLoading = true;
    var dataModel = {
      "dealer_client_id": this.dealerClientId,
      "fpp": this.finalObj.fpp,
      "trc": this.finalObj.rc,
      // "isReverse": this.isReverse ? "YES" : "NO",
      "isBackup": this.isBackup ? "YES" : "NO",
      "selectedHand": this.selectedHand,
      "selectedFinger": this.selectedFingure
    }

    var payload1 = JSON.stringify(dataModel).toString();
    var loginPayload = this.cryptoService.getEncryptedBody(localStorage.getItem("guid"), payload1);
    //console.log("encrypted model: ", loginPayload.toString());

    var model = {
      //"headers": { "x-shyld-app-id": congnitoIdEnc },
      "data": loginPayload.toString()
    }

    this.workpadService.saveWorkpadData(model).subscribe((result: any) => {
      var res = JSON.parse(this.cryptoService.getDecryptedBody(localStorage.getItem('guid'), result.data));
      if (res.status == "success") {
        // alert("Finger details updated..!!");
        this.removeLeftImage();
        this.removeRightImage();
        if (res.dataMap.ArcEnable.toLowerCase() == "yes") {
          this.isArcDisabled = false;
          this.runDisabled = true;
        }
      }
      else {
        alert("Error occurred. Please try again later.");
      }
      this.saveLoading = false;
    });
  }

  arcCalculation() {
    if(this.gluten == -1 && this.lactose == -1) {
      alert('Please select lactose and gluten first..!!');
      return;
    }

    this.arcLoading = true;
    var dataModel = {
      "dealer_client_id": this.dealerClientId,
      "lactose": this.lactose,
      "gluten": this.gluten
    }

    var payload1 = JSON.stringify(dataModel).toString();
    var loginPayload = this.cryptoService.getEncryptedBody(localStorage.getItem("guid"), payload1);
    //console.log("encrypted model: ", loginPayload.toString());

    var model = {
      //"headers": { "x-shyld-app-id": congnitoIdEnc },
      "data": loginPayload.toString()
    }

    this.workpadService.arcCalculation(model).subscribe((result: any) => {
      var res = JSON.parse(this.cryptoService.getDecryptedBody(localStorage.getItem('guid'), result.data));
      var clientId = this.route.snapshot.paramMap.get('clientId');

      if (res.status == "success") {
        //this.dealerClientId = "SD00003";
        //var clientId = "BOG0022";
        alert("ARC calculation completed..!!");
        
        var model = {
          "dealer_client_id": this.dealerClientId,
          "clientId": clientId
        };

        this.removeLeftImage();
        this.removeRightImage();

        this.loadWorkpadDetail(model);
      }
      else
      {
        alert(res.status);
      }

      this.runDisabled = false;
      this.arcLoading = false;
    });
  }

  enableLeftDot(event) {
    this.isLeftDot = event.checked;
  }

  enableRightDot(event) {
    this.isRightDot = event.checked;
  }

  ngAfterViewInit() {
    setTimeout(() => this.sideNavService.close(), 100);
  }

  onReverseChange(event) {
    this.isReverse = event.checked;
    console.log(this.isReverse);
  }

  onBackupChange(event) {
    const val = event.checked; 
    this.currentHand.isBackup = (val == true) ? 'YES' : 'NO';
    // console.log(this.isBackup);
  }

  openDialog() {
    const dialogRef = this.dialog.open(LactoseGlutenModalComponent, {
      disableClose: true,
      data: {lactose: this.lactose, gluten: this.gluten}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      // console.log(this.lactose);
      if(result) {
        this.lactose = result.lactose;
        this.gluten = result.gluten;
      }
      
    });
  }
}
