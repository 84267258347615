<div class="forty-nine-page mt-40">
    <div class="text-right">
        <img class="logo-img" src="assets/images/Header.png" />
    </div>

    <div class="mt-20 blue-header">
        YOUR VALUABLE FEEDBACK
    </div>

    <table border="1" class="table-class">
        <tr>
            <td class="td-class">YOUR NAME</td>
            <td class="td-class">{{ data.dataMap.page3.personal.name }}</td>
        </tr>
        <tr>
            <td class="td-class">PARENT / SPOUSE NAME</td>
            <td class="td-class">{{ data.dataMap.page3.personal.guardian }}</td>
        </tr>

        <tr>
            <td class="td-class">CONTACT NUMBER</td>
            <td class="td-class">{{ data.dataMap.page3.personal.contact }}</td>
        </tr>

        <tr>
            <td class="td-class">EMAIL</td>
            <td class="td-class">{{ data.dataMap.page3.personal.email }}</td>
        </tr>
        <tr>
            <td class="td-class">ADDRESS</td>
            <td class="td-class">{{ data.dataMap.page3.personal.address }}</td>
        </tr>
        <tr>
            <td class="td-class">EVALUATION NO.</td>
            <td class="td-class">{{ data.dataMap.page3.personal.eval }}</td>
        </tr>
        <tr>
            <td class="td-class">DEALER CODE</td>
            <td class="td-class">{{ data.dataMap.page3.company.dealerCode }}</td>
        </tr>

    </table>

    <div class="mt-20 blue-header" style="font-size: 14px;">
        We assure you that all the details of these report shall remain confidential
    </div>

    <div class="mt-10 text-center text-bold">
        WE APPRECIATE YOUR VALUABLE FEEDBACK
    </div>

    <div class="mt-20 flex-row">
        <div class="flex-2 text-bold">
            How Was Our Counselling & Presentation..........................
        </div>
        <div class="flex-15 text-bold text-right">
            <div class="check-wrapper">
                <input class="check" type="checkbox" /> FAIR
            </div>
            <div class="check-wrapper">
                <input class="check" type="checkbox" /> GOOD
            </div>
            <div class="check-wrapper" style="margin-right: 0;">
                <input class="check" type="checkbox" /> EXCELLENT
            </div>
            
        </div>  
    </div>

    <div class="mt-20 flex-row">
        <div class="flex-2 text-bold">
            Please Rate the Details Given In the Report........................
        </div>
        <div class="flex-15 text-bold text-right">
            <div class="check-wrapper">
                <input class="check" type="checkbox" /> FAIR
            </div>
            <div class="check-wrapper">
                <input class="check" type="checkbox" /> GOOD
            </div>
            <div class="check-wrapper" style="margin-right: 0;">
                <input class="check" type="checkbox" /> EXCELLENT
            </div>
            
        </div>  
    </div>

    <div class="mt-20 flex-row">
        <div class="flex-2 text-bold">
            Please Rate the Suggestions & Remedies Given................
        </div>
        <div class="flex-15 text-bold text-right">
            <div class="check-wrapper">
                <input class="check" type="checkbox" /> FAIR
            </div>
            <div class="check-wrapper">
                <input class="check" type="checkbox" /> GOOD
            </div>
            <div class="check-wrapper" style="margin-right: 0;">
                <input class="check" type="checkbox" /> EXCELLENT
            </div>
            
        </div>  
    </div>

    <div class="mt-20 flex-row">
        <div class="flex-2 text-bold">
            How Was Your Overall Experience........................................
        </div>
        <div class="flex-15 text-bold text-right">
            <div class="check-wrapper">
                <input class="check" type="checkbox" /> FAIR
            </div>
            <div class="check-wrapper">
                <input class="check" type="checkbox" /> GOOD
            </div>
            <div class="check-wrapper" style="margin-right: 0;">
                <input class="check" type="checkbox" /> EXCELLENT
            </div>
            
        </div>  
    </div>

    <div class="mt-30">
        YOUR COMMENTS : ..........................................................................................................................................
    </div>
    <div class="mt-20">
        ..............................................................................................................................................................................
    </div>
    <div class="mt-20">
        ..............................................................................................................................................................................
    </div>
    <div class="mt-20">
        ..............................................................................................................................................................................
    </div>

    <div class="mt-40">
        GIVING YOUR TESTIMONY IS VOLUNTARY : .................................................................................................
    </div>
    <div class="mt-20">
        ..............................................................................................................................................................................
    </div>
    <div class="mt-20">
        ..............................................................................................................................................................................
    </div>
    <div class="mt-20">
        ..............................................................................................................................................................................
    </div>

    <div class="mt-30 gray-bx">
         I confirm the above feedback, comments & testimony.
    </div>
 
    <div class="mt-30 flex-row">
        <div class="flex-1" style="margin-right: 20px;">
            <div class="signature-box"></div>
            <div class="text-center">Signature</div>
        </div>
        <div class="flex-2 text-center ">
            <div class="text-bold mt-10" style="font-size: 15px;">
                I give my consent to share my written or video testimonies
            </div>
            <div class="text-bold mt-10">
                <div class="check-wrapper" style="margin-right: 20px;">
                    <input class="check" type="checkbox" /> YES
                </div>
                <div class="check-wrapper">
                    <input class="check" type="checkbox" /> NO
                </div>
            </div>
        </div>
    </div>
</div>