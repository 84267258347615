<div class="eleventh-page mt-40">
  <div class="text-right">
      <img class="logo-img" src="assets/images/Header.png" />
  </div>

  <div class="mt-20">
    <div class="eleventh-page-cont1" style="width: 70%;">
      <div class="eleventh-page-cont12" ><strong>{{data.dataMap.page10_11.section2.rank}}</strong></div>
      <div class="eleventh-page-cont13" style="width: 90%; color: #00abc5; "><strong>
        {{data.dataMap.page10_11.section2.title}}</strong> </div>
      <div class="clear-both"></div>
      
      <div class="" style=" padding-bottom: 5px;">
       
        <div class="eleventh-page-fs23" style="width: 100%; ">
          {{data.dataMap.page10_11.section2.para1}}
        </div>
        <div class="eleventh-page-fs23" style="width: 100%; padding-top: 5px; ">
          {{data.dataMap.page10_11.section2.para2}}
       </div>
      </div>
    </div>

    <div class="eleventh-page-first-box" >
      <img class="width-100" [attr.src]="data.dataMap.page10_11.section2.image">
    </div>
    <div class="clear-both"></div>
    <div class="eleventh-page-row" style="padding-top: 2px; padding-right: 10px;">
      {{data.dataMap.page10_11.section2.para3}}
    </div>
  </div>
  <div class="eleventh-page-row mt-10 text-center" style="color: #00a9c2;">
    <strong>THE SUBJECTS OF YOUR INTEREST</strong>
  </div>

  <div class="eleventh-page-subof">
    <div>
      <ul class="eleventh-page-listsub">
        <li *ngFor="let subCategory of data.dataMap.page10_11.section2.interests?.split(';')">
          {{ subCategory }}
        </li>

        <div class="clear-both"></div>
      </ul>
      </div>
    <div class="clear-both"></div>
  </div>

  <div class="mt-20">
    <div class="eleventh-page-cont1" style="width: 70%;">
      <div class="eleventh-page-cont12" ><strong>{{data.dataMap.page10_11.section3.rank}}</strong></div>
      <div class="eleventh-page-cont13" style="width: 90%; color: #00abc5; "><strong>
        {{data.dataMap.page10_11.section3.title}}</strong> </div>
      <div class="clear-both"></div>
      
      <div class="" style=" padding-bottom: 5px;">
       
        <div class="eleventh-page-fs23" style="width: 100%; ">
          {{data.dataMap.page10_11.section3.para1}}

        </div>
        <div class="eleventh-page-fs23" style="width: 100%; padding-top: 5px; ">
          {{data.dataMap.page10_11.section3.para2}}
       </div>
      </div>
    </div>

    <div class="eleventh-page-first-box" >
      <img class="width-100" [attr.src]="data.dataMap.page10_11.section3.image">
    </div>
    <div class="clear-both"></div>
    <div class="eleventh-page-row" style="padding-top: 2px; padding-right: 10px;">
      {{data.dataMap.page10_11.section3.para3}}
    </div>
  </div>
  <div class="eleventh-page-row mt-10 text-center" style="color: #00a9c2;">
    <strong>THE SUBJECTS OF YOUR INTEREST</strong>
  </div>

  <div class="eleventh-page-subof">
    <div>
      <ul class="eleventh-page-listsub">
        <li *ngFor="let subCategory of data.dataMap.page10_11.section3.interests?.split(';')">
          {{ subCategory }}
        </li>
        <div class="clear-both"></div>
      </ul>
      </div>
    <div class="clear-both"></div>
  </div>
</div>