import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-page18',
  templateUrl: './page18.component.html',
  styleUrls: ['./page18.component.css']
})
export class Page18Component implements OnInit {
  @Input() data: any;
  constructor() { }

  ngOnInit(): void {
    // this.data.dataMap.page18.winner = ['gm_3', 'pm_2', 'st_2'];
  }
  isWinner(value: string, array: string[] | undefined): boolean {
    return array && array.indexOf(value) !== -1;
  }
}
