import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { LoginComponent } from './login/login.component';
import { AnalystListComponent } from './analyst/analyst-list/analyst-list.component';
import { AnalystCreateComponent } from './analyst/analyst-create/analyst-create.component';
import { WorkpadComponent } from './workpad/workpad.component';
import { ClientListComponent } from './client-list/client-list.component';
import { ReportComponent } from './report/report.component';
import { ClientDetailComponent } from './client-detail/client-detail.component';
import { PendingReportComponent } from './pending-report/pending-report.component';
import { AuthGuardGuard } from './auth-guard.guard';
import { AddClientComponent } from './add-client/add-client.component';

export const AppRoutes: Routes = [
  {
    path: '', component: LoginComponent
  },
  {
    path: 'analyst', component: FullComponent, children: [
      { path: 'analyst-list', component: AnalystListComponent, canActivate: [AuthGuardGuard] }
    ]
  },
  {
    path: 'analyst', component: FullComponent, children: [
      { path: 'analyst-create', component: AnalystCreateComponent, canActivate: [AuthGuardGuard] }
    ]
  },
  {
    path: 'analyst', component: FullComponent, children: [
      { path: 'analyst-edit/:id', component: AnalystCreateComponent, canActivate: [AuthGuardGuard] }
    ]
  },
  {
    path: 'workpad', component: FullComponent, children: [
      { path: 'load-workpad/:dealerClientId/:clientId/:sequenceClientId', component: WorkpadComponent, canActivate: [AuthGuardGuard] }
    ]
  },
  {
    path: 'client', component: FullComponent, children: [
      { path: 'client-list', component: ClientListComponent, canActivate: [AuthGuardGuard] }
    ]
  },
  {
    path: 'client',
    component: FullComponent, 
    children: [
      { path: 'pending-report', component: PendingReportComponent, canActivate: [AuthGuardGuard] }
    ]
  },
  {
    path: 'client',
    component: FullComponent, 
    children: [
      { path: 'add-client', component: AddClientComponent, canActivate: [AuthGuardGuard] }
    ]
  },
  {
    path: 'client', component: FullComponent, children: [
      { path: 'client-detail/:clientId/:dealerClientId/:type', component: ClientDetailComponent, canActivate: [AuthGuardGuard] }
    ]
  },
  {
    path: 'report/:dealerClientId/:clientId/:sequenceClientId', component: ReportComponent, canActivate: [AuthGuardGuard]
  },
];
