<div class="twenty-sixth-page mt-40" style="margin-top: 50%;">
    <div class="text-right">
        <img class="logo-img" src="assets/images/Header.png" />
    </div>
    <div class="blue-header mt-10">
        {{ data.dataMap.page26_29.main_title }}
    </div>

    <div class="flex-row mt-10">
        <div class="flex-cols mr-5">
            <div class="flex-heading">
                <h4>INQUISITORS</h4>
                Guided By Logic
            </div>
            <div class="flex-content mt-5">
                <div [ngClass]="{ 'highlight' : data.dataMap.page25.winner == 'strategist' }" class="flex-row">
                    <div class="flex-cols col-content">STRATEGIST</div>
                    <div class="flex-perc">{{ data.dataMap.page25.strategist }}%</div>
                </div>
            </div>
            <div class="flex-content mt-5">
                <div [ngClass]="{ 'highlight' : data.dataMap.page25.winner == 'administrator' }" class="flex-row">
                    <div class="flex-cols col-content">ADMINISTRATOR</div>
                    <div class="flex-perc">{{ data.dataMap.page25.administrator }}%</div>
                </div>
            </div>
            <div class="flex-content mt-5">
                <div [ngClass]="{ 'highlight' : data.dataMap.page25.winner == 'theorist' }" class="flex-row">
                    <div class="flex-cols col-content">THEORIST</div>
                    <div class="flex-perc">{{ data.dataMap.page25.theorist }}%</div>
                </div>
            </div>
            <div class="flex-content mt-5">
                <div [ngClass]="{ 'highlight' : data.dataMap.page25.winner == 'challenger' }" class="flex-row">
                    <div class="flex-cols col-content">CHALLENGER</div>
                    <div class="flex-perc">{{ data.dataMap.page25.challenger }}%</div>
                </div>
            </div>
        </div>
        <div class="flex-cols mr-5">
            <div class="flex-heading">
                <h4>ANTICIPATORS</h4>
                Guided By Principles
            </div>
            <div class="flex-content mt-5">
                <div [ngClass]="{ 'highlight' : data.dataMap.page25.winner == 'samaritan' }" class="flex-row">
                    <div class="flex-cols col-content">SAMARITAN</div>
                    <div class="flex-perc">{{ data.dataMap.page25.samaritan }}%</div>
                </div>
            </div>
            <div class="flex-content mt-5">
                <div [ngClass]="{ 'highlight' : data.dataMap.page25.winner == 'socializer' }" class="flex-row">
                    <div class="flex-cols col-content">SOCIALIZER</div>
                    <div class="flex-perc">{{ data.dataMap.page25.socializer }}%</div>
                </div>
            </div>
            <div class="flex-content mt-5">
                <div [ngClass]="{ 'highlight' : data.dataMap.page25.winner == 'optimist' }" class="flex-row">
                    <div class="flex-cols col-content">OPTIMIST</div>
                    <div class="flex-perc">{{ data.dataMap.page25.optimist }}%</div>
                </div>
            </div>
            <div class="flex-content mt-5">
                <div [ngClass]="{ 'highlight' : data.dataMap.page25.winner == 'energizer' }" class="flex-row">
                    <div class="flex-cols col-content">ENERGIZER</div>
                    <div class="flex-perc">{{ data.dataMap.page25.energizer }}%</div>
                </div>
            </div>
        </div>
        <div class="flex-cols mr-5">
            <div class="flex-heading">
                <h4>REGULATORS</h4>
                Guided By Practicality
            </div>
            <div class="flex-content mt-5">
                <div [ngClass]="{ 'highlight' : data.dataMap.page25.winner == 'pragmatist' }" class="flex-row">
                    <div class="flex-cols col-content">PRAGMATIST</div>
                    <div class="flex-perc">{{ data.dataMap.page25.pragmatist }}%</div>
                </div>
            </div>
            <div class="flex-content mt-5">
                <div [ngClass]="{ 'highlight' : data.dataMap.page25.winner == 'exponent' }" class="flex-row">
                    <div class="flex-cols col-content">EXPONENT</div>
                    <div class="flex-perc">{{ data.dataMap.page25.exponent }}%</div>
                </div>
            </div>
            <div class="flex-content mt-5">
                <div [ngClass]="{ 'highlight' : data.dataMap.page25.winner == 'benevolent' }" class="flex-row">
                    <div class="flex-cols col-content">BENEVOLENT</div>
                    <div class="flex-perc">{{ data.dataMap.page25.benevolent }}%</div>
                </div>
            </div>
            <div class="flex-content mt-5">
                <div [ngClass]="{ 'highlight' : data.dataMap.page25.winner == 'indulgent' }" class="flex-row">
                    <div class="flex-cols col-content">INDULGENT</div>
                    <div class="flex-perc">{{ data.dataMap.page25.indulgent }}%</div>
                </div>
            </div>
        </div>
        <div class="flex-cols">
            <div class="flex-heading">
                <h4>PARTICIPATORS</h4>
                Guided By Excitement
            </div>
            <div class="flex-content mt-5">
                <div [ngClass]="{ 'highlight' : data.dataMap.page25.winner == 'improviser' }" class="flex-row">
                    <div class="flex-cols col-content">IMPROVISER</div>
                    <div class="flex-perc">{{ data.dataMap.page25.improviser }}%</div>
                </div>
            </div>
            <div class="flex-content mt-5">
                <div [ngClass]="{ 'highlight' : data.dataMap.page25.winner == 'explorer' }" class="flex-row">
                    <div class="flex-cols col-content">EXPLORER</div>
                    <div class="flex-perc">{{ data.dataMap.page25.explorer }}%</div>
                </div>
            </div>
            <div class="flex-content mt-5">
                <div [ngClass]="{ 'highlight' : data.dataMap.page25.winner == 'empiric' }" class="flex-row">
                    <div class="flex-cols col-content">EMPIRIC</div>
                    <div class="flex-perc">{{ data.dataMap.page25.empiric }}%</div>
                </div>
            </div>
            <div class="flex-content mt-5">
                <div [ngClass]="{ 'highlight' : data.dataMap.page25.winner == 'jubilant' }" class="flex-row">
                    <div class="flex-cols col-content">JUBILANT</div>
                    <div class="flex-perc">{{ data.dataMap.page25.jubilant }}%</div>
                </div>
            </div>
        </div>
    </div>

    <div class="mt-20">
        <div class="twenty-nine-page-sec">
            <div style="padding-top: 10px;" class="text-center twenty-nine-page-Research">{{ data.dataMap.page26_29.intro_title }}</div>
            <div style="padding-top: 0px;" class="twenty-nine-page-Rsa-1">
                {{ data.dataMap.page26_29.intro_para }}
                
            </div>
        </div>
        <div class="clear-both"></div>
    </div>
    <div class="mt-20">
        <div class="twenty-sixth-page-row-wrapper">
            <div class="twentyth-page-section-heading" style="margin-right: 5px;">
                PROS
            </div>
            
           
            <div class="twentyth-page-section-heading">
                CONS
            </div>
        </div>
        <div class="clear-both"></div>
    </div>
    <div class="mt-5">
        <div class="twenty-sixth-page-row-wrapper">
            <div class="twenty-sixth-page-column-one">
                <div *ngFor="let item of data.dataMap.page26_29.pros?.split(';')" class="twentyth-page-section-subheading">
                   {{ item }}
                </div>
            </div>
            <div class="twenty-sixth-page-column-two">
                <div *ngFor="let item of data.dataMap.page26_29.cons?.split(';')" class="twentyth-page-section-subheading">
                    {{ item }}
                </div>
            </div>
        </div>
        <div class="clear-both"></div>
    </div>

</div>