
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { LocationStrategy, PathLocationStrategy, HashLocationStrategy } from '@angular/common';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { FullComponent } from './layouts/full/full.component';
import { AppHeaderComponent } from './layouts/full/header/header.component';
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoMaterialModule } from './demo-material-module';

import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';
import { LoginComponent } from './login/login.component';
import { AnalystListComponent } from './analyst/analyst-list/analyst-list.component';
import { AnalystCreateComponent } from './analyst/analyst-create/analyst-create.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { WorkpadComponent } from './workpad/workpad.component';
import { NumberDirective } from './directives/numbers-only.directive';
import { ClientListComponent } from './client-list/client-list.component';
import { PinchZoomModule } from './ivypinch_pro_ng7/pinch-zoom.module';
import { Ng2PanZoomModule } from 'ng2-panzoom';
import { ReportComponent } from './report/report.component';
import { PendingReportComponent } from './pending-report/pending-report.component';
import { ClientDetailComponent } from './client-detail/client-detail.component';
import { NgxSpinnerModule } from "ngx-spinner";  
import { ImageViewerModule } from '@hallysonh/ngx-imageviewer';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button'; 

import { Page6Component } from './page6/page6.component';
import { Page7Component } from './page7/page7.component';
import { FooterComponent } from './footer/footer.component';
import { Page8Component } from './page8/page8.component';
import { Page9Component } from './page9/page9.component';
import { Page10Component } from './page10/page10.component';
import { Page1Component } from './page1/page1.component';
import { Page2Component } from './page2/page2.component';
import { Page3Component } from './page3/page3.component';
import { Page4Component } from './page4/page4.component';
import { Page5Component } from './page5/page5.component';
import { Page11Component } from './page11/page11.component';
import { Page12Component } from './page12/page12.component';
import { Page13Component } from './page13/page13.component';
import { Page14Component } from './page14/page14.component';
import { Page15Component } from './page15/page15.component';
import { Page16Component } from './page16/page16.component';
import { Page17Component } from './page17/page17.component';
import { Page18Component } from './page18/page18.component';
import { Page19Component } from './page19/page19.component';
import { Page20Component } from './page20/page20.component';
import { Page21Component } from './page21/page21.component';
import { Page22Component } from './page22/page22.component';
import { Page23Component } from './page23/page23.component';
import { Page24Component } from './page24/page24.component';
import { Page27Component } from './page27/page27.component';
import { Page28Component } from './page28/page28.component';
import { Page29Component } from './page29/page29.component';
import { Page26Component } from './page26/page26.component';
import { Page25Component } from './page25/page25.component';
import { Page30Component } from './page30/page30.component';
import { Page31Component } from './page31/page31.component';
import { Page32Component } from './page32/page32.component';
import { Page33Component } from './page33/page33.component';
import { Page34Component } from './page34/page34.component';
import { Page35Component } from './page35/page35.component';
import { Page36Component } from './page36/page36.component';
import { Page37Component } from './page37/page37.component';
import { Page38Component } from './page38/page38.component';
import { Page39Component } from './page39/page39.component';
import { Page40Component } from './page40/page40.component';
import { Page41Component } from './page41/page41.component';
import { Page42Component } from './page42/page42.component';
import { Page43Component } from './page43/page43.component';
import { Page44Component } from './page44/page44.component';
import { Page45Component } from './page45/page45.component';
import { Page46Component } from './page46/page46.component';
import { Page47Component } from './page47/page47.component';
import { Page48Component } from './page48/page48.component';
import { Page49Component } from './page49/page49.component';
import { LactoseGlutenModalComponent } from './lactose-gluten-modal/lactose-gluten-modal.component';
import { AddClientComponent } from './add-client/add-client.component';
// import { FontAwesomeModule } from '@fontawesome/angular-fontawesome';
import { ReactiveFormsModule } from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { DatePipe } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    AppHeaderComponent,
    SpinnerComponent,
    AppSidebarComponent,
    LoginComponent,
    AnalystListComponent,
    AnalystCreateComponent,
    ConfirmationDialogComponent,
    WorkpadComponent,
    NumberDirective,
    ClientListComponent,
    ReportComponent,
    PendingReportComponent,
    ClientDetailComponent,

    Page6Component,
    Page7Component,
    FooterComponent,
    Page8Component,
    Page9Component,
    Page10Component,
    Page1Component,
    Page2Component,
    Page3Component,
    Page4Component,
    Page5Component,
    Page11Component,
    Page12Component,
    Page13Component,
    Page14Component,
    Page15Component,
    Page16Component,
    Page17Component,
    Page18Component,
    Page19Component,
    Page20Component,
    Page21Component,
    Page22Component,
    Page23Component,
    Page24Component,
    Page27Component,
    Page28Component,
    Page29Component,
    Page26Component,
    Page25Component,
    Page30Component,
    Page31Component,
    Page32Component,
    Page33Component,
    Page34Component,
    Page35Component,
    Page36Component,
    Page37Component,
    Page38Component,
    Page39Component,
    Page40Component,
    Page41Component,
    Page42Component,
    Page43Component,
    Page44Component,
    Page48Component,
    Page49Component,
    Page45Component,
    Page46Component,
    Page47Component,
    LactoseGlutenModalComponent,
    AddClientComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    FlexLayoutModule,
    HttpClientModule,
    SharedModule,
    PinchZoomModule,
    Ng2PanZoomModule,
    RouterModule.forRoot(AppRoutes, {useHash: true}),
    NgxSpinnerModule,
    ImageViewerModule
    // FontAwesomeModule
    //BackButtonDisableModule.forRoot()  
    //RouterModule.forRoot(AppRoutes)
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
