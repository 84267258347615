<div class="twenty-eight-page mt-20">
    <div class="">
        <div class="text-right">
            <img class="logo-img" src="assets/images/Header.png" />
        </div>
        <div class="mt-10">
            <div>
                <div class="text-center twenty-eight-page-Research">{{ data.dataMap.page26_29.lifepartner_title }}</div>
                <div class="twenty-eight-page-sec">
                    <div class="twenty-eight-page-Rsa-1">
                        {{ data.dataMap.page26_29.lifepartner_para }}
                        </div>
                </div>
            </div>
            <div class="clear-both"></div>
        </div>
        <div class="mt-20">
            <img class="width-100" [attr.src]="data.dataMap.page26_29.image1"> 
        </div>
        <div class="mt-20">
            <div>
                <div class="text-center twenty-eight-page-Research">{{ data.dataMap.page26_29.parent_title }}</div>
                <div class="twenty-eight-page-sec">
                    <div class="twenty-eight-page-Rsa-1">
                        {{ data.dataMap.page26_29.parent_para }}
                        </div>
                </div>
            </div>
            <div class="clear-both"></div>
        </div>
       </div>
</div>