import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-page47',
  templateUrl: './page47.component.html',
  styleUrls: ['./page47.component.css']
})
export class Page47Component implements OnInit {
  @Input() data: any;
  constructor() { }

  ngOnInit(): void {
  }

}
