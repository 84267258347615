<div class="eighth-page mt-40">
    <div class="text-right">
        <img class="logo-img" src="assets/images/Header.png" />
    </div>

    <div class="blue-header mt-20">
        AUDITORY LEARNERS
    </div>

    <div>
        <p>
            Auditory learners are sound sensitive. They need a quiet ambience for them to concentrate. Smallest amount of sound gets 
            registered, while a smallest amount of sound can also distract. Studying late night or early morning or at a place where there
             is a pin drop silence will give best results. 
        </p>
        <p>
            Auditory learners acquire knowledge more by listening, thus they are 
            required to read aloud and also summarize orally, so that they hear the words into their ears to get them registered in their memory.
            Since they are inclined towards Music, Rhythm and Melodies they can even link data and events of importance to Music. 
            Frequent Music breaks in between studies keeps auditory learners refreshed. 
        </p>     
        <p>
            In the classroom they are required to sit in the front rows so that the teacher is clearly audible and also there is 
            less noise as compared to the back benches. They should be encouraged to participate in debate and discussions so 
            that they can grasp the information. Also, syllabus on Audio tapes or recording question/ answers in own voice and 
            listening them helps them to memorize precisely. 
        </p>
    </div>


    <div class="mt-10" style="height: 195px; overflow: hidden;">
        <div class="eight-page-left-sec" style="height: 100%;">
            <img class="width-100" style="height: 100%;" src="assets/images/Page 9/IMG 1.png" />
        </div>
        <div class="eight-page-left-sec" style="margin-left: 20px;">
            <div class="blue-box-wrapper">
                <div class="blue-box-heading">
                    PARENTING TIPS
                </div>
                <div class="blue-box-section" style="border-top: none">
                    <div class="left-dot-sec">
                        <span class="blue-box-dot"></span>
                    </div>
                    <div class="left-detail-sec">
                        Encourage your child to read aloud and create pin- drop silence space for them. 
                    Never distract them by talking in front of them while they are studying. Allow them to study late 
                    night or early morning. Keep your mobile phone away when they are studying.
                    </div>
                    <div class="clear-both"></div>
                </div>
                <div class="blue-box-section">
                    <div class="left-dot-sec">
                        <span class="blue-box-dot"></span>
                    </div>
                    <div class="left-detail-sec">
                        Allow them to listen music every day. Play music in your home & car for your child's delight. 
                        Provide a variety of musical instruments for your child to enjoy.
                    </div>
                     
                    <div class="clear-both"></div>
                </div>
                <div class="blue-box-section">
                    <div class="left-dot-sec">
                        <span class="blue-box-dot"></span>
                    </div>
                    <div class="left-detail-sec">
                        Use soft & polite tone, don't use harsh or rude language while asking them to study. Whenever there is a difficulty,
                     start a conversation about it, and let your child work through the logic of the problem by talking to you about it. 
                    </div>
                    
                    <div class="clear-both"></div>
                </div>
                <div class="blue-box-section">
                    <div class="left-dot-sec">
                        <span class="blue-box-dot"></span>
                    </div>
                    <div class="left-detail-sec">
                        If they tend to read at the last moment before exam, let them do so. Provide them auditory syllabus if available 
                    </div>
                    
                    <div class="clear-both"></div>
                </div>
            </div>
        </div>
        <div class="clear-both"></div>
    </div>

    <div class="blue-header mt-20">
        VISUAL LEARNERS
    </div>

    <div>
        <p>
            Visual Learners register information in their memory best by Observing. For them, the most easiest method of learning is to use 
            notes. They can differentiate easily between Fonts, Colors, Shapes, Sizes etc. and link them to recall their memory. 
            So, marking the important formulas or highlighting the important Text by different colors is beneficial for Visual Learners. 
        </p>
        <p>
            Visual Learners can also collect basic clues from oral lectures and convert them to flowcharts or pin-pointers and then memorize 
        them to be recalled whenever required. Making appropriate use of Graphs, Charts, Spread Sheets etc. can also help. Other Visual 
        aids such as Video Tapes, Computer Programs, Learning Applications and Flashcards can enhance memory retention
        </p>     
        <p>
            In the Classroom, 
            a Visual Learner will be good at catching the expressions of a teacher and also the reactions of his/her classmates on the subject.
             If given opportunity to put things into perspective or given a chance to organize something, they can retain the entire proceedings
              into their memory. Frequent use of Acronyms, Visual chains or Mnemonics can also catch the attention of a Visual learner
        </p>
    </div>

    <div class="mt-10" style="height: 217px; overflow: hidden;">
        <div class="eight-page-left-sec" style="height: 100%;">
            <img class="width-100" style="height: 100%;" src="assets/images/Page 9/IMG 2.png" />
        </div>
        <div class="eight-page-left-sec" style="margin-left: 20px;">
            <div class="blue-box-wrapper">
                <div class="blue-box-heading">
                    PARENTING TIPS
                </div>
                <div class="blue-box-section" style="border-top: none">
                    <div class="left-dot-sec">
                        <span class="blue-box-dot"></span>
                    </div>
                    <div class="left-detail-sec">
                        Encourage your child to engage their sense of sight & draw a Mind Map for memory retention. Provide them colorful pens 
                        for practice & colorful book covers which will attract them. If they chose to highlight text or formulas, please allow them 
                    </div>
                    <div class="clear-both"></div>
                </div>
                <div class="blue-box-section">
                    <div class="left-dot-sec">
                        <span class="blue-box-dot"></span>
                    </div>
                    <div class="left-detail-sec">
                        Provide them a quiet ambience when they are reading. Let them follow their own pattern. Since they have photographic memory,
                     they may like to draw a picture to memorize the chapters
                    </div>
                    <div class="clear-both"></div>
                </div>
                <div class="blue-box-section">
                    <div class="left-dot-sec">
                        <span class="blue-box-dot"></span>
                    </div>
                    <div class="left-detail-sec">
                        Their affinity towards neatness & cleanliness may force them to write slowly. Don't rush on them. Ask them to increase the speed gradually.
                        Don't be worried if they prefer to read or write with small fonts. 
                    </div>
                    <div class="clear-both"></div>
                </div>
                <div class="blue-box-section">
                    <div class="left-dot-sec">
                        <span class="blue-box-dot"></span>
                    </div>
                    <div class="left-detail-sec">
                        Provide them an Animation or Video of their syllabus if available. Stock up Art & craft supplies & also 
                    provide them a white board, drawing books, picture books & Flash cards. 
                    </div>
                    <div class="clear-both"></div>
                </div>
            </div>
        </div>
        <div class="clear-both"></div>
    </div>

</div>