<div class="thirty-four-page">
    <div class="text-right">
        <img class="logo-img" src="assets/images/Header.png" />
    </div>
    <div style="background-color: #c4c5a5;">
        <div class="thirty-three-page-blue-header">
            INTERPERSONAL (People-Smart)
        </div>
        <div>
            <div class="mt-5">
                <div class="thirty-three-page-cont1">
                    <div>
                        <div class="thirty-three-page-fs23">
                            Interpersonal is socializing skills. So Interpersonal intelligence is ability to interact
                            skillfully with others. People with high Interpersonal intelligence thrive on Social
                            interactions. They are gifted at establishing rapport with strangers and make friends
                            easily. They are adept at reading, empathizing and understanding others. People with
                            interpersonal intelligence work well with others and often have many friends. They are
                            masters of collaboration. They are sensitive and intuitive towards the needs of others.
                        </div>
                        <div class="thirty-three-page-ipr">
                            Anything related to Others comes from Interpersonal
                        </div>
                        <div class="clear-both"></div>
                        <div class="thirty-three-page-line-height">
                            <ul>
                                <li>All about We, Us & Ours (People Connect)</li>
                                <li>Interpersonal skills & Social interaction</li>
                                <li>Leadership & Relationship management</li>
                                <li>Sensitivity & Response </li>
                                <li>Empathy & Compassion</li>
                                <li>Administration- by Motivating others</li>
                                <li>Social service, History & Politics</li>
                                <li>Intuitive Powers & Goal Visualization</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="thirty-three-page-first-box" style="text-align: center;">
                    <img src="assets/images/Page 34/PAGE-34-INTERPERSONAL.jpg" class="width-100">

                </div>
                <div class="clear-both"></div>
            </div>
        </div>
    </div>
    <div class="mt-20">
        <div class="purple-header">
            CHARACTERISTICS OF PERSON HAVING HIGH INTERPERSONAL INTELLIGENCE
        </div>
        <div class="thirty-three-page-row-wrapper">
            <div class="tbl-bx">
                <div class="thirty-three-page-section-heading">
                    PROS
                </div>
                <div class="clear-both"></div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Sensitive to other people's Feelings and <br>Genuinely concerned for others.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-two">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Can sense Temperaments, Intentions and <br>Motives of others, Good negotiators.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-two">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Good at Management, More co-operative in <br>group work, Ability to Influence &
                                Persuade.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-two" style="border-bottom: none;">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Socially very active in all the platforms <br>(In media and In-person).
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="clear-both"></div>
            </div>
            <div class="tbl-bx" style="border-left: none;">
                <div class="thirty-three-page-section-heading">
                    CONS
                </div>
                <div class="clear-both"></div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Trusting causally on others often Backfires.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-two">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Setting up Personal goals
                                <br> keeping others in mind.

                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-two">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Prone to be taken for granted or manipulated.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-two" style="border-bottom: none;">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>At times extreme Extrovert behavior. <br>Neglects self and family.
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="clear-both"></div>
            </div>
        </div>
    </div>

    <div class="mt-20">
        <div class="purple-header">
            REMEDIAL MEASURES IF YOUR INTERPERSONAL INTELLIGENCE IS LOW
        </div>
        <div class="IIL-bg">
            <div>
                <div class="IIL-sec1">1) DEVELOP INTERPERSONAL SKILLS</div>
                <div>
                    <ul class="thirty-three-page-list-IIL">
                        <li>Learn to notice how all people are different. Accept their personal style and try not to
                            change them, rather change your approach and acceptance.
                        </li>
                        <li>Try not to be arrogant, insensitive, distant, or too busy to pay attention to the people you
                            don't like to talk to. Practice empathy by placing yourself in their shoes.
                        </li>
                        <li>Learn to break the ice. The first 3 minutes are crucial to set the tone and make the first
                            impression. Try to make people comfortable, be open and approachable. Practice to make the
                            first move even if you feel shy.
                        </li>
                    </ul>
                </div>
                <div class="clear-both"></div>
            </div>
            <div>
                <div class="IIL-sec1">2) DEVELOP MANAGEMENT AND LEADERSHIP SKILLS</div>
                <div>
                    <ul class="thirty-three-page-list-IIL">
                        <li>Indulge into team building exercises and activities, learn to build trust, mitigate
                            conflicts and proliferate collaboration
                        </li>
                        <li>Read or Google out skills on how to influence and get the job done in right manner. Start
                            with small leadership roles or responsibilities in groups. Follow or take guidance from
                            experienced managers
                        </li>
                    </ul>
                </div>
                <div class="clear-both"></div>
            </div>
            <div>
                <div class="IIL-sec1">3) SET UP GOALS</div>
                <div>
                    <ul class="thirty-three-page-list-IIL">
                        <li>Avoid casual approach for the important events in your life.
                        </li>
                        <li>Try to break it down into small goals to start with. Follow this process to accomplish your
                            goals -
                        </li>
                        <li>DECIDE <span style='vertical-align: middle;'>&#10142;</span>
                            WRITE IT DOWN <span style='vertical-align: middle;'>&#10142;</span>
                             ACTION PLAN A <span style='vertical-align: middle;'>&#10142;</span>
                            (KEEP PLAN B & C FOR BACK UP) <span
                                style='vertical-align: middle;'>&#10142;</span>
                            TAKE THE FIRST STEP <span style='vertical-align: middle;'>&#10142;</span>
                            KEEP GOING <span style='vertical-align: middle;'>&#10142;</span>
                            UTILIZE ALL THE ABOVE MEASURES <span
                                style='vertical-align: middle;'>&#10142;</span>
                            ACCOMPLISH <span style='vertical-align: middle;'>&#10142;</span>
                            CELEBRATE  <span style='vertical-align: middle;'>&#10142;</span>
                            SET THE NEXT GOAL

                        </li>
                    </ul>
                </div>
                <div class="clear-both"></div>
            </div>

        </div>
    </div>



</div>