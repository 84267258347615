<div class="thirty-eight-page">
    <div class="text-right">
        <img class="logo-img" src="assets/images/Header.png" />
    </div>

    <div style="background-color: #acd283;">
        <div class="thirty-three-page-blue-header">
            GROSS MOTOR SKILLS (Body Smart)
        </div>
        <div class="mt-10">
            <div class="mt-5">
                <div class="thirty-three-page-cont1">
                    <div>
                        <div class="thirty-three-page-fs23">
                            Gross Motor Skills (GMS) is the co-ordination of large muscular movements of the body in
                            synchronization with the thought process. People with high GMS are very swift in moving and
                            performing action. They are very active physically as well as mentally. They tend to be
                            pro-active towards kinesthetic challenges which involve physical action. They are
                            experienced based learners and understand better by exploring pragmatically.
                        </div>
                        <div class="thirty-three-page-ipr">
                            GMS Defines Profoundness <br>of Pro-activity of a Person
                        </div>
                        <div class="clear-both"></div>
                        <div class="thirty-three-page-line-height">
                            <ul>
                                <li>Big muscular movements </li>
                                <li>Running, Jumping, Walking etc.</li>
                                
                                <li>All Physical activities</li>
                                <li>Strength & Stamina</li>
                                <li>Pro-activeness & Endurance</li>
                                <li>Rhythmic movements & Co-ordination</li>
                                <li>Outdoor & All field & track games </li>
                                <li>Grabbing Opportunities</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="thirty-three-page-first-box" style="text-align: center;">
                    <img src="assets/images/Page 38/PAGE-38-GMS.jpg" class="width-100">

                </div>
                <div class="clear-both"></div>
            </div>
        </div>
    </div>

    <div class="mt-20">
        <div class="purple-header">
            CHARACTERSTICS OF OF PERSON HAVING HIGH GROSS MOTORS SKILL
        </div>
        <div class="thirty-three-page-row-wrapper">
            <div class="tbl-bx">
                <div class="thirty-three-page-section-heading">
                    PROS
                </div>
                <div class="clear-both"></div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Can excel in Sports: All track & Field sports,
                                <br> Boxing, Martial arts, Swimming, etc.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Can excel in Music: Playing of all Gross <br>instruments e.g. Drums, Dhol etc.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Can excel in Performing Arts: Different Dance <br>forms, Action Films, Reality Shows
                                etc.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-one" style="border-bottom: none;">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Good at Operations, Grabbing opportunities & <br>Adventure activities. Always on the go.
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="clear-both"></div>
            </div>
            <div class="tbl-bx" style="border-left: none;">
                <div class="thirty-three-page-section-heading">
                    CONS
                </div>
                <div class="clear-both"></div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Need constant mobility for paying attention or to <br>concentrate. Brain sleeps when body
                                is not mobile.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Constantly moving legs, changing siting <br>positions or causing distractions in meetings or lectures.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Often very hyperactive. May leave tasks halfway, <br />
                                frequently change mind or cut-off relations in haste.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-one" style="border-bottom: none;">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Good at physical activity but still requires equal <br>contributions from other
                                intelligences to perform.
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="clear-both"></div>
            </div>
        </div>
    </div>

    <div class="mt-20">
        <div class="purple-header">
            REMEDIAL MEASURES IF YOUR GROSS MOTOR SKILLS IS LOW 
        </div>
        <div class="IIL-bg">
            <div>
                <div class="IIL-sec">Take measures to improve the following </div>
                <div class="IIL-sec1">1) FIND A WAY TO INDULGE INTO ROUTINE PHYSICAL TASKS.</div>
                <div>
                    <ul class="thirty-three-page-list-IIL">
                        <li>Morning walk (Brisk walking) or jogging.
                        </li>
                        <li>Join Gymnasium or hire a professional trainer.
                        </li>
                        <li>Join Yoga/ karate/ Swimming classes.
                        </li>
                    </ul>
                </div>
                <div class="clear-both"></div>
            </div>

            <div>
                <div class="IIL-sec1">2) PLAY OUTDOORS SPORTS WHENEVER YOU FIND TIME, ESPECIALLY AT WEEKENDS.</div>
                <div class="clear-both"></div>
            </div>
            <div>
                <div class="IIL-sec1">3) DEVELOP A HOBBY WHICH UTILIZE GROSS MUSCLES LIKE HIKING, TREKKING ETC.</div>

                <div class="clear-both"></div>
            </div>
            <div>
                <div class="IIL-sec1">4) PARTICIPATE IN TEAM ACTIVITIES, ATTEND SEMINARS, PARTIES — BREAK THE ROUTINE.
                </div>

                <div class="clear-both"></div>
            </div>
            <div>
                <div class="IIL-sec1">5) DIVERT MIND FROM THE BAD PATCHES OF PAST AND LEARN TO LET GO.</div>

                <div class="clear-both"></div>
            </div>
            <div>
                <div class="IIL-sec1">6)EXPLORE NEW OPPORTUNITIES AND LEARN TO TAKE CALCULATIVE RISKS.</div>

                <div class="clear-both"></div>
            </div>
            <div>
                <div class="IIL-sec1">7) PLAN AND PARTICIPATE IN FREQUENT OUTINGS WITH FRIENDS , COLLEAGUE AND FAMILY.
                </div>
                <div class="clear-both"></div>
            </div>

        </div>
    </div>

</div>