import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page44',
  templateUrl: './page44.component.html',
  styleUrls: ['./page44.component.css']
})
export class Page44Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
