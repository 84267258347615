import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-page14',
  templateUrl: './page14.component.html',
  styleUrls: ['./page14.component.css']
})
export class Page14Component implements OnInit {
  @Input() data: any;
  constructor() { }

  ngOnInit(): void {
  }

  addTen(value: string){
    return parseInt(value) + 10;
  }
}
