import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page33',
  templateUrl: './page33.component.html',
  styleUrls: ['./page33.component.css']
})
export class Page33Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
