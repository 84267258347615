import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
//import { String, StringBuilder } from 'typescript-string-operations';
//import { allURLS } from './allURL';
//const crypto = require('crypto');
const crypto = require('crypto-js');
@Injectable({
  providedIn: 'root'
})
export class CryptoService {
  encryptSecretKey="@10.BRAINOGRAPHY"; //allURLS['ENCRYPTSECRETKEY'];
  ivParameterSpec: StaticRange;
  KEY: any = [];
  IV: any = [];
  encodedUuid: string = null;
  encryptedString: string = null;
  mdString: string = null;

  constructor() { }

  //encrypting the payload body.
  getEncryptedBody(cognitoId: string, plainText: string) {
      this.createKeys(cognitoId);
      this.encryptedString = this.encrypt(plainText)
      return this.encryptedString;
  }

  //decripting the payload body.
  getDecryptedBody(cognitoId: string, encryptedText: string) {
      this.createKeys(cognitoId)
      return this.decrypt(encryptedText)
  }

  //converting congnitoId in base64 format.
  getBase64String(plainText: string) {
      return Buffer.from(plainText.toString()).toString('base64');
  }

  //create MD5 hash using congitoId and encryptSecretKey.
  createKeys(cognitoId: String) {
      var uuidSimple = cognitoId.toString().replace(/-/g, '');
      this.mdString = crypto.MD5(Buffer.from((uuidSimple + this.encryptSecretKey))
             .toString("ASCII"), 0, (uuidSimple.length + this.encryptSecretKey.length)).toString(crypto.enc.Hex);
    //   this.mdString = crypto.createHash('md5').update(Buffer.from((uuidSimple + this.encryptSecretKey))
    //       .toString("ASCII"), 0, (uuidSimple.length + this.encryptSecretKey.length)).digest('hex');
      this.KEY = CryptoJS.enc.Utf8.parse(this.mdString!!.substring(0, 16));
      this.IV = CryptoJS.enc.Utf8.parse(this.mdString!!.substring(16));
      this.encodedUuid = Buffer.from(cognitoId.toString()).toString('base64');
  }

  //converting text to Encryption.
  encrypt(textToEncrypt: string) {
      var encrypted = CryptoJS.AES.encrypt(textToEncrypt,
          this.KEY,
          {
              keySize: 128 / 8,
              iv: this.IV,
              mode: CryptoJS.mode.CBC,
              padding: CryptoJS.pad.Pkcs7
          });
      return encrypted.toString();
  }

  //converting text to Decryption.
  decrypt(textToDecrypt: string) {
      var decrypted = CryptoJS.AES.decrypt(textToDecrypt, this.KEY, {
          keySize: 128 / 8,
          iv: this.IV,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
      });

      return decrypted.toString(CryptoJS.enc.Utf8);
  }
}
