<div class="fourty-page mt-40">
    <div class="text-right">
        <img class="logo-img" src="assets/images/Header.png" />
    </div>

    <div style="background-color: #6d3d29;">
        <div class="thirty-three-page-blue-header">
            MUSIC & EMOTIONS (Music Smart)
        </div>
        <div class="mt-10">
            <div class="mt-5">
                <div class="thirty-three-page-cont1">
                    <div>
                        <div class="thirty-three-page-fs23">
                            Musical & Emotional intelligence is sensitivity and understanding of sounds which generate
                            sensations and feelings within oneself. People with high musical intelligence are attracted
                            to music by default and they are very sensible towards melodies, rhythms, tunes, tones,
                            pitch, meter ,tempo, and timbre. They can develop 'Absolute Pitch' to be able to explore
                            music. They are also highly emotional persons by default.
                        </div>
                        <div class="thirty-three-page-ipr">
                            Musical Intelligence is Relating <br>Sounds with Feelings.
                        </div>
                        <div class="clear-both"></div>
                        <div class="thirty-three-page-line-height">
                            <ul>
                                <li>Sound Sensitivity</li>
                                <li>Sound pattern & Volume</li>
                                <li>Love & Urge for Music</li>
                                <li>Melodies, Tune, Rhythm & Tempo</li>
                                <li>Frequency, Pitch, Tone & Timbre</li>
                                <li>Feelings & Emotions</li>
                                <li>Non-verbal communication </li>
                                <li>Sympathy & Compassion</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="thirty-three-page-first-box" style="text-align: center;">
                    <img src="assets/images/Page 40/PAGE-40-MUSIC-&-EMOTIONS.jpg" class="width-100">

                </div>
                <div class="clear-both"></div>
            </div>
        </div>
    </div>

    <div class="mt-20">
        <div class="purple-header">
            CHARACTERISTICS OF PERSON WITH HIGH MUSICIAL & EMOTIONAL INTELLIGENCE
        </div>
        <div class="thirty-three-page-row-wrapper">
            <div class="tbl-bx">
                <div class="thirty-three-page-section-heading">
                    PROS
                </div>
                <div class="clear-both"></div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Aptitude for understanding Rhythms, Patterns & <br>Music as a Whole.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Good in either playing/performing, composing or <br>appreciating different forms of
                                music.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Always thinks of doing good to others. <br>Zeal for Social- Service.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-one" style="border-bottom: none;">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Highly Emotional & Sympathetic. Connects by <br>Heart & communicates through feelings.
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="clear-both"></div>
            </div>
            <div class="tbl-bx" style="border-left: none;">
                <div class="thirty-three-page-section-heading">
                    CONS
                </div>
                <div class="clear-both"></div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Feels uneasy or mentally disturbed if neglects <br>music for longer duration. Music is
                                unavoidable.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li> Vulnerable to manipulation or exploitation due to
                                <br>altruistic nature. May be also taken for granted.

                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Takes things too personal. At times disconnects with <br>outer world when emotionally
                                disturbed or hurt.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-one" style="border-bottom: none;">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Makes personal sacrifices for others.
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="clear-both"></div>
            </div>
        </div>
    </div>

    <div class="mt-20">
        <div class="purple-header">
            REMEDIAL MEASURES IF YOUR MUSICIAL- EMOTIONAL INTELLIGENCE IS LOW
        </div>
        <div class="IIL-bg">
            <div>
                <div class="IIL-sec">Take measures to improve the following</div>
                <div class="IIL-sec1">1) IMPROVE YOUR TOLERANCE
                    <ul class="thirty-three-page-list-IIL">
                        <li>Listen to unfamiliar style of music even if you don't like it, twice a week. Try to develop
                            your taste for it. </li>
                        <li> Engage yourself frequently with people whom you don't like, without getting irritated.</li>
                        <li> Appreciate atleast 5 people everyday</li>
                    </ul>
                </div>
                <div class="clear-both"></div>
            </div>

            <div>
                <div class="IIL-sec1">2) LEARN TO EXPRESS SYMPATHY TOWARDS OTHERS</div>
                <ul class="thirty-three-page-list-IIL">
                    <li>Give others a chance to talk about their emotions. Listen first, offer comments later.
                        Avoid invalidating other person's feelings. </li>
                    <li>Express support and use appropriate physical contact.</li>

                </ul>
                <div class="clear-both"></div>
            </div>
            <div>
                <div class="IIL-sec1">3) DEVELOP A MUSICAL HOBBY</div>
                <ul class="thirty-three-page-list-IIL">
                    <li>Learn at least one Musical instrument and practice religiously or join singing classes.
                        Practice with group of people or <br>Karaoke tracks. </li>
                </ul>

                <div class="clear-both"></div>
            </div>
            <div>
                <div class="IIL-sec1">4) INDULGE INTO SOCIAL WORKS</div>
                <ul class="thirty-three-page-list-IIL">
                    <li>Visit Orphanage frequently or Help Physically/ Mentally challenged & elderly people. Join NGO's, 
                        Trusts or Organizations <br>related to Social Cause and Charity.  </li>
                </ul>
                <div class="clear-both"></div>
            </div>

            




        </div>
    </div>

</div>