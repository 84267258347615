<div class="twelveth-page mt-40">
    <div class="text-right">
        <img class="logo-img" src="assets/images/Header.png" />
    </div>
  
      <div class="" style="background-color: #e6e7e8!important;">
        <div class="fifth-page-row">
          <div class="fifth-page-first-box" style="width: 2%;"><strong>Q</strong></div>
          <div class="fifth-page-first-box" style="width: 90%;"><strong>Why am I more attracted towards these activities?</strong> </div>
          <div class="clear-both"></div>
        </div>
        <div class="fifth-page-row">
          <div class="fifth-page-first-box" style="width: 2%;"><strong>A</strong></div>
          <div class="fifth-page-first-box" style="width: 90%; text-align: justify; ">
            Flow of Neurons is not equally distributed between the 10 lobes of the brain.
            The brain lobes with higher neural densities (where maximum amount of Neurons are flowing) will always 
            push you towards the activities in which they are capable, no matter how adverse circumstances 
            you may face. The combination of 3 to 4 lobes out of your 10 lobes with higher ridge counts will 
            trigger your liking for these capabilities to an extent that, it will force yourself into repeating 
            those activities again and again. These activities will give you more thrill & excitement then the 
            activities coming from other lobes where the neural density is low.Thus,the excitement derived from 
            doing these acts leads you towards your Passion.
          </div>
          <div class="clear-both"></div>
        </div>
      </div>
      <div class="mt-10" style="background-color: #e6e7e8!important;">
        <div class="fifth-page-row">
          <div class="fifth-page-first-box" style="width: 2%;"><strong>Q</strong></div>
          <div class="fifth-page-first-box" style="width: 90%;"><strong>But then what is a Hobby? Is it different from Passion?</strong> </div>
          <div class="clear-both"></div>
        </div>
        <div class="fifth-page-row">
          <div class="fifth-page-first-box" style="width: 2%;"><strong>A</strong></div>
          <div class="fifth-page-first-box" style="width: 90%; text-align: justify; ">
            Yes, Hobbies are often confused with Passion. Hobbies are engagements with your 
            likings when you have nothing else to do. They are perused just as a stress buster or 
            relaxation whereas Passion is not just what you like, but your willingness to be in pain 
            to keep doing it. Passion is powerful and gripping. It is something for which you are willing 
            to sacrifice your time, money, energy & sleep. Something you can't stay away from even in 
            your bad days. Having said that, Hobby of one person can be Passion of the other & vice versa. 
            Also today a Hobby, can become a Passion tomorrow.
            <p style="margin-top: 5px; margin-bottom:0">There can be hundreds of different Hobbies. We have illustrated few of them on next page, 
                but before that explore your <strong> Orientation</strong>. Different people may have similar Hobbies, 
                but their approach may differ. Your <strong> Orientation</strong> will give you clarity on your approach 
                towards your Passion & Hobbies</p>
          </div>
          <div class="clear-both"></div>
        </div>
      </div>
      <div class="twelveth-page-bluebx mt-20">
       YOUR ORIENTATION
    </div>

    <div class="mt-20">
      <div class="twelveth-page-hrt50" >
         
        <div class="twelveth-page-sect25">
          <div class="perc-row">
            <div class="perc-row-left">
              
              <div class="perc-bar-wrapper">
                <div class="perc-num" [ngStyle]="{'bottom': convertString(data.dataMap.page12.heart)+'px' }" >{{data.dataMap.page12.heart}}%</div>
                <span class="perc-bar" [ngStyle]="{'height': addTwenty(data.dataMap.page12.heart)+'px' }" style="background-color: #d4c4b4;"></span>
              </div>
              <div class="perc-bar-border"></div>
              <div class="perc-bar-title">HEART</div>
            </div>
            <div class="perc-row-left">
              
              <div class="perc-bar-wrapper">
                <div class="perc-num" [ngStyle]="{'bottom': convertString(data.dataMap.page12.Mind)+'px' }">{{data.dataMap.page12.Mind}}%</div>
                <span class="perc-bar" [ngStyle]="{'height': addTwenty(data.dataMap.page12.Mind)+'px' }" style="background-color: #e9b15a;"></span>
               
              </div>
              <div class="perc-bar-border"></div>
              <div class="perc-bar-title">MIND</div>
            </div>
            <div class="clear-both"></div>
          </div>

          <div class="perc-row twelveth-page-content12">
            How you like to think? - By HEART or By MIND
          </div>
        </div>

        <div class="twelveth-page-sect25">
          <div class="perc-row">
            <div class="perc-row-left">
              
              <div class="perc-bar-wrapper">
                <div class="perc-num" [ngStyle]="{'bottom': convertString(data.dataMap.page12.indoor)+'px' }">{{data.dataMap.page12.indoor}}%</div>
                <span class="perc-bar" [ngStyle]="{'height': addTwenty(data.dataMap.page12.indoor)+'px' }" style="background-color: #c8d8cd; "></span>
               
              </div>
              <div class="perc-bar-border"></div>
              <div class="perc-bar-title">INDOOR</div>
            </div>
            <div class="perc-row-left">
              
              <div class="perc-bar-wrapper">
                <div class="perc-num" [ngStyle]="{'bottom': convertString(data.dataMap.page12.outdoor)+'px' }">{{data.dataMap.page12.outdoor}}%</div>
                <span class="perc-bar" [ngStyle]="{'height': addTwenty(data.dataMap.page12.outdoor)+'px' }" style="background-color: #b7c5c5; "></span>
              
              </div>
              <div class="perc-bar-border"></div>
              <div class="perc-bar-title">OUTDOOR</div>
            </div>
            <div class="clear-both"></div>
          </div>

          <div class="perc-row twelveth-page-content12">
            Where do you prefer to stay? INDOOR or OUTDOOR
          </div>
        </div>

        <div class="twelveth-page-sect25">
          <div class="perc-row">
            <div class="perc-row-left">
              
              <div class="perc-bar-wrapper">
                <div class="perc-num" [ngStyle]="{'bottom': convertString(data.dataMap.page12.individual)+'px' }">{{data.dataMap.page12.individual}}%</div>
                <span class="perc-bar" [ngStyle]="{'height': addTwenty(data.dataMap.page12.individual)+'px' }" style="background-color: #f3aeb1;"></span>
                
              </div>
              <div class="perc-bar-border"></div>
              <div class="perc-bar-title">INDIVIDUAL</div>
            </div>
            <div class="perc-row-left">
              
              <div class="perc-bar-wrapper">
                <div class="perc-num" [ngStyle]="{'bottom': convertString(data.dataMap.page12.team)+'px' }">{{data.dataMap.page12.team}}%</div>
                <span class="perc-bar" [ngStyle]="{'height': addTwenty(data.dataMap.page12.team)+'px' }" style="background-color: #91cff4;"></span>
              
              </div>
              <div class="perc-bar-border"></div>
              <div class="perc-bar-title">TEAM</div>
            </div>
            <div class="clear-both"></div>
          </div>

          <div class="perc-row twelveth-page-content12">
            What Delights you? to be INDIVIDUAL or in a TEAM
          </div>
        </div>

        <div class="twelveth-page-sect25" style="margin-right: 2px;">
          <div class="perc-row">
            <div class="perc-row-left">
              
              <div class="perc-bar-wrapper">
                <div class="perc-num" [ngStyle]="{'bottom': convertString(data.dataMap.page12.physically)+'px' }">{{data.dataMap.page12.physically}}%</div>
                <span class="perc-bar" [ngStyle]="{'height': addTwenty(data.dataMap.page12.physically)+'px' }" style="background-color: #b7c5c5;"></span>
                
              </div>
              <div class="perc-bar-border"></div>
              <div class="perc-bar-title">PHYSICALLY</div>
            </div>
            <div class="perc-row-left">
              
              <div class="perc-bar-wrapper">
                <div class="perc-num" [ngStyle]="{'bottom': convertString(data.dataMap.page12.mentally)+'px' }">{{data.dataMap.page12.mentally}}%</div>
                <span class="perc-bar" [ngStyle]="{'height': addTwenty(data.dataMap.page12.mentally)+'px' }" style="background-color: #8cc09a;"></span>
                
              </div>
              <div class="perc-bar-border"></div>
              <div class="perc-bar-title">MENTALLY</div>
            </div>
            <div class="clear-both"></div>
          </div>

          <div class="perc-row twelveth-page-content12">
            How do you indulge? PHYSICALLY or MENTALLY
          </div>
        </div>

         <div class="clear-both"></div>
      </div>
     
      <div class="clear-both"></div>
    </div>
    <div class="twelveth-page-orientationimg width-100" style="margin-top: 10px;">
      <img src="assets/images/Page 12/PAGE-12-Hobbies-intro.jpg" style="width: 100%;">
    </div>
  </div>
  