import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-page32',
  templateUrl: './page32.component.html',
  styleUrls: ['./page32.component.css']
})
export class Page32Component implements OnInit {
  @Input() data: any;
  constructor() { }

  ngOnInit(): void {
  }

}
