import { Component, OnInit } from '@angular/core';
import { AnalystService } from '../../services/analyst.service';
import { Form, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CryptoService } from '../../crypto.service';

@Component({
  selector: 'app-analyst-create',
  templateUrl: './analyst-create.component.html',
  styleUrls: ['./analyst-create.component.css']
})
export class AnalystCreateComponent implements OnInit {
  analystModel: any = {};
  isError: boolean = false;
  isSuccess: boolean = false;
  isEdit: boolean = false;
  buttonText: string;
  successMsg: string;

  constructor(private analystService: AnalystService, private router: Router, private route: ActivatedRoute, private cryptoService: CryptoService) { }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get('id') != undefined) {
      this.isEdit = true;
      var model = { "id": this.route.snapshot.paramMap.get('id') }
      this.getAnalyst(model);
      this.buttonText = "Update";
    }
    else {
      this.buttonText = "Create";
    }

  }

  getAnalyst(dataModel) {

    var payload1 = JSON.stringify(dataModel).toString();
    var loginPayload = this.cryptoService.getEncryptedBody(localStorage.getItem("guid"), payload1);
    //console.log("encrypted model: ", loginPayload.toString());

    var model = {
      //"headers": { "x-shyld-app-id": congnitoIdEnc },
      "data": loginPayload.toString()
    }


    this.analystService.getAnalystById(model).subscribe((result: any) => {
      var res = JSON.parse(this.cryptoService.getDecryptedBody(localStorage.getItem('guid'), result.data));
      // console.log("result : ", res);
      var result = res.dataMap.data;
      this.analystModel.phone = result.analystPhone;
      this.analystModel.name = result.analystName;
      this.analystModel.email = result.analystEmail;
      //this.analystModel.analystId = result.analystId;
      this.analystModel.uname = result.analystUname;

    });
  }

  create() {
    this.analystModel.edit = this.isEdit;
    this.analystService.createAnalyst(this.analystModel).subscribe((result: any) => {
      var res = JSON.parse(this.cryptoService.getDecryptedBody(localStorage.getItem('guid'), result.data));
      if (res.status == "success") {

        if (this.isEdit) {
          this.successMsg = "Analyst updated successfully";
        }
        else {
          this.successMsg = "Analyst created successfully";
        }

        this.isSuccess = true;
        this.isError = false;
      }
      else {
        this.isSuccess = false;
        this.isError = true;
      }
    });
  }

  back() {
    this.router.navigate(['/analyst/analyst-list']);
  }
}
