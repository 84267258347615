<table class="mat-table cdk-table mat-elevation-z8" style="width: 100%;padding: 5%;">
    <tr>
        <td style="width: 25%;">
            <label>Dealer Id: </label>
        </td>
        <td>
            <label>{{clientModel.dealerId}}</label>
        </td>
    </tr>
    <tr>
        <td>
            <label>Dealer Client Id: </label>
        </td>
        <td>
            <label>{{clientModel.dealer_client_id}}</label>
        </td>
    </tr>
    <tr>
        <td>
            <label>Client Id: </label>
        </td>
        <td>
            <label>{{clientModel.clientId}}</label>
        </td>
    </tr>
    <tr>
        <td>
            <label>Client Name: </label>
        </td>
        <td>
            <label>{{clientModel.clientname}}</label>
        </td>
    </tr>
    <tr>
        <td>
            <label>Saved Date: </label>
        </td>
        <td>
            <label>{{clientModel.saveddate}}</label>
        </td>
    </tr>
    <tr>
        <td>
            <label>Gender: </label>
        </td>
        <td>
            <label>{{clientModel.gender}}</label>
        </td>
    </tr>
    <tr>
        <td>
            <label>DOB: </label>
        </td>
        <td>
            <label>{{clientModel.dob}}</label>
        </td>
    </tr>
    <tr>
        <td>
            <label>Age: </label>
        </td>
        <td>
            <label>{{clientModel.age}}</label>
        </td>
    </tr>
    <tr>
        <td>
            <label>Handiness: </label>
        </td>
        <td>
            <label>{{clientModel.handiness}}</label>
        </td>
    </tr>
    <tr>
        <td>
            <label>Nationality: </label>
        </td>
        <td>
            <label>{{clientModel.nationality}}</label>
        </td>
    </tr>
    <tr>
        <td>
            <label>Address: </label>
        </td>
        <td>
            <label>{{clientModel.address}}</label>
        </td>
    </tr>
    <tr>
        <td>
            <label>City: </label>
        </td>
        <td>
            <label>{{clientModel.city}}</label>
        </td>
    </tr>
    <tr>
        <td>
            <label>State: </label>
        </td>
        <td>
            <label>{{clientModel.state}}</label>
        </td>
    </tr>
    <tr>
        <td>
            <label>Country: </label>
        </td>
        <td>
            <label>{{clientModel.country}}</label>
        </td>
    </tr>
    <tr>
        <td>
            <label>Zipcode: </label>
        </td>
        <td>
            <label>{{clientModel.zipcode}}</label>
        </td>
    </tr>
    <tr>
        <td>
            <label>Occupation: </label>
        </td>
        <td>
            <label>{{clientModel.occupation}}</label>
        </td>
    </tr>
    <tr>
        <td>
            <label>Contact: </label>
        </td>
        <td>
            <label>{{clientModel.contact}}</label>
        </td>
    </tr>
    <tr>
        <td>
            <label>Email: </label>
        </td>
        <td>
            <label>{{clientModel.email}}</label>
        </td>
    </tr>
    <tr>
        <td>
            <label>Captured By: </label>
        </td>
        <td>
            <label>{{clientModel.capturedby}}</label>
        </td>
    </tr>
    <tr>
        <td>
            <label>Part In Research: </label>
        </td>
        <td>
            <label>{{clientModel.partinresearch}}</label>
        </td>
    </tr>
    <tr>
        <td>
            <label>Religion: </label>
        </td>
        <td>
            <label>{{clientModel.religion}}</label>
        </td>
    </tr>
    <tr>
        <td>
            <label>Cast: </label>
        </td>
        <td>
            <label>{{clientModel.cast}}</label>
        </td>
    </tr>
    <tr>
        <td>
            <label>Subcast: </label>
        </td>
        <td>
            <label>{{clientModel.subcast}}</label>
        </td>
    </tr>
    <tr>
        <td>
            <label>Birthplace: </label>
        </td>
        <td>
            <label>{{clientModel.birthplace}}</label>
        </td>
    </tr>
    <tr>
        <td>
            <label>Parent Name: </label>
        </td>
        <td>
            <label>{{clientModel.parentname}}</label>
        </td>
    </tr>
    <tr>
        <td>
            <label>Photo: </label>
        </td>
        <td>
            <img *ngIf="imageUrl" [src]="imageUrl" height="100px" width="100px" />
            <span style="font-size: 10px;" *ngIf="!imageUrl">Photo not uploaded.</span>
        </td>
    </tr>
    <tr>
        <td>
            <label>Archive: </label>
        </td>
        <td>
            <label>{{clientModel.isArchive}}</label>
        </td>
    </tr>
    <tr *ngIf="isCompleted">
        <td><label>Download Report: </label></td>
        <td>
            <a href="javascript:void(0)" (click)="download(clientModel.dealer_client_id)">
                <img src="assets/images/download.png" alt="download-link" style="width: 30px;">
                <span class="download-txt">{{clientModel.clientId}}.pdf</span>
            </a>
            

            <!-- <button mat-button style="background: #1e88e5;color: white;"
                (click)='download(clientModel.dealer_client_id)'>Download Report</button> -->

        </td>
    </tr>
    <tr>
        <td>
            <label *ngIf="isCompleted">
                Change Report:
            </label>
        </td>
        <td>
            <input style="display: block; margin-top: 25px;" type="file" id="file" (change)="handleFileInput($event)">
            <button mat-button style="background: #1e88e5;color: white; margin-top: 10px;"
                (click)='uploadFile(clientModel.clientId, clientModel.dealer_client_id)'>Upload Report</button>
        </td>
    </tr>
</table>