import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page41',
  templateUrl: './page41.component.html',
  styleUrls: ['./page41.component.css']
})
export class Page41Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
