<div class="thirtyone-page mt-40">
  <div class="text-right">
    <img class="logo-img" src="assets/images/Header.png" />
  </div>
  <div class="twelveth-page-bluebx mt-20">
    YOUR CHARACTER FACETS
  </div>
  <div class="mt-10">
    <div class="bx-row">
      <div class="thirtyone-page-sec">
        <div class="thirtyone-page-firstbx25">
          <div class="" style="padding: 0px 5px;">
            <span class="thirtyone-page-main"> Where do I tend to focus my attention?</span>
            <div class=" thirtyone-page-heading">INTROVERT</div>
            <div class="">
              <span class="gry-bx">
              </span>
              <div class="section-bx">Enjoy spending time in solitude. Need time alone to recharge & reflect.
              </div>
              <div class="clear-both"></div>
              <span class="gry-bx">
              </span>
              <div class="section-bx">Don't prefer to be the center of attention. Can be seen as reserved.
              </div>
              <div class="clear-both"></div>
              <span class="gry-bx">
              </span>
              <div class="section-bx">Value close one-on-one relationships. Think before speaking.
              </div>
              <div class="clear-both"></div>
              <span class="gry-bx">
              </span>
              <div class="section-bx">Prefer working in quiet, independent environments. Less talkative.
              </div>
              <div class="clear-both"></div>
              <span class="gry-bx">
              </span>
              <div class="section-bx">Deeply focus and think about specific interests.
              </div>
            </div>
          </div>
          <div class="clear-both"></div>
          <div class="mt-5" style="padding: 0px 5px;">
            <div class=" thirtyone-page-heading">EXTROVERT</div>
            <div class="">
              <span class="gry-bx">
              </span>
              <div class="section-bx">Gain energy from being around other people.
              </div>
              <div class="clear-both"></div>
              <span class="gry-bx">
              </span>
              <div class="section-bx">Enjoy being the center of attention. Have large social networks.
              </div>
              <div class="clear-both"></div>
              <span class="gry-bx">
              </span>
              <div class="section-bx">Tend to think out loud. Make quick decisions.
              </div>
              <div class="clear-both"></div>
              <span class="gry-bx">
              </span>
              <div class="section-bx">Outgoing, enthusiastic, and positive. Very talkative.
              </div>
              <div class="clear-both"></div>
              <span class="gry-bx">
              </span>
              <div class="section-bx">Thrive in team-oriented and open work settings.
              </div>
            </div>
          </div>
          <div class="clear-both"></div>
        </div>
        <div class="thirtyone-page-sec-bx" style="width: 22%;">
          <div class="employee-sec-width25">
            <div class="employee-num">{{ data.dataMap.page31.introvert }}%</div>
            <span class="emply-bar" [ngStyle]="{'height': addNinetyFour(data.dataMap.page31.introvert)+'px' }" style="background-color: #d4c4b4;"></span>
            <div class="emply-border"></div>
            <div class="employee-title">INTROVERT </div>
            <div class="clear-both"></div>
          </div>
          <div class="employee-sec-width25">
            <div class="employee-num">{{ data.dataMap.page31.extrovert }}%</div>
            <span class="emply-bar" [ngStyle]="{'height': addNinetyFour(data.dataMap.page31.extrovert)+'px' }" style="background-color: #e9b15a;"></span>
            <div class="emply-border"></div>
            <div class="employee-title">EXTROVERT</div>
            <div class="clear-both"></div>
          </div>
        </div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="clear-both"></div>
  </div>
  <div class="mt-10">
    <div class="bx-row">
      <div class="thirtyone-page-sec">
        <div class="thirtyone-page-firstbx25">
          <div class="" style="padding: 0px 5px;">
            <span class="thirtyone-page-main"> How do I tend to take in the information?</span>
            <div class=" thirtyone-page-heading">FACT</div>
            <div class="">
              <span class="gry-bx">
              </span>
              <div class="section-bx">Fact-based people are all about what they can rationalize, 
                understand, 
              </div>
              <div class="clear-both"></div>
              <span class="">
              </span>
              <div class="section-bx" style="padding-left: 10px;">measure & prove. Pay attention to physical reality.
              </div>
              <div class="clear-both"></div>
              <span class="gry-bx">
              </span>
              <div class="section-bx">Influenced by information, based on real occurrences which can be
              </div>
              <div class="clear-both"></div>
              <span class="">
              </span>
              <div class="section-bx" style="padding-left: 10px;">verified & supported by proofs, statistics, documentation, etc., and 
              </div>
              <div class="clear-both"></div>
              <span class="">
              </span>
              <div class="section-bx" style="padding-left: 10px;">can be tested by concrete evidence. Looks for actual, present & real.
              </div>
            </div>
          </div>
          <div class="clear-both"></div>
          <div class="" style="padding: 0px 5px;">
            <div class=" thirtyone-page-heading">OPINION</div>
            <div class="">
              <span class="gry-bx">
              </span>
              <div class="section-bx">Opinion-based people are all about feeling & believing in doing what 
              </div>
              <div class="clear-both"></div>
              <span class="">
              </span>
              <div class="section-bx" style="padding-left: 10px;">intuitively seems right to them. Pay attention to impressions & patterns. 
              </div>
              <div class="clear-both"></div>
              <span class="gry-bx">
              </span>
              <div class="section-bx">Influenced by feelings, thoughts, perceptions, desires, attitude, 
              </div>
              <div class="clear-both"></div>
              <span class="">
              </span>
              <div class="section-bx" style="padding-left: 10px;">experiences, understandings, beliefs, values, etc., which cannot be 
              </div>
              <div class="clear-both"></div>
              <span class="">
              </span>
              <div class="section-bx" style="padding-left: 10px;">tested by concrete evidence. Looks for patterns or abstract theories.
              </div>
            </div>
          </div>
          <div class="clear-both"></div>
        </div>
        <div class="thirtyone-page-sec-bx" style="width: 22%;">
          <div class="employee-sec-width25">
            <div class="employee-num">{{ data.dataMap.page31.fact }}%</div>
            <span class="emply-bar" [ngStyle]="{'height': addNinetyFour(data.dataMap.page31.fact)+'px' }" style="background-color: #f3aeb1;"></span>
            <div class="emply-border"></div>
            <div class="employee-title">FACT </div>
            <div class="clear-both"></div>
          </div>
          <div class="employee-sec-width25">
            <div class="employee-num">{{ data.dataMap.page31.opinion }}%</div>
            <span class="emply-bar" [ngStyle]="{'height': addNinetyFour(data.dataMap.page31.opinion)+'px' }" style="background-color: #91cff4;"></span>
            <div class="emply-border"></div>
            <div class="employee-title">	OPINION</div>
            <div class="clear-both"></div>
          </div>
        </div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="clear-both"></div>
  </div>
  <div class="mt-10">
    <div class="bx-row">
      <div class="thirtyone-page-sec">
        <div class="thirtyone-page-firstbx25">
          <div class="" style="padding: 0px 5px;">
            <span class="thirtyone-page-main"> How do I tend to make my decisions?</span>
            <div class=" thirtyone-page-heading">OBJECTIVE</div>
            <div class="">
              <div class="flex-row">
                <div class="flex-1">
                  <span class="gry-bx">
                  </span>
                </div>
                <div class="flex-10">
                  <div class="section-bx">Put more weight on objective principles & impersonal facts.
                  </div>
                </div>
              </div>
            
              <div class="flex-row">
                <div class="flex-1">
                  <span class="gry-bx">
                  </span>
                </div>
                <div class="flex-10">
                  <span class="section-bx">Like to find the basic truth or principle to be applied, regardless of the <br />specific situation involved. Focuses on inconsistencies.
                  </span>
                </div>
              </div>
             
              <div class="flex-row">
                <div class="flex-1">
                  <span class="gry-bx">
                  </span>
                </div>
                <div class="flex-10">
                  <div class="section-bx">Analyze pros & cons, and then be consistent & logical in deciding. 
                  </div>
                </div>
              </div>
              
             
              <div class="flex-row">
                <div class="flex-1">
                  <span class="gry-bx">
                  </span>
                </div>
                <div class="flex-10">
                  <div class="section-bx">Not influenced by personal wishes or other people's wishes. 
                  </div>
                </div>
              </div>
           
              
            </div>
          </div>
          <div class="clear-both"></div>
          <div class="" style="padding: 0px 5px;">
            <div class=" thirtyone-page-heading">SUBJECTIVE</div>
            <div class="">
              <span class="gry-bx">
              </span>
              <div class="section-bx">Put more weight on personal concerns and the people involved. 
              </div>
              <div class="clear-both"></div>

              <div class="flex-row">
                <div class="flex-1">
                  <span class="gry-bx">
                  </span>
                </div>
                <div class="flex-10">
                  <span class="section-bx">Consider what people care about & the points-of-view of persons involved <br/>in a situation. Focuses on communications.
                  </span>
                </div>
              </div>

              
              <span class="gry-bx">
              </span>
              <div class="section-bx">Concerned with values and what is the best for the people involved. 
              </div>
              <div class="clear-both"></div>
              <span class="gry-bx">
              </span>
              <div class="section-bx">Appear caring, warm, & tactful, maintaining harmony in relationships.
              </div>
            </div>
          </div>
          <div class="clear-both"></div>
        </div>
        <div class="thirtyone-page-sec-bx" style="width: 22%;">
          <div class="employee-sec-width25">
            <div class="employee-num">{{ data.dataMap.page31.objective }}%</div>
            <span class="emply-bar" [ngStyle]="{'height': addNinetyFour(data.dataMap.page31.objective)+'px' }" style="background-color: #b7c5c5;"></span>
            <div class="emply-border"></div>
            <div class="employee-title">OBJECTIVE  </div>
            <div class="clear-both"></div>
          </div>
          <div class="employee-sec-width25">
            <div class="employee-num">{{ data.dataMap.page31.subjective }}%</div>
            <span class="emply-bar" [ngStyle]="{'height': addNinetyFour(data.dataMap.page31.subjective)+'px' }" style="background-color: #8cc09a;"></span>
            <div class="emply-border"></div>
            <div class="employee-title">SUBJECTIVE</div>
            <div class="clear-both"></div>
          </div>
        </div>
      </div>
      <div class="clear-both"></div>
    </div>
    <div class="clear-both"></div>
  </div>
  <div class="clear-both"></div>

</div>