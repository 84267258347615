<div class="twenty-fourth-page mt-20">
    <div class="">
        <div class="text-right">
            <img class="logo-img" src="assets/images/Header.png" />
        </div>
        <div class="blue-header">
            INNER PERSONALITY
        </div>
        <div class="twenty-fourth-page-heading mt-10">
             Major Contributors for 16 Different Types of Inner Personalities
            </div>
        <div class="mt-5">
            <div class="twenty-fourth-page-sec" >
                <div class="" style=" padding-bottom: 5px;">
                    <div class="twenty-third-page-fs23" style="width: 100%;">
                        <span style="color: #35b2c7; font-weight: bold;">Carl Gustav Jung:</span> A Swiss Psychiatrist, Psychoanalyst & Research scientist who gave enormous 
                        contributions in the field of Psychiatry, Anthropology, Archaeology, Literature,
                         Philosophy and Religious studies, in his famous book 'Psychological Types,
                          outlined personalities as TYPES, where he explains how a person can be-
                    </div>
                   </div>
            </div>
            <div class="twenty-third-page-first-box">
                <img style="height: 100px;" src="assets/images/Page 24/PAGE-24-Inner-personality-intro.jpg">
            </div>
            <div class="clear-both"></div>
        </div>
        <div class="text-center twenty-fourth-page-types" >
            <div class="mt-5 twenty-fourth-page-bx">
                <div class="twenty-third-page-careeroption"  >
                    E
                </div>
                <div class="twenty-third-page-careeroption1" >
                    Extraversion
                </div>
                <div class="section5">
                   vs
                </div>
                <div class="twenty-third-page-careeroption">
                    I
                </div>
                <div class="twenty-third-page-careeroption1">
                    Introversion 
                  </div>
                  
                <div class="clear-both"></div>
              </div>
              <div class="mt-10 twenty-fourth-page-bx">
                <div class="twenty-third-page-careeroption"  >
                   S
                </div>
                <div class="twenty-third-page-careeroption1" >
                   Sensing
                </div>
                <div class="section5">
                   vs
                </div>
                <div class="twenty-third-page-careeroption">
                    N
                </div>
                <div class="twenty-third-page-careeroption1">
                    Intuition 
                  </div>
                  
                <div class="clear-both"></div>
              </div>
              <div class="mt-10 twenty-fourth-page-bx">
                <div class="twenty-third-page-careeroption"  >
                   T
                </div>
                <div class="twenty-third-page-careeroption1" >
                    Thinking
                </div>
                <div class="section5">
                   vs
                </div>
                <div class="twenty-third-page-careeroption">
                    F
                </div>
                <div class="twenty-third-page-careeroption1">
                    Feeling 
                  </div>
                  
                <div class="clear-both"></div>
              </div>
              <div class="mt-10 twenty-fourth-page-bx">
                <div class="twenty-third-page-careeroption"  >
                  J
                </div>
                <div class="twenty-third-page-careeroption1" >
                   Judging
                </div>
                <div class="section5">
                   vs
                </div>
                <div class="twenty-third-page-careeroption">
                    P
                </div>
                <div class="twenty-third-page-careeroption1">
                    Perceiving 
                  </div>
                  
                <div class="clear-both"></div>
              </div>
              <div class="clear-both"></div>
        </div>
        <div class="clear-both"></div>
        <div class="mt-20">
            <div class="twenty-fourth-page-sec" >
                <div class="" style=" padding-bottom: 5px;">
                    <div class="twenty-third-page-fs23" style="width: 100%;">
                        <span style="color: #35b2c7; font-weight: bold;">Myers Briggs Type Indicator(MBTI):</span>
                        Carl Jung's theory was later extended
                        and modified to MBTI, a theory worked out by two Americans, 
                        Katherine Cook Briggs and her daughter Isabel Briggs Myers who 
                        outlined 16 Unique Types believing that every person has one preferred 
                        quality from each category mentioned above.

                    </div>
                   </div>
            </div>
            <div class="twenty-third-page-first-box">
                <img style="height: 100px;" src="assets/images/Page 24/PAGE-24-Inner-personality1.jpg">
            </div>
            <div class="clear-both"></div>
        </div>
        <div class="mt-10">
            <div class="twenty-fourth-page-sec" >
                <div class="" style=" padding-bottom: 5px;">
                    <div class="twenty-third-page-fs23" style="width: 100%;">
                        <span style="color: #35b2c7; font-weight: bold;">David keirsey:</span>
                        An American psychologist revised and expanded the MBTI theory where he 
                        first linked human behavior patterns to 4 Temperaments before categorizing 
                        it into 16 character types. He laid out self- assessed personality questionnaire 
                        known as the 'Keirsey Temperament Sorter.

                    </div>
                   </div>
            </div>
            <div class="twenty-third-page-first-box">
                <img style="height: 100px;" src="assets/images/Page 24/PAGE-24-Inner-personality2.jpg">
            </div>
            <div class="clear-both"></div>
        </div>
        <div class="mt-10" style="background-color: #f2f2f2;">
            <div class="Research-analysis-sec">
                <div class="text-center twenty-fourth-page-Research">BRAINOGRAPHY RESEARCH ANALYSIS</div>
                <div class="Rsa-1">Over the years, different Psychologists & Enthusiasts explored the 
                Jung's basic 8 types of personalities in their own theories, sometimes 
                appreciated & applauded and sometimes rejected & labelled as Pseudoscience 
                with poor reliability.</div>
                <div class="Rsa-1">We at BRAINOGRAPHY firmly believe that since every person 
                    is unique, it is impossible to categorize or classify a huge population of 
                    more than 7.5 billion people according to a fixed single personality out of 
                    just 8 or 16 TYPES. Therefore, after tremendous research and interviews we have 
                    formulated for first time a PERSONALITY METER. We have succeeded in identifying 
                    all the 8 traits (E/I, S/N, T/F & J/P) in a person by fingerprint analysis and 
                    also the proportions of these 8 traits present in the person.</div>
            </div>
            <div class="clear-both"></div>
        </div>
        <div class="text-center twenty-fourth-page-Research mt-20">
            BRAINOGRAPHY CONCLUSION
        </div>
        <div class="clear-both"></div>
        <div class="grey-header mt-10">
            THE ENTIRE WORLD POPULATION CANNOT BE DIVIDED INTO JUST 16 TYPES OF FIXED PERSONALITIES
        </div>
        <div class="Rsa-1">
            No two Personalities are ever exactly alike. Every persons Inner Personality 
            is unique mix of Jung's 8 classified personality traits in different proportions, 
            thus Inner personalities are variable instead of fixed. Check your PERSONALITY 
            METER to identify your closest match from the 16 INNER PERSONALITIES.
            </div>
    </div>
</div>