import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatDialog, MatTableDataSource, MatSort } from '@angular/material';
import { ClientService } from '../services/client.service';
import { Router } from '@angular/router';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { CryptoService } from '../crypto.service';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.css']
})
export class ClientListComponent implements OnInit {
  pageNo: number = 1;
  pageSize: number = 10;
  totalRecord: number = 0;

  clientModel: any = {}
  displayedColumns: string[] = ['dealer_client_id', 'clientId', 'clientname', 'email', 'contact', 'action'];
  dataSource: any;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private clientService: ClientService, private route: Router, private dialog: MatDialog, private SpinnerService: NgxSpinnerService, private cryptoService: CryptoService) { }

  ngOnInit(): void {
    this.getClientList();
  }


  getClientList() {
    this.SpinnerService.show();
    this.clientService.getCompletedReport(this.pageNo, this.pageSize).subscribe((result: any) => {
      var res = JSON.parse(this.cryptoService.getDecryptedBody(localStorage.getItem('guid'), result.data));
      this.dataSource = new MatTableDataSource(res.dataMap.clients);
      this.dataSource.sort = this.sort;
      this.totalRecord = res.dataMap.totalRecords;
      this.SpinnerService.hide();
    });
  }

  pageChanged(event) {
    this.pageNo = event.pageIndex + 1;
    this.pageSize = event.pageSize;

    this.getClientList();
  }

  viewWorkpad(clientId, userId, sequenceClientId) {
    this.route.navigate(['/workpad/load-workpad', clientId, userId, sequenceClientId]);
  }

  viewDetail(clientId, delaerClientId) {
    this.route.navigate(['/client/client-detail', clientId, delaerClientId, 'completed']);
  }

  download(elem) {
    this.SpinnerService.show();
    var dataModel = {
      "dealer_client_id": elem.dealer_client_id
    }

    var payload1 = JSON.stringify(dataModel).toString();
    var loginPayload = this.cryptoService.getEncryptedBody(localStorage.getItem("guid"), payload1);
    //console.log("encrypted model: ", loginPayload.toString());

    var model = {
      //"headers": { "x-shyld-app-id": congnitoIdEnc },
      "data": loginPayload.toString()
    }

    this.clientService.downloadReport(model).subscribe((result: any) => {
      var res = JSON.parse(this.cryptoService.getDecryptedBody(localStorage.getItem('guid'), result.data));
      if (res.status == "success") {
        const linkSource = res.dataMap.reportFile;
        const downloadLink = document.createElement("a");
        //const fileName = "sample.pdf";
        const fileName = res.dataMap.fileName;
        downloadLink.setAttribute("target", "_blank");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        this.SpinnerService.hide();
      }
      else {
        alert("Report is not available");
        this.SpinnerService.hide();
      }
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
}
