import { Component, OnInit, ViewChild } from '@angular/core';
import { AnalystService } from '../../services/analyst.service';
import { MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { CryptoService } from '../../crypto.service';

@Component({
  selector: 'app-analyst-list',
  templateUrl: './analyst-list.component.html',
  styleUrls: ['./analyst-list.component.css']
})
export class AnalystListComponent implements OnInit {

  pageNo: number = 1;
  pageSize: number = 10;
  totalRecord:number = 0;

  analystModel: any = {}
  displayedColumns: string[] = ['analystName', 'analystPhone', 'analystEmail', 'analystUname', 'action'];
  dataSource: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private analystService: AnalystService, private route: Router, private dialog: MatDialog, private cryptoService: CryptoService) { }

  ngOnInit(): void {
    this.getAnalystList();
  }

  getAnalystList() {
    this.analystModel = {
      "offset": this.pageNo,
      "limit": this.pageSize
    }

    var payload1 = JSON.stringify(this.analystModel).toString();
    var loginPayload = this.cryptoService.getEncryptedBody(localStorage.getItem("guid"), payload1);
    //console.log("encrypted model: ", loginPayload.toString());

    var model = {
      //"headers": { "x-shyld-app-id": congnitoIdEnc },
      "data": loginPayload.toString()
    }


    this.analystService.getAnalystList(model).subscribe((result: any) => {
      var res = JSON.parse(this.cryptoService.getDecryptedBody(localStorage.getItem('guid'), result.data));
      console.log("result : ", res);
      this.dataSource = new MatTableDataSource(res.dataMap.list);
      this.totalRecord = res.dataMap.total;
    });
  }

  pageChanged(event) {
    console.log("page change event : ", event);
    this.pageNo = event.pageIndex + 1;
    this.pageSize = event.pageSize;

    this.getAnalystList();
  }

  addAnalyst() {
    this.route.navigate(['/analyst/analyst-create']);
  }

  edit(elem) {
    this.route.navigate(['/analyst/analyst-edit', elem.analystId]);
  }

  delete(elem) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: { id: elem.analystId }
    });

    dialogRef.afterClosed().subscribe(result => {

      var dataModel = { "id": result }

      var payload1 = JSON.stringify(dataModel).toString();
      var loginPayload = this.cryptoService.getEncryptedBody(localStorage.getItem("guid"), payload1);
      //console.log("encrypted model: ", loginPayload.toString());
  
      var model = {
        //"headers": { "x-shyld-app-id": congnitoIdEnc },
        "data": loginPayload.toString()
      }
  

      this.analystService.deleteAnalyst(model).subscribe((res: any) => {
        //this.dataSource = new MatTableDataSource(res.dataMap.list);
        this.getAnalystList();
      });
    });

  }
}
