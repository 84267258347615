<div class="forty-seven-page mt-40">
    <div class="text-right">
        <img class="logo-img" src="assets/images/Header.png" />
    </div>

    <div class="blue-header">
        FORWARD
    </div>

    <div class="mt-10 flex-row">
        <div class="flex-1 photo-bg">
            <img src="assets/images/Page 47/page47-kamlesh.PNG" />
            <div class="text-bold" style="font-size: 12px;">Brainographer</div>
            <div class="text-bold" style="margin-top: 3px; font-size: 16px;">KAMLESH SHETH</div>
            <div style="margin-top: 2px; font-style: italic; font-size: 12px;">Chief Executive Officer</div>
        </div>
        <div class="flex-2 content-bg text-justify">
            I like to thank my mentors who pointed me in the right direction as I was searching for
             answers to my questions and helped to sharpen my critical thinking skills. I marvel and respect the great thinkers in
              the field of 'Dermatoglyphics', who came before me and upon whose work I have been able to develop 'BRAINOGRAPHY'. 
              Many thanks to my Mother, Father, Wife, Children, Family & Friends for their love, encouragement, and for helping me
               to become who I am. I would like to gratefully acknowledge the entire '10 LOBES BRAINOGRAPHY' team, 
               especially Vishal Saraiya, Krunal Kakaiya, Darshak Doshi, & Dr. Priya Kurle. 
               Thank you for your encouragement, patience, forbearance, and hard work. The days we have spent creating 
               'BRAINOGRAPHY' will always be cherished memories. 
        </div>
    </div>

    <div class="mt-10 flex-row">
        <div class="flex-1 photo-bg">
            <img src="assets/images/Page 47/page47-vishal.PNG" />
            <div class="text-bold" style="font-size: 12px;">Brainographer</div>
            <div class="text-bold" style="margin-top: 3px; font-size: 16px;">VISHAL SARAIYA</div>
            <div style="margin-top: 2px; font-style: italic; font-size: 12px;">Chief Operating Officer</div>
        </div>
        <div class="flex-2 content-bg text-justify">
            I express my gratitude to Param Pujya Hariprasad Swamiji for blessing me with all the success I got in my life. 
            'ACADEMY OF BRAINOGRAPHY' is dedicated to all my teachers and my students who are also my teachers. 
            I feel obliged to share my knowledge, analyses, and conclusions. I shall always remain indebted 
            to all my mentors- "The Maha Gurus" of Dermatoglyphics Science, for training & guiding me. 
            I feel blessed to have a wonderful family, who always stood by me in my testing times. I also thank Rohan Somaiya, 
            Dhruv Mehta, Dr. Shailesh Barot & Mukesh Joshi for inspiring me & encouraging me to choose the right path. 
            Last but not least, I thank Kamlesh Sheth & the entire '10 LOBES BRAINOGRAPHY' team, especially Darshak Doshi. 
            Without your support, the project 'BRAINOGRAPHY' would not be possible 
        </div>
    </div>

    <div class="mt-10 blue-header">
        BRAINOGRAPHY CORE TEAM 
    </div>

    <div class="mt-10 flex-row">
        <div style="flex: 2; padding: 5px;" class="flex-1 photo-bg">
            <img src="assets/images/Page 47/page47-priya.PNG" />
            <div class="text-bold" style="font-size: 12px;">Brainographer</div>
            <div class="text-bold" style="margin-top: 3px;">Dr. PRIYA KURLE </div>
        </div>
        <div style="flex: 3; margin-right: 10px; padding: 5px; font-size: 13px; line-height: 18px;" class="flex-2 content-bg">
            Dr. Priya Kurle is a professional Psychologist (PHD) with 11 years of work experience in career guidance & overseas education counselling. She is the country representative for Eastern Michigan University. Prior to that she worked with The Columbia University, Ohio State University & London School of Business and Fina-nce. 
        </div>

        <div style="flex: 2; padding: 5px;" class="flex-1 photo-bg">
            <img src="assets/images/Page 47/page47-darshak.PNG" />
            <div class="text-bold" style="font-size: 12px;">Brainographer</div>
            <div class="text-bold" style="margin-top: 3px;">DARSHAK DOSHI </div>
        </div>
        <div style="flex: 3; padding: 5px; font-size: 13px; line-height: 18px;" class="flex-2 content-bg">
            Mr Darshak Doshi (B-tech in Computer Science)- Director & Co-founder of Intellify World-wide Pvt. Ltd. He has 8 years of experience in Artificial Intelli-gence, Machine Learning, Data Science & Mixed Reality. Mr Darshak has designed and developed the Algorithm & Software for 'BRAINOGRA-PHY'. 
        </div>

    </div>

    <div class="mt-10 flex-row">
        <div style="flex: 2; padding: 5px;" class="flex-1 photo-bg">
            <img src="assets/images/Page 47/page47-sameer.PNG" />
            <div class="text-bold" style="font-size: 12px;">Brainographer</div>
            <div class="text-bold" style="margin-top: 3px;">Dr. SAMEER Z.</div>
        </div>
        <div style="flex: 3; margin-right: 10px; padding: 5px; font-size: 13px; line-height: 18px;" class="flex-2 content-bg">
            Dr. Samir Zaparde is a Physician, MBBS & MD. Completed Post Doc. Regen-erative Medicine from USA. Having 13 years of experience in various medical fields. Dr. Samir has deep interest in the field of Dermatoglyphics and invests great amount of time in child/ students counseling 
        </div>

        <div style="flex: 2; padding: 5px;" class="flex-1 photo-bg">
            <!-- <img src="assets/images/Page 47/page47-raj.PNG" /> -->
            <img [attr.src]="data.dataMap.page46.profile_pic" />
            <div class="text-bold" style="font-size: 12px;">{{data.dataMap.page46.title}}</div>
            <div class="text-bold" style="margin-top: 3px;">{{data.dataMap.page46.brainographer}}</div>
        </div>
        <div style="flex: 3; padding: 5px; font-size: 13px; line-height: 18px;" class="flex-2 content-bg">
            {{data.dataMap.page46.description}}
            <!-- Mr. Raj Singh, Director of Punon Technologies Pvt. Ltd. is a tech expert. He has 10 years of experience in telecommuni-cation and software industry. He provides online learning plat-form to school, colleges and universities in Asia. Mr Raj is the promoter of the project.  -->
        </div>

    </div>

</div>