<div *ngIf="footerData" style="margin-top: 5px; margin-left: 40px;">
    <div class="footer-elem">
      Evaluation# {{ footerData.footer.evalNo }}
    </div>
    <div class="footer-elem2 text-left">
      Date {{ footerData.footer.date }}
    </div>
    <div class="footer-elem1">
      @Copyright - Siyasaar Enterprise
    </div>
    <div class="footer-elem">
      www.brainography.com
    </div>
    <div class="footer-elem">
      Design By Freepik
    </div>

    <div class="clear-both"></div>
  </div>