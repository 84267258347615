<table width="100%" border="0" cellspacing="0" cellpadding="0">
  <tr>
    <td>
      <table width="100%" border="0" cellspacing="0" cellpadding="0" class="thumbtable">
        <tr>
          <td width="15%" rowspan="3" valign="top">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tr>
                <td>Client Name: <strong>{{clientName}}<br />
                    Client ID : <strong>{{clientId}}<br />
                    </strong></strong></td>
              </tr>
              <tr>
                <td class="thumbimages">
                  <table width="100%" border="0" cellspacing="0" cellpadding="0" class="rightTable">
                    <div *ngFor="let rightHand of rightHandData; let i = index">
                      <tr>
                        <td>R{{i+1}}
                          <span class="backup-class" *ngIf="rightHand.isBackup == 'YES'">B</span>
                          <span class="reverse-class" *ngIf="rightHand.isReverse == 'YES'">R</span>
                        </td>
                        <td>{{rightHand.fpp}}</td>
                        <td>{{rightHand.rc}}</td>
                      </tr>
                      <tr>
                        <td width="33%" *ngFor="let rightHandImg of rightHand.fingerPrintList; let ii=index">
                          <img src="data:image/png;base64,{{rightHandImg.base64}}" width="100%" style="cursor: pointer;"
                            [ngClass]="{ 'thumbnail-border': rightHandImg.selected == true}"
                            (click)="addImageToContainer(rightHandImg.base64, i, rightHand.hand, rightHand.fingerIdentity, rightHandImg.nameFinger, $event, ii, rightHandImg, rightHand.isBackup, rightHand)" />
                        </td>
                      </tr>
                    </div>
                  </table>
                </td>
              </tr>

            </table>
          </td>
          <td colspan="2" valign="top" class="imagehead">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tr>
                <!-- <td class="button pink" style="cursor: pointer;" (click)="removeLeftImage()"></td> -->
                <td style="width: 430px;">
                  RC: <strong style="margin-right: 10px;">{{leftridgeCount}}</strong>
                  Fingure: <strong>{{leftFingure}}</strong>
                </td>
                <td>
                  RC: <strong style="margin-right: 10px;">{{rightridgeCount}}</strong>
                  Fingure: <strong>{{rightFingure}}</strong>
                </td>
                <!-- <td class="button pink" style="cursor: pointer;" (click)="removeRightImage()"></td> -->
              </tr>
            </table>
          </td>
          <td width="15%" rowspan="3" valign="top">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tr>
                <td>Client Name: <strong>{{clientName}}<br />
                    Client ID : <strong>{{clientId}}</strong></strong></td>
              </tr>
              <tr>
                <td class="thumbimages">
                  <table width="100%" border="0" cellspacing="0" cellpadding="0" class="leftTable">
                    <div *ngFor="let leftHand of leftHandData; let i = index">
                      <tr>
                        <td>L{{i+1}}
                          <span class="backup-class" *ngIf="leftHand.isBackup == 'YES'">B</span>
                          <span class="reverse-class" *ngIf="leftHand.isReverse == 'YES'">R</span>
                        </td>
                        <td>{{leftHand.fpp}}</td>
                        <td>{{leftHand.rc}}</td>
                      </tr>
                      <tr>
                        <td width="33%" *ngFor="let leftHandImg of leftHand.fingerPrintList; let ii = index">
                          <img src="data:image/png;base64,{{leftHandImg.base64}}" width="100%" style="cursor: pointer;"
                          [ngClass]="{ 'thumbnail-border': leftHandImg.selected == true}"
                            (click)="addImageToContainer(leftHandImg.base64, i, leftHand.hand, leftHand.fingerIdentity, leftHandImg.nameFinger, $event, ii, leftHandImg, leftHand.isBackup, leftHand)" />
                        </td>
                      </tr>
                    </div>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td width="35%" align="center" valign="top" style="height: 470px; position: relative;">
            <div *ngIf="mainImage1" style="text-align: right;">
              <span (click)="removeLeftImage()" class="close-icon">X</span>
            </div>
            <pinch-zoom [properties]="myProperties" class="canvas1" style="cursor: auto !important;">
              <canvas #canvas1 (click)="getCordinatesforpoint($event,true);"
                style="width: 462px!important;height: 470px!important;cursor: auto !important;">
                <img id="img1" src="{{mainImage1}}" width="100%" />
              </canvas>
            </pinch-zoom>
          </td>
          <td width="35%" align="center" valign="top" style="height: 470px; position: relative;">
            <div *ngIf="mainImage2" style="text-align: right;">
              <span (click)="removeRightImage()" class="close-icon">X</span>
            </div>
            <pinch-zoom [properties]="myProperties" style="cursor: auto !important;" class="canvas2">
              <canvas #canvas2 (click)="getCordinatesforpoint($event,false);"
                style="width: 462px!important;height: 470px!important;cursor: auto !important;">
                <img id="img2" src="{{mainImage2}}" width="100%" />
              </canvas>
            </pinch-zoom>
          </td>
        </tr>

        <tr>
          <td valign="top">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tr>
                <td width="50%">
                  <mat-slide-toggle [checked]="isLeftDot" (change)="enableLeftDot($event)" style="font-size: 14px;margin-left: 5px;" tabindex="0">Place Dot
                  </mat-slide-toggle>
                </td>
                <td class="button green" (click)="drawLine(true)" style="cursor: pointer;">DRAW A LINE</td>
              </tr>
              <tr>
                <td colspan="2">
                  <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tr>
                      <td style="width: 225px;">
                        <!-- <mat-slide-toggle [checked]="isReverse" (change)="onReverseChange($event)" [disabled]="disabled"
                          style="font-size: 14px;margin-right: 10px;">Reverse</mat-slide-toggle> -->
                        <mat-slide-toggle [checked]="isBackup" (change)="onBackupChange($event)" [disabled]="disabled"
                          style="font-size: 14px;">Backup</mat-slide-toggle>
                      <td style="width: 225px;">
                        <input type="text" tabindex="0" placeholder="F.P.P" style="height: 25px; width: 200px"
                          [(ngModel)]='finalObj.fpp' [ngModelOptions]="{standalone:true}" (keyup)="fppChange()"
                          (ngModelChange)="finalObj.fpp = $event.toUpperCase()" [disabled]="disabled" required /></td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
          <td valign="top">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tr>
                <td class="button green" (click)="drawLine(false)" style="cursor: pointer;">DRAW A LINE</td>
                <td width="50%" align="right">
                  <mat-slide-toggle [checked]="isRightDot" (change)="enableRightDot($event)" style="font-size: 14px;padding-right: 10px;">Place
                    Dot</mat-slide-toggle>
                </td>
              </tr>
              <tr>
                <td colspan="2" class="button" style="cursor: pointer;" (click)="drawLine(false)">
                  <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tr>
                      <td style="width: 225px; border: none;"><input type="number" tabindex="1" placeholder="TRC"
                          style="height: 25px; width: 200px" [(ngModel)]='finalObj.rc'
                          [ngModelOptions]="{standalone:true}" numbersonly="numbersOnly" [disabled]="disabled" /></td>

                      <td style="border: none;">                       
                       <button class="button green save-btn" [disabled]="disabled" (click)="saveData()">Save

                        <i *ngIf="saveLoading" class="fa fa-spinner fa-spin"></i>
                       </button>
                      </td>
                      
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>

        <tr>
          <td style="border: none;">

          </td>
          <td align="center" style="padding: 10px;" colspan="2">
            <a class="lactoseLink" (click)="openDialog()">
              Lactose & Gluten
            </a>

            <div *ngIf="!isArcDisabled" style="display: inline-block; margin: 0 5px;">
              <button type="button" class="button" (click)="arcCalculation()"
              style="border: none; font-size: 14px; cursor: pointer; padding: 8px 20px; text-transform: capitalize; background: #82768e;
              border-radius: 15px;"> ARC 
              <i *ngIf="arcLoading" class="fa fa-spinner fa-spin"></i>  
              </button>
            </div>

            <div *ngIf="isArcDisabled" class="button disBtn" style="display: inline-block; margin: 0 5px;">
              <button type="button" class="button" style="border: none; font-size: 14px; background: #82768e; cursor: not-allowed;
               padding: 8px 20px; border-radius: 15px; text-transform: capitalize; opacity: 0.5;" disabled> ARC
               </button>
            </div>

            <div class="button" style="display: inline-block; margin: 0 5px;">
              <a *ngIf="isArcDisabled && (lactose == -1 && gluten == -1)" href="javscript:void(0)" class="run-btn-disabled">
                Run
              </a>
             
              <a *ngIf="lactose != -1 && gluten != -1" class="run-btn" 
              href="#/report/{{dealerClientId}}/{{clientIdURL}}/{{sequenceClientId}}" target="_blank">Run</a>
              
              
            </div>

          </td>
        </tr>
      </table>
    </td>
  </tr>
</table>
