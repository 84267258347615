import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-page16',
  templateUrl: './page16.component.html',
  styleUrls: ['./page16.component.css']
})
export class Page16Component implements OnInit {
  @Input() data: any;
  constructor() { }

  ngOnInit(): void {
    // this.data.dataMap.page16.winner = ['sp_1', 'sp_6', 'sp_44'];
  }

  isWinner(value: string, array: string[] | undefined): boolean {
    return array && array.indexOf(value) !== -1;
  }

}
