import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Common } from '../model/common.model';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  common: Common;
  reqHeader = new HttpHeaders({ 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, HEAD, OPTIONS', 'guid':btoa(localStorage.getItem('guid')) });

  constructor(private http: HttpClient) {
    this.common = new Common();
  }

  getClientList(model) {
    return this.http.post(this.common.APIUrl + '/admin/getUsersList', model, { headers: this.reqHeader });
  }

  getCompletedReport(pageNo, pageSize) {
    var reqHeader = new HttpHeaders({ 'guid':btoa(localStorage.getItem('guid')) });
    return this.http.get(this.common.APIUrl + '/completedclientreports?pageno=' + pageNo + '&pagesize=' + pageSize, { headers: reqHeader});
  }

  getPendingReport(pageNo, pageSize) {
    var reqHeader = new HttpHeaders({ 'guid':btoa(localStorage.getItem('guid')) });
    return this.http.get(this.common.APIUrl + '/pendingclientreports?pageno=' + pageNo + '&pagesize=' + pageSize, { headers: reqHeader});
  }

  downloadReport(model) {
    return this.http.post(this.common.APIUrl + '/downloadreports', model, { headers: this.reqHeader });
  }

  getClientDetail(model) {
    return this.http.post(this.common.APIUrl + '/getclientdetails', model, { headers: this.reqHeader });
  }

  uploadReport(model)
  {
    return this.http.post(this.common.APIUrl + '/uploadreport', model, { headers: this.reqHeader });    
  }

  uploadReportwithURL(URL, model)
  {
    return this.http.put(URL, model, { headers: this.reqHeader });    
  }

  addClientDetails(model) {
    return this.http.post(this.common.APIUrl + '/addclient', model, { headers: this.reqHeader });
  }

  addClientFingerPrintDetails(model) {
    return this.http.post(this.common.APIUrl + '/addfingerprints', model, { headers: this.reqHeader });
  }
}
