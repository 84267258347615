import { Injectable } from '@angular/core';
import { Common } from '../model/common.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  common: Common;

  constructor(private http: HttpClient) {
    this.common = new Common();
  }

  login(model, congnitoIdEnc) {
    var reqHeader = new HttpHeaders({ 'guid':congnitoIdEnc });
    return this.http.post(this.common.APIUrl + '/adminlogin', model, { headers: reqHeader, observe: 'response' });
  }

}
