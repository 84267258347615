<div class="twenty-fifth-page mt-20">
    <div class="text-right">
        <img class="logo-img" src="assets/images/Header.png" />
    </div>

    <div class="main-content">
        <div class="flex-row">
            <div [ngClass]="{ 'highlight' : data.dataMap.page25.winner == 'exponent' }" class="pink-section">
                <div class="flex-row">
                    <div class="pink-heading">EXPONENT</div>
                    <div class="pink-perc">{{ data.dataMap.page25.exponent }}%</div>
                </div>
                <div class="pink-body">
                    Value tradition & order. Respect honesty & dedication both in themselves & others. Practical decision-makers,
                    looking for ways to quickly & effectively see results. Highly Organized & logical, good at both creating & implementing plans. 
                    Do not hesitate to take tough decisions & work towards bringing others together fora common purpose. 
                </div>
            </div>

            <div [ngClass]="{ 'highlight' : data.dataMap.page25.winner == 'benevolent' }" class="pink-section">
                <div class="flex-row">
                    <div class="pink-heading">BENEVOLENT</div>
                    <div class="pink-perc">{{ data.dataMap.page25.benevolent }}%</div>
                </div>
                <div class="pink-body">
                    Conventional & grounded, strive to uphold established structures. Create & maintain orderly environments. Strong work ethic that includes serving others & dedicated to their duties. Conscientious & metho-dical workers who are not satisfied until the job is done. Always ready to defend loved ones. 
                </div>
            </div>

            <div [ngClass]="{ 'highlight' : data.dataMap.page25.winner == 'indulgent' }" class="pink-section">
                <div class="flex-row">
                    <div class="pink-heading">INDULGENT</div>
                    <div class="pink-perc">{{ data.dataMap.page25.indulgent }}%</div>
                </div>
                <div class="pink-body">
                    Serious & practical, committed to their responsibilities, and sensitive to the needs of others. Strive for harmony and are sociable, generous with their time, efforts, & emotions, eager to please people around. Value loyalty & tradition and hold to a strict moral code. Like to follow regular schedule to stay organized & productive. 
                </div>
            </div>

            <div [ngClass]="{ 'highlight' : data.dataMap.page25.winner == 'jubilant' }" class="pink-section">
                <div class="flex-row">
                    <div class="green-heading">JUBILANT</div>
                    <div class="pink-perc">{{ data.dataMap.page25.jubilant }}%</div>
                </div>
                <div class="green-body">
                    Outgoing, friendly & generous. Enjoy spending time around others, spreading excitement & joy both at home & workplace. While practical & realistic in their work, they also value having fun achieving their goals. Highly enthusiastic & flexible, encou-raging others along the way. Spontaneous & energetic, hardly a dull moment around. 
                </div>
            </div>

            <div [ngClass]="{ 'highlight' : data.dataMap.page25.winner == 'empiric' }" class="pink-section">
                <div class="flex-row">
                    <div class="green-heading">EMPERIC</div>
                    <div class="pink-perc">{{ data.dataMap.page25.empiric }}%</div>
                </div>
                <div class="green-body">
                    Friendly & quiet, enjoy observi-ng & absorbing the environment around them. Prefer autonomy, working in their own space & completing tasks on their own schedule. Value loyalty & commitment in their personal relationships. Maintains harmony & avoid confron-tations. Artistic, creative & Passionate 
                </div>
            </div>

            <div [ngClass]="{ 'highlight' : data.dataMap.page25.winner == 'explorer' }" class="pink-section">
                <div class="flex-row">
                    <div class="green-heading">EXPLORER</div>
                    <div class="pink-perc">{{ data.dataMap.page25.explorer }}%</div>
                </div>
                <div class="green-body">
                    Energetic, pragmatic & entre-preneurial. Eagerly search for fast results, often taking risks to come up with the best solutions. Acts firsts, thinks later, fixing & adapting processes as they go. Enjoy a fast-paced lifestyle by living "in the moment". Highly perceptive and eager to socialize with groups of people. 
                </div>
            </div>

        </div>

        <div class="flex-row mt-5">
            <div class="second-flex-row">
                <div [ngClass]="{ 'highlight' : data.dataMap.page25.winner == 'pragmatist' }" class="pink-section" >
                    <div class="flex-row">
                        <div class="pink-heading">PRAGMATIST</div>
                        <div class="pink-perc">{{ data.dataMap.page25.pragmatist }}%</div>
                    </div>
                    <div class="pink-body" style="min-height: 140px;">
                        Thrive on logical decision-making, accomplishing tasks in an orderly & organized fashion. Practical with a plan, whose reliability cannot be doubted. Enjoy a neat & organized space, both at home & at work. Value traditions, loyalty & order, making it sometimes difficult for them to be flexible during times of change.  
                    </div>
                </div>

                <div [ngClass]="{ 'highlight' : data.dataMap.page25.winner == 'strategist' }" class="pink-section mt-5" >
                    <div class="flex-row">
                        <div class="blue-heading">STRATEGIST</div>
                        <div class="pink-perc">{{ data.dataMap.page25.strategist }}%</div>
                    </div>
                    <div class="blue-body" style="padding-bottom: 10px;">
                        Imaginative with a plan for everything. Driven by gaining & utilizing knowledge. Future oriented, focuses on 'Big picture'. Highly confident, seek to reform & improve the world around them. Uncomfortable in large groups or among people they don't know well. Prefer to discuss ideas & facts rather than engage in superficial talks. 
                    </div>
                </div>
            </div>

            <div class="second-flex-middle-row">
                <div class="flex-row" style="margin: 5px 10px;">
                    <div class="second-flex-row" style="flex: 1.4;">
                        <div class="pink-section" style="margin-right: 0;">
                            <div class="pink-body" style="padding: 10px 15px; font-style: italic; background: #f7d7dc;">
                                <div class="inside-heading">REGULATORS</div>
                                <span style="font-weight: bold; text-decoration: underline;">Conscientious, Firm & Dutiful :</span>  Hard working, meticulous & traditional, excellent in fields that rely on clear hierarchies & rules. These traits forces REGULATORS to stick to their plans and do not shy away from undertaking challenging tasks, but they can also be very inflexible & reluctant to accept opinions of others 
                            </div>
                        </div>

                        <div class="pink-section mt-5" style="margin-right: 0;">
                            <div class="blue-body" style="padding: 10px 15px; font-style: italic; background: #bde1f5;">
                                <div class="inside-heading">INQUISITORS</div>
                                <span style="font-weight: bold; text-decoration: underline;">Ingenious, Cerebral & Aspiring :</span>  Fiercely independent, open-minded, strong-willed & imaginative, interested in doing what is right, than what satisfies everybody. These traits forces INQUISATORS to be excellent negotiators & manipulators, but also cause difficulties when it comes to endeavoring social or romantic pursuits. 
                            </div>
                        </div>

                    </div>
                    <div style="flex: 2;" class="second-flex-row">
                        <img style="height: 290px; width: 100%; margin-top: 25px;" src="assets/images/page 25/personality meter.png" />
                    </div>
                    <div class="second-flex-row">
                        <div class="pink-section">
                            
                            <div class="green-body" style="padding: 10px 15px; font-style: italic; background: #c4e5d4; padding-bottom: 20px;">
                                <div class="inside-heading">PARTICIPATORS</div>
                                <span style="font-weight: bold; text-decoration: underline;">Curious, Convivial & Playful :</span>  Self-reliant, quick-thinking & flexible with ability to connect with their surroundings. These traits forces PARTICIPATORS to be spontaneous & practical giving them an edge in trouble-shooting & handling of equip-ment, but they often venture into risky tasks & focus more on physical pleasures 
                            </div>
                        </div>

                        <div class="pink-section mt-5">
                            <div class="purple-body" style="padding: 10px 15px; font-style: italic; background: #cfcbe1; padding-bottom: 20px;">
                                <div class="inside-heading">ANTICIPATORS</div>
                                <span style="font-weight: bold; text-decoration: underline;">Compassionate Kind & Caring :</span> Warm, sympathetic & diplom-atic individuals, highly influential & co-operative. These traits forces ANTICIPATORS to play the role of peace makers in their social circles, but they struggle when there is a need to rely exclusively on cold rationality or make important decisions for self. 
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="second-flex-row">
                <div [ngClass]="{ 'highlight' : data.dataMap.page25.winner == 'improviser' }" class="pink-section">
                    <div class="flex-row">
                        <div class="green-heading">IMPROVISER</div>
                        <div class="pink-perc">{{ data.dataMap.page25.improviser }}%</div>
                    </div>
                    <div class="green-body" style="min-height: 140px;">
                        Quiet & observant, when prob-lem arises, they are tolerant, flexible & quick to find a solution. Highly organized & practical. Values data & facts to under-stand issues. Good in handling equipment. Bold & practical experimenters, find ways to make things work & improvise along the way. 
                    </div>
                </div>

                <div [ngClass]="{ 'highlight' : data.dataMap.page25.winner == 'samaritan' }" class="pink-section mt-5">
                    <div class="flex-row">
                        <div class="purple-heading">SAMARITAN</div>
                        <div class="pink-perc">{{ data.dataMap.page25.samaritan }}%</div>
                    </div>
                    <div class="purple-body" style="padding-bottom: 10px;">
                        Inspiring & tireless idealist, highly insightful about people's needs, motivations & concerns. Value relationships with others. Often artistic, creative & comp-lex. Deeply caring & gentle, find meaning in work that directly helps others. Highly intro-spective & imaginative but often exhaust themselves in pursuing their passion. 
                    </div>
                </div>
            </div>
        </div>

        <div class="flex-row mt-5">
            <div [ngClass]="{ 'highlight' : data.dataMap.page25.winner == 'administrator' }" class="pink-section">
                <div class="flex-row">
                    <div class="blue-heading">ADMINISTRATOR</div>
                    <div class="pink-perc">{{ data.dataMap.page25.administrator }}%</div>
                </div>
                <div class="blue-body">
                    Imaginative & strong-willed, ready to make bold decisions. Quick to spot inefficiencies & develop ways to solve probl-ems. Natural leaders, pioneers at goal setting, organization & planning. Charismatic & confi-dent which helps them to rally a group behind a common goal.At times tend to be seen as harsh by those around them. 
                </div>
            </div>

            <div [ngClass]="{ 'highlight' : data.dataMap.page25.winner == 'theorist' }" class="pink-section">
                <div class="flex-row">
                    <div class="blue-heading">THEORIST</div>
                    <div class="pink-perc">{{ data.dataMap.page25.theorist }}%</div>
                </div>
                <div class="blue-body">
                    Quiet & contained, enjoy abstract ideas & deep thought. Highly creative, intelligent & attentive. Desire logical answers to questions or problems that arise in the environment. Skeptical & analytical with unquenchable thirst for knowledge. Innovative, inventive & creative problem-solvers. 
                </div>
            </div>

            <div [ngClass]="{ 'highlight' : data.dataMap.page25.winner == 'challenger' }" class="pink-section">
                <div class="flex-row">
                    <div class="blue-heading">CHALLENGER</div>
                    <div class="pink-perc">{{ data.dataMap.page25.challenger }}%</div>
                </div>
                <div class="blue-body">
                    Smart & curious thinkers, ideal for entrepreneurial thinking. Cannot resist an intellectual challenge or debate. Struggle to meet deadlines & work within hierarchies, which can restrict their innovative talents. Prefer to focus on the "big idea" & resist routine & repetitive tasks. Keen on conceptual work, leaving details to others. 
                </div>
            </div>

            <div [ngClass]="{ 'highlight' : data.dataMap.page25.winner == 'energizer' }" class="pink-section">
                <div class="flex-row">
                    <div class="purple-heading">ENERGIZER</div>
                    <div class="pink-perc">{{ data.dataMap.page25.energizer }}%</div>
                </div>
                <div class="purple-body">
                    Innovative, inspiring & never unafraid of taking risks. Enthusiastic, creative & free-spirited, highly perceptive when understanding how individuals & groups function. Seek excite-ment & always look for a reason to smile. Enjoy abstract & experiential learning. Outsta-nding communicator. Life of the party & events. 
                </div>
            </div>

            <div [ngClass]="{ 'highlight' : data.dataMap.page25.winner == 'optimist' }" class="pink-section">
                <div class="flex-row">
                    <div class="purple-heading">OPTIMIST</div>
                    <div class="pink-perc">{{ data.dataMap.page25.optimist }}%</div>
                </div>
                <div class="purple-body">
                    Curious, inquisitive & innovative. Highly creative, easily find connections in hidden patterns & enjoy abstract thinking. Excellent communication skills. Poetic, compassionate & altruistic, always eager to contribute to a good cause. Courageously defends idealistic beliefs & ideas. 
                </div>
            </div>

            <div [ngClass]="{ 'highlight' : data.dataMap.page25.winner == 'socializer' }" class="pink-section">
                <div class="flex-row">
                    <div class="purple-heading">SOCIALIZER</div>
                    <div class="pink-perc">{{ data.dataMap.page25.socializer }}%</div>
                </div>
                <div class="purple-body">
                    Charismatic & empathetic leaders. Highly intuitive when it comes to other's emotions, needs & motivations. Loyal and responsible, looking for ways to lead with inspiration and mesm-erize their listeners. Seek opportunities where they can make the world better and can bring people together to make a difference. 
                </div>
            </div>

        </div>
    </div>

    <div style="transform: translate(0px, 280px);">
        <div class="footer-elem"> Evaluation# {{ data.dataMap.footer.evalNo }}
        </div>
        <div class="footer-elem2 text-left">
          Date {{ data.dataMap.footer.date }}
        </div>
        <div class="footer-elem1">
          @Copyright - Siyasaar Enterprise
        </div>
        <div class="footer-elem">
          www.brainography.com
        </div>
        <div class="footer-elem">
          Design By Freepik
        </div>

        <div class="clear-both"></div>

        <div style="font-size: 14px;" class="text-center">
            25
        </div>
    </div>

   
</div>
