import { Component, Inject, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
  lactose: string;
  gluten: string;
}

@Component({
  selector: 'app-lactose-gluten-modal',
  templateUrl: './lactose-gluten-modal.component.html',
  styleUrls: ['./lactose-gluten-modal.component.css']
})
export class LactoseGlutenModalComponent implements OnInit {
  msg: string;

  constructor(public dialogRef: MatDialogRef<LactoseGlutenModalComponent>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData) { 
      }

  ngOnInit(): void {
    console.log(this.data);
  }

  onNoClick(): void {
    // if(this.data.lactose) {
    //   this.msg = 'Please select lactose';
    //   return;
    // }
    // if(this.data.gluten) {
    //   this.msg = 'Please select gluten';
    //   return;
    // }
    this.msg = '';
    this.dialogRef.close(this.data);
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
