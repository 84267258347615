<form class="example-form" style="border: 1px solid #ccc; padding: 25px;">
    <mat-form-field class="example-full-width">
        <mat-label>Name</mat-label>
        <input matInput [(ngModel)]='analystModel.name' [ngModelOptions]="{standalone:true}">
    </mat-form-field>

    <mat-form-field class="example-full-width">
        <mat-label>Email</mat-label>
        <input type="email" matInput [(ngModel)]='analystModel.email' [ngModelOptions]="{standalone:true}">
    </mat-form-field>

    <mat-form-field class="example-full-width">
        <mat-label>Phone</mat-label>
        <input type="text" matInput [(ngModel)]='analystModel.phone' [ngModelOptions]="{standalone:true}">
    </mat-form-field>


    <mat-form-field class="example-full-width">
        <mat-label>Username</mat-label>
        <input type="text" matInput [(ngModel)]='analystModel.uname' [ngModelOptions]="{standalone:true}">
    </mat-form-field>


    <mat-form-field class="example-full-width">
        <mat-label>password</mat-label>
        <input type="text" matInput [(ngModel)]='analystModel.pwd' [ngModelOptions]="{standalone:true}">
    </mat-form-field>

    <button mat-button style="background: #1e88e5;color: white;" (click)='create()'>{{buttonText}}</button>
    <button mat-button style="background: #1e88e5;color: white; margin-left: 10px;" (click)='back()'>Back</button>
    <br>
    <label style="position: relative;top: 10px;color: green;" *ngIf="isSuccess">{{successMsg}}</label>
    <br>
    <label style="position: relative;top: 10px;color: red;" *ngIf="isError">Error occurred. Please try again
        later.</label>
</form>