import { Injectable } from '@angular/core';
import { Common } from '../model/common.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WorkpadService {
  common: Common;
  reqHeader = new HttpHeaders({ 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Methods':'GET, PUT, POST, DELETE, HEAD, OPTIONS', 'guid':btoa(localStorage.getItem('guid')) });
 
  constructor(private http: HttpClient) { 
    this.common = new Common();
  }

  getWorkpadData(model) {
    return this.http.post(this.common.APIUrl +'/workpaddetail', model, { headers: this.reqHeader });
  }

  calculateAutoRC(model){
    return this.http.post(this.common.PYAPI + '/count-base64', model, { headers: this.reqHeader });
  }

  saveWorkpadData(model)
  {
    return this.http.post(this.common.APIUrl +'/updatefingerdetail', model, { headers: this.reqHeader });
  }

  arcCalculation(model)
  {
    return this.http.post(this.common.APIUrl +'/arccalculation', model, { headers: this.reqHeader });
  }

  getFingureImage(model)
  {
    return this.http.post(this.common.APIUrl +'/admin/getFingerImage', model, { headers: this.reqHeader });
  }
}
