<div class="thirty-three-page mt-40">
    <div class="text-right">
        <img class="logo-img" src="assets/images/Header.png" />
    </div>
    <div style="background-color: #282961;">
        <div class="thirty-three-page-blue-header">
            INTRAPERSONAL (Self-Smart)
        </div>
        <div>
            <div class="mt-5">
                <div class="thirty-three-page-cont1">
                    <div>
                        <div class="thirty-three-page-fs23">
                            Intrapersonal means "within the self". — so, Intrapersonal intelligence is another term for
                            self-awareness or introspection. People who have high Intrapersonal intelligence are more
                            aware of their emotions, motivations, beliefs, goals, limitations and fears. They have
                            better clarity on "what I like", "what I dislike", "who I am", and "what I want'. Although
                            they are introvert by nature and prefer to work alone, they can also be good at socializing
                            when required.
                        </div>
                        <div class="thirty-three-page-ipr">
                            Everything related to self comes for Intrapersonal
                        </div>
                        <div class="clear-both"></div>
                        <div class="thirty-three-page-line-height">
                            <ul>
                                <li>All about Me, My & I, (Self-connect)</li>
                                <li>Self-respect & Self-Confidence</li>
                                <li>Self-motivation & Self-introspection</li>
                                <li>Self-Assessment & Self-Belief
                                <li>Honesty & Integrity</li> 
                                <li>Rational thinking</li>
                                <li>Administration- by controlling others</li>
                                <li>Economics, Psychology, Philosophy & Theology.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="thirty-three-page-first-box" style="text-align: center;">
                    <img src="assets/images/Page 33/PAGE-33-INTRAPERSONAL.jpg" class="width-100">

                </div>
                <div class="clear-both"></div>
            </div>
        </div>
    </div>
    <div class="mt-20">
        <div class="purple-header">
            CHARACTERISTICS OF PERSON HAVING HIGH INTRAPERSONAL INTELLIGENCE
        </div>
        <div class="thirty-three-page-row-wrapper">
            <div class="tbl-bx">
                <div class="thirty-three-page-section-heading">
                    PROS
                </div>
                <div class="clear-both"></div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Self-disciplined, Responsible and good in
                                time <br>Management.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-two">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Self-directed, learns from his/her Failures and <br>Successes.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-two">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Good in Planning, Execution, Administration and <br>Management.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-two" style="border-bottom: none;">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Rational and judicious thinker, high on <br>Self-reflection.
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="clear-both"></div>
            </div>
            <div class="tbl-bx" style="border-left: none;">
                <div class="thirty-three-page-section-heading">
                    CONS
                </div>
                <div class="clear-both"></div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Cannot handle — Criticism, Comparison and <br>Direct orders (need others to request).
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-two">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Need self-respect (egoistic) and Privacy
                                <br> (need others to respect their privacy).
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-two">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Prefer one to one Interaction, have difficulties
                                <br> working in team. Highly Independent.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-two" style="border-bottom: none;">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Needs lot of Appreciation, Reciprocation and <br>Acknowledgement.
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="clear-both"></div>
            </div>
        </div>
    </div>

    <div class="mt-20">
        <div class="purple-header">
            REMEDIAL MEASURES IF YOUR INTRAPERSONAL INTELLIGENCE IS LOW
        </div>
        <div class="IIL-bg">
            <div>
                <div class="IIL-sec">Take measures to improve the following</div>
                <div class="IIL-sec1">1) YOUR SELF-RESPECT</div>
                <div>
                    <ul class="thirty-three-page-list-IIL">
                        <li>Don't let others to take you for granted. Avoid activities that can bring disgrace to
                            your
                            image. Get yourself counted for good reasons.
                        </li>
                    </ul>
                </div>
                <div class="clear-both"></div>
            </div>
            <div>
                <div class="IIL-sec1">2) YOUR SELF-BELIEF AND CONFIDENCE</div>
                <div>
                    <ul class="thirty-three-page-list-IIL">
                        <li> Read /watch motivational books and videos, say loudly "I can do it".
                            Boost your self- esteem. Avoid company of losers.
                        </li>
                    </ul>
                </div>
                <div class="clear-both"></div>
            </div>
            <div>
                <div class="IIL-sec1">3) YOUR DISCIPLINE</div>
                <div>
                    <ul class="thirty-three-page-list-IIL">
                        <li>Work on your Personality development — set short term and long term goals,
                            follow timetables and to-do lists strictly.
                        </li>
                    </ul>
                </div>
                <div class="clear-both"></div>
            </div>
            <div>
                <div class="IIL-sec1">4) YOUR RATIONAL THINKING</div>
                <div>
                    <ul class="thirty-three-page-list-IIL">
                        <li> Be Honest with yourself and Reasonable with others.
                        </li>
                    </ul>
                </div>
                <div class="clear-both"></div>
            </div>
            <div style="padding-bottom: 25px;">
                <div class="IIL-sec1">5) YOUR IDENTITY WITH YOURSELF</div>
                <div>
                    <ul class="thirty-three-page-list-IIL">
                        <li>Spend time with yourself, Play target based games, develop a hobby,
                            attend courses and tests to explore your Potential.
                        </li>
                    </ul>
                </div>
                <div class="clear-both"></div>
            </div>
        </div>
    </div>



</div>