<div class="fifteenth-page mt-40">
  <div class="text-right">
      <img class="logo-img" src="assets/images/Header.png" />
  </div>

  <div class="blue-header mt-20">
      CAREER PROSPECTS
  </div>

  <div class="mt-10" style="background-color: #e6e7e8!important;">
      <div class="fifthteenth-page-row">
        <div class="fifthteenth-page-first-box" style="width: 2%;"><strong>Q.</strong></div>
        <div class="fifthteenth-page-first-box" style="width: 90%;"><strong>I want to be very precise in selecting my stream after 10th. Can you elaborate further?</strong> </div>
        <div class="clear-both"></div>
      </div>
      <div class="fifthteenth-page-row">
        <div class="fifthteenth-page-first-box" style="width: 2%;"><strong>A.</strong></div>
        <div class="fifthteenth-page-first-box" style="width: 90%; text-align: justify; ">
          <p style="margin-top: 0px; margin-bottom: 5px;">Find out what is the choice of your HEART and your MIND. The best way is to do reverse 
          calculation.</p>
          <p style="margin-top: 0px; margin-bottom: 9px;"><span style="font-weight: bold; text-decoration: underline;">For choice of your MIND-</span>
            Go through all your preferred career prospects 
          (next page onwards) in each stream and decide what you want to be in future. Once you zero down on 
          occupation of your choice, figure out the right stream after 10th grade that will lead you to that 
          occupation.</p>
          <p style="margin-top: 0px;"><span style="font-weight: bold; text-decoration: underline;">For choice of your HEART-</span> Spend some time with yourself and try to 
              identify, which is the Passion within you that you would love to convert into Profession. 
              There is an effective tool which can help you in that direction which is called the 
             <span style="font-weight: bold;">IKIGAI. </span> 
              It is a <span style="color: #20026f; text-decoration: underline;">Japanese </span> concept that means <span style="color: #20026f; text-decoration: underline;">"a reason for being".</span> 
              The word refers to having a 
              direction or purpose in life, which makes one's life worthwhile, and towards which an 
              individual takes spontaneous & willing actions giving them satisfaction and a sense of 
              <span style="color: #20026f; text-decoration: underline;">meaning of life.</span> IKIGAI reflects the inner -self of an individual, & has been also 
              associated with health & longevity.</p>
        </div>
        <div class="clear-both"></div>
      </div>
    </div>

  <div class="fifthteenth-page-aboutikifai mt-10">
       FIND YOUR IKIGAI 
  </div>
  <div class="mt-1" style=" overflow: hidden; padding: 0px 30px;">
     <div class="eight-page-left-sec" >
          <div class="">
              <div class="fifthteenth-page-heading">
                  <h5 style="margin: 5px; font-size: 17px;">THE 10 RULES OF LONGEVITY</h5>
                  <div class="fifthteenth-page-rules">
                      <div class="fifthteenth-page-rules-sec" style="width: 2%;"><strong>1.</strong></div>
                      <div class="fifthteenth-page-rules-sec" style="width: 90%;"><strong>Stay active, don't retire.</strong> </div>
                      <div class="fifthteenth-page-rules-sec" style="width: 2%;"><strong>2.</strong></div>
                      <div class="fifthteenth-page-rules-sec" style="width: 90%;"><strong>Take it slow. (Have patience)</strong> </div>
                      <div class="fifthteenth-page-rules-sec" style="width: 2%;"><strong>3.</strong></div>
                      <div class="fifthteenth-page-rules-sec" style="width: 90%;"><strong>Don't fill your stomach.</strong> </div>
                      <div class="fifthteenth-page-rules-sec" style="width: 2%;"><strong>4.</strong></div>
                      <div class="fifthteenth-page-rules-sec" style="width: 90%;"><strong>Surround yourself with good friends.</strong> </div>
                      <div class="fifthteenth-page-rules-sec" style="width: 2%;"><strong>5.</strong></div>
                      <div class="fifthteenth-page-rules-sec" style="width: 90%;"><strong>Get in shape for your next birthday.</strong> </div>
                      <div class="fifthteenth-page-rules-sec" style="width: 2%;"><strong>6.</strong></div>
                      <div class="fifthteenth-page-rules-sec" style="width: 90%;"><strong>Smile. (Physically & mentally)</strong> </div>
                      <div class="fifthteenth-page-rules-sec" style="width: 2%;"><strong>7.</strong></div>
                      <div class="fifthteenth-page-rules-sec" style="width: 90%;"><strong>Reconnect with nature.</strong> </div>
                      <div class="fifthteenth-page-rules-sec" style="width: 2%;"><strong>8.</strong></div>
                      <div class="fifthteenth-page-rules-sec" style="width: 90%;"><strong>Give thanks. (Develop Gratitude)</strong> </div>
                      <div class="fifthteenth-page-rules-sec" style="width: 2%;"><strong>9.</strong></div>
                      <div class="fifthteenth-page-rules-sec" style="width: 90%;"><strong>Live in the moment.</strong> </div>
                      <div class="fifthteenth-page-rules-sec" style="width: 2%;"><strong>10.</strong></div>
                      <div class="fifthteenth-page-rules-sec" style="width: 90%;"><strong>Follow your IKIGAI </strong> </div>
                      
                      <div class="clear-both"></div>
                    </div>
                 <div class="clear-both"></div>
                </div>
              
          </div>
      </div>
      <div class="eight-page-left-sec" style="margin-left: 20px;float: left">
          <img style="height: 225px;" src="assets/images/Page 15/PAGE-15-IKAIGAI.jpg" />
      </div>
      <div class="clear-both"></div>
  </div>
  <div class="fifthteenth-page-brf mt-10">
      Let's understand briefly about IKIGAI
  </div>
  <div class="mt-1" >
      <div class="fifthteenth-page-row" >
        <div class="fifthteenth-page-IKIGAIsec" style="width: 2%;">
          <div class="fifthteenth-page-IKIGAI"></div>
        </div>
        <div class="fifthteenth-page-IKIGAIsec" style="width: 90%;">In Japanese, ikigai 
          is written by 
          combining the symbols that mean "life" with "to be worthwhile". 
      </div>
       <div class="clear-both"></div>
       <div class="fifthteenth-page-IKIGAIsec" style="width: 2%;">
          <div class="fifthteenth-page-IKIGAI"></div>
        </div>
        <div class="fifthteenth-page-IKIGAIsec" style="width: 90%;">"Translates roughly as 
          'the happiness of always being busy." 
      </div>
      <div class="clear-both"></div>
      <div class="fifthteenth-page-IKIGAIsec" style="width: 2%;">
         <div class="fifthteenth-page-IKIGAI"></div>
       </div>
       <div class="fifthteenth-page-IKIGAIsec" style="width: 90%;">"There is a passion inside you, a 
          unique talent that gives meaning to your days and drives you to share the best of yourself 
          until the very end. If you don't know your ikigai yet, your mission is to discover it." 
     </div>
     <div class="clear-both"></div>
     <div class="fifthteenth-page-IKIGAIsec" style="width: 2%;">
        <div class="fifthteenth-page-IKIGAI"></div>
      </div>
      <div class="fifthteenth-page-IKIGAIsec" style="width: 90%;">"Our ikigai is different for all of us, 
          but one thing we have in common is that we are all searching for meaning. When we spend 
          our days feeling connected to what 
          is meaningful to us, we live more fully; when we lose the connection, we feel despair."
    </div>
    <div class="clear-both"></div>
    <div class="fifthteenth-page-IKIGAIsec" style="width: 2%;">
       <div class="fifthteenth-page-IKIGAI"></div>
     </div>
     <div class="fifthteenth-page-IKIGAIsec" style="width: 90%;">"Our ikigai is hidden deep inside 
      each of us, and finding it requires a patient search. According to those born on Okinawa, the island with the most centenarians 
      in the world, our ikigai is the reason we get up in the morning."
   </div>
   <div class="clear-both"></div>
   <div class="fifthteenth-page-IKIGAIsec" style="width: 2%;">
      <div class="fifthteenth-page-IKIGAI"></div>
    </div>
    <div class="fifthteenth-page-IKIGAIsec" style="width: 90%;">"Once you discover your ikigai, 
      pursuing it and nurturing it every day will bring meaning to your life."
  </div>
  <div class="clear-both"></div>
  <div class="fifthteenth-page-IKIGAIsec" style="width: 2%;">
     <div class="fifthteenth-page-IKIGAI"></div>
    </div>
   <div class="fifthteenth-page-IKIGAIsec" style="width: 90%;">"One thing that everyone with 
      a clearly defined ikigai 
      has in common is that they pursue their passion no matter what." 
 </div>
 <div class="clear-both"></div>
  <div class="fifthteenth-page-IKIGAIsec" style="width: 2%;">
    <div class="fifthteenth-page-IKIGAI"></div>
  </div>
   <div class="fifthteenth-page-IKIGAIsec" style="width: 90%; color: #00abc1; font-weight: bold;">To know more about IKIGAI refer 
      the book " IKIGAI- The Japanese Secret to a Long and Happy Life" 
      by Hector Garcia & Francese Miralles..
</div>
      </div>
     
    </div>
  
</div>