<div class="main-container">
    <mat-toolbar color="primary" class="topbar telative">
        <div class="navbar-header">
            <a class="navbar-brand" href="index.html">
                <b>
                    <img src="assets/images/logo-icon.png" alt="homepage" class="dark-logo">
                    <img src="assets/images/logo-light-icon.png" alt="homepage" class="light-logo">
                </b>
                <span fxShow="false" fxShow.gt-xs style="color: white;">
                    Brainography
                </span> </a>
        </div>
        <span fxFlex></span>
    </mat-toolbar>
    <div fxLayout="row wrap">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100" style="background-color: white;">
            <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="30" fxFlex="30"
                style="margin-left: 35%;margin-top: 10%;">
                <form class="example-form" style="border: 1px solid #ccc; padding: 25px;">
                    <mat-form-field class="example-full-width">
                        <mat-label>Username</mat-label>
                        <input matInput [(ngModel)]='loginModel.uName' [ngModelOptions]="{standalone:true}">
                    </mat-form-field>

                    <mat-form-field class="example-full-width">
                        <mat-label>Password</mat-label>
                        <input type="password" matInput [(ngModel)]='loginModel.uPwd'
                            [ngModelOptions]="{standalone:true}">
                    </mat-form-field>
                    <button mat-button style="background: #1e88e5;color: white;" (click)='login()'>Login</button>
                    <br>
                    <label style="position: relative;top: 10px;color: red;" *ngIf="isError">{{errMsg}}</label>
                </form>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">Please wait... </p>
</ngx-spinner>