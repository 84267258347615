<button mat-button style="background: #1e88e5;color: white; margin-bottom: 10px;float: right;"
    (click)='addAnalyst()'>Add Analyst</button>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%;">
    <ng-container matColumnDef="analystName">
        <th mat-header-cell *matHeaderCellDef> Analyst Name </th>
        <td mat-cell *matCellDef="let element"> {{element.analystName}} </td>
    </ng-container>
    <ng-container matColumnDef="analystPhone">
        <th mat-header-cell *matHeaderCellDef> Analyst Phone </th>
        <td mat-cell *matCellDef="let element"> {{element.analystPhone}} </td>
    </ng-container>
    <ng-container matColumnDef="analystEmail">
        <th mat-header-cell *matHeaderCellDef> Analyst Email </th>
        <td mat-cell *matCellDef="let element"> {{element.analystEmail}} </td>
    </ng-container>
    <ng-container matColumnDef="analystUname">
        <th mat-header-cell *matHeaderCellDef> Analyst Username </th>
        <td mat-cell *matCellDef="let element"> {{element.analystUname}} </td>
    </ng-container>
    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element">
            <i class="material-icons" (click)="edit(element)" style="cursor: pointer;">
                edit
            </i>
            <i class="material-icons" (click)="delete(element)" style="cursor: pointer;">
                delete
            </i>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 50, 100]" (page)="pageChanged($event)" [length]="totalRecord">
</mat-paginator>