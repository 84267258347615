<div class="forty-six-page mt-40">
    <div class="text-right">
        <img class="logo-img" src="assets/images/Header.png" />
    </div>

    <div class="blue-header">
        BRAINOGRAPHY RESEARCH & SUPPORT TEAM 
    </div>

    <div class="mt-10 flex-row">
        <div class="flex-1 photo-bg">
            <img src="assets/images/Page 46/page46-makrand.PNG" />
            <div class="text-bold" style="font-size: 12px;">Brainographer</div>
            <div class="text-bold" style="margin-top: 3px;">MAKRAND KADAM</div>
        </div>
        <div class="flex-2 content-bg">
            Mr. Makarand Kadam is a Musicologist and Sound Designer. He is a University topper, counseling children for last 10 years to understand the Career opportunities in the Music Industry. Mr. Makrand has mentored many talented individuals of silver screen in reality shows. He is also a recording technician, arranger & show organizer. 
        </div>
    </div>

    <div class="mt-10 flex-row">
        <div class="flex-1 photo-bg">
            
        </div>
        <div class="flex-2 content-bg">
           
        </div>
    </div>

    <div class="mt-10 flex-row">
        <div class="flex-1 photo-bg">
            
        </div>
        <div class="flex-2 content-bg">
           
        </div>
    </div>

    <div class="mt-10 flex-row">
        <div class="flex-1 photo-bg">
            
        </div>
        <div class="flex-2 content-bg">
           
        </div>
    </div>

    <div class="mt-10 flex-row">
        <div class="flex-1 photo-bg">
            
        </div>
        <div class="flex-2 content-bg">
           
        </div>
    </div>

    <div class="mt-10 flex-row">
        <div class="flex-1 photo-bg">
            
        </div>
        <div class="flex-2 content-bg">
           
        </div>
    </div>
    
</div>
