<div class="thirty-seven-page">
    <div class="text-right">
        <img class="logo-img" src="assets/images/Header.png" />
    </div>

    <div style="background-color: #905293;">
        <div class="thirty-three-page-blue-header">
            FINE MOTOR SKILLS (Finger & Gesture Smart)
        </div>
        <div class="mt-10">
            <div class="mt-5">
                <div class="thirty-three-page-cont1">
                    <div>
                        <div class="thirty-three-page-fs23">
                            Fine Motor Skills (FMS) is the co-ordination of small muscular movements of the body in
                            synchronization with the thought process. People with high FMS are very good at Dexterity
                            (Finger based activities), hand-eye co-ordination and presentation with expressions. They
                            tend to be pro-active towards kinesthetic challenges which involves action by hands. They
                            are experienced based learners and understand better by hands-on activity.
                        </div>
                        <div class="thirty-three-page-ipr">
                            FMS Defines Efficiency in Dexterity & Facial Expressions 
                        </div>
                        <div class="clear-both"></div>
                        <div class="thirty-three-page-line-height">
                            <ul>
                                <li>Small & Micro muscular movements</li>
                                <li>Writing, Drawing, Coloring, Crafting etc.</li>
                                <li>Finishing touches (Finesse), Presentation</li>
                                <li>Chewing, Facial expression / suppression</li>
                                <li>All Precision based tasks</li>
                                <li>Cutting, Chopping, Stitching etc. </li>
                                <li>All Indoor games- carom, dart, pool etc. </li>
                                <li>Racket & Reflex based sports </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="thirty-three-page-first-box" style="text-align: center;">
                    <img src="assets/images/Page 37/PAGE-37-FMS.jpg" class="width-100">

                </div>
                <div class="clear-both"></div>
            </div>
        </div>
    </div>

    <div class="mt-20">
        <div class="purple-header">
            CHARACTERSTICS OF PERSON HAVING HIGH FINE MOTORS SKILL
        </div>
        <div class="thirty-three-page-row-wrapper">
            <div class="tbl-bx">
                <div class="thirty-three-page-section-heading">
                    PROS
                </div>
                <div class="clear-both"></div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Can excel in Sports- Carom, Dart, Pool, Snooker, 
                                <br>Spinner & Racket based sports etc.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Can excel in Music— Playing of Guitar, Piano, 
                                <br>Table, Congo, Bongo, etc. _
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Can excel in Fine Arts — Drawing, Painting, 
                                <br>Sketching, Calligraphy, Sculpturing etc.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-one" style="border-bottom: none;">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Good in Precision based Tasks & <br>Non-fire Based Cooking.
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="clear-both"></div>
            </div>
            <div class="tbl-bx" style="border-left: none;">
                <div class="thirty-three-page-section-heading">
                    CONS
                </div>
                <div class="clear-both"></div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Require mobility of fingers to pay attention <br>or concentrate.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li> Constantly Tapping, Scribbling or Playing <br>Rhythms on tables may cause irritation to others.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-one">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Fingers tend to be restless when over-thinking. 
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="thirty-three-page-column-one" style="border-bottom: none;">
                    <div class="thirty-three-page-section-subheading">
                        <ul class="thirty-three-page-list">
                            <li>Although good at finger based activities but also <br>require contributions from other intelligences. 
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="clear-both"></div>
            </div>
        </div>
    </div>

    <div class="mt-20">
        <div class="purple-header">
            REMEDIAL MEASURES IF YOUR FINE MOTOR SKILLS IS LOW 
        </div>
        <div class="IIL-bg">
            <div>
                <div class="IIL-sec">Take measures to improve the following</div>
                <div class="IIL-sec1">1) FIND A WAY TO DAILY EXERCISE FINGERS 
                    <ul class="thirty-three-page-list-IIL">
                        <li> Indulge into target/ racket based sports which occupies your fingers.</li>
                        <li> Play musical instruments where finger movements are required.</li>
                        <li> Develop a precision based hobby
                            like watch repairing, handcraft, painting etc.</li>
                    </ul>
                </div>
                <div class="clear-both"></div>
            </div>

            <div>
                <div class="IIL-sec1">2) JOIN ACTING CLASSES. OBSERVE & PRACTICE DIFFERENT EXPRESSIONS</div>

                <div class="clear-both"></div>
            </div>
            <div>
                <div class="IIL-sec1">3) STOP ALL OTHER ACTIVITIES WHILE EATING AND PAY ATTENTION TO MASTICATING WHILE CHEWING</div>

                <div class="clear-both"></div>
            </div>
            <div>
                <div class="IIL-sec1">4) TAKE EXTRA CARE OF YOUR TEETH. REGULAR BRUSHING AND PERIODIC CHECK UPS</div>

                <div class="clear-both"></div>
            </div>

            <div>
                <div class="IIL-sec1">5) WORK ON YOUR PRESENTATION SKILLS. PRACTICE REPETITIVELY</div>
            </div>

            <div>
                <div class="IIL-sec1">6) INDULGE INTO THERAPEUTIC FINE MOTOR ACTIVITIES. Example-STACKING PENNIES, 
                    <br>MOVING BEANS, USING STRESS BALL, RUBBER BAND EXERCISES, ETC </div>
            </div>

            

        </div>
    </div>

</div>