<div class="twenty-third-page mt-40">
    <div class="text-right">
        <img class="logo-img" src="assets/images/Header.png" />
    </div>
    <div class="blue-header mt-20">
       {{ data.dataMap.page23.title }}
    </div>
    <div class="mt-20">
        <div class="twenty-third-page-cont1" style="width: 70%;">
            <div class="" style=" padding-bottom: 5px;">
                <div class="twenty-third-page-fs23" style="width: 100%;">
                    {{ data.dataMap.page23.para_1 }}
                </div>
                <div class="twenty-third-page-fs23" style="width: 100%; padding-top: 15px; ">
                    {{ data.dataMap.page23.para_2 }}
                </div>
            </div>
        </div>
        <div class="twenty-third-page-first-box" style="text-align: center;">
            <img style="height: 180px;" [attr.src]="data.dataMap.page23.image">
            <div class="twentythird-page-ext-sec">{{ data.dataMap.page23.image_caption }}</div>
        </div>
        <div class="clear-both"></div>
    </div>
    <div class="mt-20">
        <div class="text-center">
            <div class="twentythird-page-ext-char">COMMON CHARACTERISTICS</div>
        </div>
        <div class="mt-5" style="background-color: #e7e7e7;">
            <div class="pd20">
                <div>
                    <ul class="twenty-third-page-list">
                        <li *ngFor="let item of data.dataMap.page23.common_characteristic">
                            {{ item }}
                        </li>
                        <!-- <li>Well-organized & systematic. Will thrive in solving complex
                            problems through careful analysis.
                        </li>
                        <li>Will follow systems & clear processes. Like rules & procedures.
                            Will prefer to discuss & sort things out.
                        </li>
                        <li>Highly perfectionist & methodical approach. Choose to
                            finish harder tasks first than the easier ones.
                        </li>
                        <li>Keep evaluating critically. Will constantly keep assessing
                            things that can go wrong, minimising risks.
                        </li>
                        <li>Like to get to the root of the problems in a constant pursuit of
                            excellence & producing accurate results.
                        </li>
                        <li>Try to maintain balance by perseverance, diligence & penchant
                            for structure. Always careful & cautious.
                        </li>
                        <li>Quick-witted & outspoken. Highly objective as they present
                            ideas with concrete facts & logic.
                        </li>
                        <li>Motivated by knowledge & expertise. Painstakingly detail
                            oriented. Adept at fault- finding & fault- fixing.
                        </li> -->
                    </ul>
                </div>
            </div>
            <div class="clear-both"></div>
        </div>
    </div>
    <div class="mt-10">
        <div class="text-center">
            <div class="twentythird-page-ext-char">CHALLENGES</div>
        </div>
        <div class="mt-5" style="background-color: #e7e7e7;">
            <div class="pd20">
                <div>
                    <ul class="twenty-third-page-list">
                        <li *ngFor="let item of data.dataMap.page23.challenges">
                            {{ item }}
                        </li>
                        <!-- <li>Over- analytical. While being obsessive to focus too much
                            on details, may lose sight of the 'Big picture'.
                        </li>
                        <li>Take too long to plan, trying to address every trivial issue,
                            resulting in very less time for action later.
                        </li>
                        <li>Complexity while making a decision. Unable to take chances or risks
                            due to constant critical evaluation.
                        </li>
                        <li>Confusion to single out. Often gets stuck at crossroads, especially
                            when making a judgement for self.
                        </li>
                        <li>Difficulty in stepping out of comfort zone. Often tend to be
                            bull headed & unbending if logic dictates so.
                        </li>
                        <li>Tend to exhaust precious time in perfecting irreverent jobs,
                            missing out focus on more significant ones.
                        </li>
                        <li>Love self-discipline & perfection but also expects the
                            same from others. Tend to see faults in others.
                        </li>
                        <li>Prefer logic over feelings. Difficulty in changing opinions,
                            even if they experience conflict of conscious.
                        </li> -->
                    </ul>
                </div>
            </div>
            <div class="clear-both"></div>
        </div>
    </div>
    <div class="mt-10">
        <div class="text-center">
            <div class="twentythird-page-ext-char">REMEDIES</div>
        </div>
        <div class="mt-5" style="background-color: #e7e7e7;">
            <div class="pd20">
                <div>
                    <ul class="twenty-third-page-list">
                        <li *ngFor="let item of data.dataMap.page23.remedies">
                            {{ item }}
                        </li>
                        <!-- <li>
                            While focusing on the details, always keep an eye on your goal.
                            Never lose sight of your 'Big Picture'.
                        </li>
                        <li>
                            Don't be influenced by others misgivings and fear. Keep trying
                            something new. Don't be afraid of losing.
                        </li>
                        <li>
                            Start implementing the course of action once you have your plan.
                            Perfect it on the way, simultaneously.
                        </li>
                        <li>
                            When you have multiple options, use 'Strike out' method to
                            zero down on 'Best & Final' alternative.
                        </li>
                        <li>
                            Don't get bogged down by failures. Analyse your mistakes &
                            use this knowledge for the next attempt.
                        </li> -->
                    </ul>
                </div>
            </div>
            <div class="clear-both"></div>
        </div>
    </div>
    <div class="mt-10">
        <div class="text-center">
            <div class="twentythird-page-ext-char1">COMPATIBILITY OF YOUR PERSONALITY WITH OTHER 
                PERSONALITIES</div>
        </div>
        <div class="mt-5 twenty-third-page-fsize">
            <div class="twenty-third-page-careeroption" style="width: 20%;" >
                NATURAL BLENDING
            </div>
            <div class="twenty-third-page-careeroption1" >
                You both can easily get along with each other. <br>
                Your Personalities complement each other.
            </div>
            <div class="twenty-third-page-careeroption">
                C&S
            </div>
            <div class="twenty-third-page-careeroption">
                S&I
            </div>
            <div class="twenty-third-page-careeroption">
                I & I
              </div>
              <div class="twenty-third-page-careeroption" style="border-right: none;">
                S & S
              </div>
            <div class="clear-both"></div>
          </div>
          <div class="mt-5 twenty-third-page-fsize" >
            <div class="twenty-third-page-careeroption" style="width: 20%;" >
                COMPLIMENTING
            </div>
            <div class="twenty-third-page-careeroption1" >
                You both can maintain a good relationship but can  <br>
                have a minor personality conflict
            </div>
            <div class="twenty-third-page-careeroption">
                D&I
            </div>
            <div class="twenty-third-page-careeroption">
                D&C 
            </div>
            <div class="twenty-third-page-careeroption">
                C&C 
              </div>
              <div class="twenty-third-page-careeroption" style="border-right: none;">
                D&S
              </div>
            <div class="clear-both"></div>
          </div>
          <div class="mt-5 twenty-third-page-fsize">
            <div class="twenty-third-page-careeroption" style="width: 20%;" >
                CONFLICTING
            </div>
            <div class="twenty-third-page-careeroption1" >
                You both face difficulty in maintaining effective relationship <br>
                as you both are opposite personalties
            </div>
            <div class="twenty-third-page-careeroption">
                
            </div>
            <div class="twenty-third-page-careeroption">
                C&I
            </div>
            <div class="twenty-third-page-careeroption">
                D&D
              </div>
              <div class="twenty-third-page-careeroption" style="border-right: none;">
                
              </div>
            <div class="clear-both"></div>
          </div>
        
    </div>

 
</div>