<div class="first-page">
    <div class="cover-page-heading">
      <h5>WHAT DOES A 10 MEAN TO YOU ?</h5>

      <div class="mt-20">
        <ul class="list-color-disc-cover">
          <li>
            <span class="disc-color-cover"></span>
            10 is Considered The Most Sacred Number</li>
          <li>
            <span class="disc-color-cover"></span>
            10 Symbolizes the Completion of a Cycle</li>
          <li>
            <span class="disc-color-cover"></span>
            10 is the Most frequently used number in BIBLE</li>
          <li>
            <span class="disc-color-cover"></span>
            10 Annual Days of Repentance observed by JEWS</li>
          <li>
            <span class="disc-color-cover"></span>
            10 Avatars of Vishnu in HINDUISM</li>

          <li>
            <span class="disc-color-cover"></span>
            10 Gurus in SIKHISM
          </li>

          <li>
            <span class="disc-color-cover"></span>
            10 Sefirot (Fundamentals Forces) in KABBALAH
          </li>

          <li>
            <span class="disc-color-cover"></span>
            10 is the Beginning of a Countdown
          </li>

          <li>
            <span class="disc-color-cover"></span>
            10 is the Card of the Wheel of Fortune in the Tarot
          </li>

          <li>
            <span class="disc-color-cover"></span>
            <h5 class="cover-list-heading"> 10 means so much more...</h5>
          </li>

        </ul>
      </div>
    </div>
    <div class="cover-page-first-logo">
      <img src="assets/images/AOB Logo_New.png" />
    </div>

    <div class="cover-page-second-logo">
      <img src="assets/images/Page-1_Lobes Logo.png" />
    </div>

    <div class="clear-both"></div>

    <div class="text-center first-pg-img-wrapper">
      <img class="width-100" src="assets/images/Img.png" />

      <h5 class="cover-list-heading2">Explore the Significance of 10 in Your Life...</h5>
    </div>

    <div class="clear-both"></div>

    <div class="first-page-name-wrapper">
      <div class="first-page-name-box">
        <div class="logo-wrapper" style="width: 45%;">
          <img class="width-100" src="assets/images/Page-1_Main Logo.png" />
        </div>

        <div class="logo-wrapper" *ngIf="data" style="width: 55%;">
          <div class="content-wrapper">
            <div class="content-row">
              <div class="content-col" style="width: 20%;">Name :</div>
              <div class="content-col-name" style="width: 78%;">{{ data.dataMap.page1.name }}</div>
              <div class="clear-both"></div>
            </div>

            <div class="content-row mt-10">
              <div class="content-col" style="width: 42%;">Evaluation No. :</div>
              <div class="content-col-eval" style="width: 56%;">{{ data.dataMap.footer.evalNo }}</div>
              <div class="clear-both"></div>
            </div>
          </div>
        </div>

        <div class="clear-both"></div>
      </div>
    </div>

    <div class="mt-2 font-bold">
      <div class="inline-block">
        <img class="bottom-academy-logo" src="assets/images/Icon _1.png" />
        <strong class="font-size-11"> ACADEMY OF BRAINOGRAPHY - MUMBAI (INDIA)</strong>
      </div>

      <div class="inline-block font-size-12" style="margin-left: 3%; font-weight: 900;">
        <img class="bottom-academy-logo" src="assets/images/Icon_2.png" />
        www.brainography.com
      </div>

      <div class="inline-block" style="margin-left: 3%; font-weight: 900;">
        <img class="bottom-academy-logo" src="assets/images/Icon_3.png" />

        <span class="font-size-12 ">
          info@brainography.com</span>
        </div>
        
      <div class="clear-both"></div>
    </div>
    <div clas="mt-2">
      <div class="font-size-10 text-center" style="margin-top: 30px;">
        <strong>@Copyright. All Rights Reserved.</strong>
      </div>
  </div>

  </div>