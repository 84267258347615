import { Injectable } from '@angular/core';
import { Common } from '../model/common.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AnalystService {
  common: Common;
  reqHeader = new HttpHeaders({ 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Methods':'GET, PUT, POST, DELETE, HEAD, OPTIONS',  'guid':btoa(localStorage.getItem('guid'))});
 
  constructor(private http: HttpClient) {
    this.common = new Common();
  }

  getAnalystList(model) {
    return this.http.post(this.common.APIUrl + '/admin/getAnalyst', model, { headers: this.reqHeader });
  }

  getAnalystById(model) {
    return this.http.post(this.common.APIUrl + '/admin/getAnalystById', model, { headers: this.reqHeader });
  }

  createAnalyst(model) {
    return this.http.post(this.common.APIUrl + '/admin/addAnalyst', model, { headers: this.reqHeader });
  }

  deleteAnalyst(model) {
    return this.http.post(this.common.APIUrl + '/admin/deleteAnalyst', model, { headers: this.reqHeader });
  }
}
