<div class="thirty-page mt-20">
    <div id="page30-1">
        <div class="text-right">
            <img class="logo-img" src="assets/images/Header.png" />
        </div>
    
        <div class="blue-header">
            YOUR BRAIN DOMINANCE
        </div>
    
        <div class="grey-bg mt-5">
            Your brain is command & control centre of all what you THINK, FEEL, & DO. It is divided into two halves or hemispheres 
            namely LEFT & RIGHT Brain, which look very much alike, but there's a huge difference in how they process information. 
            This SPLIT-BRAIN THEORY came to light in the 1960s, thanks to the research of psycho-biologist and Nobel Prize Winner 
            <strong>Dr. Roger W. Sperry.</strong> LEFT BRAIN is mostly Analytical and Methodical in thinking, while the RIGHT BRAIN is more Creative 
            or Artistic. Despite their contrasting styles, they don't work independently of each other. Tied together by the bundles
             of Nerve fibres, they work in synergy, complimenting each other. For example- the LEFT BRAIN is credited with language,
              but the right brain helps you understand context and tone. Still, it's a fact that the two sides of your brain are different
               and certain areas of your brain do have specialties. So let's explore the aggregate of both sides. 
        </div>
    
        <div class="flex-row mt-10">
            <div class="content-flex">
                <div class="perc-wrapper" style="left: 50px;">
                    <div class="text-bold">LEFT BRAIN</div>
                    <div class="perc-center">{{ data.dataMap.page30.left_brain }}%
						<br /> {{ data.dataMap.page30.left_brain_2 }}
					</div>
                </div>
            </div>
            <div class="img-flex text-center">
                <img style="height: 240px;" [attr.src]="data.dataMap.page30.image" />
            </div>
            <div class="content-flex">
                <div class="perc-wrapper" style="left: -30px;">
                    <div class="text-bold">RIGHT BRAIN</div>
                    <div class="perc-center">{{ data.dataMap.page30.right_brain }}%
						<br /> {{ data.dataMap.page30.right_brain_2 }}
					</div>
                </div>
            </div>
        </div>
    
        <div class="flex-row mt-10">
            <div class="first-col">
                <div class="td-blue-heading">ASPECTS</div>
            </div>
            <div class="main-col">
                <div class="td-blue-heading">LEFT BRAIN</div>
            </div>
            <div class="main-col">
                <div class="td-blue-heading">RIGHT BRAIN</div>
            </div>
        </div>
    
        <div style="font-size: 10px;">
            <div class="flex-row mt-5">
                <div class="first-col-gray">
                    <div class="td-dark-gray">CONTROLS</div>
                </div>
                <div class="main-col-gray">
                    <div class="td-gray">Muscles of Right Side of the Body.</div>
                    <img class="arrow-icon" src="assets/images/Page 30/two-way-arrow.png" />
                </div>
                <div class="main-col-gray">
                    <div class="td-gray ml-10">Muscles of Left Side of the Body.</div>
                </div>
            </div>
        
            <div class="flex-row mt-5">
                <div class="first-col-gray">
                    <div class="td-dark-gray">FUNCTIONS</div>
                </div>
                <div class="main-col-gray">
                    <div class="td-gray"> <strong>DIGITAL BRAIN:</strong> Speech & Language, <br/>
                         Logical Analysis & Reasoning, <br/> Mathematical Computations, 
                        Precision.</div>
                    <img class="arrow-icon" style="top: 15px;" src="assets/images/Page 30/two-way-arrow.png" />
                </div>
                <div class="main-col-gray">
                    <div class="td-gray ml-10"><strong>ANALOG BRAIN:</strong> Spatial Awareness,<br />
                         Intuition, Facial Recognition, Visual Imagery,<br /> Music Awareness, Rhythm, Art.</div>
                </div>
            </div>
        
            <div class="flex-row mt-5">
                <div class="first-col-gray">
                    <div class="td-dark-gray">INBORN & PERCEIVED TRAITS</div>
                </div>
                <div class="main-col-gray">
                    <div class="td-gray">
                        <ul class="col-li" style="padding-left: 10px; margin: 0;">
                            <li>Analytical, Logical, Practical & Pragmatic.</li>
                            <li>Time Conscious - Cautious & Plays Safe.</li>
                            <li>Linear Thinking - Sequential Processing. </li>
                            <li>Logical Decision Making - Reality Oriented.</li>
                            <li>Pay Attention To Detail.</li>
                        </ul>
                    </div>
                    <img class="arrow-icon" src="assets/images/Page 30/two-way-arrow.png" />
                    <img class="arrow-icon" style="top: 26px;" src="assets/images/Page 30/two-way-arrow.png" />
                    <img class="arrow-icon" style="top: 49px;" src="assets/images/Page 30/two-way-arrow.png" />
                    <img class="arrow-icon" style="top: 71px;" src="assets/images/Page 30/two-way-arrow.png" />
                    <img class="arrow-icon" style="top: 92px;" src="assets/images/Page 30/two-way-arrow.png" />
                </div>
                <div class="main-col-gray">
                    <div class="td-gray ml-10">
                        <ul class="col-li" style="padding-left: 10px; margin: 0;">
                            <li>Creative, Artistic, Impetuous & Spontaneous. </li>
                            <li>Subject Conscious - Courageous & Takes Risks.</li>
                            <li>Holistic Thinking - Random Processing.</li>
                            <li>Intuitive Decision Making - Fantasy Oriented.</li>
                            <li>Open-Minded.</li>
                        </ul>
    
                    </div>
                </div>
            </div>
    
            <div class="flex-row mt-5">
                <div class="first-col-gray">
                    <div class="td-dark-gray">OVERALL THOUGHT PROCESS </div>
                </div>
                <div class="main-col-gray">
                    <div class="td-gray">
                        <ul class="col-li" style="padding-left: 10px; margin: 0;">
                            <li>Detail Oriented - "Details to Whole".</li>
                            <li>Looks at Past to Present.</li>
                            <li>Sequential - Verbal (Process with Words). </li>
                            <li>Acknowledges the Achievements.</li>
                            <li>Attention to Inner World.</li>
                            <li>Discrimination & Evaluation.</li>
                        </ul>
                    </div>
                    <img class="arrow-icon" src="assets/images/Page 30/two-way-arrow.png" />
                    <img class="arrow-icon" style="top: 26px;" src="assets/images/Page 30/two-way-arrow.png" />
                    <img class="arrow-icon" style="top: 49px;" src="assets/images/Page 30/two-way-arrow.png" />
                    <img class="arrow-icon" style="top: 71px;" src="assets/images/Page 30/two-way-arrow.png" />
                    <img class="arrow-icon" style="top: 92px;" src="assets/images/Page 30/two-way-arrow.png" />
                    <img class="arrow-icon" style="top: 112px;" src="assets/images/Page 30/two-way-arrow.png" />
                </div>
                <div class="main-col-gray">
                    <div class="td-gray ml-10">
                        <ul class="col-li" style="padding-left: 10px; margin: 0;">
                            <li>Big-Picture Oriented - "Whole to Details".</li>
                            <li>Looks at Present to Future.</li>
                            <li>Random - Non Verbal (Process with Visuals). </li>
                            <li>Appreciates the Achievements.</li>
                            <li>Attention to Outer World. </li>
                            <li>Belief & Faith.</li>
                        </ul>
    
                    </div>
                </div>
            </div>
    
            <div class="flex-row mt-5">
                <div class="first-col-gray">
                    <div class="td-dark-gray">PROBLEM SOLVING </div>
                </div>
                <div class="main-col-gray">
                    <div class="td-gray">
                        <ul class="col-li" style="padding-left: 10px; margin: 0;">
                            <li>Logical - Order/Pattern Judging.</li>
                            <li>Emphasis on Strategies.</li>
                        </ul>
                    </div>
                    <img class="arrow-icon" src="assets/images/Page 30/two-way-arrow.png" />
                    <img class="arrow-icon" style="top: 26px;" src="assets/images/Page 30/two-way-arrow.png" />
                </div>
                <div class="main-col-gray">
                    <div class="td-gray ml-10">
                        <ul class="col-li" style="padding-left: 10px; margin: 0;">
                            <li>Intuitive - Spatial/Abstract Perception.</li>
                            <li>Emphasis on Possibilities. </li>
                        </ul>
    
                    </div>
                </div>
            </div>
    
            <div class="flex-row mt-5">
                <div class="first-col-gray">
                    <div class="td-dark-gray">STRENGTHS</div>
                </div>
                <div class="main-col-gray">
                    <div class="td-gray">
                        Mathematics, Analytics, Reading, Spelling, Writing, Sequencing, Verbal And Written Language, Sensing, Observing.
                    </div>
                    <img class="arrow-icon" src="assets/images/Page 30/two-way-arrow.png" />
                </div>
                <div class="main-col-gray">
                    <div class="td-gray ml-10">
                        Multi-Dimensional Thinking, Art, Music, Drawing, Athletics, Coordination, Repairs, Remembers Faces, Places, Events. 
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>