<div class="eighth-page mt-40">
    <div class="text-right">
        <img class="logo-img" src="assets/images/Header.png" />
    </div>

    <div class="blue-header mt-20">
        AQUIRING STYLES
    </div>

    <div class="mt-10" style="background-color: #e6e7e8;">
        <div class="fifth-page-row">
          <div class="fifth-page-first-box" style="width: 2%;"><strong>Q</strong></div>
          <div class="fifth-page-first-box" style="width: 90%;"><strong>Can you elaborate more on Learning methods or Acquiring styles?</strong> </div>
          <div class="clear-both"></div>
        </div>
        <div class="fifth-page-row">
          <div class="fifth-page-first-box" style="width: 2%;"><strong>A</strong></div>
          <div class="fifth-page-first-box" style="width: 90%; text-align: justify; ">
            Education has traditionally been focused around linguistic & logical-mathematical learning styles, 
            however certain student-centred learning models such as the Montessori method and the International 
            Baccalaureate program have found better success rate by helping children learn in the way that is best 
            suited for them. To streamline your learning processes with neuroscience and help you to learn more effectively 
            and with greater enjoyment you need to first identify your most preferred learning method.
            
            <p>As mentioned earlier, 
            your Input Zone is the key to your most preferred learning method or Acquiring style. Let's go through all Methods 
            Zone wise to understand what suits you better. Please be informed that a person may prefer more than one method or 
            sometimes all the methods depending upon the flow of Neurons in the input zones. (Refer table on page no 5) </p>
          </div>
          <div class="clear-both"></div>

          <div>
              <ul class="eigth-middle-row-section">
                  <li>Kinesthetic learners — absorbs information by moving</li>
                  <li>Auditory learners — absorbs information by sound</li>
                  <li>Visual learners — absorbs information by sight </li>
              </ul>
          </div>
        </div>
      </div>

      <div class="blue-header mt-10">
        KINESTHETIC (TACTILE) LEARNERS
      </div>

      <div>
          <p>
            Kinesthetic learners require physical mobility to memorize. They can retain knowledge better when they move around while learning. 
            They prefer more of a practical learning (Hands on Activities) to understand and save data into their memory. 
            Kinesthetic learners absorb information best through touch, movement and motion. 
          </p>
          <p>
            Kinesthetic learner's mind goes to sleep when they sit still for longer duration. 
            They need to periodically get up, and move around or may develop a habit of walking around while reading. 
            They may also prefer to sit on a moving swing or a rocking chair to concentrate. 
            In case if Tactile learners are required to sit at one place for a longer period, they may move hands, 
            tap feet, squeeze a stress ball or chew a gum (If student is old enough). These acts compensate for the need 
            of mobility by their brain and hence they can concentrate on grasping the knowledge. 
          </p>
          <p>
            In the classroom Tactile Learners are required to be kept engaged by allowing them to explain concepts 
            by participating in discussions. They need to point out things and describe by hand movements while speaking. 
            Since they face hard time sitting still and focus, the basic idea is to keep them physically engaged somehow to 
            keep Kinesthetic learners in the game. 
          </p>
       </div>


       <div class="mt-20" style="height: 215px; overflow: hidden;">
            <div class="eight-page-left-sec" style="height: 100%;">
                <img class="width-100" style="height: 100%;" src="assets/images/Page 8/IMG 1.png" />
            </div>
            <div class="eight-page-left-sec" style="margin-left: 20px;">
                <div class="blue-box-wrapper">
                    <div class="blue-box-heading">
                        PARENTING TIPS
                    </div>
                    <div class="blue-box-section" style="border-top: none">
                        <div class="left-dot-sec">
                            <span class="blue-box-dot"></span>
                        </div>
                        <div class="left-detail-sec">
                            Encourage your child to write notes or allow them to execute directly rather than learning by instructions. 
                            Plan a routine physical activity like dance, or sports in evening.
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="blue-box-section">
                        <div class="left-dot-sec">
                            <span class="blue-box-dot"></span>
                        </div>
                        <div class="left-detail-sec">
                            Don't point out their hands & legs movements or their funny facial expressions while studying. 
                            Let them stand up & move around every 20 minutes when they are learning, to refresh their attention. 
                            Provide a rocking chair or moving swing if required. 
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="blue-box-section">
                        <div class="left-dot-sec">
                            <span class="blue-box-dot"></span>
                        </div>
                        <div class="left-detail-sec">
                            Be open with your child's teacher and share what you have discovered that works and doesn't work for your child's learning. 
                            Make them follow a time table for their studies to avoid Procrastination. 
                        </div>
                        <div class="clear-both"></div>
                    </div>
                    <div class="blue-box-section">
                        <div class="left-dot-sec">
                            <span class="blue-box-dot"></span>
                        </div>
                        <div class="left-detail-sec">
                            To internalize a new concept, encourage Physical math manipulatives, such as pattern blocks and base ten blocks etc. 
                            Provide textured paper to write on & a variety of pencils or pens to choose from.
                        </div>  
                        <div class="clear-both"></div>
                    </div>
                </div>
            </div>
            <div class="clear-both"></div>
       </div>
    
</div>
