import * as $ from 'jquery';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router, ActivatedRoute } from '@angular/router';
import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  ViewChild,
  HostListener,
  Directive,
  AfterViewInit
} from '@angular/core';
import { MenuItems } from '../shared/menu-items/menu-items';
import { LoginService } from '../services/login.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CryptoService } from '../crypto.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnDestroy, AfterViewInit {

  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;
  loginModel: any = {};
  isError: boolean = false;
  errMsg: string;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    private loginService: LoginService,
    private route: Router,
    private SpinnerService: NgxSpinnerService,
    private cryptoService: CryptoService
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  login() {
    if(!this.loginModel.uName) {
      this.errMsg = 'Please Enter Username';
      this.isError = true;
      return;
    }
    else if(!this.loginModel.uPwd) {
      this.errMsg = 'Please Enter Password';
      this.isError = true;
      return;
    }
    this.SpinnerService.show();

    var congnitoIdEnc = this.cryptoService.getBase64String("b582a4ec-8237-4d30-95f9-211eb62dd4d0");
    var payload1 = JSON.stringify(this.loginModel).toString();
    var loginPayload = this.cryptoService.getEncryptedBody("b582a4ec-8237-4d30-95f9-211eb62dd4d0", payload1);
    console.log("encrypted model: ", loginPayload.toString());

    var model = {
      //"headers": { "x-shyld-app-id": congnitoIdEnc },
      "data": loginPayload.toString()
    }

    console.log('guid', congnitoIdEnc);
    this.loginService.login(model, congnitoIdEnc).subscribe((result: any) => {
      localStorage.setItem('guid',atob(result.headers.get('guid')));
      var res = JSON.parse(this.cryptoService.getDecryptedBody("b582a4ec-8237-4d30-95f9-211eb62dd4d0", result.body.data));
      if (res.status == "success") {
        console.log("login success respose: ", res);
        localStorage.setItem("Login", "success");
        this.SpinnerService.hide();
        this.route.navigate(['/client/pending-report']);
      }
      else {
        localStorage.setItem("Login", "fail");
        this.errMsg = 'Invalid username or password';
        this.isError = true;
        this.SpinnerService.hide();
      }
    },
    error => {
      localStorage.setItem("Login", "fail");
      this.errMsg = 'Error occured. Please contact system admin';
      this.isError = true;
      this.SpinnerService.hide();
    });

  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  ngAfterViewInit() { }

}
