import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatSidenav } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {
  //public sideNavToggleSubject: BehaviorSubject<any> = new BehaviorSubject(null);

  private sidenav: MatSidenav;
  
  constructor() { }

  // public toggle() {
  //   return this.sideNavToggleSubject.next(null);
  // } 

	public setSidenav(sidenav: MatSidenav) {
		this.sidenav = sidenav;
	}

	public open() {
		return this.sidenav.open();
	}


	public close() {
		return this.sidenav.close();
	}

	public toggle(): void {
		this.sidenav.toggle();
	}
}
