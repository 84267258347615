<div class="twentysecond-page mt-20">
    <div class="text-right">
        <img class="logo-img" src="assets/images/Header.png" />
    </div>

    <div class="mt-5 text-center">
        <img style="width: 60%;" src="assets/images/Page 22/PAGE-22-Ages-32.jpg" />
    </div>

    <div class="mt-10" style="background-color: #e6e7e8!important;">
        <div class="fifthteenth-page-row">
          <div class="fifthteenth-page-first-box" style="width: 2%;"><strong>Q.</strong></div>
          <div class="fifthteenth-page-first-box" style="width: 90%;">
            <strong>What are the highlights of my Character?
              </strong> </div>
          <div class="clear-both"></div>
        </div>
        <div class="fifthteenth-page-row">
          <div class="fifthteenth-page-first-box" style="width: 2%;"><strong>A.</strong></div>
          <div class="fifthteenth-page-first-box" style="width: 90%; text-align: justify; ">
            Your Outer Personality and your Inner Personality has major influence over your Character. The sum of your developed Personality over the years determines your true character. 
          
          </div>
          <div class="clear-both"></div>
        </div>
    </div>

    <div class="mt-10" style="background-color: #e6e7e8!important;">
        <div class="fifthteenth-page-row">
          <div class="fifthteenth-page-first-box" style="width: 2%;"><strong>Q.</strong></div>
          <div class="fifthteenth-page-first-box" style="width: 90%;">
            <strong>What is the Outer and Inner Personality?
              </strong> </div>
          <div class="clear-both"></div>
        </div>
        <div class="fifthteenth-page-row">
          <div class="fifthteenth-page-first-box" style="width: 2%;"><strong>A.</strong></div>
          <div class="fifthteenth-page-first-box" style="width: 90%; text-align: justify; ">
            Consciously or Unconsciously, you constantly illustrate your emotions. People make judgements about you on the basis of your behavior patterns and the impression it creates on others. Your Outer Personality is how people perceive your behavior & Your Inner Personality is how you are from within in respective to the people's perception. Let's understand both briefly... 
          
          </div>
          <div class="clear-both"></div>
        </div>
    </div>

    <div class="blue-header mt-10">
        OUTER PERSONALITY
    </div>

    <div class="mt-5 text-bold" style="font-size: 10px;">
        William Moulton Marston, a lawyer and a psychologist; (who contributed to the first polygraph test), after conducting research 
        on human emotions, published his findings in his 1928 book called Emotions of Normal People in which he explained that:
         People illustrate their emotions using four behaviour types: Dominance (D), Inducement (I), Submission (S), and Compliance 
         (C). He argued that these behavioural types came from people's sense of self (i.e. Intrapersonal) and their interaction 
         with the environment. (i.e. Interpersonal). Further, over the years, what evolved from the collective assessments of Psychologists
          and researchers Merenda, Peter F, Walter Clarke and John Geier is what we call 'DISC' Profile which is now owned by John Wiley
           and Sons. 
    </div>

    <div class="mt-20 flex-row">
        <div class="flex-col" style="margin-right: 10px;">
            <div class="dominant-bg mt-20">
                <div class="text-center">
                    <input type="checkbox" value="1" [attr.checked]="data.dataMap.page22.outer == '1' ? true : null "  /> 
                    <span class="text-bold">DOMINANT</span>
                </div>
                <div class="text-center">
                    <ul class="list-elem">
                        <li><span class="text-bold">DIRECT</span></li>
                        <li><span class="text-bold">DECISIVE</span></li>
                        <li><span class="text-bold">DOER</span></li>
                        <li><span class="text-bold">DETERMINED</span></li>
                        <div class="clear-both"></div>
                    </ul>

                    
                </div>
                <div class="text-center">
                    <ul class="list-elem-red">
                        <li><span class="text-bold">DOMINEERING</span></li>
                        <li><span class="text-bold">DEMANDING</span></li>
                        <div class="clear-both"></div>
                    </ul>
                </div>
            </div>

            <div class="text-right mt-10">
                <span class="text-bold" style="color: #19397a">TASK FOCUS</span>
            </div>

            <div class="compliant-bg mt-10">
                <div class="text-center">
                    <input type="checkbox" value="3" [attr.checked]="data.dataMap.page22.outer == '3' ? true : null " /> 
                    <span class="text-bold">COMPLIANT</span>
                </div>
                <div class="text-center">
                    <ul class="list-elem">
                        <li><span class="text-bold">CAUTIOUS</span></li>
                        <li><span class="text-bold">CONSCIENTIOUS</span></li>
                        <li><span class="text-bold">CAREFUL</span></li>                        
                        <div class="clear-both"></div>
                    </ul>

                </div>
                <div class="text-center">
                    <ul class="list-elem-red">
                        <li><span class="text-bold">CALCULATING</span></li>
                        <li><span class="text-bold">CONDESCENDING</span></li>
                        <div class="clear-both"></div>
                    </ul>
                </div>
            </div>

        </div>
        <div class="flex-col-img">
            <div class="text-center">
                <span class="text-bold" style="color: #19397a">ACTIVE</span>
            </div>
            <img src="assets/images/Page 22/PAGE-22-Age-32active.jpg" />
            <div class="text-center">
                <span class="text-bold" style="color: #19397a">REFLECTIVE</span>
            </div>
        </div>
        <div class="flex-col" style="margin-left: 10px;">
            <div class="influential-bg mt-20">
                <div class="text-center">
                    <input type="checkbox" value="2" [attr.checked]="data.dataMap.page22.outer == '2' ? true : null "/> 
                    <span class="text-bold">INFLUENTIAL</span>
                </div>
                <div class="text-center">
                    <ul class="list-elem">
                        <li><span class="text-bold">INSPIRATIONAL</span></li>
                        <li><span class="text-bold">INTERACTIVE</span></li>
                        <li><span class="text-bold">INTERESTING</span></li>
                        <div class="clear-both"></div>
                    </ul>

                    
                </div>
                <div class="text-center">
                    <ul class="list-elem-red">
                        <li><span class="text-bold">IMPULSIVE</span></li>
                        <li><span class="text-bold">INDIGNANT</span></li>
                        <div class="clear-both"></div>
                    </ul>
                </div>
            </div>

            <div class="mt-10">
                <span class="text-bold" style="color: #19397a">PEOPLE FOCUS </span>
            </div>

            <div class="steady-bg mt-10">
                <div class="text-center">
                    <input type="checkbox" value="4" [attr.checked]="data.dataMap.page22.outer == '4' ? true : null "/> 
                    <span class="text-bold">STEADY</span>
                </div>
                <div class="text-center">
                    <ul class="list-elem">
                        <li><span class="text-bold">STABLE</span></li>
                        <li><span class="text-bold">SUPPORTIVE</span></li>
                        <li><span class="text-bold">SINCERE</span></li>                        
                        <li><span class="text-bold">SECURE</span></li>             
                        <div class="clear-both"></div>
                    </ul>

                </div>
                <div class="text-center">
                    <ul class="list-elem-red">
                        <li><span class="text-bold">SUBMISSIVE</span></li>
                        <li><span class="text-bold">SENSITIVE</span></li>
                        <div class="clear-both"></div>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="mt-5 text-bold" style="font-size: 10px;">
        Over the years, different versions of the same theory developed, but Dr. Gary Couture's version using bird names 
        (Dove, Owl, Peacock and Eagle) has become quite popular as most people can relate easier to a visual object like a bird, 
        rather than just a descriptive word. For this reason, we'll go with Dr. Couture's 'bird' version. 
    </div>

    <div class="mt-20 flex-row">
        <div class="flex-col1" style="margin-top: 20px;">
            <div class="flex-row">
                <div class="float-left" >
                    <div class="flex-col-head">EXTREME</div>
                    <div class="flex-col-body">
                        <img *ngIf="data.dataMap.page22.outer == '1' && data.dataMap.page22.outer_sub == '1'" src="assets/images/Page 22/tick.jpg" />
                    </div>
                </div>
                <div class="float-left">
                    <div class="flex-col-head">PROMINANT</div>
                    <div class="flex-col-body" style="border-left:0">
                        <img *ngIf="data.dataMap.page22.outer == '1' && data.dataMap.page22.outer_sub == '2'" src="assets/images/Page 22/tick.jpg" />
                    </div>
                </div>
            </div>
            <div class="flex-row">
                <div class="float-left">
                    <div class="flex-col-head">MODERATE</div>
                    <div class="flex-col-body">
                        <img *ngIf="data.dataMap.page22.outer == '1' && data.dataMap.page22.outer_sub == '3'" src="assets/images/Page 22/tick.jpg" />
                    </div>
                </div>
                <div class="float-left">
                    <div class="flex-col-head">MARGINAL</div>
                    <div class="flex-col-body" style="border-left:0">
                        <img *ngIf="data.dataMap.page22.outer == '1' && data.dataMap.page22.outer_sub == '4'" src="assets/images/Page 22/tick.jpg" />
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-col1" style="margin-right: 10px;">
            <div class="icon-wrapper">
                <div class="text-center text-bold" style="color: #19397a">EAGLE</div>
                <img src="assets/images/Page 22/PAGE-22-Age-32-egale.jpg" />
                <div class="text-center text-bold" style="color: #19397a">DOMINANT - DRIVER</div>
            </div>
        </div>
        <div class="flex-col1" style="margin-left: 10px; margin-right: 15px;">
            <div class="icon-wrapper">
                <div class="text-center text-bold" style="color: #19397a">PEACOCK</div>
                <img src="assets/images/Page 22/PAGE-22-Age-32peacock.jpg" />
                <div class="text-center text-bold" style="color: #19397a">INFLUENTIAL - INSPIRING</div>
            </div>

        </div>
        <div class="flex-col1" style="margin-top: 20px;">
            <div class="flex-row">
                <div class="float-left">
                    <div class="flex-col-head">EXTREME</div>
                    <div class="flex-col-body">
                        <img *ngIf="data.dataMap.page22.outer == '2' && data.dataMap.page22.outer_sub == '1'" src="assets/images/Page 22/tick.jpg" />
                    </div>
                </div>
                <div class="float-left">
                    <div class="flex-col-head">PROMINANT</div>
                    <div class="flex-col-body" style="border-left:0">
                        <img *ngIf="data.dataMap.page22.outer == '2' && data.dataMap.page22.outer_sub == '2'" src="assets/images/Page 22/tick.jpg" />
                    </div>
                </div>
            </div>
            <div class="flex-row">
                <div class="float-left">
                    <div class="flex-col-head">MODERATE</div>
                    <div class="flex-col-body">
                        <img *ngIf="data.dataMap.page22.outer == '2' && data.dataMap.page22.outer_sub == '3'" src="assets/images/Page 22/tick.jpg" />
                    </div>
                </div>
                <div class="float-left">
                    <div class="flex-col-head">MARGINAL</div>
                    <div class="flex-col-body" style="border-left:0">
                        <img *ngIf="data.dataMap.page22.outer == '2' && data.dataMap.page22.outer_sub == '4'" src="assets/images/Page 22/tick.jpg" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="mt-20 flex-row">
        <div class="flex-col1" style="margin-top: 20px;">
            <div class="flex-row">
                <div class="float-left" >
                    <div class="flex-col-head">EXTREME</div>
                    <div class="flex-col-body">
                        <img *ngIf="data.dataMap.page22.outer == '3' && data.dataMap.page22.outer_sub == '1'" src="assets/images/Page 22/tick.jpg" />
                    </div>
                </div>
                <div class="float-left">
                    <div class="flex-col-head">PROMINANT</div>
                    <div class="flex-col-body" style="border-left:0">
                        <img *ngIf="data.dataMap.page22.outer == '3' && data.dataMap.page22.outer_sub == '2'" src="assets/images/Page 22/tick.jpg" />
                    </div>
                </div>
            </div>
            <div class="flex-row">
                <div class="float-left">
                    <div class="flex-col-head">MODERATE</div>
                    <div class="flex-col-body">
                        <img *ngIf="data.dataMap.page22.outer == '3' && data.dataMap.page22.outer_sub == '3'" src="assets/images/Page 22/tick.jpg" />
                    </div>
                </div>
                <div class="float-left">
                    <div class="flex-col-head">MARGINAL</div>
                    <div class="flex-col-body" style="border-left:0">
                        <img *ngIf="data.dataMap.page22.outer == '3' && data.dataMap.page22.outer_sub == '4'" src="assets/images/Page 22/tick.jpg" />
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-col1" style="margin-right: 10px;">
            <div class="icon-wrapper">
                <div class="text-center text-bold" style="color: #19397a">OWL</div>
                <img src="assets/images/Page 22/PAGE-22-Age-32owl.jpg" />
                <div class="text-center text-bold" style="color: #19397a">COMPLIANT - CORRECT</div>
            </div>
        </div>
        <div class="flex-col1" style="margin-left: 10px; margin-right: 15px;">
            <div class="icon-wrapper">
                <div class="text-center text-bold" style="color: #19397a">DOVE</div>
                <img src="assets/images/Page 22/PAGE-22-Age-32dove.jpg" />
                <div class="text-center text-bold" style="color: #19397a">STEADY - STABLE</div>
            </div>

        </div>
        <div class="flex-col1" style="margin-top: 20px;">
            <div class="flex-row">
                <div class="float-left">
                    <div class="flex-col-head">EXTREME</div>
                    <div class="flex-col-body">
                        <img *ngIf="data.dataMap.page22.outer == '4' && data.dataMap.page22.outer_sub == '1'" src="assets/images/Page 22/tick.jpg" />
                    </div>
                </div>
                <div class="float-left">
                    <div class="flex-col-head">PROMINANT</div>
                    <div class="flex-col-body" style="border-left:0">
                        <img *ngIf="data.dataMap.page22.outer == '4' && data.dataMap.page22.outer_sub == '2'" src="assets/images/Page 22/tick.jpg" />
                    </div>
                </div>
            </div>
            <div class="flex-row">
                <div class="float-left">
                    <div class="flex-col-head">MODERATE</div>
                    <div class="flex-col-body">
                        <img *ngIf="data.dataMap.page22.outer == '4' && data.dataMap.page22.outer_sub == '3'" src="assets/images/Page 22/tick.jpg" />
                    </div>
                </div>
                <div class="float-left">
                    <div class="flex-col-head">MARGINAL</div>
                    <div class="flex-col-body" style="border-left:0">
                        <img *ngIf="data.dataMap.page22.outer == '4' && data.dataMap.page22.outer_sub == '4'" src="assets/images/Page 22/tick.jpg" />
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
