<div class="forth-page mt-40">
  <div class="ml-40">
    <div class="section4">
      <tr>
        <td>
          <table width="100%" border="0" cellspacing="0" cellpadding="0">

            <tr>
                <td align="right" class="full-width-image">
                  <img class="logo-img" src="assets/images/Header.png" />
                </td>
            </tr>

            <tr>
              <td style="padding:0px 0px 0px; line-height:0px;" class="">
                <table cellspacing="0" cellpadding="0" width="100%" border="0">
                  <tr>

                    <td width="100%" align="left" valign="top" style="line-height:0px; ">
                      <table width="100%" border="0" cellspacing="0" cellpadding="0">
                        <tr>
                          <td align="center">
                            <table border="0" cellspacing="0" cellpadding="0">
                              <tbody>
                                <tr>
                                  <td align="center" valign="top" bgcolor="#ffffff"
                                    class="page4-main-head"><i> Character Is, For The Most Part, <br />
                                      Simply Habit Become Fixed. <br />
                                      You Cannot Dream Yourself Into A Character, <br/>
                                      You Must Hammer & Forge Yourself One.</i></td>
                                </tr>
                              </tbody>
                            </table>
                          </td>

                        </tr>
                        <tr>
                          <td bgcolor="#ffffff" align="center">
                            <table width="100%" border="0" cellspacing="0" cellpadding="0" style="width: 100%; margin-top: 10px;">
                              <tbody>
                                <tr>
                                  <td width="100%" align="center" valign="top" class="page4-head">THIS
                                    BRAINOGRAPHY REPORT WILL HELP YOU TO INDENTIFY YOUR TRUE CHARACTER</td>
                                </tr>
                              </tbody>
                            </table>
                          </td>

                        </tr>
                        <tr>
                          <td height="5">&nbsp;</td>
                        </tr>
                        <tr>
                          <td align="center" valign="top">
                            <table width="100%" border="0" cellspacing="0" cellpadding="0" style="width: 100%;">
                              <tbody>
                                <tr>
                                  <td class="page4-subhead">A person's character is what he really is. It is
                                    his real moral condition. It can be defined as 'the distinctive mark of
                                    an individual' which depends partly upon inborn qualities and partly on
                                    nurturing and the circumstances in which a person has been brought up.
                                    <strong>"Sow an act & you reap a habit. Sow a habit & you a reap a
                                      character."</strong> Character can be cultivated by forming good habits.
                                    If we sow good habits, we build up a good character. Nature of a person
                                    can hardly be changed, but habits can be transformed
                                </tr>
                              </tbody>
                            </table>
                          </td>

                        </tr>
                        <tr>
                          <td height="1" bgcolor="#ffffff" style="line-height: 0px;">&nbsp;</td>
                        </tr>
                        <tr>
                          <td align="center" valign="top">
                            <table width="100%" border="0" cellspacing="0" cellpadding="0" style="width: 100%;">
                              <tbody>
                                <tr>
                                  <td class="page4-subhead-20">LET'S BEGIN THE JOURNEY TOWARDS MAPPING YOUR
                                    CHARACTER.
                                </tr>
                              </tbody>
                            </table>
                          </td>

                        </tr>

                        
                        <tr>
                          <td align="center" valign="top">
                            <table width="100%" border="0" cellspacing="0" cellpadding="0" style="width: 100%;">
                              <tbody>
                                <tr>
                                  <td class="page4-subhead-20" style="color: #32b5bf;">CONTENTS OF THE REPORT
                                </tr>
                              </tbody>
                            </table>
                          </td>

                        </tr>
                        <tr>
                          <td width="100%" align="center" valign="top" style="padding: 0px 0px">
                            <table width="100%" align="center" cellpadding="0" cellspacing="0"
                              border="0" class="">


                              <tr>
                                <td align="left" valign="top" style="padding-bottom: 10px; padding-right: 0px;">
                                  <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                    <tbody>
                                      <tr>
                                        <td height="40" bgcolor="#ebebeb" align="center" valign="top"
                                          width="33.33%" class="full ">
                                          <table style="width:100%;" cellspacing="0" cellpadding="0" width="100%"
                                            border="0">
                                            <tbody>

                                              <tr>
                                                <td class="Page4-bxt1" align="center" valign="top" height="22">
                                                  Ages 1, 2 & 3 </td>
                                              </tr>
                                              <tr>
                                                <td class="page4-bx1padd" style="" align="center" valign="top"
                                                  height="22">Page no. 5-6 </td>
                                              </tr>

                                              <tr>
                                                <td class="page4-paddbx12" valign="top" height="22"
                                                  align="center">Most preferrd input method
                                                  of your brain. Explore the stren- gth &
                                                  limitations of your
                                                  skill- sets. </td>
                                              </tr>

                                            </tbody>
                                          </table>
                                        </td>
                                        <td height="1" width="1%" bgcolor="#ffffff"></td>
                                        <td height="40" bgcolor="#ebebeb" align="center" valign="top"
                                          width="32.33%">
                                          <table style="width:100%;" cellspacing="0" cellpadding="0" width="100%"
                                            border="0">
                                            <tbody>

                                              <tr>
                                                <td class="Page4-bxt1" align="center" valign="top" height="22">
                                                  Ages 4 & 5 </td>
                                              </tr>
                                              <tr>
                                                <td class="page4-bx1padd" style="" align="center" valign="top"
                                                  height="22">Page no. 7-9</td>
                                              </tr>

                                              <tr>
                                                <td class="page4-paddbx12" valign="top" height="22" align="center"
                                                  height="22">Your most preferred school board.
                                                  Your best learning
                                                  methods & acquiring styles. </td>
                                              </tr>

                                            </tbody>
                                          </table>
                                        </td>
                                        <td height="1" width="1%" bgcolor="#ffffff"></td>
                                        <td height="40" bgcolor="#ebebeb" align="center" valign="top"
                                          width="32.33%">
                                          <table style="width:100%;" cellspacing="0" cellpadding="0" width="100%"
                                            border="0">
                                            <tbody>

                                              <tr>
                                                <td class="Page4-bxt1" align="center" valign="top" height="22">
                                                  Ages 6 to 12 </td>
                                              </tr>
                                              <tr>
                                                <td class="page4-bx1padd" style="" align="center" valign="top"
                                                  height="22">Page no. 10-13 </td>
                                              </tr>

                                              <tr>
                                                <td class="page4-paddbx12" valign="top" height="22"
                                                  align="center"><table style="width:100%;" cellspacing="0" cellpadding="0" width="100%"
                                                  border="0"> <table style="width: 97%;">Discover your passions. Identify&nbsp;your
                                                  hobbies &
                                                  orientations of how you
                                                  approach&nbsp;to them </table></table></td>
                                              </tr>

                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>

                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td align="left" valign="top" style="padding-bottom: 10px; padding-right: 0px;">
                                  <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                    <tbody>
                                      <tr>
                                        <td height="40" bgcolor="#ebebeb" align="center" valign="top"
                                          width="33.33%" class="full ">
                                          <table style="width:100%;" cellspacing="0" cellpadding="0" width="100%"
                                            border="0">
                                            <tbody>

                                              <tr>
                                                <td class="Page4-bxt1" align="center" valign="top" height="22">
                                                  Ages 13 to 15</td>
                                              </tr>
                                              <tr>
                                                <td class="page4-bx1padd" style="" align="center" valign="top"
                                                  height="22">Page no. 14-19 </td>
                                              </tr>

                                              <tr>
                                                <td class="page4-paddbx12" valign="top" height="22"
                                                  align="center">Your most preferred stream
                                                  after grade 10. Select the
                                                  right career & IKAIGAI the
                                                  purpose of your life</td>
                                              </tr>

                                            </tbody>
                                          </table>
                                        </td>
                                        <td height="1" width="1%" bgcolor="#ffffff"></td>
                                        <td height="40" bgcolor="#ebebeb" align="center" valign="top"
                                          width="32.33%">
                                          <table style="width:100%;" cellspacing="0" cellpadding="0" width="100%"
                                            border="0">
                                            <tbody>

                                              <tr>
                                                <td class="Page4-bxt1" align="center" valign="top" height="22">Age
                                                  22 Onwards </td>
                                              </tr>
                                              <tr>
                                                <td class="page4-bx1padd" style="" align="center" valign="top"
                                                  height="22">Page no.20-21</td>
                                              </tr>

                                              <tr>
                                                <td class="page4-paddbx12" valign="top" height="22" align="center"
                                                  height="22">Yor professional life. Your
                                                  professional Quadrants,
                                                  your forte & your personal
                                                  quotients. </td>
                                              </tr>

                                            </tbody>
                                          </table>
                                        </td>
                                        <td height="1" width="1%" bgcolor="#ffffff"></td>
                                        <td height="40" bgcolor="#ebebeb" align="center" valign="top"
                                          width="32.33%">
                                          <table style="width:100%;" cellspacing="0" cellpadding="0" width="100%"
                                            border="0">
                                            <tbody>

                                              <tr>
                                                <td class="Page4-bxt1" align="center" valign="top" height="22">Age
                                                  32 Onwards </td>
                                              </tr>
                                              <tr>
                                                <td class="page4-bx1padd" style="" align="center" valign="top"
                                                  height="22">Page no. 22-29 </td>
                                              </tr>

                                              <tr>
                                                <td class="page4-paddbx12" valign="top" height="22"
                                                  align="center"><table style="width:100%;" cellspacing="0" cellpadding="0" width="100%"
                                                  border="0"> <table style="width: 98%;padding-right: 7px;
                                                   ">Your outer and Your inner
                                                  personality. Your persona- lity
                                                  meter. Know the featu- res of your character.</table></table></td>
                                              </tr>

                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>

                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td align="left" valign="top" style="padding-bottom: 10px; padding-right: 0px;">
                                  <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                    <tbody>
                                      <tr>
                                        <td height="40" bgcolor="#ebebeb" align="center" valign="top"
                                          width="33.33%" class="full ">
                                          <table style="width:100%;" cellspacing="0" cellpadding="0" width="100%"
                                            border="0">
                                            <tbody>

                                              <tr>
                                                <td class="Page4-bxt1" align="center" valign="top" height="22">
                                                  Your Innate Potential</td>
                                              </tr>
                                              <tr>
                                                <td class="page4-bx1padd" style="" align="center" valign="top"
                                                  height="22">Page no. 30-32 </td>
                                              </tr>

                                              <tr>
                                                <td class="page4-paddbx12" valign="top" height="22"
                                                  align="center"><table style="width:100%;" cellspacing="0" cellpadding="0" width="100%"
                                                  border="0"> <table style="width: 98%;
                                                  
                                                  padding-right: 7px;">Your brain-balance, your character facets &
                                                  your 10
                                                  Lobes Brainography.</table></table> </td>
                                              </tr>

                                            </tbody>
                                          </table>
                                        </td>
                                        <td height="1" width="1%" bgcolor="#ffffff"></td>
                                        <td height="40" bgcolor="#ebebeb" align="center" valign="top"
                                          width="32.33%">
                                          <table style="width:100%;" cellspacing="0" cellpadding="0" width="100%"
                                            border="0">
                                            <tbody>

                                              <tr>
                                                <td class="Page4-bxt1" align="center" valign="top" height="22">
                                                  Understanding Brain Lobes</td>
                                              </tr>
                                              <tr>
                                                <td class="page4-bx1padd" style="" align="center" valign="top"
                                                  height="22">Page no. 33-42</td>
                                              </tr>

                                              <tr>
                                                <td class="page4-paddbx12" valign="top" height="22" align="center"
                                                  height="22"><table style="width:100%;" cellspacing="0" cellpadding="0" width="100%"
                                                  border="0"> <table style="width: 98%;
                                                  
                                                  padding-right: 7px;">Features of all the 10 Lobes
                                                  of the Brain. Pros & cons of
                                                  strength. Remedi- es for
                                                  limitations </table></table></td>
                                              </tr>

                                            </tbody>
                                          </table>
                                        </td>
                                        <td height="1" width="1%" bgcolor="#ffffff"></td>
                                        <td height="40" bgcolor="#ebebeb" align="center" valign="top"
                                          width="32.33%">
                                          <table style="width:100%;" cellspacing="0" cellpadding="0" width="100%"
                                            border="0">
                                            <tbody>

                                              <tr>
                                                <td class="Page4-bxt1" align="center" valign="top" height="22">
                                                  Understanding Fingerprints </td>
                                              </tr>
                                              <tr>
                                                <td class="page4-bx1padd" style="" align="center" valign="top"
                                                  height="22">Page no. 43-44 </td>
                                              </tr>

                                              <tr>
                                                <td class="page4-paddbx12" valign="top" height="22"
                                                  align="center"><table style="width:100%;" cellspacing="0" cellpadding="0" width="100%"
                                                  border="0"> <table style="width: 98%;
                                                  
                                                  padding-right: 7px;">The origin & science of
                                                  fingerprints. Fingerprint
                                                  types and their basic
                                                  characteristics.</table></table></td>
                                              </tr>

                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>

                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td align="left" valign="top" style="padding-bottom: 5px; padding-right: 0px;">
                                  <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                    <tbody>
                                      <tr>
                                        <td height="40" bgcolor="#ebebeb" align="center" valign="top"
                                          width="33.33%" class="full ">
                                          <table style="width:100%;" cellspacing="0" cellpadding="0" width="100%"
                                            border="0">
                                            <tbody>

                                              <tr>
                                                <td class="Page4-bxt1" align="center" valign="top" height="22">
                                                  Health Symptoms</td>
                                              </tr>
                                              <tr>
                                                <td class="page4-bx1padd" style="" align="center" valign="top"
                                                  height="22">Page no. 45</td>
                                              </tr>

                                              <tr>
                                                <td class="page4-paddbx12" valign="top" height="22"
                                                  align="center"><table style="width:100%;" cellspacing="0" cellpadding="0" width="100%"
                                                  border="0"> <table style="width: 98%;padding-right: 7px;
                                                   ">Probability of diseases
                                                  identified by the fingerprint
                                                  patterns & precautions. </table></table></td>
                                              </tr>

                                            </tbody>
                                          </table>
                                        </td>
                                        <td height="1" width="1%" bgcolor="#ffffff"></td>
                                        <td height="40" bgcolor="#ebebeb" align="center" valign="top"
                                          width="32.33%">
                                          <table style="width:100%;" cellspacing="0" cellpadding="0" width="100%"
                                            border="0">
                                            <tbody>

                                              <tr>
                                                <td class="Page4-bxt1" align="center" valign="top" height="22">
                                                  Brainography Invitation </td>
                                              </tr>
                                              <tr>
                                                <td class="page4-bx1padd" style="" align="center" valign="top"
                                                  height="22">Page no. 46-48</td>
                                              </tr>

                                              <tr>
                                                <td class="page4-paddbx12" valign="top" height="22" align="center"
                                                  height="22"><table style="width:100%;" cellspacing="0" cellpadding="0" width="100%"
                                                  border="0"> <table style="width: 98%;
                                                  
                                                  padding-right: 7px;">Brainography vision &
                                                  mission. Invitation to
                                                  become a Brainographer.</table></table></td>
                                              </tr>

                                            </tbody>
                                          </table>
                                        </td>
                                        <td height="1" width="1%" bgcolor="#ffffff"></td>
                                        <td height="40" bgcolor="#ebebeb" align="center" valign="top"
                                          width="32.33%">
                                          <table style="width:100%;" cellspacing="0" cellpadding="0" width="100%"
                                            border="0">
                                            <tbody>

                                              <tr>
                                                <td class="Page4-bxt1" align="center" valign="top" height="22">
                                                  Your Feedback </td>
                                              </tr>
                                              <tr>
                                                <td class="page4-bx1padd"  align="center" valign="top"
                                                  height="22">Page no. 49 </td>
                                              </tr>

                                              <tr>
                                                <td class="page4-paddbx12" valign="top" height="22"
                                                  align="center"><table style="width:100%;" cellspacing="0" cellpadding="0" width="100%"
                                                  border="0"> <table style="width: 90%;">This Science is still evolv- ing.
                                                  Give your valuable feedback
                                                  & volunteer for further
                                                  research.</table></table> </td>
                                              </tr>

                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>

                                    </tbody>
                                  </table>
                                </td>
                              </tr>

                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td height="1" bgcolor="#ffffff" style="line-height: 0px;">&nbsp;</td>
                        </tr>

                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>


          </table>
        </td>
      </tr>

      <div>
        <div class="footer-elem">
          Evaluation# {{ data.dataMap.footer.evalNo }}
        </div>
        <div class="footer-elem2 text-left">
          Date {{ data.dataMap.footer.date }}
        </div>
        <div class="footer-elem1">
          @Copyright - Siyasaar Enterprise
        </div>
        <div class="footer-elem">
          www.brainography.com
        </div>
        <div class="footer-elem">
          Design By Freepik
        </div>

        <div class="clear-both"></div>
      </div>

      <div class="text-center">
        4
      </div>
    </div>
  </div>
</div>
<div class="clear-both"></div>