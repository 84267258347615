<div class="forty-eight-page mt-40">
    <div class="text-right">
        <img class="logo-img" src="assets/images/Header.png" />
    </div>

    <div class="mt-10 blue-header">
        VISION
    </div>

    <div style="padding: 8px 20px;">
        <div class="mt-5 text-center text-bold">
            <span style="text-decoration: underline;">'To Help You Discover Your True Passion and Define Your Character.'</span>
        </div>
        <div class="mt-5">
            PASSION plays an important role in quantifying the depth of your potential, and your propensity to give optimum performance. PASSION is the powerful flow of energy within you when you focus on what you love to do. Furthermore, converting your 'Passion' into a 'Profession' brings an unshakeable state of peace and fulfillment in your life. It proves to be critically important to your happiness and emotional well-being.
        </div>
        <div class="mt-5">
            CHARACTER plays an essential role in your Personality Development. It is the amalgamation of your individual qualities such as 'temperament', 'essence', 'psyche', 'traits' etc., which makes you unique and differentiates you from the rest. 
        </div>
        <div class="mt-5 text-bold">
            "Enlightenment Is The Discovery Of A Whole Dimension Of Your True Self, You Had Never Experienced Before." 
        </div>
        <div class="mt-5">
            Our vision is to show you the mirror of your true self by the scientific method- 'BRAINOGRAPHY'. Much has been said and written on the methods of motivating, engaging, and bringing out the best in others. However, 'BRAINOGRAPHY' is the most scientific and notably accurate when compared to others, since it's based on 'Dermatoglyphics'- A medical study of Fingerprints. The inexpressible knowledge that you gain by knowing your Self is so astonishingly clear, certain, and revealing that it blows your mind and shatters all your previous ideas and conceptions based on ignorance, revealing the truth about yourself. 
        </div>
    </div>
    

    <div class="mt-10 blue-header">
        MISSION
    </div>
    <div style="padding: 8px 20px;">
        <div class="mt-5 text-center text-bold">
            <span style="text-decoration: underline;">'Academy Of Brainography' — AOB, Is Committed To The Improvement Of Human Lives.</span>
        </div>
        <div class="mt-5">
            Our mission is to positively impact as many people and organizations in the world through 'BRAINOGRAPHY'. Our goal is to enrich people's lives through scientific evaluation of their strengths and limitations. We wish to enlighten people towards recognizing their thought patterns and the social evils caused by 'Depression', 'Stress', and 'Anxiety' through the study of their fingerprints, providing effective remedial measures to eradicate them. ss
        </div>
    </div>
    

    <div class="mt-10 blue-header">
        INVITATION 
    </div>
    <div style="padding: 8px 20px;">
        <div class="mt-5 text-center text-bold">
            <span style="text-decoration: underline;">We Invite Like-Minded Individuals To Join Us In Our Mission</span>
        </div>  
        <div class="mt-5">
            Be a 'Brainographer' and Spread The Power Of 'BRAINOGRAPHY'. Join our mission to create transformational change in our society through delivering the best research from the field of human psychology based on the science of 'Dermatoglyphics'. No qualification except fluency in the English language is required. Our delivery of value includes a Formal training, One-month internship, Study materials, One-on-One coaching, and Hand-holding support for practicing counseling 
        </div>
    </div>
    

    <div class="mt-10 disc-bg">
        <div class="text-center" style="font-weight: bold; font-size: 14px;">GENERAL DISCLAIMER</div>

        <div class="mt-10">
            You are permitted to read and download the data for subsequent viewing purpose or only for personal and non-commercial use. You may not share or reproduce any part of this report by any means without our written permission. Unauthorized access, redistribution, transmission and dealing with any information contained in this report, re- production in any other manner, either in whole or in part, is strictly prohibited, failing which legal action will be initiated against such users. All the date, information including assessment tests and reports, software to operate it, trademarks, logos and other intellectual property used in connection with this report are our intellectual property and hence any infringement would be liable for civil action. 
        </div>
        <div class="mt-10">
            We neither ensure the accuracy of the information provided in the report nor the findings in the report. We do not warrant the reliability of the findings in the report. We reserve the right to make changes to the information provided during the service, or to alter the nature of the service at our discretion. You are therefore advised to check the information provided to you through our service as any reliance that you place on the accuracy of that information is at your own risk. The information provided herein is not guaranteed or warranted to produce any particular result and the advice herein may not be suitable for every individual. We are not responsible for any consequences under any circumstance. The results provided in the report is only indicative and we shall in no case be liable for failure in any particular course or activity recommended in the report or any important decisions taken based on this report. We will not be liable under any circumstances whatsoever for any direct, indirect, consequential, punitive or special damages arising from your use of our service. 
        </div>
    </div>

   

</div>